.ant-menu-submenu-selected{
  color: rgb(0, 0, 0) !important;
}
@media screen and (max-width: 800px) {
  .nav-wrapper-desktop {
    display: none;
  }

  .navigation-wrapper {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 75px;
    display: flex;
    justify-content: center;
    background-color: #ffffff;
    z-index: 3;
  }

  .navigation-menu {
    height: 100%;
    width: 90px;
    cursor: pointer;
  }

  // .active-navigation-tab {
  //   font-size: 14px;
  //   font-family: "Gilroy ExtraBold";
  // }

  .nav-icon {
    padding-top: 15px;
  }
}

@media screen and (min-width: 320px) and (max-width: 786px) {
  .navigation-wrapper {
    box-shadow: 0px 2px 8px rgb(0 0 0 / 15%);
  }
  .navigation-menu {
    .navigation-text {
      margin-top: 4px;
      color: #00000040;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 17px;
      text-align: center;
      font-family: var(--primary-font-family);
        font-weight: 700;
    }
    .nav-icon {
      position: relative;
      padding-top: 15px;
      font-size: 22px;
      color: #00000040;

      img {
        position: absolute;
        left: 50%;
        top: 30%;
      }
    }
  }
}

@media screen and (min-width: 800px) {
  .nav-wrapper-desktop {
    position: relative;
    width: 210px;
    height: 100%;
    justify-content: left;
    background-color: #ffffff;
    box-shadow: 0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%),
      0 1px 3px 0 rgb(0 0 0 / 20%);
    z-index: 11;
    .newTag-banner {
      margin-left: 8px;
      background-color: var(--primary-theme-color);
      color: #ffffff;
      font-size: 12px;
    }
    .arrow-fold-btn {
      position: absolute;
      top: 45%;
      right: -20px;
      width: 20px;
      height: 70px;
      box-shadow: 0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%),
        0 1px 3px 0 rgb(0 0 0 / 20%);
      border: 0.5px solid rgba(0, 0, 0, 0.15);
      background-color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .ant-menu-submenu-title {
      padding-left: 16px !important;
    }
  }

  .navigation-menu {
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 15px;
    &:first-child {
      padding-top: 10px;
    }
  }

  .navigation-wrapper {
    display: none;
  }

  .navigation-text {
    color: #3d3c3a;
    // font-weight: bold;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
    margin-left: 10px;
  }

  .active-navigation-tab {
    font-size: 14px;
    //font-family: 'Gilroy ExtraBold';
  }

  .nav-icon {
    //padding-top: 25px;
    img {
      width: 28px;
      height: 28px;
    }
    i {
      font-size: 15px;
    }
  }
  #logout-icon {
    width: 100%;
    bottom: 0;
    // position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .logo-logoutText {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
