.hotline-download-container {
    background: #FDFAF9;
    padding: 90px 32px;
    position: relative;
}

.hotline-upper-section {
    display: flex;
    position: relative;
    align-items: flex-end;
}

.hero-left-section, .hero-right-section {
    flex: 1;
}

.highlighter-text {
    color: #000;
    font-family: "Unbounded";
    font-size: 96px;
    font-style: normal;
    font-weight: 900;
    line-height: 64px;
    margin-top: 28px;
}

.hero-left-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.download-hotline-btn {
    border-radius: 8px !important;
    border: 1px #4B5265 !important;
    background: #1D5FFF !important;
    height: 52px !important;
    padding: 12px !important;
    width: 300px;
    margin-top: 20px;
}

.download-bg-indicator {
    background: #F3E4F7 !important;
    position: relative;
}

.download-hotline-download-indicator {
    position: absolute;
    height: 100%;
    background: #1D5FFF !important;
    border-radius: 4px;
    top: 0;
    left: 0;
}

.download-hotline-text {
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; 
}

.daily-call-attempts {
    color: #000;
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 35px;
    margin-top: 36px;
    text-align: start;
}

.hotline-description {
    color: #000;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 44px;
    margin-top: 20px;
    text-align: start;
}

.encryption-highlighting-container {
    display: flex;
    margin-top: 28px;
    align-items: center;
    gap: 12px;
    color: #1D5FFF;

font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 36px;
}

.background-picture {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 653px;
    object-fit: contain;
    z-index: 0;
}

.hotline-testimonial-section {
    border-radius: 10px;
    border: 1px solid #5F5F5F;
    background: #FFF;
    padding: 12px 16px;
    display: flex;
    flex-direction: column;
    z-index: 1;
    position: relative;
    margin-top: 60px;
}

.testimonial-header {
    color: #000;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    text-align: start;
    margin-top: 14px;
}

.testimonial-content {
    display: flex;
    align-items: center;
    margin-top: 0px;
    gap: 36px;
}

.testimonial {
    display: flex;
    align-items: flex-start;
}

.name-and-comment {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}

.testimonial-name {
    color: #000;

font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 900;
text-align: start;
}

.testimonial-content-text {
    color: #000;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    text-align: start;
}

.hotline-hero-img {
    width: 100%;
    object-fit: contain;
}

.hotline-call-icon {
    width: 62px;
    object-fit: contain;
}

.post-download-instructions-container {
    border-radius: 10px;
    border: 1px solid #5F5F5F;
    background: #FFF;
    display: flex;
    flex-direction: column;
    max-width: 560px;
    margin: 0 auto;
    padding: 42px 32px;
}

.back-text-styles {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}

.install-unknown-apps {
    color: #000;
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    margin-top: 100px;
}

.chrome-icon-size {
    width: 96px;
    object-fit: contain;
    margin: 42px auto 0;
}

.chrome-text {
    color: #000;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    margin-top: 24px;
}

.chrome-version {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-top: 12px;
}

.allow-from-source-container {
    margin-top: 36px;
}

.allow-from-source {
    color: #000;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    display: flex;
}

.detail-info-container {
    display: flex;
    align-items: flex-start;
    margin-top: 24px;
}

.unknown-source-desc {
    color: #606060;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-align: start;
    margin-left: 6px;
}

.back-button-section {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.back-btn-mobile {
    display: none;
}

.toggle-image { 
    display: none;
}

@media only screen and (min-width: 320px) and (max-width: 786px) {

    .back-btn-mobile {
        display: flex !important;
        align-items: center;
    }
    .back-button-section {
        display: none;
    }

    .hotline-upper-section {
        flex-direction: column-reverse;
        align-items: flex-start;
    }

    .hotline-hero-img {
        width: 75%;
        object-fit: contain;
    }

    .hotline-download-container {
        padding: 40px 24px 136px;
    }

    .hotline-call-icon {
        width: 32px;
    }

    .highlighter-text {
        color: #000;
        font-family: "Unbounded";
        font-size: 54px;
        font-style: normal;
        font-weight: 900;
        line-height: 44px;
        margin-top: 16px;
    }

    .daily-call-attempts {
        color: #000;
        font-family: Inter;
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: 35px;
        margin-top: 28px;
        text-align: start;
    }

    .hotline-description {
        color: #000;
        font-family: Inter;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        margin-top: 8px;
        text-align: start;
    }

    .encryption-highlighting-container {
        display: flex;
        margin-top: 18px;
        align-items: center;
        grid-gap: 12px;
        gap: 12px;
        color: #1D5FFF;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 36px;
    }

    .download-hotline-btn {
        height: 48px !important;
    }

    .hotline-testimonial-section {
        margin-top: 36px;
    }

    .testimonial-header {
        margin-top: 14px;
    }

    .testimonial-content {
        flex-direction: column;
        margin-top: 16px;
        gap: 32px;
        padding-bottom: 32px;
        align-items: flex-start;
    }

    .post-download-instructions-container {
        margin-top: 60px;
        padding: 48px 24px;
    }

    .install-unknown-apps {
        font-size: 24px;
        margin-top: 14px;
    }

    .chrome-icon-size {
        width: 70px;
        object-fit: contain;
        margin: 32px auto 0;
    }

    .chrome-text {
        font-size: 26px;
    }

    .allow-from-source-container {
        margin-top: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .allow-from-source {
        font-size: 16px;
    }

    .unknown-source-desc {
        font-size: 14px;
    }

    .toggle-image {
        width: 72px;
        object-fit: contain;
        display: flex;
    }

    .background-picture { 
        width: 100%;
        bottom: 76px;
    }

    .hero-left-section {
        margin-top: 32px;
    }
}
