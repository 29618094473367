.universal-topbar-main-container {
    padding: 12px 20px;
    box-shadow: 0px 0px 4px 0px rgba(41, 91, 209, 0.20), 0px 0px 16px 0px rgba(41, 91, 209, 0.08);
    background-color: #FFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 12;
}

.topbar-searchbox {
    border-radius: 4px !important;
    background: #F6F9FF !important;
    width: 240px !important;
    padding: 4px 8px !important;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

    
.popover-profile-image-style {
    height: 24px;
    width: 24px;
    background: #1d5fff;
    border-radius: 50%;
}

.continue-to-old-theme-btn-styles {
    border-radius: 8px !important;
    background-color: #ff4545 !important;
    border: 1px solid #0d2455 !important;
}

.topbar-searchbox > .ant-input {
    background: #F6F9FF !important;
}

.topbar-right-section {
    display: flex;
    align-items: center;
    gap: 20px;
}

.transition-image {
    width: 50%;
}

.visibility-mobile {
    display: none !important;
}

.visibility-desktop {
    display: flex !important;
}

.no-margin {
    margin: 0 !important;
}

.topbar-right-section > .ant-popover > .ant-popover-content > .ant-popover-inner {
    border-radius: 8px !important;
}

.topbar-right-section {
    .ant-popover-content {
        width: 208px !important;
    }
    .ant-popover-inner {
        border-radius: 8px !important;

        .ant-popover-inner-content {
            padding: 16px 12px !important;
        }
    }
}

.notification-dot-indicator {
    height: 12px;
    width: 12px;
    background: #e79f1a;
    border-radius: 50%;
    position: absolute;
    top: -1px;
    right: -4px;
}

@media screen and (max-width: 800px) {
    .visibility-mobile {
        display: flex !important;
    }

    .visibility-desktop {
        display: none !important;
    }

    .topbar-right-section {
        gap: 16px;
    }

    .transition-image {
        width: 90%;
    }
}