.card-container {
  width: auto;
  .desktop-only {
    display: block;
  }
  .mobile-only {
    display: none;
  }
  .margin-bottom-15px {
    margin: 0px auto 15px auto !important;
  }
  .margin-right-15px {
    margin: auto 15px auto 0px !important;
  }
  .margin-right-70px {
    margin: auto 70px auto 0px !important;
  }
  svg {
    margin-top: -5px;
  }
  display: flex;
  flex-direction: row !important;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 12px;
  .right-card-info {
    display: flex;
    flex-direction: row;
  }
  .name-container {
    display: flex;
    flex-direction: column;
    margin: auto;
  }
  .total-f10 {
    background: #f6ffed;
    border: 1px solid #52c41a;
    border-radius: 4px;
    font-size: 11px;
    margin: auto;
    margin-right: 20px;
    width: 180px;
    pointer-events: none;
    text-align: left;
    display: flex;
    flex-direction: row;
  }
  .total-activation {
    background: #f0f0f0;
    border: 1px solid #595959;
    border-radius: 4px;
    font-size: 12px;
    margin: auto;
    width: 180px;
    pointer-events: none;
    text-align: left;
    display: flex;
    flex-direction: row;
  }
  .trips-heading{
    width: 50%;
    margin: auto;
  }
  .trips-number-leaderboard{
    text-align: right;
    width: 50%;
    font-weight: bold;
    margin: auto;
  }
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .card-container {
    .desktop-only {
      display: none;
    }
    .mobile-only {
      display: block;
    }
    display: flex;
    flex-direction: column !important;
    width: 100%;
    margin-top: 0px;
    margin-bottom: 12px;
    .right-card-info {
      display: flex;
      flex-direction: row;
      margin-bottom: 15px;
    }
    .name-container {
      display: flex;
      flex-direction: column;
      margin: 0px;
    }
    .total-f10 {
      background: #f6ffed;
      border: 1px solid #52c41a;
      border-radius: 4px;
      font-size: 11px;
      margin: auto;
      margin-right: 20px;
      width: 130px;
      padding-left: 5px !important;
    }
    .total-activation {
      background: #f0f0f0;
      border: 1px solid #595959;
      border-radius: 4px;
      font-size: 11px;
      margin: auto;
      width: 150px;
      padding-left: 5px !important;
    }
  }
}
