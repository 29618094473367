.bulk-actions-new-parent {
    height: 100%;
    width: 100%;

    .ant-divider-horizontal{
        margin-bottom: 24px !important;
    }

    .padded-section {
        padding: 24px 36px 60px 36px;
        background-color: white;

        .ba-processing-header {
            background-color: rgba(230, 247, 255, 1);
            padding: 12px;
            border-radius: 4px;
            margin-bottom: 2vw;

            .ba-processing-header-txt {
                font-weight: 400;
                font-size: 14px;
                color: rgba(0, 39, 102, 1);
            }
        }
    }

    .vertical-divider {
        width: 1px;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.06);
    }

    .ba-cp {
        cursor: pointer;
    }

    .ba-list-loader {
        margin-top: 2vw;
    }

    .bulk-section {
        width: 45%;
        position: relative;

        .ant-popover-placement-bottom {
            left: -32px !important;
            top: 101px !important;
        }

        .ant-popover-placement-bottom .ant-popover-arrow {
            left: 27%;
        }

        .ant-popover-content {
            width: 60%;
        } 
    }

    .bulk-section-cards {
        height: 24vh;
        min-height: 24vh;
        align-items: flex-start;
    }

    .bulk-section-card {
        margin-bottom: 12px;
        cursor: pointer;
    }

    .bulk-section-card-ai-filtering {
        background: linear-gradient(90deg, var(--primary-theme-color) 0%, var(--primary-blur-color) 100%), #FFFFFF;

        .shine {
            position: absolute;
            width: 58px;
            height: 140px;
            left: -100px;
            background: #FFFFFF29;
            transform: rotate(12deg);
            animation: shine 5s infinite;
            animation-delay: 1s;
        }

        @keyframes shine {
            0% {
                left: -100%;
            }
            100% {
                left: 130%;
            }
        }

        .shine-small-width {
            position: absolute;
            width: 17px;
            height: 140px;
            left: -100px;
            background: #FFFFFF29;
            transform: rotate(12deg);
            animation: shine-small-width 5s infinite;
            animation-delay: 1s;
        }

        @keyframes shine-small-width {
            0% {
                left: -85%;
            }
            100% {
                left: 145%;
            }
        }
    }

    .bulk-section-title {
        font-size: 14;
        color: rgba(0, 0, 0, 0.65);
        font-weight: 400;
        margin-bottom: 3vh;
    }

    .bulk-section-card-img {
        margin-right: 12px;
    }

    .orange-img {
        font-size: 22px;
        color: var(--primary-theme-color);
        font-weight: 800;
    }

    .white-img {
        font-size: 22px;
        color: #fff;
        font-weight: 800;
    }

    .non-orange-img {
        font-size: 18px;
        color: rgba(0, 0, 0, 0.65);
    }

    .bulk-referral-card-heading {
        font-size: 16px;
        font-weight: 600;
    }

    .bulk-referral-orange-txt {
        color: var(--primary-theme-color);
    }

    .bulk-referral-white-txt {
        color: #fff;
        gap: 8px;
        flex-wrap: wrap;
        text-align: start;

        .new-tag {
            background: #fff;
            color: #FF712D;
            font-size: 12px;
            padding: 0 8px;
        }
    }

    .bulk-referral-black-txt {
        color: rgba(0, 0, 0, 0.65);
    }

    .bulk-section-card-heading {
        color: rgba(0, 0, 0, 0.45);
        font-size: 16px;
        font-weight: 600;
    }

    .bulk-section-card-subtitle {
        color: rgba(0, 0, 0, 0.45);
        font-size: 14px;
        font-weight: 400;
        text-align: start;
    }

    .bulk-section-card-subtitle-white {
        color: #FFFFFFA6;

    }

    .flex-dir-col {
        flex-direction: column;
    }

    .bulk-section-card {
        border: 1px solid rgba(0, 0, 0, 0.06);
        border-radius: 4px;
        padding: 12px;
        position: relative;
    }

    .bo-tabs-section {
        margin-top: 10vh;
    }

    .bo-tabs-section-mobile {
        margin-top: 4vh;
    }

    .selected-tab {
        border: 1px solid var(--primary-theme-color);
        color: var(--primary-theme-color);
    }

    .unselected-tab {
        border: 1px solid rgba(217, 217, 217, 1);
    }

    .bo-filters-parent {
        margin-top: 16px;
        padding: 12px 24px;
        background-color: rgba(245, 245, 245, 1);
        border-radius: 12px;
        border: 1px solid rgba(0, 0, 0, 0.06);
    }

    .bo-listing-section {
        .ba-display-block {
            display: block !important;
        }
    }

    @media only screen and (min-width: 320px) and (max-width: 786px) {
        .bo-filters-parent {
            padding: 6px 12px !important;
        }
    }

    .bo-filters-section {
        .apply-filter-btn {
            color: var(--primary-theme-color);
            // background-color: var(--primary-theme-light);
            border: 1px solid var(--primary-theme-color);
            display: flex;
            align-items: center;
            width: 10%;
            justify-content: center;
        }

        .apply-filter-btn-mobile {
            color: var(--primary-theme-color);
            // background-color: var(--primary-theme-light);
            border: 1px solid var(--primary-theme-color);
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .added-filters {
            width: 85%;
            padding: 0px 2px;
            background-color: #FFFFFF;
            border-radius: 6px;
        }

        .single-filter-item {
            background-color: rgba(240, 240, 240, 1);
            padding: 4px
        }
    }

    .ba-uploads-refresh-download-section {
        margin-top: 1.5vw;

        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .ba-total-uploads-txt {
                font-size: 12px;
            }
        }

        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .ba-listing-above-btns {
                width: 75%;
            }
        }

        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .ba-listing-above-refresh-btn {
                width: 40%;
                padding: 2px 4px;
                justify-content: center;
            }
        }

        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .ba-listing-above-download-btn {
                width: 55%;
                padding: 2px 4px;
                justify-content: center;
            }
        }

        .ba-listing-above-download-btn {
            color: var(--primary-theme-color);
            border: 1px solid var(--primary-theme-color);
            margin-left: 12px;
        }
    }

    .bo-filters-section-desktop {
        margin-top: 16px;
    }
}

@media only screen and (min-width: 320px) and (max-width: 786px) {
    .bulk-actions-new-parent .bulk-section-card-ai-filtering {

        .shine {
            width: 35px;
        }
    }
}