.bulk-ivr-parent{
    .display-session {
        font-family: var(--primary-font-family);
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        padding: 20px;
        .date-download {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .download-btn {
            display: flex;
            background-color: #fff7f4;
            align-items: center;
            color: var(--primary-theme-color);
            font-family: var(--primary-font-family);
            font-weight: 500;
            &:hover {
              background-color: #ffffff;
            }
          }
        }
        .select-cardview {
          display: flex;
          flex-direction: column;
          > .ant-space-item {
            width: 100%;
          }
          .list-of-candidates {
            height: 70vh;
            overflow: auto;
          }
          .selectall-btns {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            width: 100%;
            .total-leads {
              font-weight: 700;
              font-size: 12px;
              line-height: 20px;
              font-family: var(--primary-font-family);
              font-weight: 700;
              display: flex;
              align-items: center;
              text-align: right;
              color: #595959;
              border: none;
              border-radius: 20px;
              background-color: #f0f0f0;
              padding: 0 8px;
              span {
                color: #000;
                margin-left: 3px;
              }
            }
          }
          .cardview-wrapper {
            width: 100%;
            margin-left: 1px;
            margin-right: 1px;
            .cardview-space {
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: flex-end;
              .name-number {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                .name {
                  font-family: var(--primary-font-family);
                  font-weight: 500;
                  text-align: left;
                }
                .number {
                  svg {
                    margin-bottom: 6px;
                    font-size: 12px;
                  }
                }
              }
              .city {
                font-family: var(--primary-font-family);
                font-weight: 700;
              }
            }
            .text-status {
              font-family: var(--primary-font-family);
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
            }
          }
        }
      }
}