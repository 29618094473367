// Report page css start

.ReportPage {
  .pre-ob-box-container{
    padding: 0 5px;
    padding-top: 8px !important;
    margin: 10px 0;
    width: 50%;
    border-right: 1px solid #f0f0f0;
  }
  .pre-ob-box-container-bottom{
    padding: 0 5px;
    padding-bottom: 8px !important;
    margin: 8px 0;
    width: 50%;
    .total-referral-container-bottom{
      margin-top: 20px;
      margin-bottom: 0;
      flex-direction: column;
    }
  }
  .content-area{
    .milestone-container {
      display: flex;
      gap: 12px;
      margin: 12px 24px;
      flex-wrap: wrap;
  .individual-milestone {
    display: flex;
    align-items: center;
    gap: 12px;

    background-color: #FFF;

    padding: 4px 8px;

    .dot-indicator {
      height: 12px;
      width: 12px;
      background-color: #CF953F;
      border-radius: 12px;
    }

    .milestone-name {
      color: #00000073;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    }

    .milestone-count {
      color: #000000A6;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    }
  }
}
}
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
    white-space: normal !important;
  }
  .header {
    justify-content: space-between;
  }
  .action-banner-payout-dash {
    background:#FAFAFA;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-family: var(--primary-font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    padding: 8px;
    white-space: pre-line;
    gap: 1.5rem;
    margin-bottom: 8px;

    .tag-description {
      flex:4,;
      display:flex;
      align-items:center;
      gap:5px
    }

    .tag-leads-information {
      flex: 1;
      display:flex;
      align-items: center;
    }
  }
  .Dropdown-control {
    border: none;
    padding: 0;
    background: none;
  }
  .overall-performance {
    margin-right: 25px;
    width: 390px;
    height: fit-content;
    // padding: 0 20%;
  }
  .overal-performance-heading {
    font-family: var(--primary-font-family);
        font-weight: 500;
    display: flex;
    align-items: center;
    font-size: 18px;
    svg {
      margin-bottom: 5px;
    }
    .see-All {
      color: #ff8850;
      font-size: 12px;
    }
  }
  .single-card {
    display: flex;
    border-radius: 2px;
    padding: 0 2%;
    margin: 5px 0;
    font-size: 15px;
    align-items: center;
  }
  .card-left-overall-performance {
    width: 55%;
    text-align: left;
  }

  .chart-container {
    display: flex;
    flex-direction: row;
    margin: 15px 0;
  }
  .single-label-container {
    display: flex;
    flex-direction: row;
    padding: 2px 0;
    align-items: center;
    gap: 2;
    .label-dot {
      border-radius: 50%;
      height: 10px;
      width: 10px;
    }
    .label-text {
      padding-left: 5px;
    }
  }
  .filtered-team-member {
    background-color: #feffe6;
  }

  .leaderboard-single-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .leaderboard-indexes {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .user-images {
        background: #ff8850;
        color: #ffffff;
      }
      .ant-list-item-meta-title {
        width: 200px;
      }
    }
  }
  .leaderboard-view-all {
    background-color: var(--primary-theme-color);
    width: 100%;
    color: white;
  }
  .main-container {
    display: flex;
    flex-direction: column;
    padding-bottom: 60px;
    .overall-summary-box{
      display: flex;
      flex-direction: row;
      .total-referral-box {
        margin-bottom: 12px;
        margin-right: 12px;
        border: 1px solid #f0f0f0;
        padding: 12px 0px;
        width: 50%;
        background: #fff;
        border-radius: 12px;
        .vahan-app-pre-ob {
          flex-direction: row;
          .pre-ob-box-container-bottom {
            padding: 0 5px;
            padding-bottom: 8px !important;
            margin: 8px 0;
            width: 50%;
            .total-referral-container-bottom {
              margin-top: 20px;
              margin-bottom: 0;
              flex-direction: column;
              .pre-ob-text {
                font-family: var(--primary-font-family);
                font-weight: 600;
                font-size: 12px;
                margin-right: 10px 
              }
            }
          }
          .pre-ob-box-container {
            padding: 0 5px;
            padding-top: 8px !important;
            margin: 10px 0;
            width: 50%;
            border-right: 1px solid #f0f0f0;
            .total-referral-container {
              margin-top: 0px;
              margin-bottom: 20px;
              .pre-ob-text {
                font-family: var(--primary-font-family);
                font-weight: 600;
                font-size: 12px;
                margin-right: 10px 
              }
            }
          }
        }
        .total-referral-container {
          margin-top: 10px;
          .vahan-download-container {
            font-family: var(--primary-font-family);
            font-weight: 600;
            font-size: 12px;
          }
        }
      }
      .overall-summary-static-container {
          display: flex;
          width: 100%;
          // flex: 0 0 60%;
      
          .total-referral-box {
            margin-bottom: 20px;
            margin-right: 14px;
            border: 1px solid #f0f0f0;
            padding: 24px 0px;
            max-height: 62%;
            width: 49%;
            background: #fff;
            border-radius: 12px;
          }
        }
        .remaining-milestone-container{
        width: 50%;
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
      }
    }
    .flipkart-flex-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      flex-wrap: wrap
    }
    .overall-summary-box-mitra-app-downloads{
      display: flex;
      flex-direction: row;
      .overall-summary-static-container {
          // display: flex;
          width: 100%;
          flex:2;
          // flex: 0 0 60%;
      
          .total-referral-box {
            margin-bottom: 20px;
            margin-right: 14px;
            border: 1px solid #f0f0f0;
            padding: 24px 0px;
            // max-height: 62%;
            // width: 49%;
            .vahan-app-pre-ob {
              flex-direction: row;
              .pre-ob-box-container {
                padding: 5px;
                padding-top: 0 !important;
                margin-top: 20px;
                width: 50%;
                .total-referral-container {
                  margin-top: 10px;
                  margin-bottom: 0;
                  .pre-ob-text {
                    font-family: var(--primary-font-family);
                    font-weight: 600;
                    font-size: 12px;
                    margin-right: 10px 
                  }
                }
              }
            }
            .total-referral-container {
              margin-top: 10px;
              .vahan-download-container {
                font-family: var(--primary-font-family);
                font-weight: 600;
                font-size: 12px;
              }
            }
          }
        }
        .remaining-milestone-container{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex: 1;
        // justify-content: space-around;

        .overall-card {
          width: 100%;
          height: 106px
        }
      }
    }
    .total-referral-container{
      text-align: center;
      margin-bottom: 25px;
      align-items: center;
      display: flex;
      flex-direction: column;
    }
    .total-referral-container-lead-rates{
      text-align: center;
      margin-bottom: 20px;
      align-items: center;
      display: flex;
      width: 25%;
      margin: 0 1%;
      padding: 24px;
      border: 1px solid rgba(0, 0, 0, 0.06);
      flex-direction: column;
      background: rgb(255, 243, 238);
    }
    .total-referral-number{
      font-size: 20px;
      font-weight: 400;
      color: #00000073;
      align-items: center;
    }
    .overall-card {
      margin-bottom: 12px ;
      margin-right: 12px ;
      width:  47%;
      border-radius: 12px;
      // flex: 1 0 32%;
      .imgResponse {
        padding-bottom: 15px;
      }
      .overall-header {
        display: flex;
        align-items: center;
        font-family: var(--primary-font-family);
        font-weight: 600;
        font-size: 12px;
        .summary-header {
          margin-left: 5px;
          margin-right: 5px;
        }
        .summary-color {
          height: 10px;
          width: 10px;
          border-radius: 50%;
          display: inline-block;
        }
      }
      .overall-number {
        margin-top: 6px;
        font-size: 20px;
        color: #00000073;
        display: flex;
        align-items: center;
        cursor: pointer;
        .ant-typography {
          color: #00000073;
          display: flex;
        }
        .number {
          &:after {
            content: "";
            display: flex;
            margin: auto;
            height: 3px;
            width: 0;
            background: transparent;
            transition: all 0.3s;
          }
          &:hover:after {
            width: 100%;
            background: #595959;
          }
        }
      }
      .card-view-tab {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        .card-items {
          margin-right: 20px;
          margin-top: 20px;
          width: 25%;
          .overall-header {
            font-size: 12px;
            height: auto;
            max-height: 60px;
            .summary-header {
              color: #000000A6;
            }
          }
          .overall-number {
            margin-top: 0;
            margin-left: 5px;
            font-size: 20px;
            .ant-typography u {
              text-decoration: none !important;
            }
          }
        }
        .summary-header {
          color: #8c8c8c;
        }
      }
      &.overall-card-clientperformance {
        // width: 32%;
        // height: 250px;
        margin: 0 15px 15px 0;
        flex: 1 1 340px;
      }
    }
    .payout-breakup-container{
      svg {
        margin-top: -7px;
      }
      .payout-breakup-header{
        margin-bottom: 24px;
        font-weight: bold;
        font-size: 14px;
        font-family: var(--primary-font-family);
        font-weight: 700;
      }
      .payout-breakup-basic-pay{
        background: #ffc2a5;
        margin-bottom: 24px;
        font-weight: bold;
        font-size: 14px;
        font-family: var(--primary-font-family);
        font-weight: 700;
      }
      .payout-breakup-additions{
        background: #d9f7be;
        margin-bottom: 24px;
        font-weight: bold;
        font-size: 14px;
        font-family: var(--primary-font-family);
        font-weight: 700;
      }
      .payout-breakup-deductions{
        background: #ffccc7;
        margin-bottom: 24px;
        font-weight: bold;
        font-size: 14px;
        font-family: var(--primary-font-family);
        font-weight: 700;
      }
      .payout-breakup-total{
        margin-bottom: 24px;
        font-weight: bold;
        font-size: 14px;
        font-family: var(--primary-font-family);
        font-weight: 700;
        border: 1px solid #595959;
      }
    }

    .components-table-demo-nested {
      thead[class*="ant-table-thead"] th {
        background-color: #f0f0f0;
        font-size: 14px;
        font-family: var(--primary-font-family);
        font-weight: 700;
        // &:nth-child(5) {
        //   background-color: #52c41a;
        //   color: #ffffff;
        // }
        // &:nth-child(n + 4) {
        //   background-color: #ffc2a5;
        // }
      }
      tbody[class*="ant-table-tbody"] tr {
        &.row-layout {
          &:nth-of-type(2n) {
            background-color: #ffc2a5;
            font-family: var(--primary-font-family);
        font-weight: 700;
            svg {
              color: var(--primary-theme-color);
            }
          }
          &:nth-of-type(3n) {
            background-color: #d9f7be;
            font-family: var(--primary-font-family);
        font-weight: 700;
            svg {
              color: #722ed1;
            }
          }
          &:nth-of-type(4n) {
            background-color: #ffccc7;
            font-family: var(--primary-font-family);
        font-weight: 700;
            svg {
              color: #f5222d;
            }
          }
          &:last-child {
            font-family: var(--primary-font-family);
        font-weight: 700;
            td {
              background-color: #52c41a;
              color: white;
              // &:nth-child(5) {
              //   background-color: #ffffff;
              //   color: #000000;
              // }
              // &:nth-child(n +3) {
              //   background-color: #ffffff;
              //   color: #000000;
              // }
            }
          }
        }
      }
      .payout-table {
        .ant-table-thead {
          visibility: hidden;
          display: table-footer-group;
        }
        .campaign-name {
          width: 170px;
        }
      }
      .totallead-text {
        display: flex;
        align-items: center;
        cursor: pointer;
      }
      .ant-table-row-expand-icon-cell {
        width: 52px;
      }
    }
    .payout-process-banner-container{
      background: #F0F5FF;
      margin-bottom: 25px;
      padding: 10px;
      display: flex;
      flex-direction: row;
      .payout-banner-left{
        font-weight: bold;
      }
      .payout-banner-right{
        font-weight: bold;
        margin: auto 0px auto auto;
        .payout-banner-button{
          background: #F0F5FF;
          border: 1px solid #2F54EB;
          border-radius: 2px;
          color: #2F54EB;
        }
      }
    }
  }

  .tab-view-report {
    background-color: #ffffff;
    // width: 100%;
    .ant-tabs-nav {
      padding-left: 20px;
      &::before {
        border-bottom: none !important;
      }
    }
  }
}

.updated-on-information-container {
  color: #00000073;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  font-style: italic;
}

.card-header-section {
  border-bottom: 1px solid #0000000F;
}
.target-header {
  color: var(--primary-theme-color);
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  margin: 12px;
}

.target-table-header {
  color: #000000A6;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin: 12px;
}

.team-header {
  flex: 1;
  text-align: start;
  margin-left: 12px;
}

.individual-target {
  display: flex;
  margin: 12px 24px;
}


.ft-header {
  flex: 1;
}

.team-name {
  color: #000000D9;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}

.misc-details {
  display: flex;
    padding: 12px 24px;
    gap: 48px;

    .tl-label {
      color: #1890FF;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;

      .tl-name {
        color: #000000A6;
        font-weight: 400;
      }
    }

    .tc-label {
      color: #4BCF3F;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;

      .tc-count {
        color: #000000A6;
        font-weight: 400;
      }
    }

}

.forgot-payout-password{
  text-align: initial;
  margin-top: 10px;
}

.individual-settarget-btn {
  border: 1px solid var(--primary-theme-color);
  color: var(--primary-theme-color);
  font-weight: 700;
  width: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.member-leaderboard-input-section {
  display: flex;
    gap: 12px;
    align-items: center;
  flex-wrap: wrap;
}

.team-leaderboard-content-container {
  margin: 24px 0px;
  .milestones-container {
    flex: 1.1;
    border-right: 1px solid #0000000F;

    .milestones-inner-container {
      display: flex;
      max-width: 90%;
      flex-wrap: wrap;
      gap: 8px;

      .individual-milestone {
        display: flex;
        align-items: center;
        gap: 12px;
  
        border: 1px solid #0000000F;
        background-color: '#FFF';
  
        padding: 4px 8px;
  
        .dot-indicator {
          height: 12px;
          width: 12px;
          background-color: #CF953F;
          border-radius: 12px;
        }
  
        .milestone-name {
          color: #00000073;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
        }
  
        .milestone-count {
          color: #000000A6;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
        }
      }
    }
  }


  .top-performer-container {
    flex: 1;
    display: flex;
    align-items: center;
    margin-left: 24px;
    gap: 16px;

    .top-performer-label {
      color: #1890FF;
      font-size: 14px;
      line-height: 22px;
      font-weight: 400;
    }

    .top-performer-name {
      color: #000000A6;
      font-size: 14px;
      line-height: 22px;
      font-weight: 400;
    }
  }
}

.member-leaderboard-card-container {
  .team-name {
    color: #000000D9;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    margin: 10px 0;
  }

  .ft-count {
    color: var(--primary-theme-color);
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;

    .ft-label {
      color: #000000A6;
    }
  }

  .member-leaderboard-card {
    margin: 12px 24px;
    border-radius: 12px;

    .member-leaderboard-card-mobile {
      display: none !important;
    }

    .member-leaderboard-card-desktop {
      display: flex !important;
    }

    .team-leaderboard-footer-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 12px;
      margin: 10px 0;

      .team-details {
        display: flex;
        align-items: center;
        gap: 48px;

        .team-leader-info {
          display: flex;
          gap: 16px;

          .tl-label{
            color: #1890FF;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
          }

          .tl-name {
            color: #000000A6;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
          }
        }

        .tc-count {
          display: flex;
          gap: 8px;

          .tc-label {
            color: #4BCF3F;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
          }

          .tc-count {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #000000A6;
          }
        }
      }

      .view-details {
        width: 240px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2px;
        background: var(--primary-theme-color);
        color: #FFFFFF;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
      }
    }

    .individual-settarget-btn {
      border: 1px solid var(--primary-theme-color);
      color: var(--primary-theme-color);
      font-weight: 700;
      width: 240px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .round-image-container {
      height: 32px;
      width: 32px;
      border-radius: 32px;
      border: 1px solid #4BCF3F;
      color: #4BCF3F;
      background-color: #4BCF3F0D;
      display: flex;
      align-items: center;
      justify-content: center;

      font-weight: 400;
      font-size: 12px;
    }

    .member-post-container {
      display: flex;
      gap: 12px;
      .post-name {
        border: 1px solid #4BCF3F;
        color: #4BCF3F;
        background-color: #4BCF3F0D;

        border-radius: 4px;
        width: 80px;
        height: 22px;

        font-weight: 600;
        font-size: 14px;
      }
    }
  }
}

.tab-layout-performance-dashboard, .tab-layout-performance-dashboard-desktop{
  display: flex;
  background-color: var(--page-bg-color);
  padding: 40px 24px 12px;
  align-items: center;
  flex-wrap: wrap;

  .individual-tab {
    background-color: #FFF;
    padding: 5px 16px;
    border: 1px solid #D9D9D9;
    font-weight: 400;
    color: #000000D9;
    font-size: 14px;
    line-height: 22px;
    cursor: pointer;
  }
  .overview-tab {
    font-weight: 600;
    color: #000000D9;
    font-size: 14px;
    line-height: 22px;
  }

  .active-tab {
    border: 1px solid var(--primary-theme-color);
    font-weight: 700;
    color: var(--primary-theme-color);
  }
}

.new-date-container {
  border: 1px solid #D9D9D9;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}

.perf-dash-filter-container {
  background-color: #FFF;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 12px 12px;
  margin: 12px 24px;
  border-radius: 12px;
}

.common-card-section {
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  padding-bottom: 16px;
  border-bottom: 1px solid #00000026;

  .common-card-input-container{
    display: flex;
    background: var(--page-bg-color);
    padding: 12px 24px;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .tab-layout-performance-dashboard-desktop {
    display: none;
  }
  .fixed-column {
    max-width: 10px; // set the reduced width for the fixed column
  }
  .ReportPage {
    .containerTop {
      padding: 0;
    }
    .containerMiddle {
      overflow: scroll;
      height: calc(100vh - 120px);
    }
    .overall-performance {
      padding: 1.5% 5%;
      margin-bottom: 6px;
    }
    .main-container {
      margin-bottom: 60px;
      .overall-summary-box,.overall-summary-box-mitra-app-downloads{
        display: flex;
        flex-direction: column;
        .overall-summary-static-container {
             display: block;
            .total-referral-box {
              margin-bottom: 20px;
              margin-right: 14px;
              border: 1px solid #f0f0f0;
              padding: 24px 0px;
              width: 100%;
              background: #fff;
              border-radius: 12px;
            }
          }
          .total-referral-box {
            margin-bottom: 20px;
            margin-right: 14px;
            border: 1px solid #f0f0f0;
            padding: 24px 0px;
            width: 100%;
            background: #fff;
            border-radius: 12px;
          }
        .remaining-milestone-container{
          width: 100%;
          display: block;
        }
      }
      .overall-card {
        height:100;
        margin-bottom: 20px ;
        margin-right: 0px ;
        width:  100%;
        &.overall-card-clientperformance {
          width: 100%;
        }
      }.components-table-demo-nested {
        .payout-table {
          .campaign-name {
            width: 68px;
          }
        }
      }
    }
  }

  .team-leaderboard-content-container {
    flex-direction: column;
    gap: 24px;

    .milestones-container {
      flex: 1.1;
      border-right: 1px solid #0000000F;
  
      .milestones-inner-container {
        display: flex;
        max-width: 100%;
        flex-wrap: wrap;
        gap: 8px;
  
        .individual-milestone {
          display: flex;
          align-items: center;
          gap: 12px;
    
          border: 1px solid #0000000F;
          background-color: '#FFF';
    
          padding: 4px 8px;
    
          .dot-indicator {
            height: 12px;
            width: 12px;
            background-color: #CF953F;
            border-radius: 12px;
          }
    
          .milestone-name {
            color: #00000073;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
          }
    
          .milestone-count {
            color: #000000A6;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
          }
        }
      }
    }
  
  
    .top-performer-container {
      flex: 1;
      display: flex;
      align-items: center;
      margin-left: 0px;
      gap: 16px;
  
      .top-performer-label {
        color: #1890FF;
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
      }
  
      .top-performer-name {
        color: #000000A6;
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
      }
    }
  }

  .member-leaderboard-card-container {
    padding-bottom: 100px !important;
    .member-leaderboard-card {

      .member-leaderboard-card-desktop {
        display: none !important;
      }

      .member-leaderboard-card-mobile {
        display: flex !important;
      }

      .team-leaderboard-footer-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 12px;
        flex-direction: column;

        margin: 16px 0px;
  
        .team-details {
          display: flex;
          align-items: center;
          gap: 48px;
          width: 100%;
          justify-content: space-between;
  
          .team-leader-info {
            display: flex;
            gap: 16px;
  
            .tl-label{
              color: #1890FF;
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
            }
  
            .tl-name {
              color: #000000A6;
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
            }
          }
  
          .tc-count {
            display: flex;
            gap: 8px;
  
            .tc-label {
              color: #4BCF3F;
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
            }
  
            .tc-count {
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
              color: #000000A6;
            }
          }
        }
  
        .view-details {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 2px;
          background: var(--primary-theme-color);
          color: #FFFFFF;
          font-weight: 600;
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
  }
}

// Report page css end

/////
.header-text {
  // font-family: "Nunito Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  width: 53%;
  text-align: left;
}

.header-download {
  text-align: right;
  padding: 2px 0px;
  margin-left: 10px;
}

.download-button {
  height: 28px;
  background: #fff7f4;
  border: 1px solid var(--primary-theme-color);
  box-sizing: border-box;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 2px;
  // font-family: "Nunito Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: var(--primary-theme-color);
}

// .main-container {
//   // font-family: "Nunito Sans", sans-serif;
//   font-style: normal;
//   font-weight: normal;
//   margin-bottom: 60px;
// }

.total-earnings {
  display: flex;
  flex-direction: column;
  background: white;
  padding: 1.5% 5%;
}

.total-earning-header {
  display: flex;
  flex-direction: row;
  padding: 3% 0%;
}

.total-earning-header-text {
  width: 50%;
  text-align: left;
  font-weight: bold;
}

.total-earning-header-date {
  width: 50%;
  text-align: right;
}

.horizontal-bar {
  /* height: 60px; */
  padding: 12px 0px;
}

.horizontal-bar-text {
  display: flex;
  flex-direction: row;
  padding: 1% 0%;
}

.paid-amount-container {
  width: 50%;
  text-align: left;
}

.unpaid-amount-container {
  width: 50%;
  text-align: right;
}
.percentage {
  margin-left: 23px;
}

.card-left {
  width: 65%;
  text-align: left;
}

.card-right-overall-performance {
  width: 55%;
  text-align: right;
  padding: 12px 0;
  height: 60px;
}

.card-right {
  width: 35%;
  text-align: right;
  padding: 12px 0px;
  font-weight: bold;
}

.report-right-arrow {
  padding: 12px 0;
  width: 10%;
  padding-left: 8px;
}

.progressBar {
  width: 100%;
  height: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #0d6efd;
  transition: width 0.6s ease;
}

progress::-moz-progress-bar {
  background: lightcolor;
}

progress::-webkit-progress-value {
  background: #52c41a;
}

progress::-webkit-progress-bar {
  background: #d48806;
}

.overal-performance-headings {
  text-align: left;
  font-weight: bolder;
  font-size: 20px;
  font-weight: 1000;
  padding: 3% 0%;
  height: 56px;
}

.chart {
  width: 50%;
  margin: auto;
}

.chart-details {
  width: 50%;
  padding: 10% 0%;
}

.table-container {
  background-color: white;
  padding: 1px 5%;
  padding-bottom: 80px;
}

.table-layout {
  margin: 20px 0px;
}

.table-heading {
  background: #fafafa;
  border: 1px solid #f0f0f0;
  text-align: left;
  padding: 10px 10px;
  font-size: 14px;
  width: 1%;
  font-weight: bold;
  color: #666565;
}

.single-cell {
  background: white;
  border: 1px solid #f0f0f0;
  text-align: left;
  padding: 10px 10px;
  font-size: 14px;
}

.tile-container {
  background-color: var(--page-bg-color);
  padding: 12px 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  .divider-style {
    color: white;
    border-color: #00000026;
    border-width: 1px;
    height: auto;
  }
  
  .tile-container-overall-card{
    width: 14rem;
    border-radius: 12px;
  }

  .card-body-style {
    display: flex;
    flex-direction: column;
    text-align: start;
    padding: 12px 12px;
  }

  .figure-section {
    font-size: 20px;
    color: var(--primary-theme-color);
    line-height: 28px;
  }

  .content-section {
    color: #000000A6;
    font-size: 12px;
    line-height: 20px;
  }
}


.total-container {
  // font-family: "Nunito Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
}

.total-candidates {
  font-size: 20px;
  padding-bottom: 10px;
  font-weight: bold;
}

.teamMember-name {
  // font-family: var(--primary-font-family)
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

@media screen and (min-width: 840px) {
  .small-size-screen {
    display: none;
    visibility: hidden;
  }

  .big-screen {
    display: block;
    visibility: visible;
  }

  .date-range {
    position: relative;
    display: inline-block;
    margin-left: 1%;
  }

  // .main-container {
  //   display: flex;
  //   background-color: white;
  //   padding: 20px;
  //   // padding-right: 3%;
  // }

  .header-download {
    text-align: right;
    padding: 2px 0px;
    margin-left: 20px;
  }

  .space-needed {
    height: 20px;
  }
  .visible {
    width: 100%;
  }
  .total-earnings {
    border: 1px solid #f4f4f4;
    margin-right: 10px;
  }
}

@media screen and (min-height: 500px) and (max-height: 8400px) {
  .small-size-screen {
    display: none;
    visibility: hidden;
  }
}

@media screen and (max-width: 500px) {
  .small-size-screen {
    display: block;
    visibility: visible;
    margin: auto 0;
    margin-right: 5px;
  }
  .date-range {
    margin-left: 5%;
  }
  .big-screen {
    display: none;
    visibility: hidden;
  }

  .total-candidates {
    font-size: 15px;
    padding-bottom: 10px;
    font-weight: bold;
  }
}

.leads-wrapper {
  background-color: #ffffff;
  padding: 0px 20px;
  padding-bottom: 16%;
  padding-top: 10px;
  .date-download {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .download-btn {
      display: flex;
      background-color: #fff7f4;
      align-items: center;
      color: var(--primary-theme-color);
      font-family: var(--primary-font-family);
        font-weight: 500;
      &:hover {
        background-color: #ffffff;
      }
    }
  }
}

@media screen and (min-width: 700px) and (max-width: 1025px) {
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
    white-space: nowrap !important;
  }
  .ant-page-header-heading-title {
    text-align: left;
    white-space: normal !important
  }
  .leads-wrapper {
    background-color: #ffffff;
    // padding: 0px 5%;
    padding-bottom: 16%;
  }
}

.leaderboard-icon {
  transform: rotate(-45deg);
  color: #ff8850;
}

.leaderboard-arrow {
  border: 1px solid var(--primary-theme-color);
  background-color: var(--primary-theme-color);
  border-radius: 7px;
  margin: 4px 0px;
}

.leaderboard-lead-name {
  // font-family: var(--primary-font-family)
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.leaderboard-lead-role {
  // font-family: var(--primary-font-family)
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.leaderboard-lead-candi-count {
  // font-family: var(--primary-font-family)
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  text-decoration: underline;
}
.leaderboard-lead-candidate {
  width: 40%;
}
.leaderboard-lead-deatils {
  margin-left: 2%;
  width: 250%;
  font-weight: bolder;
  height: 60px;
}
.tagBarClass{
  background: #f8f8f8;
  border: 1px solid #bfbfbf;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 1%;
    display: flex;
    flex-direction: row;
}
.all-leads-payout-table-wrapper{
  width: 100%;
  overflow-x: auto;
}
.right-circle-icon {
  font-size: 18px;
  margin-left: 10px;
  color: var(--primary-theme-color) !important;
}
