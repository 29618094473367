.pitch-modal-parent {
    .ant-modal-body {
        padding: 0;
    }

    .ant-modal-close-x {
        display: none;
    }

    .pitch-modal-content {
        .pim-header {
            padding: 24px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);

            .pim-header-title {
                .pim-comp-logo {
                    height: 40px;
                    width: 40px;
                    margin-right: 8px;
                    object-fit: contain;
                }

                .pim-header-icon {
                    font-size: 20px;
                    margin-right: 8px;
                }

                .pim-header-txt {
                    color: var(--black-85, rgba(0, 0, 0, 0.85));
                    font-size: 20px;
                    font-weight: 600;
                }
            }

            .pim-header-close {
                padding: 8px;
                border-radius: 2px;
                border: 1px solid rgba(0, 0, 0, 0.15);
                background: #FFF;

                .pim-header-close-icon {
                    font-size: 20px;
                }
            }
        }

        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .pim-body {
                max-height: 54vh;
                overflow: scroll;
            }
        }

        .pim-body {
            min-height: 50px;

            .pim-loading {
                padding: 24px;
            }

            .pim-no-data {
                color: var(--black-85, rgba(0, 0, 0, 0.85));
                font-size: 16px;
                font-weight: 600;
                text-align: center;
                padding: 24px;
            }

            .pim-content {
                .pim-common-header-txt {
                    color: var(--black-85, rgba(0, 0, 0, 0.85));
                    font-size: 16px;
                    font-weight: 600;
                }

                .pim-common-list-item-txt {
                    color: var(--black-65, rgba(0, 0, 0, 0.65));
                    font-size: 16px;
                    font-weight: 400;
                }

                .pim-common-padding {
                    padding: 24px;
                }

                .pim-arrow-icon {
                    font-size: 12px;
                    margin-right: 8px;
                }

                .pim-section-header {
                    color: var(--black-65, rgba(0, 0, 0, 0.65));
                    font-size: 16px;
                    font-weight: 600;
                }

                .pim-upper-body {
                    background-color: var(--black-6, rgba(0, 0, 0, 0.06));
                    padding: 24px;

                    .pim-salary-body {
                        margin-bottom: 2em;
                    }

                    @media only screen and (min-width: 320px) and (max-width: 786px) {
                        .pim-hours-order {
                            flex-direction: column;
                        }
                    }

                    .pim-hours-order {
                        display: flex;
                        margin-top: 2em;

                        @media only screen and (min-width: 320px) and (max-width: 786px) {
                            .pim-hours {
                                width: 100% !important;
                            }
                        }

                        .pim-hours {
                            width: 44%;
                        }

                        @media only screen and (min-width: 320px) and (max-width: 786px) {
                            .pim-vertical-divider {
                                margin: 0 1vh !important;
                            }
                        }

                        .pim-vertical-divider {
                            width: 1px;
                            background-color: rgba(0, 0, 0, 0.06);
                            margin: 0 1vw;
                        }

                        @media only screen and (min-width: 320px) and (max-width: 786px) {
                            .pim-orders {
                                width: 100% !important;
                            }
                        }

                        .pim-orders {
                            width: 40%;
                        }
                    }
                }

                .pim-other-details {

                    .pim-other-details-header {
                        border: 1px solid var(--Neutral-5, #D9D9D9);
                        background: var(--Neutral-2, #FAFAFA);
                        padding: 12px 24px;

                        .pim-other-details-header-icon {
                            font-size: 12px;
                        }
                    }

                    .pim-other-details-body {
                        margin-top: 1em;
                        padding: 6px 24px;
                    }
                }

                .pim-insurance-header {
                    margin-bottom: 1em;
                }

                .pim-tips {
                    .pim-tips-header {
                        border: 1px solid var(--Neutral-5, #D9D9D9);
                        background: var(--Neutral-2, #FAFAFA);
                        padding: 12px 24px;

                        .pim-tips-header-icon {
                            font-size: 12px;
                        }
                    }

                    .pim-tips-body {
                        margin-top: 1em;
                        padding: 6px 24px;
                    }
                }

                .pim-offers {
                    padding: 24px;

                    .pim-offers-header {
                        .pim-offers-header-icon {
                            font-size: 20px;
                            color: rgba(24, 144, 255, 0.85);
                            margin-right: 8px;
                        }

                        .pim-offers-header-txt {
                            color: #1890FF;
                            font-size: 16px;
                            font-weight: 600;
                        }
                    }

                    .pim-offers-body {

                        @media only screen and (min-width: 320px) and (max-width: 786px) {
                            .pim-offers-card {
                                max-width: 50% !important;
                            }
                        }

                        .pim-offers-card {
                            padding: 16px;
                            border-radius: 8px;
                            border: 1px solid var(--black-15, rgba(0, 0, 0, 0.15));
                            background: #FFF;
                            margin: 1em 1em 1em 0;
                            max-width: 33%;

                            .pim-offers-card-body {}
                        }
                    }
                }
            }
        }
    }
}