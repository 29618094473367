

.chat-message-style {
    background-color: #FDF6F3;
    border-radius: 10px;
    text-align: start;
    min-width: 30%;
    max-width: 70%;
    width: fit-content;
    display: flex;
    flex-direction: column;
    position: relative;
}

.new-custom-height {
    height: 85vh !important;
}

.message-sender-name{
    font-size:14px;color: #D34B18;font-weight:600;padding:6px;
}

.image-styles {
    padding: 0 6px 6px 6px;
}

.message-text{
    padding: 0 6px 6px;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
}
.time-frame{
    color: '#b3bbe1';
    font-family: "Nunito Sans";
    font-size: 10px;
    margin-left: auto;
    margin-right: 8px;
}
.image-upload>input {
    display: none;
  }

.conversation-header{
    height: auto;
    max-height: 40px;
    min-height: 30px;
    background-color: rgb(245, 245, 245);
    display: flex;
    align-items: center;
    padding-left: 16px;
    gap: 0.8rem;
}

.notification-indicating-container{
    height: 20px;
    width: 20px;
    background-color: #D34B18;
    border-radius: 50%;
    color: #fff;
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat-section-space-container{
    display: flex; width: 100%; height: 75vh;  overflow: scroll;margin-bottom:30px;margin-top: 10px
}

.arrow-right{
    position: absolute;
    width: 0;
    height: 0;
    align-self: flex-end;
}

.arrow-right::before {
    content: "";
    height: 15px;
    width: 13px;
    background-color: rgb(236, 194, 182);
    position: absolute;
    right: -10px;
    top: 3px;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    transform: rotate(90deg);
}

.arrow-left{
    position: absolute;
    width: 0;
    height: 0;
}

.arrow-left::before {
    content: "";
    height: 15px;
    width: 13px;
    background-color: #FDF6F3;
    position: absolute;
    right: -3px;
    top: 3px;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    transform: rotate(-90deg);
}

.image-upload .paperClipButton {
    font-size: 20px;
    padding-right: 20px;
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
    .inputContainer {
        display: flex;
        justify-content: space-between;
    }

    .chatTypeContainer{
        position: relative;
        flex: 3;
    }

    .input{
        width: 90%;
    }

    .inputContainer{
        position: absolute;
        width: 90%;
        bottom: 10px;
        display: flex;
        align-items: center;
        background-color: white;
        height: 70px;
    }
      .chatTypeContainer {
        border: 1px solid #ccc;
        border-radius: 10px;
        display: flex;
        width: 100%;
        align-items: center;
      }
      .inputContainer .chatTypeContainer button {
        margin-top: -0.5%;
        color: rgb(91, 91, 91);
        font-size: 16px;
      }
      .input {
        padding: 10px;
        flex: 1;
        border: none;
        margin-right: 10px;
        font-size: 14px;
        margin-left: 10px;
        height: 50px;
      }
      .imgContainer .sendButton {
        background: #D34B18;
        padding: 50%;
        color: #fff;
        border-radius: 50%;
      }
      .conversation-header{
        display: none;
      }
      .chat-section-space-container{
        margin-bottom: 70px;
      }
      #scrollableDiv{
        margin-bottom: 15%;
      }
  }
  
