@import "~antd/dist/antd.css";
@import "~antd/dist/antd.variable.css";
@import "./App.css";
// @import url("~bootstrap/dist/css/bootstrap.min.css");

@font-face {
  font-family: Nunito Sans;
  src: local("NunitoSans"),
    url("./fonts/NunitoSans-Light.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: Nunito Sans Medium;
  src: local("NunitoSans"),
    url("./fonts/NunitoSans-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: Nunito Sans Bold;
  src: local("NunitoSans"),
    url("./fonts/NunitoSans-Bold.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: Nunito Sans Extrabold;
  src: local("NunitoSans"),
    url("./fonts/NunitoSans-Black.ttf") format("truetype");
  font-weight: normal;
}

.vahanOld {
  --ant-primary-color: #d34612;
  --ant-primary-color-hover: #ff712d;
  --ant-primary-color-active: #c14010;
  --ant-primary-color-outline: rgba(24, 144, 255, 0.2);
  --ant-primary-1: #fff7f4;
  --ant-primary-2: #d34612;
  --ant-primary-3: #c14010;
  --ant-primary-4: #ff712d;
  --ant-primary-5: #ff712d;
  --ant-primary-6: #d34612;
  --ant-primary-7: #d34612;
  --ant-primary-color-deprecated-pure: ;
  --ant-primary-color-deprecated-l-35: #ff712d;
  --ant-primary-color-deprecated-l-20: #ff712d;
  --ant-primary-color-deprecated-t-20: #ff712d;
  --ant-primary-color-deprecated-t-50: #ff712d;
  --ant-primary-color-deprecated-f-12: rgba(24, 144, 255, 0.12);
  --ant-primary-color-active-deprecated-f-30: rgba(230, 247, 255, 0.3);
  --ant-primary-color-active-deprecated-d-02: #dcf4ff;
  --ant-info-color: #d34612;
  --ant-info-color-deprecated-bg: #e6f7ff;
  --ant-info-color-deprecated-border: #d34612;

  --primary-theme-color: #d34612;
  --primary-theme-light: #FFEAE0;
  --page-bg-color: #f5f5f5;
  --primary-blur-color: #F49C7B;
  --primary-font-family: 'Nunito Sans';
  --additional-info-screen-height: 100vh;
  --additional-info-screen-mob-padding-bottom: 100px;
  --primary-bg-color: whitesmoke;
  --account-page-footer-bottom: 0px;
  --account-page-footer-bottom-mobile: 8vh !important;
}

.vahanPro {
  --ant-primary-color: #1D5FFF;
  --ant-primary-color-hover: #948bf5;
  --ant-primary-color-active: #c14010;
  --ant-primary-color-outline: rgba(24, 144, 255, 0.2);
  --ant-primary-1: #E5ECFF;
  --ant-primary-2: #d34612;
  --ant-primary-3: #1D5FFF;
  --ant-primary-4: #948bf5;
  --ant-primary-5: #948bf5;
  --ant-primary-6: #d34612;
  --ant-primary-7: #d34612;
  --ant-primary-color-deprecated-pure: ;
  --ant-primary-color-deprecated-l-35: #948bf5;
  --ant-primary-color-deprecated-l-20: #948bf5;
  --ant-primary-color-deprecated-t-20: #948bf5;
  --ant-primary-color-deprecated-t-50: #948bf5;
  --ant-primary-color-deprecated-f-12: rgba(24, 144, 255, 0.12);
  --ant-primary-color-active-deprecated-f-30: rgba(230, 247, 255, 0.3);
  --ant-primary-color-active-deprecated-d-02: #dcf4ff;
  --ant-info-color: #d34612;
  --ant-info-color-deprecated-bg: #e6f7ff;
  --ant-info-color-deprecated-border: #d34612;

  --primary-theme-color: #1D5FFF;
  --primary-theme-light: #E5ECFF;
  --page-bg-color: linear-gradient(0deg, #FDFAF9 0%, #FDFAF9 100%), #FFF;
  --primary-blur-color: #a385ef;
  --primary-font-family: 'Inter';
  --additional-info-screen-height: calc(100vh - 64px);
  --additional-info-screen-mob-padding-bottom: 10px;
  --primary-bg-color: linear-gradient(0deg, #FDFAF9 0%, #FDFAF9 100%), #FFF;
  --account-page-footer-bottom: 58px;
  --account-page-footer-bottom-mobile: 0vh !important;
}

html,
body {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5 !important;
}

//AntDesign Css -Start
.ant-modal-confirm-btns .ant-btn {
  display: inline !important;
}

.ant-alert {
  z-index: 4;
}

.ant-breadcrumb-link {
  font-size: 18px;
}

.ant-spin-nested-loading {
  width: 100%;
  height: 100vh;

  overflow: auto;
  flex: 1;
  transition: width 0.3s;

}

.ant-picker-panel-container {
  .ant-picker-preset {
    .ant-tag-blue {
      background: none;
    }
  }
}

.ant-pagination-item-link {
  display: flex !important;
  align-items: center !important;
  justify-content: center;
}

// .ant-btn {
//   display: flex !important;
//   align-items: center !important;
//   justify-content: center;
// }
.ant-input-search-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

//AntDesign Css -End

//Tool-tip css
.tool-tip {
  position: relative;
  display: inline-block;
}

.tool-tip .tooltiptext {
  visibility: hidden;
  /* width: 250px; */
  width: fit-content;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  bottom: 103%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tool-tip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tool-tip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

//Extra common
.filter-set-container {
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  flex-wrap: wrap;

  .date-client-status {
    display: flex;
    align-items: baseline;

    button {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      margin-right: 8px;
    }
  }

  .side-btns {
    display: flex;
    justify-self: space-around;
    align-items: center;
  }

  &>div {
    margin-right: 10px;
  }
}

.candidate-card-view-details-container {
  margin-top: auto;
  margin-bottom: 30px;
}

.show-only-desktop-disp-block {
  display: block !important;
}

.show-only-mobile {
  display: none !important;
}

.show-only-mobile-disp-flex {
  display: none !important;
}

.show-only-desktop-no-font {
  display: flex !important;
  align-items: center;
}

.show-only-desktop {
  display: flex !important;
  align-items: center;
  font-family: Inter;
  font-weight: 500;
}

.card-view-candidate {
  margin-top: 8px;
}

//Mobile to table -> write common css for mobile
@media only screen and (min-width: 320px) and (max-width: 786px) {
  .show-only-mobile {
    display: block !important;
    font-family: Inter;
    font-family: 500;
  }

  .show-only-mobile-disp-flex {
    display: flex !important;
    font-family: Inter;
    font-family: 500;
  }

  .show-only-desktop {
    display: none !important;
  }

  .show-only-desktop-disp-block {
    display: none !important;
  }

  .show-only-desktop-no-font {
    display: none !important;
  }
}