.level-indicators {
    display: flex;
    width: 100%;
    height: 8px;
}
.level-completed {
    background: #52C41A;
}
.level-in-progress {
    background: var(--primary-theme-color);
    border: 1px solid black;
}
.level-yet-to-begin {
    background-color: #F0F0F0;
}
.progress-bar-detail-content {
    display: flex;
    flex-direction:column;
    justify-content:space-around;
    align-items: center;
  }

  .timeline-details {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;

    .ant-timeline-item {
      padding-bottom: 0px;
    }
  }
.current-position-pointer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .pointer-text{
    font-family: var(--primary-font-family);
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: var(--primary-theme-color);
  }
}

@media only screen and (min-width: 320px) and (max-width: 786px){
.custom-width-mobile{
    max-width: 350px;
}
.timeline-details {
    display: flex;
    flex-direction: column;
  }
}
