.verification-type-container,
.pan-verification-container, 
.permanent-address-container,
.upload-selfie-container,
.digilocker-verification-container {
    margin-top: 32px;
}

.mt-24 {
    margin-top: 24px;
}

.anchor-styles {
    color: #0000cd;
    text-align: start;
    margin-top: 8px;
    cursor: pointer;
}

.status-styles {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 12px;
}

.anchor-styles:hover{
    text-decoration: underline;
    text-underline-offset: 3px;
}

.mt-16-imp {
    margin-top: 16px !important;
}

.label {
    text-align: start;
    margin-bottom: 6px;
}

.pan-verification-container,
.permanent-address-container,
.upload-selfie-container,
.digilocker-verification-container {
    border: 1px solid #e7e9ec;
    border-radius: 4px;
}

.container-header {
    background-color: #f7f7f7;
    background-color: #f7f7f7;
    text-align: start;
    padding: 8px;
    border-bottom: 1px solid #e7e9ec;
    border-radius: 4px;
}

.input{
    height: 36px;
    border: 1px solid #D9D9D9 !important;
    background-color: '#FFFFFF';
    text-align: start;
    width: 100% !important;
    border-radius: 0px !important;
}

.input-error {
    border: 1px solid #d34613 !important;
    border-radius: 4px;
}

.pan-form-container,
.selfie-form,
.digilocker-form-container {
    margin: 16px;
}

.ant-radio-group {
    display: flex !important;
}

.button-container {
    margin-top: 24px;
}

.submit-button {
    border-radius: 8px !important;
    background-color: #ff702d !important;
    color: #fffcfb !important;
    font-weight: 600 !important;
}

.aadhar-steps-header {
    font-weight: 700;
    font-size: 13px;
    text-align: start;
}

.status-bg {
    padding: 6px;
    border-radius: 6px;
}

.verified-bg {
    background-color: #4BB543 !important;
    color: #fffcfb !important;
}

.failed-bg {
    background-color: #ff000099 !important;
    color: #fffcfb !important;
}

.duplicate-bg {
    background-color: #f7710aad !important;
    color: #fff !important;
}

.pending-bg {
    background-color: #f7f7f7;
    color: #000
}
.error-shift-timings {
    color: #c32801;
    padding: 2px;
    margin-top: 30px;
    text-align: left;
    font-weight: 600;
    padding-left: 10px;
}
