.pagination-btn {
    border: none;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    cursor: pointer;
    background: none;
    color: #A7A7A7;
}
  
  .active-pagination-btn {
    color: #000;
  }

  .disabled-pagination-btn {
    color: #A7A7A7;
    cursor: not-allowed !important;
  }