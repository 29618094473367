.vehicle-type-container {
    margin-top: 32px;
    border: 1px solid #e7e9ec;
    border-radius: 4px;
}

.vehicle-details-container,
.city-details-container,
.city-details-containe {
    margin: 16px;
}

.label {
    text-align: start;
}

.ant-upload.ant-upload-select-picture-card{
    width: 100% !important;
    height: 44px !important;
}

.mt-24 {
    margin-top: 24px;
}

.button-container {
    margin-top: 24px;
}

.submit-button {
    border-radius: 8px !important;
    background-color: #ff702d !important;
    color: #fffcfb !important;
    font-weight: 600 !important;
}

.option-header-zone {
    text-align: start;
}

.option-item {
    border: 1px solid #e7e9ec;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px;
    margin-top: 10px;
    cursor: pointer;
}

.radio-section {
    display: flex;
    width: 6%;
    margin-top: 12px;
}

.option-radio {
    height: 20px;
    width: 20px;
    border: 1px solid #1111115e;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.radio-fill {
    background-color: #ff702d;
    height: 10px;
    width: 10px;
    border-radius: 50%;
}

.option-details {
    width: 100%;
    margin-left: 12px;
}

.option-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.option-header-bonus-details {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    font-size: 13px;
    font-weight: 500;
    color: #000000b8;
}

.custom-divider {
    height: 2px;
    width: 90%;
    display: flex;
    background-color: #e7e9ec;
    margin: 8px 0px;
}

.option-content {
    display: flex;
    flex-direction: row;

    font-size: 13px;
    font-weight: 500;
    color: #000000b8;

    flex-wrap: wrap;
}

.manual-fee-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.aadhar-fee-details {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.city-details-container {
    height: 55vh;
    overflow: scroll;
}

.blinkit-city-container {
    height: auto
}

.mobile {
    display: none;
}

.desktop {
    display: block;
}

.vertical-line {
    width: 2px;
    background-color: #e7e9ec;
    margin: 0 18px;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }
}
