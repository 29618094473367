.parentContainer {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  display: flex;
  justify-content: space-between;
  z-index: 13;
  .containerSS {
    position: fixed;
    width: 30%;
    height: 100%;
    right: 0;
    background-color: #ffffff;
    color: #000;
    animation: sidemover 0.5s linear;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    .navigator {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #f0f0f0;
      padding-bottom: 20px;
      margin: 20px;
      .close-icon {
        color: #FF4D4F;
        font-size: 16px;
        border: 1px solid #00000026;
        padding: 5px 
      }
      .nav1 {
        display: flex;
        .filter-icon {
          font-size: 18px 
        }
        .sidername {
          margin-left: 8px;
        }
      }
      img {
        cursor: pointer;
      }
    }
    @keyframes sidemover {
      0% {
        transform: translateX(100%);
      }
      100% {
        transform: translateX(0%);
      }
    }
    .childContainer {
      height: calc(100% - 140px);
      overflow-y: scroll;
    }
    .btns {
      position: absolute;
      display: flex;
      width: 100%;
      bottom: 30px;
      background-color: #fff;
      padding-left: 20px;
      padding-right: 20px;
      .submit-btn {
        font-weight:700;
        font-size: 14px;
        margin-right: 10px;
      }
      .clear-all {
        border-color: var(--primary-theme-color);
        font-weight:600;
        color: var(--primary-theme-color);
        font-size: 14px;
        margin-right: 10px !important;
      }
      .ant-btn-primary {
        border-color: var(--primary-theme-color);
        background: var(--primary-theme-color);
      }
      .commonlayoutbtn {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        // font-weight: 700;
        margin: 0 auto;
        width: 90%;
      }
      .submit {
        @extend .commonlayoutbtn;
        background-color: var(--primary-theme-color);
        color: #fff;
      }
      .removeaccess, .addaccess{
        @extend .commonlayoutbtn;
        color: var(--primary-theme-color);
        background-color: #fff;
        margin-left: 5px;
      }
      .disabledSubmit {
        @extend .submit;
        background-color: var(--page-bg-color);
        color: rgba(0, 0, 0, 0.25);
        pointer-events: none;
      }
    }
  }
  .emptyContainer {
    width: 100%;
  }
}
/* Portrait and Landscape from Iphone to Ipad */
@media only screen and (min-device-width: 320px) and (max-device-width: 1024px) {
  .parentContainer {
    width: 100%;
    .containerSS {
      width: 100%;
      .navigator {
        margin: 0;
        padding: 15px;
        border-bottom: 4px solid rgba(245, 245, 245, 1);
      }
    }
    .emptyContainer {
      display: none;
    }
  }
}
