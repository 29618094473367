.client-details-container-parent {

    .cd-new-breadcrumb-header {
        padding: 2vw 3vw;
        margin: 0;
    }

    .ant-divider-horizontal {
        margin: 0px !important;
    }

    .cd-job-details-header {
        background-color: rgba(0, 0, 0, 0.06);
        padding: 2vw 3vw;

        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .cd-company-info-btns {
                flex-direction: column;
                justify-content: flex-start !important;
                align-items: flex-start !important;
            }
        }

        .cd-company-info-btns {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .cd-company-logo-img {
                height: 40px;
                width: 40px;
                object-fit: contain;
            }

            @media only screen and (min-width: 320px) and (max-width: 786px) {
                .cd-company-name-type {
                    margin-left: 1.5vh !important;
                }
            }

            .cd-company-name-type {
                margin-left: 1vw;

                .cd-company-name {
                    color: rgba(0, 0, 0, 0.85);
                    text-align: start;
                    font-size: 24px;
                    font-weight: 600;
                }

                .cd-company-type {
                    color: var(--black-45, rgba(0, 0, 0, 0.45));
                    font-size: 14px;
                    font-weight: 400;
                    text-align: start;
                }
            }

            @media only screen and (min-width: 320px) and (max-width: 786px) {
                .cd-job-details-header-btns {
                    width: 100% !important;
                    justify-content: space-between !important;
                }
            }

            @media only screen and (min-width: 320px) and (max-width: 786px) {
                .cd-create-view-poster-btns {
                    width: 60%;
                    justify-content: space-between !important;
                }
            }

            .cd-job-details-header-btns {
                width: 20%;
                justify-content: flex-end;

                @media only screen and (min-width: 320px) and (max-width: 786px) {
                    .cd-job-details-header-btn {
                        width: auto !important;
                        margin: 1vh 0 2vh 0;
                    }
                }

                .cd-create-poster-btn {
                    margin-right: 1vw;

                    .cd-create-poster-btn-img {
                        margin-right: 4px;
                    }

                    .cd-create-poster-btn-txt {}
                }

                .cd-view-posters-btn {
                    margin-right: 1vw;

                    .cd-view-posters-btn-img {
                        margin-right: 4px;
                    }
                }

                @media only screen and (min-width: 320px) and (max-width: 786px) {
                    .cd-view-pitch-btn {
                        max-width: 25% !important;
                    }
                }

                .cd-view-pitch-btn {
                    .cd-view-pitch-btn-img {
                        margin-right: 4px;
                    }
                }
            }
        }

        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .cd-requirements-section {
                width: 100% !important;
            }
        }

        .cd-requirements-section {
            margin-top: 2vw;
            width: 35%;

            .cd-requirements-section-left {
                width: 50%;
            }

            .cd-requirements-section-mid {
                margin: 0 5%;
            }

            .cd-requirements-section-right {
                width: 42%;
            }

            .cd-requirements-section-header {
                color: var(--black-65, rgba(0, 0, 0, 0.65));
                font-size: 14px;
                font-weight: 700;
                text-align: start;
                letter-spacing: 0.5px;
            }

            .cd-requirements-section-requirements-txt {
                color: var(--black-65, rgba(0, 0, 0, 0.65));
                font-size: 14px;
                font-weight: 400;
                text-align: start;
            }

            .cd-vertical-divider {
                // min-height: 50px;
                width: 1px;
                background-color: rgba(0, 0, 0, 0.06);
                // margin: 0 1vw;
            }
        }
    }

    @media only screen and (min-width: 320px) and (max-width: 786px) {
        .cd-filters-section {
            display: block !important;
        }
    }

    .cd-filters-section {
        padding: 0 2vw;
        margin-top: 1vw;
        display: flex;

        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .cd-filters-section-left {
                width: 100% !important;
                margin-bottom: 1vh;
            }
        }

        .cd-filters-section-left {
            width: auto;
            min-width: 35%;
            flex-wrap: wrap;

            .cd-filters-empty-txt {
                color: var(--primary-default, var(--primary-theme-color));
                font-size: 14px;
                font-weight: 700;
            }

            .cd-single-filter-item {
                background-color: rgba(240, 240, 240, 1);
                padding: 4px 8px;
                margin: 0 12px 4px 12px;

                .single-filter-txt {
                    color: var(--primary-default, var(--primary-theme-color));
                    font-size: 12px;
                    font-weight: 400;
                }
            }
        }

        .cd-filters-txt-black {
            color: var(--black-85, rgba(0, 0, 0, 0.85));
            font-size: 14px;
            font-weight: 400;
        }

        .cd-filters-select {
            width: 35%;
            max-width: 175px;
            margin: 0 12px;

            .ant-select-selector {
                border: none;

                .ant-select-selection-item {
                    color: var(--primary-default, var(--primary-theme-color));
                    font-size: 14px;
                    font-weight: 700;
                }
            }

            .ant-select-arrow {
                color: var(--primary-theme-color);
            }
        }

        .ant-btn[disabled] {
            border-radius: 2px;
            border: 1px solid var(--primary-default, var(--primary-theme-color));
            opacity: 0.5;
            background-color: rgba(255, 255, 255, 0);
        }

        .cd-filters-add-filter {
            border-radius: 2px;
            border: 1px solid var(--primary-default, var(--primary-theme-color));
            padding: 0px 7px;
            margin-left: 1vw;
            height: 24px;
            color: var(--primary-theme-color);

            .cd-filter-plus-icon {
                margin-right: 2px;
            }
        }
    }

    .cd-all-cities-listing-container {
        padding: 2vw;
        overflow: scroll;
        padding-bottom: 100px;
    }

    @media only screen and (min-width: 320px) and (max-width: 786px) {
        .cd-all-cities-listing-container {
            max-height: 40%;
            height: 40%;
        }
    }

    @media only screen and (min-width: 320px) and (max-width: 786px) {
        .cd-all-cities-listing-container-overflow {
            max-height: 35%;
            height: 35%;
            margin-bottom: 3vh;
        }
    }

    .cd-all-localities-listing-container {
        overflow: scroll;
        padding: 2vw;
        padding-bottom: 100px;
    }

    @media only screen and (min-width: 320px) and (max-width: 786px) {
        .cd-all-localities-listing-container {
            max-height: 40%;
            height: 40%;
        }
    }

    @media only screen and (min-width: 320px) and (max-width: 786px) {
        .cd-all-localities-listing-container-overflow {
            max-height: 35%;
            height: 35%;
            margin-bottom: 3vh;
        }
    }
}

.cd-all-cities-listing-parent {
    padding: 20px;
    border-radius: 12px;
    border: 1px solid var(--black-15, rgba(0, 0, 0, 0.15));
    background: #FFF;
    margin-top: 1vw;

    @media only screen and (min-width: 320px) and (max-width: 786px) {
        .cd-city-openings {
            flex-direction: column;
            justify-content: flex-start !important;
        }
    }

    .cd-city-openings {
        display: flex;
        justify-content: space-between;
        width: 30%;

        .cd-acl-city {
            .cd-acl-city-icon {
                font-size: 18px;
                margin-right: 0.25vw;
                color: rgba(0, 0, 0, 0.45);
            }

            @media only screen and (min-width: 320px) and (max-width: 786px) {
                .cd-acl-city-name-txt {
                    margin-left: 0.5vh !important;
                }
            }

            .cd-acl-city-name-txt {
                color: rgba(0, 0, 0, 0.85);
                font-size: 16px;
                font-weight: 600;
            }
        }

        .cd-all-cities-total-openings-txt {
            color: var(--black-65, rgba(0, 0, 0, 0.65));
            text-align: right;
            font-size: 14px;
            font-weight: 400;
            margin-left: 22px;
        }
    }

    .cd-acl-view-localities {
        .cd-acl-view-localities-txt {
            color: var(--primary-default, var(--primary-theme-color));
            text-align: center;
            font-size: 14px;
            font-weight: 700;
        }

        .cd-acl-view-localities-icon {
            margin-left: 0.5vw;
        }
    }
}

.cd-all-localities-listing-parent {
    border-radius: 12px;
    border: 1px solid var(--black-15, rgba(0, 0, 0, 0.15));
    background: #FFF;
    padding: 24px;
    margin-bottom: 1vw;

    @media only screen and (min-width: 320px) and (max-width: 786px) {
        .cd-al-vertical-divider {
            margin: 0 0.5vh !important;
        }
    }

    .cd-al-vertical-divider {
        width: 1px;
        height: 18px;
        background-color: rgba(0, 0, 0, 0.35);
        margin: 0 1vw;
    }

    .cd-al-locality-openings {
        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .cd-al-locality-txt {}
        }

        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .cd-al-locality {
                width: 50%;
            }
        }

        .cd-al-locality {
            width: 60%;
        }

        .cd-al-locality-txt {
            color: rgba(0, 0, 0, 0.85);
            font-size: 16px;
            font-weight: 600;
            margin-left: 0.5vw;
            text-align: left;
            max-width: 80%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .cd-al-openings {
                width: 50%;
            }
        }

        .cd-al-openings {
            width: 40%;

            .cd-al-openings-txt {
                color: var(--primary-default, var(--primary-theme-color));
                font-size: 14px;
                font-weight: 500;
                text-align: left;
                max-width: 80%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .cd-al-part-time-openings-txt {
                color: var(--black-45, rgba(0, 0, 0, 0.45));
                font-size: 14px;
                font-weight: 400;
                text-align: left;
                max-width: 80%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }

    .cd-al-onboarding-upfront-fee {
        margin-top: 1vw;

        .cd-al-vehicle-fees {
            display: flex;
        }

        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .cd-al-vehicle-fees {
                flex-direction: column;
            }
        }

        .cd-al-vehicle-required-txt {
            color: var(--black-45, rgba(0, 0, 0, 0.45));
            font-size: 14px;
            font-weight: 400;
            text-align: left;
        }

        .cd-al-fee-label {
            color: rgba(0, 0, 0, 0.65);
            font-size: 14px;
            font-weight: 400;
        }

        .cd-al-onboarding-fee-txt {
            color: var(--primary-default, var(--primary-theme-color));
            font-size: 14px;
            font-weight: 700;
        }

        .cd-al-upfront-fee-txt {
            color: var(--black-65, rgba(0, 0, 0, 0.65));
            font-size: 14px;
            font-weight: 700;
        }
    }

    @media only screen and (min-width: 320px) and (max-width: 786px) {
        .cd-al-joining-bonus-btn {
            margin-top: 1.5vh !important;
        }
    }

    .cd-al-joining-bonus-btn {
        margin-top: 1.5vw;

        .cd-al-joining-bonus {
            background-color: #2DB7F5;
            color: #FFF;
            font-size: 12px;
            font-weight: 700;
            text-align: center;
            padding: 1px 8px;
            border-radius: 2px;
        }

        .cd-al-refer-btn {
            border-radius: 2px;
            background: var(--primary-default, var(--primary-theme-color));
            box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);

            .cd-al-refer-btn-txt {
                color: #FFF;
                text-align: center;
                font-size: 14px;
                font-weight: 600;
            }
        }
    }
}