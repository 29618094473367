.individual-card {
    border-radius: 8px;
    border: 1px solid #C8C9CC;
    background: #FFF;
    position: relative;
    width: 48%;
    display: flex;
    padding: 16px 24px 24px 16px;
    gap: 12px;
    cursor: pointer;
    min-width: 450px;

    transition: box-shadow 0.3s ease-in-out,  border 0.3s ease-in-out;
}

.custom-checkbox-styles > .ant-checkbox-wrapper {
    margin: 0px !important;
}

.custom-checkbox-styles > .ant-checkbox-wrapper > .ant-checkbox > .ant-checkbox-inner {
    /* border-radius: 4px !important;
    border: 2px solid #D7D7D8 !important;
    background: #D7D7D8 !important; */

    border-radius: 4px;
    border: 1px solid #83B5FF !important;
    background: #FFF !important;
}

.custom-checkbox-styles > .ant-checkbox-wrapper-checked > .ant-checkbox > .ant-checkbox-inner {
    border-radius: 4px !important;
    border: 2px solid #1D5FFF !important;
    background: #1D5FFF !important;
}

.custom-checkbox-styles > .ant-checkbox-wrapper-checked > .ant-checkbox > .ant-checkbox-inner::after {
    width: 2px;
    height: 5px;
}

.individual-card:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border: none;
}

.ic-checkbox-section {
    width: 5%;
}

.ic-content-section {
    width: 100%;
}

.ic-content-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.user-client-details {
    display: flex;
    align-items: center;
    gap: 8px;
}

.ic-user-details {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.ic-contact-details {
    display: flex;
    align-items: center;
    gap: 12px;
}

.user-referral-details {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}

.ic-tags-section {
    margin-top: 16px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
}

.ic-tag {
    border-radius: 20px;
    background: #4EBB57;
    padding: 4px 12px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.ic-milestone-section {
    margin-top: 48px;
    display: flex;
    align-items: flex-start;
    gap: 8px;
}

.success-indicator {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #4EBB57;
    margin-top: 4px;
}

.step-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.progress-line-indicator {
    height: 1px;
    width: 130px;
    background-color: #C8C9CC;
    margin-top: 7px;
    margin-left: 0px;
}

.inprogress-indicator {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 2px solid #E8BC1D;
    margin-top: 4px;
}

.ld-lead-info {
    border-radius: 4px;
    background: #F6F6F6;
    display: flex;
    flex-direction: column;
    padding: 26px 24px;
}

.new-ld-timeline-container {
    position: relative;
}

.floating-rider-image-styles {
    width: 400px;
    height: 400px;
    object-fit: contain;
}

.floating-rider-image {
    position: absolute;
    top: 10%;
    right: -61px;
}

.ld-notes-top-section {
    /* margin-top: 32px; */
}

.call-details-data {
    margin-top: 32px;
    gap: 64px;
}

.call-note-card-main-container {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    padding-bottom: 40px;
    margin-top: 32px;
    gap: 32px;
}

.individual-call-note-card {
    border-radius: 8px;
    border: 1px solid #E5ECFF;

    display: flex;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.cn-call-status {
    border-radius: 20px;
background: #EFF4D4;
display: flex;
padding: 4px 12px;
align-items: center;
gap: 12px;
color: #252526;

font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
}

.cn-remarks-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    margin-top: 32px;
}

.cn-remarks-header {
    color: #AFAFAF;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
}

.cn-remark {
    color: #000;
    font-family: Inter;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;

    border-radius: 4px;
    background: #E6E6E6;
    padding: 8px 16px 8px 16px;
    text-align: start;
}

.image-container {
    position: relative;
    width: 40px;
    height: 40px;
    margin-right: 4px;
  }
  
  .indicator {
    border: 1px solid #80808063;
    position: absolute;
    width: 40px; 
    height: 40px;
    background-color: #09121F26;
    border-radius: 4px; 
    top: -4px; 
    left: -4px; 
    z-index: 1; 
  }
  
  .image-back {
    border: 1px solid #80808063;
    position: absolute;
    width: 40px;
    height: 40px;
    top: 0px;
    left: 0px;
    z-index: 2;
  border-radius: 4px
  }
  
  .image-front {
    border: 1px solid #80808063;
    position: absolute;
    width: 40px;
    height: 40px;
    top: 4px;
    left: 4px;
    z-index: 3;
    border-radius: 4px
  }
  

@media screen and (max-width: 800px) {
    .individual-card {
        min-width: 100%;
        width: 100%;
    }

    .ic-content-header {
        flex-wrap: wrap;
        gap: 16px;
    }

    .user-referral-details {
        align-items: flex-start;
    }

    .ic-checkbox-section {
        width: 1%;
    }

    .call-details-data {
        margin-top: 16px;
        gap: 24px;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .ld-lead-info {
        padding: 12px;
    }
}