.back-text {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.margin-horizontal-32 {
    margin-left: 32px;
    margin-right: 32px;
}

.back-navigation {
    gap: 8px;
    margin: 24px 32px 0px;
    cursor: pointer;
    width: max-content;
}

.lead-list-hero-text {
    margin: 20px 32px 0px;
    color: #252526;
    text-align: center;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}

.tab-and-btn-container {
    padding: 24px 32px 0px;
}

.session-brief-description {
    display: flex;
    margin: 16px 48px;
    justify-content: space-between;
}

.session-desc {
    display: flex;
    align-items: center;
    gap: 16px;
}

.session-desc-individual-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.session-desc-individual-item-header {
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}

.session-desc-individual-item-content {
    color: #747474;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.session-type-container {
    border-radius: 20px;
    /* border: 1px solid #000; */
    background: #C7D3FF;
    display: flex;
    width: 129px;
    line-height: 14px;
    height: 29px;
    padding: 7px 6px;
    flex-direction: column;
    /* align-items: flex-start; */
    gap: 10px;
    color: #000;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
}

.create-session-btn {
    cursor: pointer;
    display: flex !important;
    align-items: center;
    justify-content: center;
    border-radius: 10px !important;
    border: 2px solid #214498 !important;

    background: #1D5FFF !important;
    height: 43px !important;
    width: 254px;
    gap: 4px;
}

.close-session-btn {
    cursor: pointer;
    display: flex !important;
    align-items: center;
    justify-content: center;
    border-radius: 10px !important;
    border: 2px solid #214498 !important;

    background: #1D5FFF !important;
    height: 43px !important;
    width: 254px;
    gap: 4px;
}

.create-session-btn-disabled {
    cursor: not-allowed;
    display: flex !important;
    align-items: center;
    justify-content: center;
    border-radius: 10px !important;
    background-color: #C8C9CC !important;
    border: 1px solid #4D4D4D !important;
    height: 43px !important;
    width: 254px;
    gap: 4px;
}

.create-session-btn-text {
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;

    display: flex !important;
    align-items: center;
    justify-content: center;
    gap: 4px;
}

.close-session-btn-text {
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;

    display: flex !important;
    align-items: center;
    justify-content: center;
    gap: 4px;
}

.card-tile-container {
    margin-top: 24px;
    display: flex;
    gap: 24px;
}

.single-tile {
    background: #FFF;
    padding: 16px 16px 24px 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 10px;
    height: 186px;
}

.shaded-section {
    height: 100%;
    width: calc(100% + 6px);
    background-color: #4E4E4E;
    border-radius: 10px;
    top: 5px;
    left: 0px;
    position: absolute;
    z-index: -1;
}

.number-text {
    color: #252526;
    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    margin-top: 48px;
    text-align: start;
}

.shaded-tile-container {
    border-radius: 10px;
    border: 1px solid #AFCFFF;
    width: 370px;
    position: relative;
    z-index: 1;
    max-height: 190px;
}

.tile-title-text {
    color: #252526;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    text-align: start;
    margin-top: 24px;
}

.icon-percent-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.percentage-text {
    color: #252526;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}

.filtered-candidate-card-container {
    margin-top: 24px;
    background: #FFF;
    border-radius: 10px;
    padding: 32px 24px;
    margin-bottom: 32px;
}

.gray-main-container {
    background: #FDFAF9;
    max-height: var(--additional-info-screen-height);
    height: var(--additional-info-screen-height);
    overflow: scroll;
    padding-top: 32px;
    padding-bottom: 85px;
}

.filtered-candidate-tab-container {
    gap: 24px;
}

.filtered-candidate-single-tab {
    color: #ACACAC;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    cursor: pointer;
}

.filtered-candidate-active-tab {
    color: #1D5FFF;
    font-weight: 700;
    border-bottom: 2px solid #1D5FFF;
}

.filtered-candidate-top-section {
    border-bottom: 1px solid #e2e2e2;
}

.filtered-candidate-options {
    gap: 40px
}

.sessionlist-filter-popup {
    display: flex !important;
}

.session-mobile-sidebar {
    display: none !important;
}

.session-list-pagination {
    margin: 12px 0px 0px 90% !important;
}

.single-option-container {
    gap: 8px;
    cursor: pointer;
}

.single-option-text {
    color: #252526;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.filtered-candidate-cards {
    flex-wrap: wrap;
    margin-top: 24px;
    margin-bottom: 24px;
    gap: 24px;
}

.filtered-individual-candidate {
    width: 48%;
    height: 188px;
    border-radius: 10px;
    background: #F6F6F6;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.filtered-individual-candidate:nth-child(odd) {
    background-color: #FFF5EC;
}

.filtered-individual-candidate:nth-child(even) {
    background-color: #CCE5E2;
}

.hoverable-card {
    box-shadow: none;

    transition: box-shadow 0.3s ease-in-out;
}

.hoverable-card:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.filtered-lead-info-container {
    padding: 16px;
    display: flex;
    justify-content: space-between;
}

.non-referred-img {
    width: 30px;
    padding-top: 12px;
}

.client-info-section {
    height: 42px;
    display: flex;
    align-items: center;
    padding-left: 22px;
}

.filtered-candidate {
    text-align: start;
    margin-left: 8px;
}

.action-ctas {
    display: flex;
    align-items: flex-start;
    gap: 8px;
}

.filtered-lead-name {
    color: #252526;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    text-align: start;
}

.filtered-lead-number {
    color: #252526;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}

.filtered-lead-other-details {
    padding: 0px 16px;
    gap: 32px;
}

.filtered-lead-header {
    color: #252526;
    text-align: center;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.filtered-lead-sub-header {
    color: #252526;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}

.chip-status {
    border-radius: 10px;
    padding: 0px 21px;
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.chip-status-interested {
    background: #40B746;
}

.chip-status-not-interested {
    background: #D83D3D;
}

.chip-status-rnr {
    background: #FF6A2A;
}

.chip-status-not-eligible {
    background: #E8E9EB;
    color: #000;
}

.ineligible-dropdown-container {
    border-radius: 4px;
    border: 1px solid #F3F5F8;
    background: #FFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 24px 32px;
    padding: 16px 18px;
    position: relative;
}

.ineligible-leads-detail-section {
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
    padding: 12px 12px 22px;
    width: 650px;
    position: absolute;
    right: 0;
    top: 52px;
    gap: 24px;
}

.ineligible-leads-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px 8px;
}

.create-session-btn-mobile {
    display: none !important;
}

.leads-dashboard-header {
    margin: 20px 32px 20px 12px;
}

.session-close-icon {
    height: 64px;
    width: 64px;
    object-fit: contain;
}

.session-close-bg {
    background-color: #F6A476;
    padding: 32px 0px;
}

.close-session-text-content {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 24px;
}

.close-session-cta-section {
    width: 90%;
    margin: 24px auto;
    padding: 24px 0px 12px;
    display: flex;
    justify-content: space-around;
}

.session-stop-primary {
    border: 1px solid #4B5265 !important;
    width: 45%;
}

.session-stop-secondary {
    border-radius: 8px !important;
    border: 1px solid #1D5FFF;
    background: #DAE5FF !important;
    width: 45%;
}

.close-session-modal .ant-modal-content {
    border-radius: 12px;
    width: fit-content;
    padding: 0px !important;
    overflow: hidden;
    margin: 0 auto;
}


@media only screen and (min-width: 320px) and (max-width: 786px) {
    .session-brief-description {
        flex-wrap: wrap;
        gap: 16px;
        margin-top: 24px;
    }

    .close-session-content-header {
        font-size: 18px !important;
    }

    .close-session-content-sub-header {
        font-size: 12px !important;
    }

    .card-tile-container {
        overflow: scroll;
        margin: 0px !important;
        height: 200px;
        margin-top: 24px !important;
        scroll-snap-type: x mandatory;
    }

    .shaded-tile-container {
        scroll-snap-align: center;
        height: fit-content !important;
    }

    .single-tile {
        /* height: fit-content !important; */
    }

    .card-tile-container > .shaded-tile-container:nth-child(1) {
        margin-left: 12px;
    }

    .card-tile-container > .shaded-tile-container:nth-child(3) {
        margin-right: 12px;
    }

    .card-tile-container>.shaded-tile-container>.single-tile {
        width: 280px;
    }

    .filtered-candidate-card-container {
        margin: 32px 18px;
    }

    .single-option-text {
        display: none;
    }

    .filtered-candidate-options {
        gap: 24px;
    }

    .sessionlist-filter-popup {
        display: none !important;
    }

    .session-mobile-sidebar {
        display: flex !important;
        align-items: center;
    }

    .filtered-individual-candidate {
        width: 100%;
        height: auto;
        padding-bottom: 20px;
    }

    .client-info-section {
        padding-top: 16px;
    }

    .filtered-lead-other-details {
        gap: 16px;
    }

    .filtered-lead-other-details {
        flex-wrap: wrap;
    }

    .filtered-lead-other-details>.field-container {
        width: 40%;
    }

    .lead-list-hero-text {
        margin: 20px 24px 0px;
        font-size: 20px;
    }

    .no-additional-margin {
        margin: 0px !important;
    }

    .tab-and-btn-container {
        padding: 24px 24px 0px;
    }

    .session-brief-description {
        margin: 16px 24px;
    }

    .filtered-candidate-cards {
        justify-content: center;
    }

    .filtered-candidate-top-section {
        margin: 0px 24px;
    }

    .create-session-btn-text {
        display: none !important;
        margin: 0px !important;
    }

    .create-session-btn {
        height: 36px !important;
        width: 36px;
        border-radius: 50% !important;
        gap: 0px;
        border: none !important;
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
        display: none !important;
    }

    .create-session-btn-disabled {
        height: 36px !important;
        width: 36px;
        border-radius: 50% !important;
        gap: 0px;
        border: none !important;
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
        display: none !important;
    }

    .create-session-btn-mobile {
        display: flex !important;
    }

    .session-list-pagination {
        margin: 12px 0px 0px 60% !important;
    }

    .back-navigation {
        gap: 8px;
        margin: 24px 24px 0px;
        cursor: pointer;
    }

    .ineligible-leads-detail-section {
        width: 100%;
    }

    .ineligible-dropdown-container {
        margin: 24px 24px;
    }

    .filter-section-sl {
        flex-direction: column;
        gap: 24px;
    }

    .close-session-btn {
        width: 43px !important;
        border-radius: 50% !important;
    }

    .close-session-btn-text {
        display: none !important;
    }

    .leads-dashboard-header {
        margin: 20px 12px;
    }
}