.parentContainer {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    color: #fff;
    display: flex;
    justify-content: space-between;
    z-index: 13;

    .containerSS {
        position: fixed;
        width: 30%;
        height: 100%;
        right: 0;
        top: 0;
        background-color: #ffffff;
        color: #000;
        animation: sidemover 0.5s linear;
        display: flex;
        flex-direction: column;

        // justify-content: space-between;
        .navigator {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #f0f0f0;
            padding-bottom: 20px;
            margin: 20px;

            .nav1 {
                display: flex;

                .fs-filter-icon {
                    font-size: 20px;
                }

                .sidername {
                    margin-left: 8px;
                    color: var(--black-85, rgba(0, 0, 0, 0.85));
                    text-align: center;
                    font-size: 20px;
                    font-weight: 400;
                }
            }

            .fs-header-close {
                padding: 4px;
                border-radius: 2px;
                border: 1px solid rgba(0, 0, 0, 0.15);
                background: #FFF;

                .fs-header-close-icon {
                    font-size: 16px;
                    color: var(--primary-theme-color);
                }
            }

            img {
                cursor: pointer;
            }
        }

        @keyframes sidemover {
            0% {
                transform: translateX(100%);
            }

            100% {
                transform: translateX(0%);
            }
        }

        .childContainer {
            height: calc(100% - 140px);
            overflow-y: scroll;
        }

        .btns {
            position: absolute;
            display: flex;
            width: 100%;
            bottom: 30px;
            background-color: #fff;
            padding-left: 20px;
            padding-right: 20px;

            .ant-btn-primary {
                border-color: var(--primary-theme-color);
                background: var(--primary-theme-color);
            }

            .fs-btn-txt-orange {
                color: var(--primary-default, var(--primary-theme-color));
            }

            .fs-btn-txt-white {
                color: white;
            }

            .fs-btn-txt {
                text-align: center;
                font-size: 14px;
                font-weight: 600;
            }

            .fs-delete-icon {
                font-size: 14px;
                color: var(--primary-theme-color);
            }

            .fs-apply-icon {
                font-size: 14px;
                color: white;
                margin-left: 8px;
            }

            .commonlayoutbtn {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                // font-weight: 700;
                margin: 0 auto;
                width: 90%;
            }

            .fs-clear-btn {
                border-radius: 2px;
                border: 1px solid var(--primary-default, var(--primary-theme-color));
                background: var(--Neutral-1, #FFF);
                box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
            }

            .submit {
                @extend .commonlayoutbtn;
                background-color: var(--primary-theme-color);
                color: #fff;
            }

            .disabledSubmit {
                @extend .submit;
                background-color: var(--page-bg-color);
                color: rgba(0, 0, 0, 0.25);
                pointer-events: none;
            }
        }
    }

    .emptyContainer {
        width: 100%;
    }
}

/* Portrait and Landscape from Iphone to Ipad */
@media only screen and (min-device-width: 320px) and (max-device-width: 1024px) {
    .parentContainer {
        width: 100%;

        .containerSS {
            width: 100%;

            .navigator {
                margin: 0;
                padding: 15px;
                border-bottom: 4px solid rgba(245, 245, 245, 1);
            }
        }

        .emptyContainer {
            display: none;
        }
    }
}