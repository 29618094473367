.lead-view-card-parent {
    background-color: white;
    padding: 16px 24px;
    border-radius: 12px;
    border: 1px solid var(--black-15, rgba(0, 0, 0, 0.15));
    background: #FFF;
    display: flex;
    margin-top: 1vw;

    .lvc-sub-txt {
        color: var(--black-45, rgba(0, 0, 0, 0.45));
        font-size: 12px;
        font-weight: 400;
    }

    @media only screen and (min-width: 320px) and (max-width: 786px) {
        .lvc-info {
            width: 100% !important;
        }
    }

    .lvc-info {
        width: 50%;

        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .lvc-company-logo {
                margin-right: 1vh;
            }
        }

        .lvc-company-logo {
            margin-right: 1vw;

            .lvc-company-logo-img {
                height: 40px;
                width: 40px;
                border-radius: 4px;
                object-fit: contain;
            }
        }

        .lvc-candidate-info {

            .lvc-candidate-name {
                color: rgba(0, 0, 0, 0.85);
                font-size: 16px;
                font-weight: 600;
                text-align: start;
            }

            .lvc-location-and-registered-on {
                .lvc-location {
                    .lvc-location-img {
                        margin-right: 4px;
                    }
                }

                .lvc-registered-on-txt {
                    color: var(--black-65, rgba(0, 0, 0, 0.65));
                    font-size: 12px;
                    font-weight: 400;
                }
            }
        }
    }

    @media only screen and (min-width: 320px) and (max-width: 786px) {
        .lvc-btns {
            width: 100% !important;
            margin-top: 2vh;
            justify-content: space-between !important;
        }
    }

    .lvc-btns {
        width: 50%;
        justify-content: flex-end;

        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .lvc-copy-message-mobile {
                width: 60%;
            }
        }

        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .lvc-refer-btn-container {
                width: 35%;
                display: flex;
                justify-content: flex-end;
            }
        }

        .lvc-btn-img-orange {
            color: var(--primary-theme-color);
            margin-right: 4px;
        }

        .lvc-btn-img-white {
            color: white;
            margin-right: 4px;
        }

        .lvc-copy-phone-btn {
            border-radius: 2px;
            border: 1px solid var(--primary-default, var(--primary-theme-color));
            background: var(--Neutral-1, #FFF);
            box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);

            .lvc-copy-phone-btn-txt {
                color: var(--primary-default, var(--primary-theme-color));
                text-align: center;
                font-size: 14px;
                font-weight: 600;
            }
        }

        .lvc-whatsapp-btn {
            border-radius: 2px;
            border: 1px solid var(--primary-default, var(--primary-theme-color));
            background: var(--Neutral-1, #FFF);
            box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);

            .lvc-whatsapp-btn-txt {
                color: var(--primary-default, var(--primary-theme-color));
                text-align: center;
                font-size: 14px;
                font-weight: 600;
            }
        }

        .lvc-refer-btn {
            border-radius: 2px;
            background-color: var(--primary-default, var(--primary-theme-color));
            box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);

            .lvc-refer-btn-txt {
                color: #FFF;
                text-align: center;
                font-size: 14px;
                font-weight: 600;
            }
        }

        .lvc-btn-margin {
            margin-left: 12px;
        }
    }
}

@media only screen and (min-width: 320px) and (max-width: 786px) {
    .lead-view-card-parent {
        flex-direction: column;
    }
}

@media only screen and (min-width: 320px) and (max-width: 786px) {
    .poster-view-card-parent {
        min-width: 100% !important;
        max-width: 100% !important;
        margin-top: 1.5vh !important;
    }
}

.poster-view-card-parent {
    min-width: 49%;
    max-width: 49%;
    padding: 28px 24px;
    background-color: white;
    border-radius: 12px;
    margin-top: 1.5vw;

    .pvc-created-on-section {
        text-align: start;
        color: var(--black-45, rgba(0, 0, 0, 0.45));
        font-size: 14px;
        font-weight: 500;

        .pvc-created-on-txt {
            color: var(--black-85, rgba(0, 0, 0, 0.85));
            font-size: 14px;
            font-weight: 500;
        }
    }

    .pvc-company-logo-name {
        .pvc-company-logo-img {
            height: 40px;
            width: 40px;
            object-fit: contain;
        }

        .pvc-company-name-txt {
            margin-left: 1vw;
            color: var(--black-65, rgba(0, 0, 0, 0.65));
            font-size: 20px;
            font-weight: 400;
        }
    }

    @media only screen and (min-width: 320px) and (max-width: 786px) {
        .pvc-location-leads {
            margin-top: 0.5vh !important;
        }
    }

    .pvc-location-leads {
        .pvc-location {
            .pvc-location-icon {
                color: rgba(0, 0, 0, 0.45);
            }

            .pvc-location-txt {
                color: var(--black-45, rgba(0, 0, 0, 0.45));
                font-size: 14px;
                font-weight: 400;
                margin-left: 4px;
            }
        }

        .pvc-total-leads-icon {
            color: var(--primary-theme-color);
        }

        .pvc-total-leads-txt {
            color: var(--primary-theme-color);
            font-size: 16px;
            font-weight: 700;
            margin-left: 4px;
        }
    }

    @media only screen and (min-width: 320px) and (max-width: 786px) {
        .pvc-btns {
            margin-top: 3vh !important;
        }
    }

    .pvc-btns {
        margin-top: 1vw;

        .pvc-btn-img-orange {
            color: var(--primary-theme-color);
        }

        .pvc-btn-img-white {
            color: white;
        }

        .pvc-btn {
            width: 45%;
        }

        .pvc-view-poster-btn {
            border-radius: 2px;
            border: 1px solid var(--primary-default, var(--primary-theme-color));
            box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);

            .pvc-view-poster-btn-img {
                margin-right: 6px;
            }
        }

        .pvc-view-leads-btn {
            border-radius: 2px;
            background-color: var(--primary-theme-color);
            box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
            color: white;

            .pvc-view-leads-btn-img {
                margin-left: 6px;
            }
        }
    }
}

.poster-view-card-margin {
    margin-left: 2vw;
}

.poster-leads-listing-parent {
    .pll-job-details-header {
        background-color: rgba(0, 0, 0, 0.06);
        padding: 2vw 3vw;

        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .pll-company-info-btns {
                flex-direction: column;
                justify-content: flex-start !important;
                align-items: flex-start !important;
            }
        }

        .pll-created-on {
            color: var(--black-65, rgba(0, 0, 0, 0.65));
            font-size: 14px;
            font-weight: 500;
            justify-content: flex-start;

            .pll-created-on-date {
                color: var(--black-85, rgba(0, 0, 0, 0.85));
                font-size: 14px;
                font-weight: 500;
            }
        }

        .pll-company-info-btns {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .pll-company-logo-img {
                height: 40px;
                width: 40px;
                object-fit: contain;
            }

            @media only screen and (min-width: 320px) and (max-width: 786px) {
                .pll-company-name-type {
                    margin-left: 1.5vh !important;
                }
            }

            .pll-company-name-type {
                margin-left: 1vw;

                .pll-company-name {
                    color: var(--black-65, rgba(0, 0, 0, 0.65));
                    font-size: 20px;
                    font-weight: 400;
                }

                .pll-company-type {
                    color: var(--black-45, rgba(0, 0, 0, 0.45));
                    font-size: 14px;
                    font-weight: 400;
                    text-align: start;
                }
            }

            @media only screen and (min-width: 320px) and (max-width: 786px) {
                .pll-job-details-header-btns {
                    width: 100% !important;
                    justify-content: space-between !important;
                    margin-top: 3vh;
                    margin-bottom: 1vh;
                }
            }

            @media only screen and (min-width: 320px) and (max-width: 786px) {
                .pll-view-download-poster-btns {
                    width: 60%;
                }
            }

            .pll-job-details-header-btns {
                width: 20%;
                justify-content: flex-end;

                @media only screen and (min-width: 320px) and (max-width: 786px) {
                    .pll-job-details-header-btn {
                        width: auto !important;
                        // margin: 1vh 0 2vh 0;
                    }
                }

                .pll-create-poster-btn {

                    .pll-create-poster-btn-img {
                        margin-right: 4px;
                    }

                    .pll-create-poster-btn-txt {}
                }

                .pll-view-posters-btn {
                    margin: 0 1vw;

                    .pll-view-posters-btn-img {
                        margin-right: 4px;
                    }
                }

                @media only screen and (min-width: 320px) and (max-width: 786px) {
                    .pll-share-poster-btn {
                        // max-width: 25% !important;
                    }
                }

                .pll-share-poster-btn {
                    .pll-share-poster-btn-img {
                        margin-right: 4px;
                    }
                }
            }
        }

        .pll-location-details {
            margin-top: 1vh;

            .pll-location-icon {
                font-size: 14px;
            }

            .pll-location-txt {
                color: var(--black-45, rgba(0, 0, 0, 0.45));
                font-size: 14px;
                font-weight: 400;
                margin-left: 1vh;
            }
        }

        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .pll-requirements-section {
                width: 100% !important;
            }
        }

        .pll-requirements-section {
            margin-top: 2vw;
            width: 30%;

            .pll-requirements-section-left {
                width: 45%;
            }

            .pll-requirements-section-mid {
                margin: 0 5%;
            }

            .pll-requirements-section-right {
                width: 40%;
            }

            .pll-requirements-section-header {
                color: var(--black-65, rgba(0, 0, 0, 0.65));
                font-size: 14px;
                font-weight: 700;
                text-align: start;
                letter-spacing: 0.5px;
            }

            .pll-requirements-section-requirements-txt {
                color: var(--black-65, rgba(0, 0, 0, 0.65));
                font-size: 14px;
                font-weight: 400;
                text-align: start;
            }

            .pll-vertical-divider {
                // min-height: 50px;
                width: 1px;
                background-color: rgba(0, 0, 0, 0.06);
                // margin: 0 1vw;
            }
        }
    }

    @media only screen and (min-width: 320px) and (max-width: 786px) {
        .pll-leads-listing {
            max-height: 56%;
            overflow: scroll;
        }
    }

    .pll-leads-listing {
        padding: 2vw 3vw;
    }
}