.signup_form .formio-component-button {
  /* font-family: 'Noto Sans Medium'; */
  background-color: inherit;
  text-transform: uppercase;
  font-size: 0.8rem;
  padding: 0px;
  margin: 0px;
  text-align: center;
}

/* .Signup .vahan {
    margin-bottom: 50px;
} */
.modal-cookie {
  position: fixed; /* Stay in place */
  z-index: 20; /* Sit on top */
  left: 0;
  text-align: center;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-cookie-content {
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  margin: auto;
  margin-top: 20%;
  width: 80%;
  border-radius: 10px;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.App {
  background-color: #ffffff;
}
// .parentFormClass  select {
//   float: right;
//   width: 35%;
//   margin-top: -13%;
//   margin-right: 1.25%;
// }
// .parentFormClass  h3 {
//   // margin-top: 3% !important;
//   // font-family: 'Nunito Sans Bold';
//   // margin-left: 7%;
//   // text-align: start;
// }
.vahan-logo.mitra-logo {
  float: left;
  max-width: 7em;
  margin-left: 2%;
  // margin-top: 1em !important;
}

.signup-main-container {
  // display: flex;
  // flex-direction: row;
  height: 100vh;
  display: flex;
  
  // .main-login-container
  // {
    .right-form-class {
      float: right;
      margin: 0;
      flex: 3;

      // .signup_form{
      .parentFormClass{
        display: flex;
        flex-direction: column;
        
        h3 {
            margin-top: 3% !important;
            font-family: var(--primary-font-family);
        font-weight: 700;
            margin-left: 7%;
            text-align: start;
          }

        .header{
          select {
            float: right;
            width: 35%;
            // margin-top: -13%;
            margin-right: 1.25%;
          }
          display: flex;
          justify-content: space-between;
        }
      }
      // }

      .OtpMessage {
        text-align: left;
        font-weight: 600;
        margin-left: 8%;
        margin-top: 5%;
      }
    }
  // }
}

// .main-login-container
.vahan-logo-png {
  max-width: 80px;
}
.main-poster {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background: var(--primary-theme-light);
  padding: 30px;
  flex: 7;
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%), 0 1px 3px 0 rgb(0 0 0 / 20%);
  height: 100%;
  position: relative;
  // width: 67%;

  .poster-container {
    width: 100%;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;

    .poster-top {
      // flex: 3;
      display: flex;
      flex-direction: row;
      height: 80%;

      .poster-left {
        display: flex;
        flex-direction: column;
        gap: 30px;

        .poster-title {
          font-weight: 900;
          font-size: 1.5rem;
          display: flex;
          flex-direction: column;
          text-align: start;

          .text-props {
            color: #595959;
            font-size: 1.4rem;
            font-weight: 600;
          }
        }

        .poster-middle {
          .text-props {
            color: var(--primary-theme-color);
          }

          font-family: var(--primary-font-family);
          font-weight: 900;
          font-size: 2.9rem;
          line-height: 3.4rem;

          text-align: start;
        }

        .poster-perks {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0px;
          gap: 25px;

          .perk-props {
            display: flex;
            align-items: center;
            gap: 10px;

            .perk-detail {
              text-align: start;
              font-weight: 500;
              font-size: 1.4rem;
              line-height: 1.4rem;
            }

            .perk-icon {
              height: 2.5rem;
              width: 2.5rem;
            }
          }
        }
      }

      .poster-right{
        position: relative;
        height: 100%;
        .poster-image {
          height: 33rem;
          bottom: 0;
          position: absolute;
        }
      }
    }

    .poster-bottom {
      padding: 20px;
      border-radius: 4px;
      height: 25%;

      background: #FFFFFF;
      border-radius: 4px;
      width: 100%;

      .bottom-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;

        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        text-align: center;

        .text-props {
          font-weight: 900;
        }

        .logo-container {
          text-align: start;

          .bottom-logos {
            border: 1px solid #F0F0F0;
            border-radius: 4px;
            margin: 4px;

            height: 40px;
            width: 95px;
          }
        }
      }
    }
  }
}
div .sendOtpAndBack {
  color: white;
  background: var(--primary-theme-color);
  padding: 8px 12px;
  width: 85%;
  margin-right: 2%;
  margin-top: 1rem;
  text-transform: capitalize;
  font-weight: 600;
}
.main-login-container.right-form-class div .arrow-back.sendOtpAndBack {
  border: 1px dashed #D9D9D9;
}
.main-login-container.right-form-class .arrow-back {
  position: relative !important;
  left: 0;
}
// .main-login-container.right-form-class .OtpMessage {
//   text-align: left;
//   font-weight: 600;
//   margin-left: 8%;
//   margin-top: 5%;
// }
.OtpMessage .btn_tertiary {
  color: #2F54EB;
}

@media screen and (max-width: 400px) {
  .modal-cookie-content {
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    margin: auto;
    margin-top: 20%;
    border-radius: 10px;
    width: 80%;
  }
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .signup-main-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .main-poster {
    width: 100%;
    flex: 1;
    padding: 13px;

    .poster-container {
      .poster-top {  
        position: relative;
        .poster-left {
          gap: 20px;
          .poster-title {
            font-size: 13px;
  
            .text-props {
              font-size: 13px;
            }
          }
  
          .poster-middle {
            font-size: 22px;
            line-height: 24px;
          }
  
          .poster-perks {
            gap: 15px;
  
            .perk-props {  
              .perk-detail {
                font-size: 12px;
                line-height: 12px;
              }
  
              .perk-icon {
                height: 20px;
                width: 20px;
              }
            }
          }
        }
  
        .poster-right{
          position: static !important;
          .poster-image {
            height: 242px;
            bottom: 0;
            right:  0;
            margin-bottom: -40px;
            position: absolute;
          }
        }
      }

      .poster-bottom {      
        margin-top: 40px;
        height: 100%;
        padding: 10px;
        .bottom-container {  
          font-size: 14px;  
          gap: 7px;
          .logo-container {  
            .bottom-logos {  
              height: 25px;
              width: 60px;
            }
          }
        }
      }
    }
  }

  .main-login-container.right-form-class {
    width: 100%;
    flex: 3;
  }
}

@media screen and (min-width: 400px) {
  .modal-cookie-content {
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    margin-left: 3%;
    border-radius: 10px;
    margin-top: 30%;
    width: 25%;
  }
}

@media screen and (max-width: 676px) {
  // .main-poster {
  //   max-width: 100%;
  // }
  p.powered {
    margin-top: 5%;
  }
}
@media screen and (min-width: 676px) {
  // .main-poster {
  //   height: 100vh;
  // }
  p.powered {
    position: absolute;
    bottom: 2%;
    display: inline-block;
    margin-left: -5%;
  }
}
