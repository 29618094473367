.team-leader-view-parent {
    .tlv-selected-tab {
        border: 1px solid var(--primary-theme-color);
        color: var(--primary-theme-color);
        font-weight: 700;
    }

    .tlv-tabs-section {
        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .tlv-tabs {
                padding: 2vh 2vh !important;
            }
        }


        .tlv-tabs {
            padding: 2vw 2vw;

            .ant-select-selection-item {
                color: var(--primary-theme-color);
            }

            .ant-select-arrow {
                color: #000;
            }
        }

        .tlv-tabs-container-mobile {
            width: 75%;
        }

        .tlv-unselected-tab {
            border: 1px solid rgba(217, 217, 217, 1);
        }

        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .tlv-lfp-tabs {
                width: 30%;
            }
        }

        .tlv-lfp-tabs {
            background-color: rgba(0, 0, 0, 0.06);
            padding: 4px 4px;
            align-items: center;
            justify-content: center;
        }

        .tlv-lfp-selected-tab {
            background-color: white;
            padding: 4px 6px;
            border-radius: 2px;
        }

        .tlv-lfp-selected-tab-left-margin {
            margin-left: 4px;
        }

        .tlv-lfp-selected-tab-right-margin {
            margin-right: 4px;
        }
    }

    .tlv-body-container {
        padding: 1em 2em;

        .tlv-sort-container {
            margin-left: 8px;

            .tlv-sort {
                .tlv-sort-txt {
                    margin-left: 4px;
                }
            }

            .tlv-sort-action-small-icon {
                font-size: 12px;
                margin-left: 4px;
            }
        }

        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .tlv-team-details-container {
                flex-direction: column;
                align-items: flex-start !important;
            }
        }
    
        .tlv-team-details-container {
            // padding: 2em;
            width: 100%;
            // background-color: rgba(0, 0, 0, 0.06);
    
            .tlv-team-details-txt-container {
                flex-direction: column;
                width: 60%;
            }
        }

        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .tlv-team-details-btns-container {
                margin-top: 1vh;
                width: 100% !important;
            }
        }
    
        .tlv-team-details-btns-container {
            width: 40%;
    
            .tlv-edit-btn {
                width: 45%;
            }
    
            .tlv-add-btn {
                width: 45%;
            }
        }
    
        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .tlv-tl-tc-info-container {
                width: 50% !important;
            }
        }
    
        .tlv-tl-tc-info-container {
            padding: 1em 0em;
            width: 15%;
    
            .tlv-tl-name-value {
                margin-left: 8px;
            }
    
            .tlv-tc-count-value {
                margin-left: 8px;
            }
        }
    }
}