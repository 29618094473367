.unsaved-changes-modal-parent {

    .ant-modal-content {
        height: 100%;
    }

    .ant-divider-horizontal {
        margin: 0px !important;
    }

    .ant-modal-body {
        padding: 0;
    }

    .ant-modal-close-x {
        display: none !important;
    }

    .ant-divider {
        margin: 0;
    }

    .ucm-content {
        .ucm-header {
            padding: 12px;
            // border-bottom: 1px solid rgba(0, 0, 0, 0.15);

            .ucm-header-txt {
                color: var(--light-theme-neutrals-neutral-1, rgba(0, 0, 0, 0.85));
                font-size: 20px;
                font-weight: 600;
                line-height: 28px;
            }


            .ucm-header-title {
                .ucm-comp-logo {
                    height: 40px;
                    width: 40px;
                    margin-right: 8px;
                    object-fit: contain;
                }

                .ucm-header-icon {
                    font-size: 20px;
                    margin-right: 8px;
                }
            }

            .ucm-header-close {
                padding: 8px;
                border-radius: 2px;
                border: 1px solid rgba(0, 0, 0, 0.15);
                background: #FFF;

                .ucm-header-close-icon {
                    font-size: 20px;
                    color: rgba(255, 77, 79, 1);
                }
            }
        }

        .ucm-body {
            padding: 1em;
        }

        .ucm-footer {
            .ucm-footer-btns-container {
                padding: 1em;
                .ucm-footer-stay-btn {
                    width: 25%;
                    padding: 4px 15px;
                    border-radius: 2px;
                    border: 1px solid var(--light-theme-neutrals-neutral-6, rgba(0, 0, 0, 0.06));
                    background: var(--light-theme-white, #FFF);
                    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
                }

                .ucm-footer-leave-btn {
                    margin-left: 12px;
                    width: 25%;
                    padding: 4px 15px;
                    border-radius: 2px;
                    background: var(--light-theme-semantic-negative, #FF4D4F);
                    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
                }
            }
        }
    }
}