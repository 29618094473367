.bulk-action-container {
  font-family: var(--primary-font-family);
  padding: 20px;
  padding-top: 0;
  .upload-completed {
    text-align: center;
    .check-circle {
      color: #52c41a;
      zoom: 6;
    }
    .file-uploaded-text {
      text-align: center;
      margin: 20px 0px;
      color: #8c8c8c;
    }
    .after-upload-details {
      .text-banner {
        display: flex;
        flex-direction: row;
        padding: 18px;
        background-color: var(--primary-theme-light);
        .bulb-icon {
          width: 5%;
          color: #f5222d;
          margin-top: -5px;
          margin-right: 3px;
        }
        .text {
          width: 95%;
          text-align: left;
          .text-heading {
            font-weight: bold;
            font-size: 14px;
          }
        }
      }
      .steps {
        margin-bottom: 70px;
        .step-container {
          margin: 27px 0px;
          .step-heading {
            display: flex;
            flex-direction: row;
            .step-button {
              // width: 12%;
              background-color: black;
              color: white;
              padding: 1px 8px;
            }
            .step-heading-text {
              // width: 88%;
              text-align: left;
              margin: auto;
              margin-left: 10px;
              font-weight: bold;
              font-size: 12px;
            }
          }
          .step-details {
            background: #ffffff;
            border: 1px solid #f0f0f0;
            border-radius: 4px;
            display: flex;
            flex-direction: row;
            padding: 16px;
            margin: 18px 0px;
            .details-left {
              display: flex;
              flex-direction: column;
              text-align: left;
              .candidate-name {
                font-weight: bold;
              }
              .phone-container {
                display: flex;
                flex-direction: row;
                margin: 6px 0px;
                .phone-icon {
                  width: 24px;
                  height: 24px;
                  border: 2px solid #d9d9d9;
                  background-color: #d9d9d9;
                  color: white;
                  text-align: center;
                  border-radius: 100px;
                  font-size: 11px;
                  padding: 0px;
                }
                .phone-no-placeholder {
                  width: 70px;
                  height: 16px;
                  background-color: #d9d9d9;
                  vertical-align: middle;
                  margin: auto 10px;
                }
              }
              .status-container {
                display: flex;
                flex-direction: row;
                margin: 5px 0px;
                .client-placeholder {
                  width: 70px;
                  height: 40px;
                  background-color: #d9d9d9;
                }
                .status-placeholder {
                  display: flex;
                  flex-direction: column;
                  margin: 0px 10px;
                  .date {
                    font-size: 13px;
                    font-weight: 700;
                  }
                  .status {
                    color: var(--primary-theme-color);
                    font-size: 15px;
                    font-weight: bold;
                  }
                }
              }
              .checkbox {
                font-size: 25px;
                color: var(--primary-theme-color);
              }
              .pipe {
                color: var(--primary-theme-color);
                font-size: 25px;
                margin: -5px auto;
              }
            }
            .details-center {
              margin: auto 10px;
              .application-date {
                text-align: left;
              }
              .created-date {
                text-align: left;
                color: rgba(0, 0, 0, 0.45);
              }
            }
            .details-right {
              margin-left: auto;
              display: flex;
              flex-direction: column;
              text-align: right;
              color: var(--primary-theme-color);
              .image-placeholder {
                background: #d9d9d9;
                width: 56px;
                height: 56px;
                border-radius: 100px;
                margin-left: auto;
              }
              .view-details-placeholder {
                background: #fff7f4;
                border: 1px solid var(--primary-theme-color);
                box-sizing: border-box;
                border-radius: 2px;
                width: fit-content;
                margin: 10px 0px;
                margin-left: auto;
                display: flex;
                flex-direction: row;
                padding: 1px 8px;
                padding-left: 0px;
                height: 33px;
                cursor: pointer;
                .arrow {
                  margin: 2px 8px;
                  margin-top: 0px;
                }
                .view-detail-text {
                  margin: auto;
                }
              }
              .edit-view-application {
                display: flex;
                flex-direction: row;
                background: #fff7f4;
                border: 1px solid var(--primary-theme-color);
                box-sizing: border-box;
                box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
                border-radius: 2px;
                .download {
                  margin: auto 8px;
                }
                .edit-view-text {
                  display: flex;
                  flex-direction: column;
                  padding: 7px 8px;
                  padding-left: 0px;
                  cursor: pointer;
                  .edit-view {
                    text-align: left;
                  }
                  .application {
                    text-align: left;
                  }
                }
              }
            }
            .details-full-width {
              width: 100%;
              .onboarding-preference {
                display: flex;
                flex-direction: row;
                .star {
                  color: #ff4d4f;
                }
                .onboarding-preference {
                  color: rgba(0, 0, 0, 0.85);
                  margin-left: 5px;
                }
              }
              .preference-option {
                background: #ffffff;
                border: 1px solid #d9d9d9;
                box-sizing: border-box;
                border-radius: 2px;
                display: flex;
                flex-direction: row;
                padding: 8px 12px;
                cursor: pointer;
              }
              .dropdown-arrow {
                margin-left: auto;
              }
            }
          }
        }
      }
    }
    .got-it-button {
      position: fixed;
      bottom: 0;
      background-color: white;
      display: flex;
      justify-content: center;
      width: 26%;
      padding: 14px 0px;
      .got-it {
        background: var(--primary-theme-color);
        border-radius: 2px;
        height: 40px;
        color: white;
        font-weight: bold;
        font-size: 14px;
        width: 317px;
      }
    }
  }
  .job-detail-container {
    background: #fafafa;
    border: 1px solid var(--primary-theme-color);
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-bottom: 20px;
    .job-details-top {
      display: flex;
      flex-direction: row;
      .checkbox,
      .client-logo {
        margin: auto;
        margin-right: 16px;
      }
      .job-type-container {
        display: flex;
        flex-direction: column;
        margin: auto;
        text-align: left;
        .job-type {
          font-weight: bold;
        }
      }
    }
    .salary-container {
      display: flex;
      flex-direction: column;
      text-align: left;
      margin-top: 18px;
      .type {
        font-weight: bold;
      }
    }
  }
  .upload-documents {
    .ant-upload {
      width: 100%;
      button {
        color: var(--primary-theme-color);
        background-color: var(--primary-theme-light);
      }
    }
  }
  .ant-btn {
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    // width: 50%;
    margin-top: 20px;
    margin-right: 10px;
    color: var(--primary-theme-color);
    border-color: var(--primary-theme-color);
    height: 60px;
    font-size: 14px;
    font-family: var(--primary-font-family);
    font-weight: 700;
    .CTA-context {
      display: flex;
      align-items: center;
      svg {
        margin-right: 3px;
      }
    }
  }
}
@media screen and (min-width: 320px) and (max-width: 786px) {
  .bulk-action-container {
    margin-top: 10px;
    .got-it-button {
      width: 85% !important;
    }
  }
  .lead-managment-list-container {
    margin-bottom: 80px;
  }
}

.upload-leads {
  width: 90%;
  height: 40px;
  background: var(--primary-theme-light);
  border: 1px solid var(--primary-theme-color);
  box-sizing: border-box;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 2px;
  margin: 0 auto;
}

.upload-leads-label {
  color: rgb(211, 70, 18);
  text-align: center;
  width: inherit;
}
.hp_slide {
  width: 100%;
  background: white;
  height: 25px;
}
.hp_range {
  width: 0;
  background: #ff9393;
  height: 50px;
}
.file-uploaded {
  background: #fafafa;
  border: 1px dashed #595959;
  box-sizing: border-box;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 2px;
  width: 90%;
  margin: 10px auto;
  height: 40px;
  padding: 7px 15px;
  color: #595959;
  font-weight: bold;
  display: flex;
  flex-direction: row;
}

.download-template {
  width: 90%;
  margin: 10px;
  height: 40px;
  font-weight: bold;
  color: var(--primary-theme-color);
  font-style: none;
  text-decoration: none;
  padding: 7px;
  background: #ffffff;
  border: 1px dashed var(--primary-theme-color);
  box-sizing: border-box;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 2px;
}

.download-template:hover {
  color: var(--primary-theme-color);
}

.error-message {
  margin: 5px 30px;
  text-align: left;
  color: red;
  font-weight: 600;
}

.submit-button {
  margin: 0 auto !important;
  background-color: rgb(211, 70, 18);
  color: white;
  margin: 0px 5%;
  padding: 5px;
  cursor: pointer;
  /* position: fixed; */
}

.view-leads {
  text-align: center;
  background: rgb(211, 70, 18);
  border-radius: 2px;
  color: white;
  margin: 0px 35%;
  cursor: pointer;
}

.noteHeading {
  font-weight: bold;
  color: revert;
  text-align: left;
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  .i-icon {
    margin: -3px 5px;
    color: #f5222d;
  }
  .text {
    font-size: 12px;
    line-height: 20px;
  }
}

.button-primary {
  width: 90%;
  height: 40px;
  margin-top: 15px;
  margin-bottom: 15px;
  svg {
    margin-bottom: 5px;
  }
}

.bulk-action-banner-container {
  padding: 10px;
  display: flex;
  flex-direction: row;
  background: var(--primary-theme-light);
  border: 1px solid var(--primary-theme-color);
  box-sizing: border-box;
  border-radius: 4px;
  text-align: left;
}

.bulk-action-banner-icon {
  width: 25px;
}

.banner-heading-icon {
  color: var(--primary-theme-color);
}

.banner-heading {
  color: var(--primary-theme-color);
}

.line-break {
  display: block;
}

.bulk-referral-note {
  padding: 5px 30px;
  text-align: justify;
  color: var(--primary-theme-color);
  line-height: normal;
}

.lead-managment-list-container {
  margin-bottom: 0px;
  margin-top: 10px;
}

.buttons-bulk-actions {
  width: 90%;
  padding: 20px !important;
  display: flex !important;
  flex-direction: row;
  margin: 10px auto;
}

@media (min-width: 420px) {
  .line-break {
    display: none;
  }
  .banner-heading {
    color: black;
  }
}
