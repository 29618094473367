.reassign-leads-container {
    .ant-divider {
        margin: 0;
    }

    .rl-header {
        padding: 26px 24px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        background-color: #FFF;

        .rl-back-icon {
            margin-right: 8px;
        }

        .rl-header-title {
            .rl-comp-logo {
                height: 40px;
                width: 40px;
                margin-right: 8px;
                object-fit: contain;
            }

            .rl-header-icon {
                font-size: 20px;
                margin-right: 8px;
            }

            .rl-header-txt {
                color: var(--black-85, rgba(0, 0, 0, 0.85));
                font-size: 20px;
                font-weight: 600;
            }
        }

        .rl-header-close {
            display: flex;
            padding: 8px;
            border-radius: 2px;
            border: 1px solid rgba(242, 36, 36, 0.15);
            background: #FFF;

            .rl-header-close-icon {
                font-size: 20px;
                color: rgba(255, 77, 79, 1);
            }
        }
    }

    @media only screen and (min-width: 320px) and (max-width: 786px) {
        .rl-body {
            max-height: 54vh;
            overflow: scroll;
        }
    }

    .rl-body-padding {
        padding: 24px 24px 60px 24px;
        background-color: rgba(0, 0, 0, 0.04);
    }

    .rl-member-name-txt {
        margin: 4px 8px 0px 0px;
        padding: 2px 4px 2px 8px;
        border-radius: 2px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        background: #FFF;
    }

    .rl-body {
        min-height: 50px;

        .rl-content {

            .rl-content-section {
                display: flex;
                flex-direction: column;
                margin-top: 24px;

                .rl-assigned-members-parent {
                    flex-wrap: wrap;

                    .rl-extra-members-count-txt {
                        margin: 4px 8px 0px 0px;
                    }
                }

                .rl-tooltip {
                    margin-left: 6px;
                }
            }

            .rl-common-header-txt {
                color: var(--black-85, rgba(0, 0, 0, 0.85));
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 4px;
            }

            .rl-common-list-item-txt {
                color: var(--black-65, rgba(0, 0, 0, 0.65));
                font-size: 16px;
                font-weight: 400;
            }

            .rl-common-padding {
                padding: 24px;
            }

            .rl-arrow-icon {
                font-size: 12px;
                margin-right: 8px;
            }

            .rl-section-header {
                color: var(--black-65, rgba(0, 0, 0, 0.65));
                font-size: 16px;
                font-weight: 600;
            }

            .rl-upper-body {
                background-color: var(--black-6, rgba(0, 0, 0, 0.06));
                padding: 24px;

                .rl-salary-body {
                    margin-bottom: 2em;
                }

                @media only screen and (min-width: 320px) and (max-width: 786px) {
                    .rl-hours-order {
                        flex-direction: column;
                    }
                }

                .rl-hours-order {
                    display: flex;
                    margin-top: 2em;

                    @media only screen and (min-width: 320px) and (max-width: 786px) {
                        .rl-hours {
                            width: 100% !important;
                        }
                    }

                    .rl-hours {
                        width: 44%;
                    }

                    @media only screen and (min-width: 320px) and (max-width: 786px) {
                        .rl-vertical-divider {
                            margin: 0 1vh !important;
                        }
                    }

                    .rl-vertical-divider {
                        width: 1px;
                        background-color: rgba(0, 0, 0, 0.06);
                        margin: 0 1vw;
                    }

                    @media only screen and (min-width: 320px) and (max-width: 786px) {
                        .rl-orders {
                            width: 100% !important;
                        }
                    }

                    .rl-orders {
                        width: 40%;
                    }
                }
            }

            .rl-other-details {

                .rl-other-details-header {
                    border: 1px solid var(--Neutral-5, #D9D9D9);
                    background: var(--Neutral-2, #FAFAFA);
                    padding: 12px 24px;

                    .rl-other-details-header-icon {
                        font-size: 12px;
                    }
                }

                .rl-other-details-body {
                    margin-top: 1em;
                    padding: 6px 24px;
                }
            }

            .rl-insurance-header {
                margin-bottom: 1em;
            }

            .rl-tips {
                .rl-tips-header {
                    border: 1px solid var(--Neutral-5, #D9D9D9);
                    background: var(--Neutral-2, #FAFAFA);
                    padding: 12px 24px;

                    .rl-tips-header-icon {
                        font-size: 12px;
                    }
                }

                .rl-tips-body {
                    margin-top: 1em;
                    padding: 6px 24px;
                }
            }

            .rl-offers {
                padding: 24px;

                .rl-offers-header {
                    .rl-offers-header-icon {
                        font-size: 20px;
                        color: rgba(24, 144, 255, 0.85);
                        margin-right: 8px;
                    }

                    .rl-offers-header-txt {
                        color: #1890FF;
                        font-size: 16px;
                        font-weight: 600;
                    }
                }

                .rl-offers-body {

                    @media only screen and (min-width: 320px) and (max-width: 786px) {
                        .rl-offers-card {
                            max-width: 50% !important;
                        }
                    }

                    .rl-offers-card {
                        padding: 16px;
                        border-radius: 8px;
                        border: 1px solid var(--black-15, rgba(0, 0, 0, 0.15));
                        background: #FFF;
                        margin: 1em 1em 1em 0;
                        max-width: 33%;

                        .rl-offers-card-body {}
                    }
                }
            }
        }
    }

    .rl-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #FFF;

        .rl-footer-btn-container {
            padding: 24px 16px;

            .rl-confirm-btn-loader {
                color: white;
                margin-left: 8px;
            }
        }
    }

    .rl-currently-assigned-members-view {
        display: flex;
        align-items: center;
        padding: 24px 24px 48px 24px;
        flex-wrap: wrap;
    }

    .rl-successful-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 24px 24px 48px 24px;

        .rl-successful-lottie {}

        .rl-successful-txt {}
    }
}