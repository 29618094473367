.leaderboard-container {
  display: flex;
  justify-content: space-between;
  padding-right: 16px;
  .desktop-only {
    display: block;
  }
  .mobile-only {
    display: none;
  }
  .margin-bottom-15px {
    margin: 0px auto 15px auto !important;
  }
  .margin-right-15px {
    margin: auto 15px auto 0px !important;
  }
  .margin-right-70px {
    margin: auto 70px auto 0px !important;
  }
  svg {
    margin-top: -5px;
  }
  .left-card-container {
    background: var(--primary-theme-light);
    border: 1px solid var(--primary-theme-color);
    border-radius: 2px;
    // height: 450px;
    width: 400px;
    .leaderboard-left-container {
      display: flex;
      flex-direction: column;
      text-align: center;
      margin: auto;
      .left-card-info {
        display: flex;
        flex-direction: column;
      }
      .left-card-avatar {
        margin: auto;
        // font-size: 50px;
      }
      .table {
        background-color: white;
        // padding: 16px !important;
      }
      .table-row {
        .imgResponse {
          zoom: 0.7;
          padding: 0px;
        }
      }
    }
    .share-button {
      background: #52c41a;
      border-radius: 2px;
      color: white;
      width: -webkit-fill-available;
    }
  }
  .leaderboard-right-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 15px;
    .card-container {
      display: flex;
      flex-direction: row !important;
      width: 100%;
      margin-top: 0px;
      margin-bottom: 12px;
      .right-card-info {
        display: flex;
        flex-direction: row;
        margin-bottom: 15px;
      }
      .name-container {
        display: flex;
        flex-direction: column;
      }
      .total-f10 {
        background: #f6ffed;
        border: 1px solid #52c41a;
        border-radius: 4px;
        font-size: 11px;
        margin: auto;
        margin-right: 20px;
        width: 180px;
      }
      .total-activation {
        background: #f0f0f0;
        border: 1px solid #595959;
        border-radius: 4px;
        font-size: 11px;
        margin: auto;
        width: 180px;
      }
    }
    .view-all {
      background: var(--primary-theme-color);
      border-radius: 2px;
      color: white;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .leaderboard-container {
    .desktop-only {
      display: none;
    }
    .mobile-only {
      display: block;
    }
    display: flex;
    flex-direction: column;
    margin: 0px;
    padding-right: 0px;
    .left-card-container {
      background: var(--primary-theme-light);
      border: 1px solid var(--primary-theme-color);
      border-radius: 2px;
      width: 100%;
      margin-bottom: 20px;
      .leaderboard-left-container {
        display: flex;
        flex-direction: column;
        .left-card-info {
          display: flex;
          flex-direction: row;
          margin-bottom: 20px;
        }
        .view-all {
          background: var(--primary-theme-color);
          border-radius: 2px;
          color: white;
        }
        .table {
          background-color: white;
        }
        .table-row {
          .imgResponse {
            zoom: 0.7;
            padding: 0px;
          }
        }
      }
      .share-button {
        background: #52c41a;
        border-radius: 2px;
        color: white;
      }
    }
    .leaderboard-right-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-left: 0px;
      .card-container {
        display: flex;
        flex-direction: column !important;
        width: 100%;
        margin-top: 0px;
        margin-bottom: 12px;
        .right-card-info {
          display: flex;
          flex-direction: row;
          margin-bottom: 15px;
        }
        .leaderboard-right-container {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-left: 0px;
          .view-all {
            background: var(--primary-theme-color);
            border-radius: 2px;
            color: white;
          }
        }
        .total-f10 {
          background: #f6ffed;
          border: 1px solid #52c41a;
          border-radius: 4px;
          font-size: 11px;
          margin: auto;
          margin-right: 20px;
          width: 130px;
        }
        .total-activation {
          background: #f0f0f0;
          border: 1px solid #595959;
          border-radius: 4px;
          font-size: 11px;
          margin: auto;
          width: 150px;
          padding-left: 5px !important;
        }
      }
      .view-all {
        background: var(--primary-theme-color);
        border-radius: 2px;
        color: white;
      }
    }
  }
}
