
.main-ticket-container .help-sider-conversation-history-container-chatbox{
    display: flex;
    flex-direction: column;
    text-align: start;
    overflow: scroll;

    padding-top: 16px;
    padding-bottom: 16px;

    .single-message-container {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .message-header {
        display: flex;
        flex-direction: row;
        margin-left: 24px;
        gap: 16px;

        .message-header-icon{
          height: 30px;
          width: 30px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          
          font-family: var(--primary-font-family);
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 26px;
          text-align: center;
          color: #FFFFFF;
        }

        .support {
          background: #39A99B;
        }

        .mitra-leader {
          background: #6C4585;
        }

        .message-header-text {
          display: flex;
          flex-direction: column;
          gap: 4px;

          .client-name-text {
            font-family: var(--primary-font-family);
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 19px;
            color: #000000;
          }

          .date-text {
            font-family: var(--primary-font-family);
            font-style: normal;
            font-weight: 700;
            font-size: 10px;
            line-height: 11px;
            color: #888888;
          }
        }
      }

      .message-description {
        margin-left: 70px;
        margin-right: 32px;
        display: flex;
        flex-direction: column;
      }
    }
  }

.ticket-modal-header-container {
  text-align: start;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .ticket-title {
    font-family: var(--primary-font-family);
    font-size: 1.3rem;
    font-weight: 700;
  }

  .ticket-summary {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 16px;

    .ticket-summary-content {
      // style={{fontSize:'1rem',display:'flex',gap:'8px'}}
      font-size: 1rem;
      display: flex;
      gap: 8px;
    }
  }
}
.current-user-msg{
    margin: 20px 10px;
    margin-left: auto;
    text-align: right;
    gap: 10px;
    display: flex;
    flex-direction: row;  
    width: fit-content;
    padding: 5px 10px;
}

.message{
    font-family: var(--primary-font-family);
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    // line-height: 22px;
    // width: 95%;
    // margin: auto;
    // display: flex;
    // flex-direction: column; 
    padding: 10px;
	color:#fff;
	line-height:25px;
	max-width:90%;
	display:inline-block;
	text-align:left;
	border-radius:5px;
	background-color:#6fbced;
}  
.icon-container{
    width: 35px;
    margin: auto;
    padding: 0 0 5px 0;
    border: 1px solid #000;
    border-radius: 100%;
    text-align: center;
}

.image-content img {
    // max-width: 100%;
    padding: 15px;
    margin-top: 5px;
    height: auto;
    width: 150px; /* ie8 */
}      

.system-msg{
    gap: 10px;
    margin: 20px 5px;
    margin-right: auto;
    text-align: left;
    display: flex;
    flex-direction: row;
    padding: 5px 10px;
    width: fit-content;

    .message{
        // background-color: lightgreen;
        background-color:#58b666;
    }
}

.chat-container
{
    overflow: scroll;
    height: calc(100vh - 250px);
    width: 100%;
}

.input-container{
    display: flex;
    height: 50px;
    align-items: center;
    flex-direction: row;
    gap: 10px;

    .chat-input-container{
        display: flex;
        height: 50px;
        border: 1px solid #ADADAD;
        border-radius: 30px;
        background-color: white;
        padding: 10px;
        align-items: center;
        justify-content: space-between;
        width: 90%;

        .ant-icon {
            font-size: 24px;
            cursor: pointer;
        }
    
        input{
            width: 100%;
            border: none;
            outline: none;
            color: #2f2d52;
            font-size: 1.2rem;
            padding-left: 15px;
    
            &::placeholder{
                color: lightgray;
            }
        }
    }
    .send{
        display: flex;
        align-items: center;
        gap: 10px;

        button{
            display: flex;
            flex-direction: row;
            align-items: center;
            color: #fff;
            font-weight: 600;
        }
        
    }
}

.image-preview{
    display: flex;
    padding-left: 16px;
    gap: 5px;
    flex-direction: column;
    text-align: start;
    // margin: 10px 0;
    img{
        width: 100px;
        height: 70px;
    }
}
