.help-automation-header-container {
    margin: 48px 24px 0px;
}

.hahc-left {
    gap: 16px;
}

.videos-and-faqs-container {
    margin: 24px 24px 60px;
    border-radius: 8px;
    border: 1px solid #AFCFFF;
    background: #FFF;
    padding: 24px;
    display: flex;
    flex-direction: row;
    position: relative;
}

.help-video-stack {
    border-radius: 8px;
    border: 1px solid #C8C9CC;
    background: #FFF8EB;
    height: 300px;
    width: 136px;
    margin-left: 24px;
    padding: 12px;

    display: flex;
    flex-direction: column;
    gap: 16px;
}

.video-thumbnail-small {
    width: 100%;
    height: 76px;
    // border: 1px solid #C8C9CC;
    border-radius: 4px;
}

.help-header-icon {
    // height: '68px', width: '68px', objectFit: 'contain'
    height: 68px;
    width: 68px;
    object-fit: contain;
}

.hero-image-container-vph {
    // style={{height: '300px', width: '400px', objectFit: 'contain'}} 
    height: 300px;
    width: 400px;
    position: relative;
}

.vph-hero-image {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.play-btn-vph {
    position: absolute;
    left: 0;
    right: 0;
    top: 42%;
    margin-inline: auto;
    width: fit-content;
}

.line-divider {
    width: 1px;
    height: 300px;
    background-color: #C8C9CC;
    margin: 0 24px;
}

.vph-hero-other {
    height: 60px;
    width: 60px;
    object-fit: contain;
    margin-top: 16px;
    margin-left: auto;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 0;
}

.issue-not-resolved-container {
    padding: 16px 20px 16px 24px;
    border-radius: 8px;
    border: 1px solid #C8C9CC;
    background: #FFF8EB;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 24px;
}

.raised-issue-list-container {
    border-radius: 8px;
    border: 1px solid #AFCFFF;
    background: #FFF;

    display: flex;
    flex-direction: column;

    margin: 56px 24px;
    padding: 32px;
}

.raised-issue-summary-container {
    display: flex;
    justify-content: space-between;
}

.tickets-resolution-indicator-container {
    border-radius: 8px;
    // background: #D7AF1D;
    background: #d7af1d80;
    position: relative;
    width: 400px;
    height: 8px;
    margin-top: 20px;
}

.tickets-resolved-indicator {
    border-radius: 8px;
    background: #4EBB57;
    height: 8px;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    z-index : 1;
}

.raised-issue-list-filter-container {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 16px;

    .ant-select-selector{
        border-radius: 4px !important;
        border: 1px solid #83B5FF !important;
        background: #FFF !important;
    }
}

.rilc-divider {
    background: #C8C9CC;
    height: 1px;
    width: 100%;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 40px;
}

.help-card-container {
    border-radius: 4px;
    border: 1px solid #C8C9CC;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    margin-bottom: 16px;
}

.help-card-child-elements {
    width: 100%;
    display: flex;
    justify-content: center;
}

.help-card-child-elements:nth-child(5) {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.active-dot-hc {
    width: 8px;
    height: 8px;
    border-radius: 50%;
}

.help-name:hover {
    color: #1d5fff !important;
    text-decoration: underline;
    text-underline-offset: 4px;
}


@media screen and (max-width: 800px) {
    .raised-issue-list-container {
        padding: 32px 12px;
    }

    .help-card-container {
        flex-wrap: wrap;
        gap: 16px;
        padding: 12px;
    }

    .issue-not-resolved-container {
        flex-direction: column;
        gap: 8px;
        margin: 0 12px;
    }

    .tickets-resolution-indicator-container {
        width: 100%;
        margin-top: 8px;
    }

    .raised-issue-list-container {
        margin: 56px 16px;
    }

    .videos-and-faqs-container {
        margin: 24px 12px 36px;
        padding: 32px 0px;
    }

    .help-automation-header-container {
        margin: 48px 12px 0px;
    }

    .hero-image-container-vph {
        height: 100%;
        width: 100%;
    }

    .vph-hero-image {
        object-fit: fill;
    }

    .help-card-child-elements, .help-card-child-elements:nth-child(5) {
        width: auto;
        display: block;
        justify-content: center;
    }
}