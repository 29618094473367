.layout_message {
    padding: 12px;
    text-align: start;
    font-size: 15px;
}

.submit-button-app-download {
    border-radius: 8px !important;
    background-color: #ff702d !important;
    color: #fffcfb !important;
    font-weight: 600 !important;
}

.secondary-btn {
    border-radius: 8px !important;
    background-color: #fff !important;
    color: #000 !important;
    font-weight: 600 !important;
    border: 1px solid black;
}

.cta_button_container {
    display: flex;
    padding: 0 12px 12px;
}

.ant-modal-title {
    font-weight: 600 !important;
    font-size: 15px !important;
}
