.summary-container {
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    gap: 24px;
}

.summary-item {
    width: 48%;
    border-radius: 8px;
    background: #F2F2F2;
    padding: 14px 15px 11px;
}

.summary-screen-content {
    padding: 48px 32px;
}

.summary-value-mobile {
    display: none;
}

.summary-image {
    width: 52px !important;
    height: 44px !important;
    object-fit: cover;
    border-radius: 4px;
    display: flex !important;
}

.summary-image-mobile {
    width: 52px !important;
    height: 44px !important;
    object-fit: cover;
    border-radius: 4px;
    display: none !important;
}

@media only screen and (min-width: 320px) and (max-width: 786px) {
    .summary-screen-content {
        padding: 24px 12px;
    }

    .summary-value-desktop {
        display: none;
    }

    .summary-value-mobile {
        display: flex;
        width: 100%;
    }

    .summary-item-left {
        flex-direction: column !important;
        width: 100%;
    }

    .summary-info {
        width: 100%;
    }

    .summary-image {
        width: 100% !important;
        height: 44px !important;
        object-fit: cover;
        border-radius: 4px;
        display: none !important;
    }

    .summary-image-mobile {
        width: 100% !important;
        height: 44px !important;
        object-fit: cover;
        border-radius: 4px;
        display: flex !important;
    }

    .summary-value-mobile {
        font-size: 18px;
    }

    .summary-item {
        width: 45%;
    }

    .summary-screen-submission-btn {
        margin: 0px !important;
        width: 100%;
    }

    .summary-info {
        text-align: start;
    }

    .summary-header {
        font-size: 14px;
        text-align: start;
    }

    .summary-sub-header {
        font-size: 12px;
        text-align: start;
    }
}