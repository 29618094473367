.jobs-page-new-parent {
    height: 100%;
    width: 100%;

    .jp-new-breadcrumb-header {
        padding: 2vw;
    }

    .ant-divider-horizontal {
        margin: 0px !important;
    }

    .jp-padded-section {
        padding: 1vw 3vw;
        padding-bottom: 48px;
        background-color: var(--primary-bg-color);

        .jp-selected-tab {
            border: 1px solid var(--primary-theme-color);
            color: var(--primary-theme-color);
            font-weight: 700;
        }

        .jp-tabs-section {
            .jp-tabs {
                .ant-select-selection-item {
                    color: var(--primary-theme-color);
                }

                .ant-select-arrow{
                    color: #000;
                }
            }

            .jp-tabs-container-mobile {
                width: 75%;
            }

            .jp-unselected-tab {
                border: 1px solid rgba(217, 217, 217, 1);
            }

            @media only screen and (min-width: 320px) and (max-width: 786px) {
                .jp-lfp-tabs {
                    width: 30%;
                }
            }

            .jp-lfp-tabs {
                background-color: rgba(0, 0, 0, 0.06);
                padding: 4px 4px;
                align-items: center;
                justify-content: center;
            }

            .jp-lfp-selected-tab {
                background-color: white;
                padding: 4px 6px;
                border-radius: 2px;
            }

            .jp-lfp-selected-tab-left-margin {
                margin-left: 4px;
            }

            .jp-lfp-selected-tab-right-margin {
                margin-right: 4px;
            }
        }

        .jp-filters-section {
            padding: 1.5vw 2.5vw;
            border-radius: 12px;
            border: 1px solid var(--black-15, rgba(0, 0, 0, 0.15));
            background: #FFF;
            margin-top: 1.5vw;

            .jp-filters-section-select-mobile {
                margin: 1vw 4vw 1vw 2.5vw;
                max-width: 75%;

                .ant-select-arrow{
                    color: #000;
                }
            }

            .jp-filters-section-mobile-txt-1 {
                width: 10%;
                text-align: left;
            }

            .jp-filters-top-section {
                @media only screen and (min-width: 320px) and (max-width: 786px) {
                    .jp-filters-leads-registered-during {
                        flex-direction: column;
                        align-items: flex-start;
                    }
                }

                @media only screen and (min-width: 320px) and (max-width: 786px) {
                    .jp-filters-top-section-date-range {
                        margin-top: 1vw;
                    }
                }

                .jp-filters-leads-registered-during {
                    display: flex;
                }

                .jp-filters-top-section-txt-1 {
                    margin: 0 2vh 0 0;
                }

                .jp-filters-top-section-txt-2 {
                    margin: 0 2vh;
                }

                .jp-filters-top-section-select {
                    width: 20%;

                    .ant-select-selection-item {
                        font-weight: 700;

                        .ant-input-affix-wrapper {
                            border: none;
                        }
                    }

                    .ant-select-arrow {
                        color: rgba(0, 0, 0, 0.85);
                    }
                }

                .jp-apply-filter-btn {
                    display: flex;
                    align-items: center;
                    width: 10%;
                    justify-content: center;
                }
            }

            .jp-filters-bottom-section {
                margin-top: 1vw;
            }
        }

        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .jp-single-filter-item {
                margin: 4px 6px !important;
            }
        }

        .jp-single-filter-item {
            background-color: rgba(240, 240, 240, 1);
            padding: 4px;
            margin: 0 12px;

            .single-filter-txt {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 150px;
            }
        }

        .jp-job-demand-listing-section {
            .jp-job-demand-count {
                margin: 1.5vw 0vw 2.5vw 0vw;
                color: #000;
                font-size: 14px;
                font-weight: 400;
            }

            @media only screen and (min-width: 320px) and (max-width: 786px) {
                .jp-job-demand-cards {
                    flex-direction: column;
                    margin-bottom: 10vh;
                }
            }

            .jp-job-demand-cards {
                display: flex;
                margin-top: 1vw;
                flex-wrap: wrap;
            }
        }

        .jp-leads-by-posters-listing-section {
            .jp-leads-by-posters-count {
                margin-top: 1vw;
            }

            .jp-filter-chips-mobile {
                flex-wrap: wrap;
                margin-top: 1vh;
                align-items: center;
                justify-content: flex-start;
            }

            .jp-leads-by-posters-cards {
                flex-wrap: wrap;
            }

            .jp-lvc-parent {}

            @media only screen and (min-width: 786px) {
                .jp-pvc-parent {
                    flex-wrap: wrap;
                    justify-content: space-between;
                }
            }

            @media only screen and (min-width: 320px) and (max-width: 786px) {
                .jp-pvc-parent {
                    flex-direction: column;
                }
            }

            .jp-pvc-parent {
                display: flex;
            }
        }
    }
}

.jp-localities-search {
    margin-top: 0.5em;

    .jp-more-localities-txt {
        color: var(--Gray-1, #333);
        text-align: center;
        font-size: 14px;
        font-weight: 400;
    }
}

.jp-primary-btn {
    border-radius: 2px !important;
    border: 1px solid var(--primary-default, var(--primary-theme-color)) !important;
    background: var(--primary-default, var(--primary-theme-color)) !important;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02) !important;
    color: white;
}

.jp-primary-btn-txt {
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
}

.jp-secondary-btn {
    border-radius: 2px !important;
    border: 1px solid var(--primary-default, var(--primary-theme-color)) !important;
    background: var(--Neutral-1, #FFF) !important;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02) !important;
}

.jp-secondary-btn-txt {
    color: var(--primary-default, var(--primary-theme-color));
    text-align: center;
    font-size: 14px;
    font-weight: 600;
}

.jp-orange-txt {
    color: var(--primary-theme-color) !important;
}

.jp-white-txt {
    color: white !important;
}

.jp-cp {
    cursor: pointer;
}

.jp-disp-block {
    display: block !important;
}