.team-leaderboard-page-container {
  overflow: scroll;
  .leaderboard-filters {
    padding: 21px 16px;
    text-align: left;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .select-class {
      width: 200px;
      margin-right: 10px;
    }
  }
  .team-member-cards-container {
    background-color: white;
    padding: 10px 30px;
    width: 100%;
  }
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .team-leaderboard-page-container {
    .team-member-cards-container {
      padding: 10px 10px;
      height: calc(100vh - 250px);
      overflow-y: auto;
      margin-bottom: 60px;
    }
  }
}
