.lead-referred-modal {
  .ant-modal-header {
    font-family: var(--primary-font-family);
    font-weight: 700;
    text-align: start;
  }
}

.submit-wo-otp-btn {
  margin-right: 8px;
}

.note-wo-otp {
  margin-top: 12px;
  text-align: left;
  font-weight: 500;
  font-size: 14px;

  .wo-otp-code {
    font-weight: 700;
    font-size: 16px;
  }
}

.otp-container-box {
  background-color: #FFEAE0 !important;
  justify-content: space-between;
  gap: 16px;
}

.inner-highlighted-section {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: #FFF;
  padding: 24px 40px;
  width: 440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin: 32px auto 60px;
}

.referral-link-container-box {
  border: 1px solid #00000026;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FFF;
  padding: 12px;
}

.otp-layout-modal > .ant-modal-content, .otp-layout-modal > .ant-modal-content > .ant-modal-body, .otp-layout-modal > .ant-modal-content > .ant-modal-header {
  border-radius: 8px;
  overflow: hidden;
}

.otp-layout-modal > .ant-modal-content {
  width: 700px;
}

.dynamic-direction {
  flex-direction: row;
}

@media screen and (min-width: 801px) {
  .otp-layout-modal {
      width: 700px !important;
  }

  .section-width-dsktp {
    width: 48%;
  }
}

@media screen and (max-width: 800px) {
  .otp-layout-modal > .ant-modal-content {
      width: 100% !important;
  }

  .section-width-dsktp {
    width: 100%;
  }

  .dynamic-direction {
    flex-direction: column;
    gap: 16px;
  }

  .inner-highlighted-section {
    width: auto;
    margin: 12px;
  }

  .zepto-otp-tab-container {
    gap: 24px;
    justify-content: space-around;

  }

  .zepto-otp-single-tab {
      color: #ACACAC;
      text-align: center;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; 
      cursor: pointer;
      width: 45%;
  }

  .zepto-otp-active-tab {
      color: var(--primary-theme-color);
      font-weight: 700;
      border-bottom: 2px solid var(--primary-theme-color);
  }

  .zepto-otp-top-section {
      border-bottom: 1px solid #e2e2e2;
  }
}