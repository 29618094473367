.section-addition-main-container {
    border-radius: 8px;
    border: 1px solid #E5ECFF;
    background: #FFF;
    padding-bottom: 32px;
    padding: 16px 24px;
    margin-top: 24px;
}

.sa-content-section {
    margin-top: 24px;
}

.av-initial-layout {
    border-radius: 4px;
border: 1px solid #E5ECFF;

background: #FFF;
padding: 24px;
justify-content: center;
align-items: center;
gap: 24px;
}

.av-dropdowns-container {
    /* d-flex align-items-center flex-gap-12 mt-24 flex-wrap */
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 24px;
    flex-wrap: wrap;
}

.av-dropdown-items {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 24px;
    flex-wrap: wrap;
}

@media screen and (max-width: 800px) {
    .av-initial-layout {
        padding: 24px;
        gap: 12px;
        flex-direction: column;
        
    }

    .av-dropdowns-container , .av-dropdown-items {
        /* d-flex align-items-center flex-gap-12 mt-24 flex-wrap */
        display: flex;
        align-items: center;
        gap: 12px;
        margin-top: 12px;
        flex-wrap: wrap;
        width: 100%;
    }

    .av-dropdown-items  > .custom-dropdown-box {
        flex: 1
    }

}