.ai-calling-consent-main-container {
    height: 100vh;
    background-color: #FFF;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    margin-top: 5em;
    max-width: 786px;
}

.branding-info {
    border: 1px dashed #c8c8c8;
    padding: 1em;
    gap: 8px;
    width: 90%;
}

.referral-details {
    margin-top: 3em;
}

.consent-copy {
    margin-top: 5em;
}

.consent-btns-container {
    width: 100%;
    margin-top: 3em;
    display: flex;
    flex-direction: column;
    gap: 4em;
}

.btn-pd {
    padding: 12px 12px !important;
    height: auto !important;
}

.post-submission-ack {
    margin-top: 7em;
}

.ack-text {
    margin-top: 3em;
}

@media (min-width: 320px) and (max-width: 768px) {
    .ai-calling-consent-main-container {
        margin: 0 24px;
        margin-top: 5em;
    }
}