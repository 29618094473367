.account-details-container-parent {
    // padding: 2vw 3vw;

    .ad-vl-profile-header {
        flex-direction: column;
        align-items: flex-start;

        .ad-vl-profile-header-icon {
            font-size: 16px;
            margin-right: 8px;
        }

        .ad-vl-profile-sub-txt{
            margin-top: 4px;
        }
    }

    @media only screen and (min-width: 320px) and (max-width: 786px) {
        .ad-vl-profile-body {
            margin-top: 2vh;
        }
    }

    .ad-vl-profile-body {
        margin-top: 2vw;

        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .ad-vl-input-container {
                flex-direction: column;
                margin-top: 1vh;
                margin-bottom: 5%;
            }
        }

        .ad-vl-input-container {
            margin-top: 1vw;

            .ant-input[disabled]{
                color: rgba(0, 0, 0, 0.85) !important;
            }

            @media only screen and (min-width: 320px) and (max-width: 786px) {
                .ad-vl-input-label {
                    width: 100% !important;
                }
            }

            .ad-vl-input-label {
                width: 15%;
            }
        }


    }
}