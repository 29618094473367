.team-member-view-parent-padding {
    padding: 2vw 3vw;
}

.team-member-view-parent {

    .tmv-header {
        flex-direction: column;
        align-items: flex-start;

        .tmv-header-icon {
            font-size: 16px;
            margin-right: 8px;
        }

        .tmv-sub-txt {
            margin-top: 4px;
        }
    }

    .tmv-body {
        margin-top: 2vw;

        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .tmv-input-container {
                margin-top: 2vh !important;
                flex-direction: column;
            }
        }

        .tmv-input-container {
            margin-top: 1vw;

            .ant-input[disabled] {
                color: rgba(0, 0, 0, 0.85) !important;
            }

            .tmv-input-label {
                width: 15%;
            }
        }


    }
}