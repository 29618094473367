
.chatbot-main-container {
    position: relative;
    // background: white;
    width: 400px; // Adjust as needed
    max-height: 90%;
    overflow-y: auto; // Internal scroll if content exceeds
    border-radius: 10px;
    top : 8rem;
    right : -38rem;
  }

.chat-box {
    height: 470px;
    width: 320px;
    background: #FFF;
    border-radius: 12px 12px 0px 12px;
    animation: slideUpFade 0.5s ease-out;
    animation-fill-mode: forwards;
    padding-bottom: 24px;
    overflow: hidden;
    position: relative;
}

.pro-icon {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 1px solid #041A4E;
    background: #1D5FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
}

.chatbot-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); // Optional dimming effect
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }

.chat-bot-header {
    padding: 12px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #C8C9CC;
    height: 48px;
}

.welcome-div {
    border-radius: 8px;
    border: 1px solid #AFCFFF;
    background: #FFF;
    margin: 24px 16px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.chat-bot-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow: scroll;
    height: 422px;
    background: #FDFAF9;
}

.messages-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: 100%;
}

.message-bubble {
    padding: 4px 8px;
    border: 1px solid #C8C9CC;
    max-width: 80%;
    text-align: start;
    margin: 0px 12px;
    font-size: 12px;
    font-family: 'Inter';
    font-weight: 400;
}

.message-bubble > div {
    font-size: 12px !important;
    font-family: 'Inter' !important;
}

.bubble-left {
    border-radius: 12px 12px 12px 0px;
    background: #FFF8EB;
    margin-right: auto;
}

.bubble-right {
    border-radius: 12px 12px 0px 12px;
    background: #F7FAFF;
    margin-left: auto;
}

// .message-loading-dot {
//     height: 8px;
//     width: 8px;
//     background-color: #83838382;
//     border-radius: 50%;
// }

.message-loading {
    margin: 16px 12px;
    display: flex;
    align-items: center;
    gap: 4px;
    width: fit-content;
}

// .message-loading-dot-active {
//     height: 8px;
//     width: 8px;
//     background-color: #838383;
//     border-radius: 50%;
// }

.message-loading-dot,
.message-loading-dot-active {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #83838382; /* Default color for non-glowing dots */
  animation: bounce 0.6s infinite ease-in-out;
//   transition: background-color 0.4s ease, transform 0.4s ease; /* Smooth transition */
}

/* Bounce animation keyframes */
@keyframes bounce {
    0%, 80%, 100% {
      transform: scale(0.6); /* Dots shrink at start and end of the cycle */
    }
    40% {
      transform: scale(1); /* Dots expand at the peak of the bounce */
      background-color: #838383;
    }
  }

// .message-loading-dot-active {
//     background-color: #838383; /* Glowing color (green, for example) */
//     transform: scale(1.2); /* Slight scaling for the glowing effect */
// }

.single-select-operation {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    border-top: 1px solid #AFCFFF;
    background: #FFF;
    padding: 16px;
    gap: 8px;
}

.scb-single-option {
    border-radius: 4px;
    border: 1px solid #83B5FF;
    padding: 4px 8px;
    color: #1D5FFF;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; 
    cursor: pointer;
}

.red-content {
    border: 1px solid #ff4545;
    color: #ff4545;
}

@keyframes slideUpFade {
    0% {
        transform: translateY(20%);
        opacity: 0;
    }
    70% {
        transform: translateY(-10px);
        opacity: 0.8;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@media screen and (max-width: 800px) {
    .chatbot-main-container {
        width: 100%;
        height: 100%;
        position: absolute;
        display: flex;
        flex-direction: column;
        z-index: 15;
        right: 0;
        bottom: 0;
    }

    .chat-box {
        height: 100%;
        width: 100%;
        padding-bottom: 0px;
    }

    .chat-bot-content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        overflow: scroll;
        height: calc(100% - 48px);
        background: #FDFAF9;
    }

    .chatbot-overlay {
        z-index: 19;
    }
}
