.trainingHubComponent {
  .containerTop {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background: #fff;
    margin-bottom: 5px;

    a {
      font-size: 16px;
      font-weight: 400;
      text-decoration: none;
    }
  }

  .containerMiddle {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-bottom: 100px;

    .tab-wrapper {
      background: #fff;

      .table-container {
        padding: 8px;
        margin: 0;

        .incentive-tags {
          margin: 10px;
          float: left;
          font-weight: 500;

          .tag-names {
            padding: 5px;
            padding-left: 0;
            float: left;
          }
        }
      }
    }

    .ant-tabs-nav {
      margin: 0;
      background-color: white;

      .ant-tabs-nav-wrap {
        margin-left: 20px;
      }

      .ant-tabs-nav-list {
        color: var(--primary-theme-color);

        .ant-tabs-tab-btn {
          color: var(--primary-theme-color) !important;
        }
      }
    }

    .ant-table-tbody {
      tr {
        td {
          border-bottom: none;
        }
      }
    }

    .th-schedule-btn-container {
      margin-top: 1em;
      .th-schedule-btn {
        width: 25%;
      }
    }
  }

  .cardParent {
    padding: 5px;
  }

  .cardDescription {
    padding-top: 20px;
  }

  .cardButton {
    background: #d53e3f;
    color: #fff;
  }
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .trainingHubComponent {
    .containerTop {
      padding: 0;
    }

    .containerMiddle {
      overflow: scroll;
      padding-bottom: 100px;

      .table-container {
        overflow: scroll;
        height: calc(100vh - 200px);
      }
    }
  }
}