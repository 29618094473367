.AdditionalInfoScreen {
    padding: 24px;
    min-height: var(--additional-info-screen-height);
    padding-bottom: 100px;
}

.header-text {
    color: #12349a;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
}

.tab-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.tab-container div:nth-child(1) {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-left: 1px solid #e6e8ec;
}

.tab-container div:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.tab-item{
    border: 1px solid #e6e8ec;
    border-left: none;
    padding: 6px 10px;
    cursor: pointer;
    color: #9a9a9a;
    display: flex;
    align-items: center;
    gap: 6px;
}

.active{
    background-color: #ff702d;
    color: #fffcfb;
    font-weight: 600;
}

.pending{
    cursor: not-allowed;
    background-color: #e5e5e5;
}

.done {
    color: #4BB543;
}

/* CSS for mobile devices */
@media screen and (max-width: 768px) and  (min-width: 320px) {
    .AdditionalInfoScreen {
        padding: 10px;
        padding-bottom: 100px;
    }
}
