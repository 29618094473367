.Notification {
  font-family: var(--primary-font-family);
  text-align: start;
  .level-1 {
    padding-left: 20px;
    height: 100vh;
    overflow: scroll;
    .circle-online {
      border-radius: 50%;
      height: 10px;
      width: 10px;
      display: block;
    }
  }
  .level-2 {
    padding-left: 18px;
    width: min-content;
    text-align: start;
    margin-right: 16px;
  }
  .tag-information {
    color: red;
    font-family: var(--primary-font-family);
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    display: flex;  
    align-items: center;
    gap: 4px;
  }
  .tag-information-zomato {
    color: #777788;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    display: flex;  
    align-items: center;
    gap: 4px;
  }
  .inbox-detail-tag, .inbox-detail-title, .inbox-detail-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }

  .inbox-detail-title span{
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    font-family: var(--primary-font-family);
    font-style: normal;
  }

  .inbox-detail-content div{
    font-family: var(--primary-font-family);
        font-weight: 400;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }

  .inbox-detail-content img {
    max-width: 100%;
    height: auto;
  }

  .custom-title {
    font-family: var(--primary-font-family);
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }

  .filter-content {
    font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  }

  .page-header-font {
    font-family: var(--primary-font-family);
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
  }
  .inputContainer {
    display: flex;
    width: 100%;
    align-items: center;
  }
  .chatTypeContainer {
    border: 1px solid #ccc;
    border-radius: 10px;
    display: flex;
    width: 100%;
    align-items: center;
  }
  .inputContainer .chatTypeContainer button {
    margin-top: -0.5%;
    color: rgb(91, 91, 91);
    font-size: 16px;
  }
  .input {
    padding: 10px;
    flex: 1;
    border: none;
    margin-right: 10px;
    font-size: 12px;
    margin-left: 10px;
  }
  .imgContainer .sendButton {
    background: #D34B18;
    padding: 50%;
    color: #fff;
    border-radius: 50%;
  }
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .Notification {
    .level-1 {
      padding-left: 0px;
      padding-bottom: 80px;
      flex: 0 0 100% !important;
    }
    .level-2 {
      display: none;
    }
  }
}
