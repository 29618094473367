.role-permissions-modal-parent {
    min-height: 50vh;
    max-height: 50vh;

    .ant-divider-horizontal {
        margin: 0px !important;
    }

    .ant-modal-content {
        height: 100%;
    }

    .ant-modal-body {
        padding: 0;
    }

    .ant-modal-close-x {
        display: none !important;
    }

    .ant-divider {
        margin: 0;
    }

    .role-permissions-container-parent {
        height: auto;
        max-height: 60vh;
        overflow: scroll;

        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .rpc-header {
                padding: 1vh 1vh !important;
            }
        }

        .rpc-header {
            padding: 1vw 1vw;
            // border-bottom: 1px solid rgba(0, 0, 0, 0.15);

            .rpc-header-txt {
                color: var(--light-theme-neutrals-neutral-1, rgba(0, 0, 0, 0.85));
                font-size: 20px;
                font-weight: 600;
                line-height: 28px;
            }


            .rpc-header-title {
                .rpc-comp-logo {
                    height: 40px;
                    width: 40px;
                    margin-right: 8px;
                    object-fit: contain;
                }

                .rpc-header-icon {
                    font-size: 20px;
                    margin-right: 8px;
                }
            }

            .rpc-header-close {
                padding: 8px;
                border-radius: 2px;
                border: 1px solid rgba(0, 0, 0, 0.15);
                background: #FFF;

                .rpc-header-close-icon {
                    font-size: 20px;
                }
            }
        }

        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .rpc-features-list {
                padding: 0vw 1vh !important;
            }
        }

        .rpc-features-list {
            padding: 0vw 1vw;
            padding-bottom: 70px !important; //Adding additional padding to display this section above footer who's height = 50px
            height: auto;
            overflow: scroll;

            .rpc-feature-item-list {
                .rpc-feature-dropdown {
                    width: 100%;
                    min-width: 2vw;

                    .ant-select-selector {
                        border: none;
                        color: var(--primary-theme-light, var(--primary-theme-color));
                        font-family: "var(--primary-font-family)";
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 22px;
                        min-width: 10vw !important;
                        min-width: 10vw !important;

                        .ant-select-selection-item {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }

                .rpc-feature-item {
                    margin-top: 12px;

                    .rpc-feature-value {
                        .rpc-num-inp {
                            flex-direction: column;

                            .rpc-num-inp-err{
                                margin-top: 8px;
                            }
                        }
                    }
                }
            }
        }

        .rpc-section-dropdown {
            width: 100%;
            min-width: 2vw;

            .ant-select-selector {

                .ant-select-selection-item {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .rpc-footer {
                padding: 2vh 1vh !important;
            }
        }

        .rpc-footer {
            padding: 2vw 1vw;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 70px;
            background-color: #FFF;
            border-top: 1px solid rgba(0, 0, 0, 0.15);
            z-index: 10;

            .rpc-tc-txt {
                color: var(--primary-theme-light, var(--primary-theme-color));
                font-size: 16px;
                font-weight: 700;
                text-decoration-line: underline;
            }

            .rpc-footer-btns-container {
                .rpc-cancel-btn {
                    width: 30%;
                }

                .rpc-submit-btn {
                    margin-left: 4%;
                    width: 85%;
                }
            }
        }
    }
}


.rpc-confirm-modal-parent {
    .ant-modal-body {
        padding: 0;
    }

    .ant-modal-close-x {
        display: none !important;
    }

    .ant-divider {
        margin: 0;
    }

    .rpc-confirm-modal-content {
        .rpc-confirm-modal-header {
            padding: 12px;
            // border-bottom: 1px solid rgba(0, 0, 0, 0.15);

            .rpc-confirm-modal-header-title {
                .rpc-confirm-modal-comp-logo {
                    height: 40px;
                    width: 40px;
                    margin-right: 8px;
                    object-fit: contain;
                }

                .rpc-confirm-modal-header-icon {
                    font-size: 20px;
                    margin-right: 8px;
                }
            }

            .rpc-confirm-modal-header-close {
                padding: 8px;
                border-radius: 2px;
                border: 1px solid rgba(0, 0, 0, 0.15);
                background: #FFF;

                .rpc-confirm-modal-header-close-icon {
                    font-size: 20px;
                }
            }
        }

        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .rpc-confirm-modal-body {
                max-height: 54vh;
                overflow: scroll;
            }
        }

        .rpc-confirm-modal-body {
            padding: 24px 12px;
            min-height: 50px;

            .rpc-confirm-modal-loading {
                padding: 24px;
            }

            .rpc-confirm-modal-content {}
        }

        .rpc-confirm-modal-btns-container {
            padding: 12px;

            .rpc-confirm-modal-confirm-btn {
                margin-left: 12px;

                .rpc-confirm-btn-loader {
                    color: white;
                    margin-left: 8px;
                }
            }
        }
    }
}