.carousel-container {
    max-width: 600px; /* Adjust as needed */
    padding: 15px 0; /* Center the carousel */
    border-top: 1px solid #0000000F;
}

.full-screen-image {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 60%;
    transform: translate(-50%, -50%);
    max-width: 70%;
    max-height: 70%;
    z-index: 1000;
    cursor: pointer;
} 
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    cursor: pointer;
}


.video-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .video-wrapper {
    position: relative;
    width: 70%;
    height: 70%;
    max-width: 800px;
    max-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
        iframe {
            width: 100%;
            height: 100%;
        }
  }
  
  .close-icon-video {
    position: absolute;
    bottom: 150%;
    right: 0;
    font-size: 30px;
    color: #fff !important;
    cursor: pointer;
    z-index: 1001; /* Ensure it's above the video */
  }

.carousel-container {
    .product-updates-header {
        text-align: left;
    }
}
.product-updates-image {
    margin-right: 16px;
    .normal-image {
        height: 120px !important;
        width: auto;
        border-radius: 8px;
    }
}
.CTA-btn {
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 16px;
    font-weight: 700 !important;
    width: 95%;
    position: absolute !important;
    bottom: 12px;
    left: 2.5%;
    right: 2.5%;
}
.carousel {
  overflow: hidden;
  width: 100%;
  position: relative;
}

.slide.inactive {
  display: none;
}

.slide {
  display: block;
}

.slide img {
  max-width: 100%;
  height: auto;
}
.carousel-header {
    width: 40%;
    text-align: right;
}
.carousel-controls {
    display: flex;
    justify-content: flex-end;
    .carousel-icon {
        color: #00000040;
        display: flex;
        align-items: center;
        font-size: 10px;
    }
}
.carousel-number {
    color: #000000A6;
    font-weight: 600;
    font-size: 14px;
}
.product-updates-header {
    width: 60%;
    span {
        border-radius: 2px;
        padding: 2px 8px;
        margin-left: 8px;
        color: #FFF;
        font-size: 12px;
        border: 1px solid #1890FF;
        background: #1890FF;
    }
}

.feedback-modal {
    .feedback-header {
        text-align: left;
        margin-top: 6px;
        margin-left: 6px;
    }
    .feedback-description {
        text-align: left;
        margin-top: 6px;
        margin-left: 6px;
    }
    .feedback-button-text {
        color:var(--primary-theme-color);
        padding: 4px 22px;
    }
    .vl-secondary-btn {
        margin-left: 6px;
    }
}
.performace-dash-numbers {
    margin: 12px;
    width: 33%;
    .perf-header {
        text-align: left;
        margin-top: 12px;
        color:var(--primary-theme-color);
    }
    .perf-description {
        text-align: left;
        margin-top: 6px;
    }
    .performace-container {
        border-radius: 8px;
        border: 1px solid #00000026;
        background: #FFFFFF;
        margin-bottom: 12px;
    }
    .go-to-performace-button {
        width: 80% !important;
        text-align: left !important;
        color:var(--primary-theme-color);
    }
    .go-to-performance-icon {
        width: 20% !important;
        text-align: right !important;
        color:var(--primary-theme-color);
        font-size: 24px;
    }
    .performace-container-button {
        border-radius: 8px;
        border: 1px solid var(--primary-theme-color)80;
    }
}

@media (min-width: 320px) and (max-width: 768px) {
    .full-screen-image {
        width: 90% !important;
    }
    .video-wrapper {
        width: 90% !important;
        height: auto;
    }
    .close-icon-video {
        bottom: 140%;
    }
}