@media only screen and (min-width: 320px) and (max-width: 786px) {
    .root-class {
        height: 100% !important;
    }
}

@media only screen and (min-width: 320px) and (max-width: 786px) {
    .App {
        height: 100% !important;
    }
}

@media only screen and (min-width: 320px) and (max-width: 786px) {
    .ant-spin-container {
        height: 100% !important;
        position: inherit !important;
    }

    .ant-spin-nested-loading { 
        position: inherit !important;
    }
}

@media only screen and (min-width: 320px) and (max-width: 786px) {
    .bulk-referral-new-parent {
        height: 90% !important;
        padding: 12px 20px !important;
    }
}

.bulk-referral-new-parent {
    height: var(--additional-info-screen-height);
    overflow: scroll;
    padding: 24px 40px;

    .ant-btn-default:disabled {
        opacity: 0.3;
    }

    .br-cp {
        cursor: pointer;
    }

    .br-info-icon {
        color: rgba(0, 0, 0, 0.45);
    }

    .br-info-txt {
        font-weight: 400;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.45);
        margin-left: 12px;
        text-align: left;
    }

    @media only screen and (min-width: 320px) and (max-width: 786px) {
        .br-steps-parent {
            padding: 0px 12px !important;
        }
    }

    .br-below-divider {
        .br-steps-parent {
            margin-top: 2vw;
            background-color: rgba(0, 0, 0, 0.04);
            padding: 0px 48px;

            overflow: scroll;

            // .br-step {

            // }

            .br-step-w30 {
                width: 28%;
            }

            .br-step-w20 {
                width: 16%;
            }

            // .br-step-mobile {
            //     min-width: 70%;
            // }

            .br-step-mobile-w75 {
                min-width: 75%;
            }

            .br-step-mobile-w65 {
                min-width: 65%;
            }

            .br-step-txt {
                padding: 8px 0px;
                color: #00000073;
            }

            .br-active-step {
                border-bottom: 1px solid var(--primary-theme-color);
                color: var(--primary-theme-color);
                font-weight: 600;
            }

            @media only screen and (min-width: 320px) and (max-width: 786px) {
                .br-step-arrow-img {
                    padding: 0px 12px !important;
                }
            }

            .br-step-arrow-img {
                color: rgba(0, 0, 0, 0.25);
                padding: 0px 24px;
            }
        }

        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .br-step-parent {
                padding-bottom: 2vw;
            }
        }

        .br-step-parent {
            height: 100%;
            width: 100%;
            flex-direction: column;
            overflow: scroll;
            display: flex; 
            justify-content: flex-start; 
            align-items: center;
            margin: 0 auto;

            @media only screen and (min-width: 320px) and (max-width: 786px) {
                .br-step-one-section {
                    width: 100% !important;
                }

                .br-step-three-above-divider, .br-step-dropdown-fields, .br-team-member-search-container {
                    overflow: scroll;
                    margin-bottom: 100px;
                    margin-top: 24px;
                }

                .br-step-dropdown-fields{
                    width: 100% !important;
                }
            }

            .br-step-one-dropdown {
                font-weight: 400;
                font-family: var(--primary-font-family);
            }

            .br-step-one-city-name {
                display: flex;
                margin-bottom: 4px;
                font-weight: 400;
                font-size: 14px;
                font-family: var(--primary-font-family);
            }

            .client_index_text {
                display: flex;
                font-weight: 400;
                font-size: 16px;
                font-family: var(--primary-font-family);
            }

            .br-step-one-city-name::before {
                content: "* ";
                color: red; 
            }

            .br-step-dropdown-fields {
                margin-top: 5vh;
                width: 50%;
                margin-bottom: 50px;
            }

            .side-selector-container {
                display: flex;
                justify-content: center; 
                align-items: center; 
              }

              .ant-select-selection-placeholder {
                display: flex;
                justify-content: center;
              }

              .ant-select-selection-item {
                display: flex;
                justify-content: center;
              }
            .br-step-action-fields {
                margin-top: 48px;
                flex-direction: column;
                position: absolute;
                bottom: 0;
                width: 95% !important;
                flex-direction: column;
                background-color: white;
                padding-bottom: 30px;


                @media only screen and (min-width: 320px) and (max-width: 786px) {
                    .br-step-btns {
                        justify-content: center !important;
                    }
                }

                .br-step-btns {
                    justify-content: flex-end;

                    @media only screen and (min-width: 320px) and (max-width: 786px) {
                        .br-btn-container {
                            width: 49% !important;
                        }
                    }

                    .br-btn-container {
                        width: 25%;

                        .br-step-btn-img-orange {
                            color: var(--primary-theme-color);
                        }

                        .br-step-btn-img-white {
                            color: white;
                        }
                    }

                    .br-clear-all-btn {
                        border: 1px solid var(--primary-theme-color);
                        color: var(--primary-theme-color);
                        font-weight: 600;
                    }

                    .br-next-btn {
                        background-color: var(--primary-theme-color);
                        color: white;
                        margin-left: 2vw;
                        font-weight: 600;
                    }
                }
            }

            .desktop-additional-margin-top {
                margin-top: 12vh;
            }

            @media only screen and (min-width: 320px) and (max-width: 786px) {
                .desktop-additional-margin-top {
                    margin-top: 24px;
                }

                .br-step-action-fields {
                    width: 90% !important;
                }
            }
        }

        @media only screen and (min-width: 320px) and (max-width: 786px) {
        
        .add-client-btn {
            margin-bottom: 100px;
        }
    }

        .parent-client-div {
            border: 1px solid #d9d9d9;
            border-radius: 2px;
            padding-top: 8px;
            padding-left: 20px;
            padding-right: 20px;
            margin: 0 auto 20px;
        }
        
        .add-client-btn {
            border: 1px solid var(--primary-theme-color);
            border-radius: 2px;
            padding: 4px 8px; 
            display: inline-flex; 
            justify-content: flex-start;
            width: auto; 
            align-items: center; 
            margin-bottom: 50px;
        }
        

          .remove-image-ai-filtering {
            height:14px;
            width: 14px;
            align-self: center;
            margin-right: 4px;
        }

          .remove-client-btn {
            display: inline-flex;
          }

          .remove-client-container {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;

            .green-tick-icon{
                 color: #31C824D9;
                 font-size: 14px;
                 margin-right: 8px;
                 align-self: center; 
                 margin-left: 8px
            }
          }

          .br-step-btn-img-orange {
            margin-right: 8px;
            color: var(--primary-theme-color);
            align-self: center;
        }
        .br-cross-btn-img-orange {
            margin-right: 8px;
            color: #FF4D4F;
            align-self: center;
        }

          .parent-container {
            text-align: left;
          }

          @media only screen and (min-width: 320px) and (max-width: 786px) {
            .parent-container {
               margin-bottom: 50px !important;
              }
          }

        .br-step-two-dropdown-parent {
            .br-team-member-search-container {
                flex-direction: column;


                @media only screen and (min-width: 320px) and (max-width: 786px) {
                    .br-team-member-search-txt-select {
                        height: 100% !important;
                        width: 100% !important;
                    }
                }

                .br-team-member-search-txt-select {
                    flex-direction: column;
                    height: 50%;
                    width: 50%;
                }

                .br-team-member-search-txt {
                    font-weight: 400;
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.45);
                }

                @media only screen and (min-width: 320px) and (max-width: 786px) {
                    .br-mitra-team-search-select {
                        width: 100% !important;
                    }
                }

                .br-mitra-team-search-select {
                    margin-top: 2vh;
                    width: 75%;

                    .ant-select-selection-placeholder {
                        font-weight: 400;
                        font-size: 14px;
                        color: rgba(0, 0, 0, 0.65);
                    }

                    .ant-select-selection-overflow{
                        margin-left: 24px;
                    }

                    .br-mitra-team-search-select-icon {
                        color: rgba(0, 0, 0, 0.25);
                        width: 16px;
                        position: absolute;
                        margin-right: 6px;
                        margin-top: 2px;
                        right: 0;
                        // padding-right: 20px;
                    }
                }
            }
        }

        .br-step-three-upload-parent {
            .br-step-three-info-icon {
                color: rgba(0, 0, 0, 0.45);
            }

            .br-step-three-info-txt-container {
                padding: 5vw 0;

                .br-step-three-info-txt {
                    font-weight: 400;
                    font-size: 14px;
                    margin-left: 2vw;
                    color: rgba(0, 0, 0, 0.45);
                    text-align: left;
                }
            }

            .br-step-three-upload-btn-container {

                .br-step-three-antd-upload {
                    flex-direction: column-reverse;
                    align-items: center;

                    .ant-upload-list-text-container {
                        margin-bottom: 1vw;

                        .ant-upload-text-icon {
                            display: flex;
                            color: var(--primary-theme-color) !important;

                            .anticon-paper-clip {
                                color: var(--primary-theme-color) !important;
                            }
                        }

                        .ant-upload-list-item-name {
                            color: var(--primary-theme-color) !important;
                            font-weight: 600;
                            font-size: 14px;
                        }
                    }
                }

                @media only screen and (min-width: 320px) and (max-width: 786px) {
                    .ant-upload {
                        width: 100% !important;
                    }
                }

                .ant-upload {
                    width: auto;

                    button {
                        color: white;
                    }
                }

                .br-step-three-upload-btn {
                    background-color: var(--primary-theme-color);
                    color: white;

                    .br-step-three-btn-img-white {
                        color: white;
                    }

                    .br-step-three-upload-btn-txt {
                        font-weight: 600;
                        font-size: 14px;
                        margin-left: 1vw;
                    }
                }

                .br-step-three-reupload-btn {
                    color: var(--primary-theme-color);
                    border: 1px solid var(--primary-theme-color);
                    ;

                    .br-step-three-btn-img-orange {
                        color: var(--primary-theme-color);
                        ;
                    }

                    .br-step-three-reupload-btn-txt {
                        font-weight: 600;
                        font-size: 14px;
                        margin-left: 1vw;
                        color: var(--primary-theme-color);
                        ;
                    }
                }
            }

            .br-step-three-note-txt {
                font-weight: 400;
                font-size: 14px;
                margin-top: 2vh;
            }

            .br-step-three-dont-know-txt {
                font-weight: 400;
                font-size: 14px;
                color: rgba(212, 136, 6, 1);
                margin-top: 5vw;
            }

            @media only screen and (min-width: 320px) and (max-width: 786px) {
                .br-step-three-download-btn-container {
                    width: 100% !important;
                }
            }

            @media only screen and (min-width: 320px) and (max-width: 786px) {
                .br-step-three-download-btn {
                    width: 100% !important;
                }
            }

            .br-step-three-download-btn {
                border: 1px solid var(--primary-theme-color);
                border-radius: 2px;
                color: var(--primary-theme-color);
                width: auto;
                margin-top: 2vh;

                .br-step-btn-img-orange {
                    color: var(--primary-theme-color);
                }

                .br-step-three-download-btn-txt {
                    font-weight: 600;
                    font-size: 14px;
                    margin-left: 1vw;
                    color: var(--primary-theme-color);
                }
            }

            @media only screen and (min-width: 320px) and (max-width: 786px) {
                .br-upload-action-btn {
                    width: 100% !important;
                }
            }

            .br-upload-action-btn {
                width: 25%;
                background-color: var(--primary-theme-color);

                .br-upload-action-btn-txt {
                    font-weight: 600;
                    font-size: 14px;
                    color: white;
                    margin-left: 2vh;
                }

                .br-upload-action-btn-img-white {
                    color: white;
                }
            }

        }

        .br-summary-parent {
            flex-direction: column;

            @media only screen and (min-width: 320px) and (max-width: 786px) {
                .br-summary-content-container {
                    margin-top: 10vw !important;
                    width: 100% !important;
                }
            }

            .br-summary-content-container {
                flex-direction: column;
                margin-top: 3vw;
                width: 50%;
                height: 50%;

                .br-summary-content {
                    flex-direction: column;
                }

                .br-summary-processed-icon {
                    color: rgba(49, 200, 36, 1);
                    font-size: 40px;
                }

                .br-summary-processed-txt {
                    font-weight: 400;
                    font-size: 16px;
                    color: rgba(0, 0, 0, 0.85);
                    margin-top: 1vw;
                }

                @media only screen and (min-width: 320px) and (max-width: 786px) {
                    .br-summary-info {
                        width: 100% !important;
                    }
                }

                .br-summary-info {
                    border: 1px solid rgba(0, 0, 0, 0.06);
                    padding: 24px;
                    border-radius: 4px;
                    width: 100%;
                    margin-top: 1vw;

                    @media only screen and (min-width: 320px) and (max-width: 786px) {
                        .br-summary-txt {
                            font-size: 14px !important;
                            margin-left: 3vw !important;
                        }
                    }

                    .br-summary-txt {
                        font-weight: 400;
                        font-size: 16px;
                        color: rgba(0, 0, 0, 0.65);
                        margin-left: 0.5vw;
                    }

                    @media only screen and (min-width: 320px) and (max-width: 786px) {
                        .br-summary-number-txt {
                            font-size: 14px !important;
                        }
                    }

                    .br-summary-number-txt {
                        font-weight: 400;
                        font-size: 16px;
                        color: rgba(0, 0, 0, 0.45);
                    }

                    .br-summary-section {}

                    .br-summary-total-container {

                        .br-summary-total-icon {
                            font-size: 16px;
                            color: rgba(0, 0, 0, 0.65);
                        }
                    }

                    .br-summary-processing-container {
                        margin-top: 2vw;

                        .br-summary-processing-icon {
                            font-size: 16px;
                            color: rgba(212, 136, 6, 1);
                        }

                        .br-summary-processing-txt-parent {
                            flex-direction: column;
                            align-items: flex-start;

                            .br-summary-processing-txt {
                                font-weight: 400;
                                font-size: 16px !important;
                            }

                            @media only screen and (min-width: 320px) and (max-width: 786px) {
                                .br-summary-processing-txt {
                                    font-size: 14px !important;
                                }
                            }
                        }

                        @media only screen and (min-width: 320px) and (max-width: 786px) {
                            .br-summary-processing-sub-txt {
                                font-size: 12px !important;
                            }
                        }

                        .br-summary-processing-sub-txt {
                            font-weight: 400;
                            font-size: 16px;
                            color: rgba(0, 0, 0, 0.45);
                        }
                    }

                    .br-summary-error-container {
                        margin-top: 1vw;

                        .br-summary-error-icon {
                            font-size: 16px;
                            color: rgba(255, 77, 79, 1);
                        }
                    }
                }
            }

            .br-summary-btn-container {
                justify-content: center;
                gap: 24px;
                // height: 40%;
                margin-top: 2em;

                @media only screen and (min-width: 320px) and (max-width: 786px) {
                    .br-summary-btn-section {
                        width: 100% !important;
                    }
                }

                .br-summary-btn-section {
                    width: 25%;
                }

                .br-summary-download-btn {
                    background-color: var(--primary-theme-color);
                    width: auto;

                    .br-summary-btn-img-white {
                        color: white;
                        margin-left: 1vh;
                    }

                    .br-summary-download-btn-txt {
                        color: white;
                        font-weight: 600;
                        font-size: 14px;
                        margin-left: 1vw;
                    }
                }

                .br-summary-goto-btn {
                    // margin-top: 5%;
                    width: auto;

                    .br-summary-goto-btn-txt {
                        font-weight: 600;
                        font-size: 14px;
                        color: var(--primary-theme-color);
                    }

                    .br-summary-btn-img-orange {
                        color: var(--primary-theme-color);
                        margin-right: 1vw;
                    }
                }
            }

            .ai-filteration-indicator {
                border: 1px solid #00000026;
                border-radius: 4px;
                padding: 16px;
                width: 50%;
                margin: 0 auto;

                .ai-filteration-text {
                    text-align: left;
                    color: #2F54EB;
                }
            }
        }
    }
}

.error-border {
    border: 1px solid #FF4D4F;
}

@media only screen and (min-width: 320px) and (max-width: 786px) {
    .br-summary-btn-container {
        flex-direction: column;
        justify-content: center;
        gap: 24px;
    }

    .bulk-referral-new-parent .br-below-divider .br-summary-parent .ai-filteration-indicator {width: 100%;}

    .bulk-referral-new-parent .br-below-divider .br-step-parent {height: auto; padding-bottom: 80px;}
}

.tickets-resolution-indicator-container {
    border-radius: 8px;
    // background: #D7AF1D;
    background: #d7af1d80;
    position: relative;
    width: 400px;
    height: 8px;
    margin-top: 20px;
}

.bu-tickets-resolved-indicator {
    border-radius: 8px;
    background: #31c824;
    height: 8px;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    z-index: 3;
}

.tickets-rejected-indicator {
    border-radius: 8px;
    background: #FF4D4F;
    height: 8px;
    position: absolute;
    top: 0;
    height: 100%;
    z-index: 2;
}

.tickets-warning-indicator {
    border-radius: 8px;
    background: #D7AF1D;
    height: 8px;
    position: absolute;
    top: 0;
    height: 100%;
    z-index: 1;
}

.br-summary-new-contents {
    width: 474px;
    background: #f6f6f6;
    border-radius: 8px;
    padding: 32px;
}

.bottom-fixed-cta-bar {
    height: 64px;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #FFF;
    box-shadow: 0px 0px 4px 0px rgba(41, 91, 209, 0.20), 0px 0px 16px 0px rgba(41, 91, 209, 0.08);
}

.bottom-fixed-button-width {
    width: 274px;
}

@media screen and (max-width: 800px) { 
    .bottom-fixed-cta-bar {
        bottom: 75px;
    }

    .bottom-fixed-button-width {
        width: 90%;
    }
    .br-summary-new-contents {
        width: 100%;
        background: #f6f6f6;
        border-radius: 8px;
        padding: 16px;
    }
    .tickets-resolution-indicator-container {
        width: 100%;
        margin-top: 8px;
    }

    .lead-count-summary-content {
        width: 100%;
        gap: 16px !important;
    }
}