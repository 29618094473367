.session-info-section {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.session-card-container {
    border-radius: 10px;
    border: 1px solid #C8C9CC;
    width: 370px;
    padding: 22px 12px;

    box-shadow: none;

    transition: box-shadow 0.3s ease-in-out,  border 0.3s ease-in-out;
}

.session-card-container:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border: none;
}

.client-grp-container {
    margin-top: 16px;
    display: flex;
    align-items: center;
    gap: 4px
}

.session-type-image {
    width: 56px;
    height: 50px;
    border-radius: 8px;
    border: 1px solid #9D9D9D;
}

.session-type-execution-status {
    border-radius: 10px;
    background: #40B746;
    width: 72px;
    height: 14px;

    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
}

.session-type-execution-status-incomplete {
    background: #ff8704;
}

.session-type-info {
    display: flex;
    gap: 8px;
}

.session-type {
    color: #000;

text-align: center;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 18px;
}

.session-summary {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 16px;
    margin-top: 16px;
}

.info-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
}

.session-info-item-header {
    color: #333;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.session-info-item-sub-header {
    color: #323232;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}

.view-session-btn {
    color: rgba(29, 95, 255, 1) !important;
    border-radius: 4px !important;
    border: 2px solid #1D5FFF !important;
    background: none !important;
    height: 44px !important;
    width: 285px;

    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    /* line-height: 47px; */

    margin-top: 16px;
}

.view-session-btn:hover {
    color: #FFF !important;
    border-radius: 4px !important;
    border: 2px solid #1D5FFF !important;

    background: #1D5FFF !important;
    height: 44px !important;
    width: 285px;

    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;

    margin-top: 16px;
}

@media only screen and (min-width: 320px) and (max-width: 786px) {
    .view-session-btn, .view-session-btn:hover {
        width: 100%;
    }
}