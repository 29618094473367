.App {
  text-align: center;
  display: flex;

  overflow-y: hidden;
  max-height: 100vh;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 1023px) {
  .App {
    display: flex;
    flex-direction: column;
  }
}

.app-powered-by {
  position: absolute;
  bottom: 1em;
  right: 1em;
}

.powered-by-footer-img {
  max-width: 28px;
  margin-left: 8px;
}
/* @font-face {
  font-family: "Gilroy ExtraBold";
  src: url("./fonts/Gilroy-ExtraBold.otf");
}
@font-face {
  font-family: "Noto Sans Light";
  src: url("./fonts/NotoSans-ExtraLight.ttf");
}
@font-face {
  font-family: "Noto Sans Medium";
  src: url("./fonts/NotoSans-Medium.ttf");
}

@font-face {
  font-family: "Noto Sans Regular";
  src: url("./fonts/NotoSans-Regular.ttf");
} */

.formio-component {
  /* font-family: "Noto Sans Regular"; */
  text-align: left;
  margin: 1rem;
}
.field-required:after {
  color: red;
  content: " *";
  display: inline;
}
.formio-component .formio-errors {
  /* font-family: "Noto Sans Regular"; */
  color: red;
  font-size: 0.9rem;
}
.formio-select-autocomplete-input {
  display: none;
}
.formio-component select::placeholder,
.formio-component textarea::placeholder {
  /* font-family: "Noto Sans Regular"; */
  font-size: 1rem;
  color: #5f5f5f;
}
.formio-component-button {
  /* font-family: "Noto Sans Medium"; */
  background-color: #ff712d;
  text-transform: uppercase;
  font-size: 0.8rem;
  padding: 0.5rem 2rem;
  margin: 2rem 0 5rem 0;
  text-align: center;
}

.vertical-middle {
  vertical-align: middle;
  margin: auto 0px;
}

.Home {
  /* font-family: "Noto Sans Medium"; */
  padding-bottom: 100px !important;
}
/* .Home select {
.home{
  margin: 0px !important;
}
.Home select {
  width: calc(20% + 50px) !important;
  background-color: #e0e0e0 !important;
} */
.Home input[type="file"] {
  min-width: 20% !important;
  font-size: 15px;
  padding: 1rem 2rem 1rem 0rem;
}
.Home input[type="text"],
.Home input[type="date"],
.Home input[type="email"],
.Home textarea,
.Home input[type="tel"],
.Home select,
.Home.submit-mitra {
  /* min-width: 20% !important;
  font-size: 15px;
  background-color: #e0e0e0;
  padding: 1rem 2rem 1rem 1rem !important;
  border: 2px solid #e0e0e0;
  z-index: 1;
  border-radius: 10px; */
}
.Home input[type="submit"],
.Home input[type="button"],
.Home input[type="reset"] {
  /* font-family: "Noto Sans Medium"; */
  background-color: #ff712d;
  text-transform: uppercase;
  font-size: 0.8rem;
  padding: 0.5rem 2rem;
  margin: 2rem 0 5rem 0;
}
/* .Home button {
  font-family: 'Noto Sans Medium';
  background-color: #FF712D;
  text-transform: uppercase;
  font-size: 0.8rem;
} */
.Home button.btn {
  margin: 0.5rem;
}
.Home .heading_1 button {
  /* margin:0px; */
}
.Home div.form_control,
.Home nav.pagination {
  text-align: center;
  margin: 1rem;
}

.Home .save-mitra {
  display: inline-block;
  width: 41.5%;
  text-align: center;
}
.Logout div.form_control p {
  margin-left: 39%;
  margin-right: 39%;
}
span.page-link:link,
span.page-link:visited {
  text-decoration: none;
  display: inline-block;
  color: #0f349a;
}
span.page-link:hover,
span.page-item:active {
  text-decoration: underline;
  font-weight: bold;
}
.Home div.form_control span.errMsg,
.Home div.form_control p.errMsg,
.Home .alert-danger {
  /* font-family: "Noto Sans Regular"; */
  color: red;
  font-size: 0.9rem;
  margin: 1rem;
  text-align: left;
}
.Home div.form_control p.successMsg {
  /* font-family: "Noto Sans Regular"; */
  color: green;
  font-size: 0.9rem;
}
/* .Home input:focus {
  border: 1px solid #ff712d !important;
} */
.Home input::placeholder,
.Home textarea::placeholder {
  /* font-family: "Noto Sans Regular"; */
  font-size: 14px;
  color: #5f5f5f;
}
.radio-button-title,
.input-text-title {
  font-size: 16px;
  /* margin-left: 5%; */
  /* font-family: "Noto Sans Regular"; */
}
.input-text-title {
  text-align: left;
  margin: 2rem 0 0.5rem 39%;
}
.radio-button-title input[type="radio"],
.radio-button-title input[type="checkbox"] {
  margin: 0.7rem !important;
}
.radio-button-title label {
  width: 100% !important;
  /* font-family: "Noto Sans Regular"; */
}
.form-sub-heading {
  max-width: 100%;
  color: #3d3c3a !important;
  background-color: transparent !important;
}
.formio-component-select {
  display: flex;
  flex-direction: column;
}
/* input[type='radio']:after {
  width: 12px;
  height: 12px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #f1f1f1;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid #FF712D;
}
input[type='radio']:checked:after {
  width: 10px;
  height: 10px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #FF712D;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 4px solid white;
} */

/* input[type='checkbox']:after {
  width: 12px;
  height: 12px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #f1f1f1;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid #ffffff;
}
input[type='checkbox']:checked:after {
  width: 10px;
  height: 10px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #FF712D;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 4px solid white;
} */
.Home .heading_1 {
  width: 100%;
  padding: 0.5rem 0;
  margin: 0;
  background-color: #ffffff;
}
/* .Home .heading_1.add-candidate {
  text-align: left;
  color: #ff6b00 !important;
} */
.subheading {
  height: 30px;
  /* width: 230px; */
  color: #3d3c3a;
  /* font-family: "Noto Sans Medium"; */
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
}
.cards-elements-surface-filled {
  border-radius: 3%;
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.16);
  height: 300px;
  /* margin: 0 3rem; */
  padding: 50px 0px 100px;
  text-align: center;
  /* width: 20px; */
  border: 1px solid #e4e3e3;
  background-color: #ffffff;
}
.log-in {
  height: 19px;
  /* width: 300px; */
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
}
.surface {
  box-sizing: border-box;
  height: 42px;
}
.heading_1 {
  /* height: 29px; */
  /* width: 137px; */
  color: #0f349a;
  /* font-family: "Gilroy ExtraBold"; */
  font-size: 24px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 29px;
  text-align: center;
}
.btn_tertiary {
  /* height: 16px !important; */
  /* width: 104px; */
  color: rgb(26, 20, 9);
  /* font-family: "Noto Sans Regular" !important; */
  /* font-size: 13.8px !important;
  font-weight: 500 !important; */
  letter-spacing: 1.25px !important;
  /* line-height: 16px !important; */
  /* text-align: center; */
}
.buttons-contained-enabled-copy button,
input[type="button"] {
  text-transform: none;
  height: 45px;
  padding: 0 5.6rem;
  margin-top: 1rem;
  color: rgba(255, 255, 255, 0.87) !important;
  /* font-family: "Gilroy ExtraBold"; */
  font-size: 15px;
  letter-spacing: 1.25px;
  line-height: 16px;
  background-image: linear-gradient(to right, #ff712d, var(--primary-theme-color));
  text-align: center;
  border-radius: 10px;
}
input[type="button" i]:disabled {
  color: #6b6b6b !important;
  cursor: not-allowed;
}
h5.OtpMessage {
  /* font-family: "Noto Sans Light"; */
}
.button-text {
  height: 16px;
  width: 104px;
  color: #9d6b00;
  /* font-family: "Noto Sans"; */
  font-size: 13.8px;
  font-weight: 500;
  letter-spacing: 1.25px;
  line-height: 16px;
  text-align: center;
}
.main-login-container {
  width: 25%;
  margin: 0 auto;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label,
.mdc-floating-label--float-above {
  color: #000000 !important;
}
.marginTopClass {
  margin-top: 0 !important;
}
.errorMsg {
  margin-bottom: 0;
  margin-top: 0.5rem;
  color: #b00020;
}
.otp-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
}
.otp-popup-inner {
  position: relative;
  top: 5%;
  border-radius: 8px;
  margin: auto;
  width: 40%;
  background: white;
}
.otp-popup-inner .heading_1 {
  /* margin-top: 2.5rem; */
}
.subheading-popup {
  height: 38px;
  margin: 5% 10%;
  margin-top: 4rem;
  /* width: 205px; */
  color: rgba(0, 0, 0, 0.87);
  /* font-family: "Noto Sans Medium"; */
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
}
.btn-home-screen button {
  color: rgba(0, 0, 0, 0.87) !important;
  /* font-family: "Noto Sans Medium"; */
}
.footer-popup {
  height: 38px;
  margin: 2rem 3rem;
  /* width: 205px; */
  opacity: 0.5;
  color: rgba(0, 0, 0, 0.87);
  /* font-family: "Noto Sans Light"; */
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  padding-bottom: 4rem;
}
:root {
  --mdc-theme-primary: #ff712d;
  --mdc-theme-secondary: #03dac4;
  --mdc-theme-error: #b00020;
  --mdc-theme-background: #fff;
  --mdc-theme-surface: #fff;
  --mdc-theme-on-primary: rgba(255, 255, 255, 1);
  --mdc-theme-on-secondary: rgba(0, 0, 0, 0.87);
  --mdc-theme-on-surface: rgba(0, 0, 0, 0.87);
  --mdc-theme-on-error: #fff;
  --mdc-theme-text-primary-on-background: rgba(0, 0, 0, 0.87);
  --mdc-theme-text-secondary-on-background: rgba(0, 0, 0, 0.54);
  --mdc-theme-text-hint-on-background: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-disabled-on-background: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-icon-on-background: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-primary-on-light: rgba(0, 0, 0, 0.87);
  --mdc-theme-text-secondary-on-light: rgba(0, 0, 0, 0.54);
  --mdc-theme-text-hint-on-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-disabled-on-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-icon-on-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-primary-on-dark: white;
  --mdc-theme-text-secondary-on-dark: rgba(255, 255, 255, 0.7);
  --mdc-theme-text-hint-on-dark: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-disabled-on-dark: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-icon-on-dark: rgba(255, 255, 255, 0.5);
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 400px) {
  .buttons-contained-enabled-copy button {
    width: 100%;
    padding: 0 !important;
  }
  .cards-elements-surface-filled {
    padding: 5% 10% 10% !important;
  }
}
div.form_control h5.instruction {
  color: red;
}
div.form_control h5.blue {
  color: var(--primary-theme-color);
  /* font-family: "Noto Sans Regular"; */
  font-size: 0.8rem;
}
div.form_control.job-demands-table .data-table {
  width: 100%;
}
div.form_control.job-demands-table {
  margin-bottom: 60px;
}
div.form_control .extraCompanies {
  width: 60%;
  font-size: 0.9rem;
  color: var(--primary-theme-color);
  /* font-family: "Noto Sans Regular"; */
  text-align: center;
  margin: 0 auto;
}
@media screen and (max-width: 676px) {
  .Logout div.form_control p {
    margin-left: 10%;
    margin-right: 10%;
  }
  .input-text-title {
    margin: 2rem 0 0.5rem 10%;
  }
  .Home select {
    width: calc(70% + 50px) !important;
  }
  .Home input[type="text"],
  .Home input[type="date"],
  .Home input[type="email"],
  .Home textarea,
  .Home input[type="tel"],
  .Home select,
  .Home.submit-mitra {
    min-width: 70% !important;
  }

  .main-login-container {
    width: 100% !important;
  }
  .otp-popup-inner {
    width: 90%;
    top: 20%;
    min-height: 350px;
  }
}

.Home .single-candidate {
  margin-top: 5%;
}
.Home .single-candidate button {
  padding: 0 6.3rem;
}
.Home h4.add-candidate-title {
  margin-top: 5%;
  font-size: 1.5rem;
  /* font-family: "Gilroy ExtraBold"; */
  color: #151515;
}
.mitra-small-logo {
  position: absolute;
  left: 1rem;
  max-height: 70px;
  top: 0.7rem;
}

.OnBoarding .MuiButtonBase-root {
  background-color: transparent !important;
}
.OnBoarding .MuiAutocomplete-clearIndicator {
  display: none !important;
}
.OnBoarding *,
:after,
:before {
  box-sizing: unset !important;
}
.OnBoarding .form-control {
  border: 0px !important;
}
.OnBoarding select {
  width: 0px !important;
}
@media screen and (min-width: 800px) {
  .mainContentZ {
    /* margin-left: 200px; */
    min-height: var(--additional-info-screen-height);
    width: 100%;
    overflow: scroll;
  }

  .header-search {
    display: none;
    visibility: hidden;
  }

  .search-icon {
    border: none;
    vertical-align: middle;
    margin: auto;
  }

  .search-input {
    width: 100%;
    height: 28px;
    font-size: 17px;
    /* font-family: "Nunito Sans", sans-serif; */
    color: rgba(2, 2, 2, 0.87);
  }

  .search-input::placeholder {
    color: rgb(133, 133, 133);
  }
}

@media screen and (min-width: 840px) {
  .header-search {
    display: block;
    visibility: visible;
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 2px;
    align-items: center;
    margin-left: auto;
    height: 32px;
    display: flex;
    flex-direction: row;
    text-align: right;
  }

  .search-icon {
    padding: 0px 5px;
    margin-top: 2px;
    text-align: center;
    align-items: center;
    border: 1px solid rgb(133, 133, 133);
    color: rgb(133, 133, 133);
    background: white;
    height: 28px;
    float: right;
  }
}
.ant-breadcrumb-link {
  font-size: 18px;
}
.ant-alert {
  z-index: 4;
}
.ant-modal-body .ant-btn-primary {
  border-color: var(--primary-theme-color) !important;
  background: var(--primary-theme-color) !important;
}
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
  height: 0;
}
.list-inline-item .btn-wizard-nav-cancel {
  display: none !important;
}
div#moe-push-div.moe-push-class div#desktopBannerWrapped {
  display: none;
}
