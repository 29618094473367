.upload-file-here {
    border: 1px dashed #A19D9D;
    height: auto;
    padding: 22px 22px 30px;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.file-upload-progress {
    flex: 8;
    display: flex;
    flex-direction: column;
    text-align: start;
    justify-content: flex-start;
}

.file-upload-progress-bar-container {
    display: flex;
    gap: 8px;
    align-items: center;
}

.file-upload-progress-bar {
    border-radius: 4px;
    background: #E3E3E3;
    height: 4px;
    width: 100%;
    position: relative;
}

.bar-filled {
    border-radius: 4px;
    background: #1D5FFF;
    position: absolute;
    height: 100%;
}

.successful-upload-container {
    border-radius: 10px;
    border: 1px solid #4E4E4E;
    background: #9EEA79;
    margin: 24px 32px;
    display: flex;
    padding: 12px 24px;
    align-items: center;
}

.error-upload-container {
    border-radius: 10px;
    border: 1px solid #FF4545;
    background: #F8F0F5;
    margin: 24px 32px;
    display: flex;
    padding: 12px 24px;
    align-items: center;
}

.adv-filter-error-container {
    border-radius: 10px;
    border: 1px solid #FF4545;
    background: #F8F0F5;
    /* margin: 12px 12px; */
    display: flex;
    padding: 12px 16px;
    align-items: center;
    height: 100%;
    width: 100%;
}

.successful-upload-text-container {
    display: flex;
    gap: 24px;
}

.successful-upload-text-details {
    display: flex;
    flex-direction: column;
    text-align: start;
}

.trophy-image {
    height: 138px;
}

.successful-upload-and-button-container {
    display: flex;
    justify-content: space-between;
    margin-left: 40px;
}

.v2-successful-upload {
    width: 30%;
}


@media only screen and (min-width: 320px) and (max-width: 786px) {
    .upload-progress-layout-container, .upload-file-here {
        width: 100% !important;
    }

    .v2-fileupload-button {
        width: 100%;
    }

    .trophy-image {
        height: 78px;
    }

    .successful-text {
        font-size: 20px;
    }

    .successful-sub-text {
        font-size: 10px;
    }

    .failure-sub-text {
        display: none;
    }

    .successful-upload-container {
        margin: 24px 12px;
    }

    .successful-upload-text-container {
        gap: 12px;
    }

    .successful-upload-and-button-container {
        display: flex;
        flex-direction: column;
        margin-left: 16px;
    }

    .v2-successful-upload {
        width: 90%;
        margin-top: 12px;
        margin-left: auto;
    }

    .error-upload-container {
        align-items: flex-start;
        margin: 24px 12px;
    }

    .error-container-sub-text {
        display: none;
    }
}