.tc-pop-up {
    .ant-modal-title {
    margin-bottom: 1em;
    }
    .ant-modal-header {
    padding-left: 0;
    }
    .ant-modal-close-x {
    display: none;
    }
    .ant-modal-body {
    background: #FAFAFA;
    border: 1px solid #F0F0F0;
    font-size: 16px;
    padding: 16px;
    font-family: var(--primary-font-family);
        font-weight: 500;
    }
    .ant-modal{
    width: 29% !important;
    }
    .ant-modal-content {
    padding: 12px 24px;
    }
    div .copy-icon {
    font-size: 22px !important;
    color: var(--primary-theme-color);
    }
    div span.title {
    font-size: 16px;
    vertical-align: -2px;
    margin-left: 8px;
    font-family: var(--primary-font-family);
        font-weight: 700;
    }

    .ant-modal-footer button {
        font-family: var(--primary-font-family);
        font-weight: 500;
    width: 100%;
    margin-top: 1em;
    }
    .ant-modal-footer p {
        font-family: var(--primary-font-family);
        font-weight: 500;
    color: #595959;
    font-size: 15px;
    margin-top: 1em;
    text-align: center;
    }
}
//Mobile to table -> write common css for mobile
@media only screen and (min-width: 320px) and (max-width: 786px) {
    .tc-pop-up {
        .ant-modal{
            width: 100% !important;
        }
    }
}