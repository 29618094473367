.run-rate-component-container{
    padding: 16px 24px 0px;
    border-bottom: 1px solid #00000026;
    background-color: var(--page-bg-color);
    .run-rate-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        flex-wrap: wrap;
        .run-rate-title{
            font-weight: 400;
            margin: 0px 10px;
        }
        .run-rate-header-left{
            display: flex;
            align-items: center;
            gap: 12px;
            .overview-icon{
                color: var(--primary-theme-color);
                zoom: 1.2;
            }
        }
        .run-rate-header-right{
            display: flex;
            align-items: center;
            // margin-left: auto;
            .graph-view-icon{
                zoom: 1.2;
            }
        }
    }
    .run-rate-card-container{
        display: flex;
        justify-content: space-between;
        width: 98.7%;
        margin: 20px 0px;
        .run-rate-card{
            border: 1px solid #f0f0f0;
            padding: 14px 14px 14px 18px;
            width: 19%;
            border-radius: 12px;
            background-color: #FFF;
            .run-rate-card-value{
                font-size: 25px;
                font-weight: 600;
                margin-bottom: 10px;
                height: 22px;
                text-align: start;
            }

            .run-rate-card-title {
                text-align: start;
            }
        }
    }
    .run-rate-graph-container{
        width: 98.6%;
        margin: 20px 0px;
        .graph-card-continer{
            display: flex;
            justify-content: space-between;
            .graph-data{
                width: 68%;
                padding: 30px;
                background: #FFF;
                .graph-view-segmented{
                    display: flex;
                    margin: -10px;
                    width: 150px;
                    margin-bottom: 20px;
                }
            }
            .card-data{
                width: 31%;
                display: flex;
                flex-wrap: wrap;
                .run-rate-card-big{
                    padding: 30px 20px 10px 20px;
                    border: 1px solid #f0f0f0;
                    width: 100%;
                    margin: 0px 1%;
                    margin-bottom: 5px;
                    height: 158px;
                    background-color: #FFF;
                    border-radius: 12px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    text-align: left;

                    .run-rate-card-value{
                        font-size: 24px;
                        font-weight: 600;
                        margin-bottom: 10px;
                    }
                }
                .run-rate-card-small{
                    padding: 30px 20px 10px 20px;
                    border: 1px solid #f0f0f0;
                    width: 48% !important;
                    margin: 5px 1%;
                    height: 158px;
                    background-color: #FFF;
                    border-radius: 12px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    text-align: left;
                    .run-rate-card-value{
                        font-size: 24px;
                        font-weight: 600;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}

// for mobile screen
@media (max-width: 768px){
    .run-rate-card-container{
        flex-direction: column;
        width: 100%;
        .run-rate-card{
            width: 100% !important;
            margin-bottom: 10px;
        }

        .mobile-run-rate-card {
            width: 49% !important;
        }
    }
    .run-rate-graph-container{
        .graph-card-continer{
            flex-direction: column;
            .graph-data{
                width: 100% !important;
                margin-bottom: 10px;
            }
            .card-data{
                width: 100% !important;
                .run-rate-card-big{
                    width: 100% !important;
                }
                .run-rate-card-small{
                    width: 100% !important;
                }
            }
        }
    }
}
