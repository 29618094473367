.desktop-sidebar-main-container {
    position: relative;
    width: 210px;
    height: 100vh;
    overflow: scroll;
    padding-bottom: 40px;
    background: #FFF;
    box-shadow: 0px 0px 4px 0px rgba(41, 91, 209, 0.20), 0px 0px 16px 0px rgba(41, 91, 209, 0.08);
    z-index: 11;
}

.desktop-sibar-individual-section {
    margin: 32px 0px;
}

.color-09121f73 {
    color: #09121f73;
}

.color-5F656D {
    color: #5F656D;
}

.color-295BD1 {
    color: #295BD1;
}

.menu-section-header {
    padding: 0 12px;
    display: flex;
    margin: 12px 0;
}

.branding-section {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    gap: 8px;
}

.single-menu-item {
    display: flex;
    padding: 8px 13px 8px 12px;
    position: relative;
    cursor: pointer;
    gap: 8px;
}

.active-menu-highlight {
    background: #1D5FFF;
    border-radius: 4px;
    margin: 0px 12px;
}

.active-menu-bar {
    position: absolute;
    height: 100%;
    width: 3px;
    background: #295BD1;
    right: 0;
    top: 0;
    border-radius: 6px 0px 0px 6px;
}

@media screen and (max-width: 800px) {
    .desktop-sidebar-main-container {
      display: none;
    }
}