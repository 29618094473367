/*******************************
* MODAL AS LEFT/RIGHT SIDEBAR
* Add "left" or "right" in modal parent div, after class="modal".
* Get free snippets on bootpen.com
*******************************/
.banner-parent {
  width: 96% !important;
  background-color: var(--primary-theme-color);
  background: linear-gradient(90deg, var(--primary-theme-color) 0%, var(--primary-blur-color) 100%);

  border-radius: 10px;
  padding: 10px 0px;

  .home-page-banner-container {
    width: 96% !important;
    background-color: var(--primary-theme-color);
    border-radius: 10px;
    padding: 10px 0px;

    .banner-data {
      color: white;
      font-size: 18px;
      height: 60px;
      font-weight: bold;
      margin-top: 5px;

      .link-styles {
        color: yellow;
        text-decoration: underline;
        margin-left: 8px;
      }
    }
  }
}


.Home {
  background-color: #fff;

  .modal.left .modal-dialog,
  .modal.right .modal-dialog {
    position: fixed;
    margin: auto;
    width: 320px;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
  }

  .carousel-class {
    width: 85vw;
  }

  .modal.left .modal-content,
  .modal.right .modal-content {
    height: 100%;
    overflow-y: auto;
  }

  .modal.left .modal-body,
  .modal.right .modal-body {
    padding: 15px 15px 80px;
  }

  /*Left*/
  .modal.left.fade .modal-dialog {
    left: -320px;
    -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
    -o-transition: opacity 0.3s linear, left 0.3s ease-out;
    transition: opacity 0.3s linear, left 0.3s ease-out;
  }

  .modal.left.fade.in .modal-dialog {
    left: 0;
  }

  /*Right*/
  .modal.right.fade .modal-dialog {
    right: -320px;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: opacity 0.3s linear, right 0.3s ease-out;
  }

  .modal.right.fade.in .modal-dialog {
    right: 0;
  }

  /* ----- MODAL STYLE ----- */
  .modal-content {
    border-radius: 0;
    border: none;
  }

  .modal-header {
    border-bottom-color: #eeeeee;
    background-color: #fafafa;
  }

  /* ----- v CAN BE DELETED v ----- */
  .demo {
    padding-top: 60px;
    padding-bottom: 110px;
  }

  .btn-demo {
    margin: 15px;
    padding: 10px 15px;
    border-radius: 0;
    font-size: 16px;
    background-color: #ffffff;
  }

  .btn-demo:focus {
    outline: 0;
  }

  .demo-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 15px;
    background-color: #212121;
    text-align: center;
  }

  .demo-footer>a {
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    color: #fff;
  }

  .blur-bg {
    filter: blur(3px);
  }

  .input-style-common {
    min-width: 20% !important;
    font-size: 15px;
    background-color: #e0e0e0;
    padding: 1rem 2rem 1rem 1rem !important;
    border: 2px solid #e0e0e0;
    z-index: 1;
    border-radius: 10px;
  }

  .header-welcome {
    width: 50%;
    display: flex;
    flex-direction: row;
    margin-left: 7px;
  }

  .icon-right {
    line-height: inherit;
  }

  .header-search {
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 2px;
    align-items: center;
    margin-left: auto;
    height: 32px;
    display: flex;
    flex-direction: row;
  }

  .header {
    padding: 15px 0 !important;
    justify-content: space-between;
    align-items: center;

    .pageheader {
      display: none;
    }
  }

  .search-icon-home {
    padding: 0px 5px;
    margin-left: -28px;
    text-align: center;
    align-items: center;
    border-left: 1px solid rgb(133, 133, 133);
    color: rgb(133, 133, 133);
    background: white;
    height: 23px;
  }

  .search-input,
  .search-input-box {
    width: 100%;
    height: 28px;
    font-size: 17px;
    color: rgba(2, 2, 2, 0.87);
  }

  .search-input::placeholder {
    color: rgb(133, 133, 133);
  }

  .filters-home {
    display: flex;
    flex-direction: row;
  }

  .date-filter {
    /* padding: ; */
    border: none;
    background-color: white;
  }

  .Dropdown-control {
    outline: white;
  }

  .filter-container,
  .filter-lead-management {
    background-color: var(--page-bg-color);
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    text-align: left;
  }

  .bulk-action-heading {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    width: 90%;
    text-align: left;
  }

  .see-all-button {
    width: 90%;
    background-color: rgb(211, 70, 18);
    color: white;
    margin: 0px 5%;
    padding: 6px;
    /* bottom: 23px; */
    /* position: fixed; */
  }

  .list-scroll-x {
    overflow-x: scroll;
  }

  .add-multiple-candidate-button-disabled {
    width: 95%;
    padding: 13px 20px;
    display: flex;
    flex-direction: row;
    margin: 3px auto;
    margin-top: 5px;
    color: #918e8e;
    border: 1px solid #918e8e;
    border-radius: 5px;
  }

  .btn-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px 0 20px;
    background-color: #fff;

    .btn-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      width: 50%;
      margin-top: 20px;
      margin-right: 10px;
      color: var(--primary-theme-color);
      border-color: var(--primary-theme-color);
      height: 60px;
      font-size: 14px;
      border-radius: 7px;
      font-family: var(--primary-font-family);
      font-weight: 700;

      svg {
        font-size: 26px;
        margin-right: 8px;
      }

      .CTA-context {
        display: flex;
        align-items: center;
      }

      &:hover {
        background-color: #fff7f4;
      }
    }
  }
  .quick-links-card-container {
    margin: 12px;
    width: 22%;
    border-radius: 8px;
    min-height: 280px;
    border: 1px solid #00000026;
  }
  .home-page-bg-color {
    background: var(--page-bg-color);
  }
  .quick-access-item-container {
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 10px 7%;
    border-top: 1px solid #0000000F;
  }
  .quick-link-single-container {
    width: 100%;
  }
  .performace-dash-numbers {
    margin: 12px;
    width: 33%;
  }
  .quick-access-heading {
    margin-bottom: 12px;
    color:var(--primary-theme-color);
    text-align: left;
  }
  .quick-link-text {
    padding: 0 10%;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }

  .quick-access-item {
    width: 85px;
    margin: 20px auto;
  }

  .quick-link-icon-container {
    justify-content: center;
    width: 52px;
    display : flex;
    align-items : center;
    height: 52px;
    margin: auto;
    background: var(--primary-theme-light);
    border-radius: 10px;
    margin-bottom: 5px;
    margin-top: 15px;
  }
  .gradient-container {
    height: 104px;
    overflow: hidden;
    border-radius: 10px;
    border: 1px solid #00000026;
    border: none;
  }
  .animated-gradient {
    width: 100%;
    height: 100%;
    background: linear-gradient(-75deg, #ffffff, #F49C7B, #F49C7B, #D34612, #D34612, #D34612);
    background-size: 150% 100%;
    animation: gradient-animation 2s ease infinite;
  }
  .feedback-modal {
    margin: 0 12px 12px !important;
    width: 46.5% !important;
    border-radius: 8px !important;
    min-height: 140px;
    border: 1px solid #00000026 !important;
  }

  @keyframes gradient-animation {
    0% {
      background-position: 100% 0%;
    }
    50% {
      background-position: 10% 10%;
    }
    100% {
      background-position: 10% 20%;
    }
  }

  .quick-hot-leads-tab {
    display: flex;
    align-items: center;
    margin: auto;
    background: #FFEAE0;
    border-radius: 10px;
    padding: 16px;
    width: 100%;
    height: 94px;
    border: 1px solid #00000026;
  }
  .quick-hot-leads-tab .quick-hot-lead-left {
    display: flex;
    flex: 1;
  }
  .quick-hot-leads-tab .quick-hot-lead-left img {
    margin: 2px 5px;
    height: 20px;
  }
  .quick-hot-leads-tab-dark {
    display: block;
    background: none;
    height: 104px;
  }
  .quick-hot-leads-tab .quick-hot-lead-left .quick-hot-leads-heading {
    color: #D34612;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
  }
  .quick-hot-leads-tab-dark .quick-hot-leads-heading {
    color: #FFFFFFD9 !important;
  }
  .quick-hot-leads-tab .quick-hot-leads-subheading {
    color: #FFFFFFD9;
    font-size: 12px;
    font-weight: 400;
  }
  .quick-hot-leads-tab .quick-hot-lead-right {
    display: flex;
  }
  .quick-hot-lead-subHeading {
    margin-left: 5px;
    margin-top: 5px;
    text-align: left;
    width: 70%;
  }
  .quick-link-icon-container.active-badge {
    padding-top: 0px !important;
  }

  .tabClass {
    .ant-tabs-nav-list {
      justify-content: space-around;
      width: 100%;

      .ant-tabs-tab-active {
        font-family: var(--primary-font-family);
        font-weight: 700;
      }
    }

    .display-session {
      font-family: var(--primary-font-family);
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      padding: 20px;

      .date-download {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .download-btn {
          display: flex;
          background-color: #fff7f4;
          align-items: center;
          color: var(--primary-theme-color);
          font-family: var(--primary-font-family);
          font-weight: 500;

          &:hover {
            background-color: #ffffff;
          }
        }
      }

      .select-cardview {
        display: flex;
        flex-direction: column;

        >.ant-space-item {
          width: 100%;
        }

        .list-of-candidates {
          height: 70vh;
          overflow: auto;
        }

        .selectall-btns {
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
          width: 100%;

          .total-leads {
            font-weight: 700;
            font-size: 12px;
            line-height: 20px;
            font-family: var(--primary-font-family);
      font-weight: 700;
            display: flex;
            align-items: center;
            text-align: right;
            color: #595959;
            border: none;
            border-radius: 20px;
            background-color: #f0f0f0;
            padding: 0 8px;

            span {
              color: #000;
              margin-left: 3px;
            }
          }
        }

        .cardview-wrapper {
          width: 100%;
          margin-left: 1px;
          margin-right: 1px;

          .cardview-space {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            .name-number {
              display: flex;
              flex-direction: column;
              align-items: flex-start;

              .name {
                font-family: var(--primary-font-family);
      font-weight: 500;
                text-align: left;
              }

              .number {
                svg {
                  margin-bottom: 6px;
                  font-size: 12px;
                }
              }
            }

            .city {
              font-family: var(--primary-font-family);
      font-weight: 700;
            }
          }

          .text-status {
            font-family: var(--primary-font-family);
            font-weight: 500;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
          }
        }
      }
    }
  }

  .add-candidate {
    display: flex;
    color: #ff6b00;
    align-items: center;

    span {
      padding-left: 10px;
    }
  }

  .header {
    .header-text {
      display: none;
    }

    .mitra-badge {
      display: none;
    }
    .search-button {
      padding: 5px 15px;
      color: var(--primary-theme-color);
    }
  }

  .card-recruiter-dashboard {
    display: flex;
    margin: 10px;

    .ant-card-body {
      padding: 15px;
      width: 100%;
    }

    .card-text-view {
      display: flex;
      align-items: baseline;
      justify-content: flex-start;

      .copy-innertext {
        margin: 0 8px 8px;
        display: flex;
        flex-direction: column;
        align-items: baseline;
        justify-content: flex-start;
      }
    }

    .message-container-update {
      .warning-message-atrisk {
        padding: 8px;
        justify-content: space-between;

        .lead-total {
          display: flex;
          align-items: center;

          svg {
            margin-left: 3px;
          }
        }
      }
    }
  }

  .bank-detils-wrapper {
    display: flex;
    margin: 16px;
    flex-direction: row;
    align-items: center;
    padding: 9px 16px;
    height: 42px;
    background: #F0F5FF;
    justify-content: space-between;

    .section-1 {
      display: flex;
      align-items: center;
      gap: 5px;
      font-family: var(--primary-font-family);
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #262626;
    }

    .section-2 {
      button {
        box-sizing: border-box;
        border: 1px solid #2F54EB;
        border-radius: 2px;
        color: #2F54EB;
        font-family: var(--primary-font-family);
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
  }

  /* responsive code */

  // @media (max-width: 550px) {
  //   .header-welcome {
  //     display: flex;
  //     flex-direction: column;
  //   }

  //   .mitra-name {
  //     margin-left: 0px;
  //   }

  //   .search-input {
  //     display: none;
  //   }

  //   .search-icon-home {
  //     border-left: solid white 0px;
  //   }

  //   .filters-home {
  //     display: none;
  //   }
  // }

  // @media (max-width: 700px) {
  //   .filter-container {
  //     display: none;
  //   }
  // }

  @media (min-width: 320px) and (max-width: 768px) {
    .banner-data {
      font-size: 12px !important;
    }
    //Mobile view
    .btn-wrapper {
      flex-direction: column;
      padding: 0;
      justify-content: center;

      .btn-container {
        width: 94%;
        margin: 0;
        margin-top: 12px;
      }
    }

    .header {
      padding: 20px !important;

      .mitra-badge {
        display: block;
      }
      .search-button {
        padding: 5px;
      }

      .header-text {
        display: flex;
        flex-direction: column;

        .welcome {
          font-family: var(--primary-font-family);
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #595959;
        }

        .mitra-name {
          font-family: var(--primary-font-family);
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: var(--primary-theme-color);
        }
      }
    }

    .carousel-class {
      width: 100%;
    }

    .tabClass {
      height: calc(100vh + 50px);
    }

    .home-page-bg-color {
      background: #FFFFFF;
    }
    .desktop-view-only {
      display: none;
    }
    .quick-links-card-container {
      width: 90% !important;
    }
    .performace-dash-numbers {
      width: 90%;
    }
    .feedback-modal {
      width: 90% !important;
      min-height: 160px;
    }
    .feedback-modal-class {
      margin-bottom: 30% !important;
    }

    .quick-access-heading {
      text-align: left;
      font-weight: bold;
      font-size: 14px;
    }

    .quick-link-icon-container {
      padding: 16px;
      width: 64px;
      height: 64px;
      background: var(--primary-theme-light);
      border-radius: 10px;
    }
  }

  .ant-page-header-back {
    display: flex !important;
  }
}

.filter-home-wrapper {
  padding: 20px;
  padding-top: 0;

  .tag-containers {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 20px;

    .tag-wrapper-container {
      display: flex;
      flex-wrap: wrap;

      .tags {
        height: 22px;
        box-sizing: border-box;
        border-radius: 2px;
        cursor: pointer;
        margin: 5px;
        margin-left: 0;

        svg {
          color: var(--primary-theme-color);
          margin-bottom: 4px;
        }
      }

      .tag-active {
        color: var(--primary-theme-color);
        background: var(--primary-theme-light);
        border: 1px solid var(--primary-theme-color);
      }
    }
  }
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description {
  font-weight: 1000;
}

.jdc-menu-item {
  .ant-dropdown-menu-title-content {
    display: flex;
    align-items: center;
  }

  .jdc-unpin-option {
    .jdc-unpin-option-icon-parent {
      position: relative;

      .jdc-unpin-option-cancel-icon {
        position: absolute;
        left: 4px;
        top: 10%;
        transform: rotate(-45deg);
        font-size: 22px;
        color: gray
      }
    }
  }

  .jdc-menu-item-icon {
    margin-right: 8px;
    font-size: 14px;
  }
}
@media (min-width: 320px) and (max-width: 378px) {
  .Home .quick-links-card-container {
      min-height: 300px !important;
  }
}

@media screen and (max-width: 800px) {
  .banner-parent > div > div {
    font-size: 14px !important;
    margin-top: 0px !important;
    height: auto !important;
    text-align: start !important;
    padding: 2px 8px 12px !important;
  }

  .carousel-class {
    .slick-dots-bottom {
      bottom: -6px !important;
    }
  }
}
