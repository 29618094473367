.hot-leads-modal-style {
.ant-modal-header {
    background: linear-gradient(to right, var(--primary-theme-color), var(--primary-theme-light)) !important;
}
.ant-modal-content {
    margin-left: 5% !important;
    width: 470px !important;
}
.ant-modal-title {
    margin: 0 20px !important;
    font-size: 21px !important;
    line-height: 28px !important;
    color: white !important;
}
.ant-modal-close {
    display: none !important;
}
.ant-modal-body {
    // padding: 0 !important;
}
.ant-modal-title {
    img {
        width: 75px;
        height: auto;
        margin-left: 5%;
        margin-top: -7%;
    }
}

.ant-modal-footer {
    border-top: none !important;
    .parent-footer {
        margin-bottom: 3%;
        margin-top: -3%;
        .remind-me-later {
            border: none !important;
            box-shadow: none !important;
            width: 50%;
            text-align: center;
            align-items: center;
        }
        .try-it-now {
            width: 50%;
            font-weight: 600;
            padding:0 70px;
        }
    }
}

.header-hot-leads-modal .main-container-images {
    width: 30%;
    margin: 7% 2%;
    height: auto;
}
.header-hot-leads-modal Text {
    font-size: 16px;
}
.header-hot-leads-modal .priority-image {
    width: 95%;
    margin: 2.5%;
}
}
@media screen and (min-width: 320px) and (max-width: 786px) {
    .header-hot-leads-modal .d-flex {
        margin-bottom: 2%;
    }
    .hot-leads-modal-style {
        .ant-modal-content {
            margin-left: 5% !important;
            width: 90% !important;
        }
        .ant-modal-title {
            margin: 0 1px !important;
            font-size: 18px !important;
            line-height: 22px !important;
            color: white !important;
        }
        .ant-modal-title {
            img {
                width: 65px;
            }
        }
        .ant-modal-body {
            padding: 24px 12px !important;
        }
        .header-hot-leads-modal .main-container-images {
            margin: 14% 0;
        }

    }
}

@media screen and (min-width: 500px) and (max-width: 786px) {
    .hot-leads-modal-style {
        .ant-modal-title {
            img {
                width: 65px;
                margin-left: 17%;
                margin-top: -5%;
            }
        }
        .ant-modal-body {
            padding: 24px 20px !important;
        }
        .header-hot-leads-modal .main-container-images {
            margin: 8% 2%;
            width: 25%;
        }
    }
}