.file-upload-steps-container {
    margin: 24px 32px 0px;
    display: flex;
}

.file-upload-steps-container-mobile {
    display: none;
}

.file-upload-steps-container div:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.left-curved-border {
    border-radius: 8px 0px 0px 8px;
}

.single-step {
    height: 37px;
    background: #EBEBEB;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    flex: 1;
    cursor: pointer;
}

.active-step {
    background: #1D5FFF;
    gap: 18px;
}

.step-count {
    color: #525252;
    text-align: center;
    font-family: Inter;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;

    border-radius: 100%;
    border: 0.5px solid #525252;

    width: 15px;
    height: 15px;
    background: #FFF;

    display: flex;
    align-items: center;
    justify-content: center;
}

.active-step-count {
    border: 0.5px solid #82A0E9;
    color: #1D5FFF;
}

.step-header {
    color: #686868;;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.active-step-header {
    color: #FFF;
}

.step-info-header {
    color: #252526;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    text-align: start;
    margin: 24px 32px 0px;
}

.step-info-sub-header {
    color: #838383;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin: 12px 32px 0px;
    text-align: start;
}

.upload-container {
    margin: 24px 32px 0px;
    border-radius: 10px;
    border: 1px solid #AFCFFF;
    position: relative;
    z-index: 1;
    margin-bottom: 40px;
}

.upload-file-main-section {
    background: #FFF;
    height: 100%;
    padding: 52px 124px 80px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.download-sample-file {
    display: flex;
    align-self: flex-end;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.upload-file-here {
    border: 1px dashed #A19D9D;
    width: 100%;
    height: 284px;
    background: #F6F6F6;
    border-radius: 10px;
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
}

.upload-file-here > .ant-upload-picture-card-wrapper > .ant-upload-select-picture-card {
    height: 100% !important;
    border: none;
    background: transparent;
}

.upload-file-here > .ant-upload-picture-card-wrapper {
    height: 100% !important;
}

.excel-upload-section {
    width: 236px;
}

.large-tile-shade {
    left: -1;
    width: 100.5%;
}

@media only screen and (min-width: 320px) and (max-width: 786px) {
    .file-upload-steps-container {
        margin: 24px 24px 0px;
    }

    .session-creation-header {
        margin: 36px 24px 0px !important;
        width: 98px;
        height: 28px;

    }

    .step-info-header {
        margin: 24px 24px 0px;
        font-size: 20px;
    }

    .upload-container {
        margin: 24px 24px 0px;
    }

    .mob-column-reverse {
        padding: 24px 16px !important;
        flex-direction: column-reverse !important;
        gap: 24px;
    }

    .require-candidate-input-section, .required-candidate-image-section {
        flex: 1 !important
    }

    .require-candidate-input-section {
        width: 100%;
    }

    .required-candidate-submit-btn {
        width: 100%;
    }

    .required-candidate-image {
        height: 114px !important;
        width: 71vw !important;
        object-fit: cover;
        border-radius: 10px;
    }

    .mob-font18 {
        font-size: 18px;
    }

    .file-upload-steps-container {
        margin: 24px 32px 0px;
        display: none;
    }
    
    .file-upload-steps-container-mobile {
        display: flex;
        flex-direction: row;
    }

    .steps-bar-indicator {
        height: 18px;
        background: #EBEBEB;
        position: relative;
    }

    .div-fill-container {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: #1D5FFF;
    }

    .steps-bar-indicator-left-radius {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    .steps-bar-indicator-right-radius {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    .active-step-header {
        color: #1D5FFF;
        font-size: 12px;
    }

    .step-header {
        font-size: 12px;
        text-align: start !important;
    }

    .steps-text-indicator {
        display: flex;
        gap: 4px;
        margin-left: 2px;
    }
}

@media only screen and (min-width: 320px) and (max-width: 786px) {
    .upload-file-main-section {
        background: #FFF;
        height: 100%;
        padding: 24px 12px 24px !important;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

