.ant-alert {
  position: fixed !important;
  width: 100% !important;
}

.range-picker-leads .ant-picker-separator {
  display: flex !important;
}

.reassign-leads-dialog {
  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-close-x {
    display: none;
  }

  .ant-modal-content {
    height: 100%;

    .ant-modal-body {
      height: 100%;
    }
  }
}

.cpp-footer-parent {
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;

  .ant-divider-horizontal {
    margin: 0px !important;
  }

  .cpp-footer {
    padding: 12px 16px;

    .cpp-footer-tc-txt {
      margin-left: 4px;
    }

    .cpp-edit {
      border-radius: 2px;
      border: 1px solid var(--primary-theme-light, var(--primary-theme-color));
      background: var(--Neutral-1, #FFF);
      box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
      padding: 2px 4px;
      margin-left: 12px;

      .cpp-edit-icon {
        font-size: 16px;
      }
    }
  }
}

.whtsappApp-button {
  background: #53c31b;
  color: #ffff;
  border-radius: 5px;
}

.show-only-mobile-search {
  display: none !important;
  width: 40%;
  align-items: flex-end;
  margin: 2%;
  justify-content: right;
}

.hot-leads-select .ant-select-item-option-content {
  display: flex;
  align-items: center;
}

.hot-leads-select .ant-select-selection-item {
  display: flex;
  align-items: center;
}

//Add your css here in LeadPage class
//desktop view starts
.SearchPage {
  .card-view-candidate {
    padding: 5px;
    margin-top: 16px;
    text-align: left;
    border-radius: 12px;

    .ant-card-body {
      padding: 22px 60px;
    }
  }

  .contact-buttons {
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      margin: 0 10px 0 0;
    }
  }

}

.LeadPage {
  .cta {
    position: fixed;
    font-size: larger;
    //bold
    font-weight: 800;
    bottom: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: #fff;
    background-color: var(--primary-theme-color);
    z-index: 1;
    transition: all 1s;
  }

  .hideCta {
    opacity: 0;
    left: -100%;
  }

  .showCta {
    opacity: 1;
    right: 10;
  }

  overflow: auto;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, .5);
  }

  .leads-wrapper {
    padding: 1px 20px;
    overflow-y: scroll;
    max-height: 90vh;
  }

  .only-desktop-visibility {
    display: block;
  }

  .allow-download {
    display: flex;
    align-items: center;
  }

  .block-download {
    display: flex;
    align-items: center;
  }

  .desktop-pagination {
    margin: auto 10px auto auto;
  }

  .desktop-pagination {
    display: block;
  }

  .mobile-pagination {
    display: none;
  }

  .card-view-candidate {
    padding: 5px;
    margin-top: 16px;
    text-align: left;
    border-radius: 12px;

    .ant-card-body {
      padding: 22px 60px;
    }
  }
  .card-view-candidate-without-referral {
    padding: 5px;
    margin-top: 16px;
    text-align: left;
    border-radius: 12px;

    .ant-card-body {
      padding: 8px 60px;
    }
  }

  .flex-desktop-row-mobile-column {
    display: flex;
    flex-direction: row;
    width: -webkit-fill-available;
  }

  .view-list-updated-expiry {
    text-align: centre;
    margin: auto 0px auto auto;
  }

  .referredAgain {
    display: flex;
    align-items: center;
    font-size: 12px;

    svg {
      margin-right: 5px;
    }
  }

  .ant-card-actions {
    background-color: inherit;
  }

  .candidates-tab-container {
    background-color: white;

    .ant-tabs-nav-list {
      justify-content: space-around;
      width: 100%;

      .ant-tabs-tab-active {
        font-family: var(--primary-font-family);
        font-weight: 700;
      }
    }
  }

  .contact-buttons {
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      margin: 0 10px 0 0;
    }
  }
}

.Recruiter {
  .view-details-arrow {
    color: #fce3e6 !important;
    border: 2px solid #fce3e6 !important;
    width: 60px !important;
    height: 60px !important;
  }

  .call-tag-recruiter-card-custom {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .contact-buttons {
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      margin: 0 10px 0 0;
    }
  }

  .download-plus {
    background-color: #ffffff;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    padding: 10px 0 0 15px;
    overflow: scroll;

    .leads-chips {
      display: flex;
      cursor: pointer;

      .single-chip {
        border-radius: 20px;
        padding: 4px;
        background-color: #ffffff;
      }
    }
  }

  .banner-copy-tag {
    width: 100%;
    display: flex;
    background-color: #ffffff;
    font-family: var(--primary-font-family);
        font-weight: 500;

    .ant-tag {
      margin: 8px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 8px;
    }
  }
}

.details-component-container {
  .call-now-button {
    margin-right: 18px;
    padding: 5px 24px;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 2px;
  }

  .message-button {
    padding: 5px 24px;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 2px;
    color: var(--primary-theme-color);
  }
}

.modal-candidate-profile {
  .contact-buttons {
    button {
      margin: 2px;
    }
  }

  .ant-modal-header {
    font-family: var(--primary-font-family);
        font-weight: 700;
  }
}

// .detail-body-container {

// }

// responsive for mobile view starts
@media screen and (min-width: 320px) and (max-width: 786px) {
  .show-only-mobile-footer {
    display: flex !important;
  }

  .show-only-mobile-search {
    display: flex !important;
  }

  .LeadPage {
    .cta {
      position: fixed;
      font-size: larger;
      //bold
      font-weight: 800;
      bottom: 90px;
      right: 20px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      color: #fff;
      background-color: var(--primary-theme-color);
      z-index: 1;
      transition: all 1s;
    }

    .hideCta {
      opacity: 0;
      left: -100%;
    }

    .showCta {
      opacity: 1;
      right: 10;
    }

    ::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: rgba(0, 0, 0, .5);
    }

    .leads-wrapper {
      padding: 20px;
      padding-bottom: 25%;
    }

    .header {
      display: flex;
      align-items: center;
    }

    .desktop-pagination {
      display: none;
    }

    .mobile-pagination {
      display: block;
    }

    .flex-desktop-row-mobile-column {
      display: flex;
      flex-direction: column;
    }

    .view-list-updated-expiry {
      text-align: centre;
      margin: auto auto auto 0px;
    }

    .card-view-candidate {
      .ant-card-body {
        padding: 10px;
      }
    }

    .card-view-candidate-without-referral {
      .ant-card-body {
        padding: 8px 10px;
      }
    }
  

    .button-whatsapp {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      padding: 0 auto;
    }

    .contact-buttons {
      button a {
        padding: 0 15%;
        width: 50%;
        align-items: center;
      }
    }

    .added-mitra-name {
      display: flex !important;
      margin: 20px 0 -10px 0;
    }

    .card-view-candidate {
      .ant-card-body {
        padding: 10px;
      }
    }

    div.milestones-mobile {
      display: block !important;
    }

    div.milestones-mobile div {
      margin-top: 8px;
    }

    div.click-to-top div.hover-priority div {
      top: 60px;
      left: 120px;
    }

    div.hover-milestones {
      top: 70%;
      left: 5%;
    }
  }

  .LeadPage {
    .card-view-candidate {
      .ant-card-body {
        padding: 10px;
      }
    }

    .added-mitra-name {
      display: flex !important;
      margin: 20px 0 -10px 0;
    }
  }

  .Recruiter {
    .ant-page-header-ghost {
      padding: 0px 16px;
    }

    .button-whatsapp {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      padding: 0 auto;
    }

    .contact-buttons {
      button a {
        padding: 0 15%;
        width: 50%;
        align-items: center;
      }
    }

    .added-mitra-name {
      display: flex !important;
      margin: 20px 0 -10px 0;
    }

    .card-view-candidate {
      .ant-card-body {
        padding: 10px;
      }
    }

    .banner-copy-tag {
      flex-direction: column;
      justify-content: space-between;
    }

    .view-details-arrow {
      color: #fce3e6 !important;
      border: 2px solid #fce3e6 !important;
      width: 48px !important;
      height: 48px !important;
    }
  }

  .SearchPage {
    .ant-page-header-ghost {
      padding: 0;
    }

    .button-whatsapp {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      padding: 0 auto;
    }

    .contact-buttons {
      button a {
        padding: 0 15%;
        width: 50%;
        align-items: center;
      }
    }

    .added-mitra-name {
      display: flex !important;
      margin: 20px 0 -10px 0;
    }

    .card-view-candidate {
      .ant-card-body {
        padding: 10px;
      }
    }

    .banner-copy-tag {
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .modal-candidate-profile {
    .contact-buttons {
      button {
        margin: 2px;
      }

      .ant-typography {
        display: none;
      }
    }
  }

  #single-container-hot-lead {
    flex-direction: column !important;
  }

  .hot-lead-main-container-left-side {
    width: 100% !important;
  }

  .hot-lead-main-container-right-side {
    width: 100% !important;
  }

  .hot-tip-sub-heading {
    margin-left: 0 !important;
  }

  .main-container-divider-right {
    border-left: 0 !important;
  }

  .hot-tip-main-container {
    margin-left: 0 !important;
  }

  .call-notes-tag {
    margin-left: 0 !important;
  }

  .main-container-divider-right .ant-divider-horizontal {
    margin: 16px 0 !important;
    display: flex !important;
  }

  .hover-document-status {
    top: 270px !important;
    right: 12px !important;
  }
}

// add styles in the above classes only

// .detail-body-container {
//   margin: 0 auto;
//   font-size: 14px;
//   background-color: #ffffff;
// }

.detail-element-body {
  z-index: 1;
}

.upload-Icon-mobile {
  display: none;
}

.chipClass {
  text-align: left;
  display: inline-block;
  padding: 0 25px;
  height: 30px;
  width: 202px;
  font-size: 12px;
  line-height: 30px;
  border-radius: 25px;
  background-color: #f1f1f1;
}

.upload-Icon {
  display: block;
}

.detail-body {
  width: 100%;
}

.detail-header {
  text-align: left;
}

.viewdetailbutton {
  margin-top: 10px;
}

.refer-again-button {
  background: var(--primary-theme-color) !important;
  color: #ffffff;
  border: 1px solid var(--primary-theme-color);
  box-sizing: border-box;
  box-shadow: 0px 2px 0px rgb(0 0 0 / 2%);
  border-radius: 4px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 1%;
  margin-left: 3%;
  margin-top: auto;
  margin-bottom: 15px;
  cursor: pointer;
  position: absolute;
  right: 12px;
}

.complete-application-button {
  background: #fff7f4;
  border: 1px solid var(--primary-theme-color);
  box-sizing: border-box;
  box-shadow: 0px 2px 0px rgb(0 0 0 / 2%);
  border-radius: 4px;
  width: -moz-fit-content;
  width: max-content;
  padding: 3%;
  margin-left: auto;
  cursor: pointer;
}

.name {
  font-size: 17px;
  color: rgba(2, 2, 2, 0.87);
  margin-right: 5px;
}

.Dropdown-control {
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  width: 200px;
  justify-content: space-between;
  padding: 10px;
  margin-left: 1%;
}

.Dropdown-option {
  text-transform: capitalize;
  border-bottom: 1px solid #b4b4b4;
  width: 150px !important;
  background-color: #ffffff;
}

.more-filters {
  background-color: var(--page-bg-color);
  display: flex;
  flex-direction: column;
  padding: 0 2%;
  text-align: left;
}

.button {
  background-color: white;
  border-color: #f4f5f6;
  padding-left: 1rem;
  display: inline-block;
  margin: 0.5rem;
  width: 150px;
  height: 47px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 13.5px;
  font-weight: 500;
  text-align: left;
  text-transform: uppercase;
  border: 1px solid #dbdbdb;
}

.month-filter {
  background: #ffffff;
}

.init-message,
.no-leads {
  padding: 2rem;
  font-size: 0.9rem;
}

.no-leads {
  color: #b00020;
}

.application-timeline-line-img {
  color: rgba(0, 0, 0, 0.15) !important
}

.application-timeline-view {
  margin-top: 1vh;

  .left-of-separator {
    align-items: flex-start;
    justify-content: flex-end;
  }

  .left-of-separator-mobile {
    align-items: flex-start;
    justify-content: flex-end;
    width: 55%;
  }

  .completed-step-icon {
    color: #4BCF3F;
    font-size: 14px;
    margin: 0px 4px;
  }

  .completed-step-icon-mobile {
    color: #4BCF3F;
    font-size: 14px;
  }

  .recently-completed-milestone-txt {
    color: #4BCF3F;
    font-size: 14px;
    font-weight: 700;
  }

  .recently-completed-milestone-txt-mobile {
    color: #4BCF3F;
    font-size: 12px;
    font-weight: 700;
    // margin-left: 1vw;
  }

  .recently-completed-milestone-mobile {
    margin-left: 1vw;
  }

  .next-milestone-txt {
    color: #D48806;
    font-size: 14px;
    font-weight: 700;
  }

  .next-milestone-txt-mobile {
    color: #D48806;
    font-size: 12px;
    font-weight: 700;
  }

  .next-txt {
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
  }

  .next-milestone-mobile {
    margin-left: 1vw;
  }

  .orange-circle {
    height: 14px;
    width: 14px;
    background-color: var(--primary-theme-color);
    border-radius: 7px;
    margin: 0px 4px;
  }

  .orange-circle-mobile {
    height: 14px;
    width: 14px;
    background-color: var(--primary-theme-color);
    border-radius: 7px;
    margin: 4px 0px;
  }

  .application-timeline-view-separator {
    background-color: rgba(0, 0, 0, 0.15);
    width: 1px;
    height: 8vh;
    margin-left: 0.5vw;
  }

  .application-timeline-view-separator-mobile {
    background-color: rgba(0, 0, 0, 0.15);
    width: 1px;
    height: 100%;
    margin-left: 0.5vw;
  }

  .steps-completed-mobile {
    width: 40%
  }

  .steps-completed-txt {
    font-size: 14px;
  }

  .trips-completed-txt {
    font-size: 12px;
    color: #00000073;
  }

  .trips-count-completed-txt {
    font-size: 24px;
    color: #000000A6;
  }

  .steps-completed-txt-mobile {
    font-size: 12px;
  }

  .timeline-steps-completed-separator-mobile {
    margin: 0 1vw;
    width: 5%;
  }

  .eye-hover-icon {
    color: #00000073;
    font-size: 14px
  }

  .hover-milestone-new-parent {
    display: flex;
    align-items: center;
    border: 1px solid rgba(217, 217, 217, 1);
    border-radius: 4px;
    box-shadow: 1px 3px 2px -1px rgba(0, 0, 0, 0.2);
    padding: 4px;
    margin-left: 8px
  }

  div.hover-milestones-new {
    display: none;
    position: absolute;
    top: 8vh;
    left: 400px;
    width: 16vw;
    z-index: 1;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
    padding: 12px 16px;
    right: 0;
    background: #fff;
    color: #00000073;
  }

  div.hover-milestones-new-mobile {
    display: none;
    position: absolute;
    top: 8vh;
    // left: 400px;
    width: 40vw;
    z-index: 1;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
    padding: 12px 16px;
    right: 0;
    background: #fff;
    color: #00000073;
  }

  div.click-for-hover-milestone:hover div.hover-milestones-new {
    display: block;
  }

  div.click-for-hover-milestone:hover div.hover-milestones-new-mobile {
    display: block;
  }
}

.lead-added {
  text-align: right;
  background-color: #e7e7e7;
  padding: 0.5rem 1rem;
}

.grouping-date {
  text-align: left;
  background-color: #f6f7f8;
  padding: 0.5rem 1rem;
}

.mobile-number {
  font-weight: bold;
  color: rgba(2, 2, 2, 0.87);
  margin-left: 5px;
}

.leads-detail {
  display: flex;
  margin-top: 10px;
}

.category-body {
  flex: 1;
  text-align: left;
}

.category {
  background-color: #ebebeb !important;
}

.category,
.client,
.role {
  font-weight: bold;
  color: #555555;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
  margin: 5px;
  padding: 2px 8px;
  border-radius: 4px;
  background-color: #b9cbf2;
}

.client-status {
  float: right;
  text-align: center;
}

.icon-body {
  flex: 1;
  text-align: right;
}

.icon {
  margin-left: 20px;
}

.lead-status {
  text-align: left;
  margin-left: 35px;
}

.detail-panel {
  width: 100%;
}

.doc-uploaded {
  display: flex;
}

.doc-pending {
  display: flex;
}

.doc-text {
  height: 24px;
  width: 172px;
  text-align: left;
  margin-left: 20px;
}

.pending-right {
  flex: 1;
  text-align: right;
}

.upload-button {
  height: 32px;
  width: 84px;
  border-radius: 4px;
  background-color: #eebb25;
  border: none;
}

hr {
  border: 0;
  border-top: 1px solid #eee;
  margin-left: 43px;
}

.hr-no-margin{
  margin-left: 0px !important;
}

.bottom-detail-wrapper {
  position: relative;
}

.navigation-container {
  line-height: 22px;
  font-size: 14px;
}

@media screen and (min-width: 768px) {
  // .navigation-container {
  //   height: 100vh;
  // }
}

.leads-error {
  margin-top: 2rem;
  font-weight: 500;
  font-size: 15px;
}

.loader_clip {
  margin-top: 10rem;
}

button.css-1r4vtzz,
button.css-48ayfv {
  background-color: #f4f5f6 !important;
  border-color: #f4f5f6 !important;
  border-radius: 5px !important;
  padding-left: 1rem;
  margin: 0.5rem;
  width: 150px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 13.5px;
  font-weight: 500;
  text-align: left;
  text-transform: uppercase;
}

.more-filters div .css-11unzgr {
  text-align: left;
}

.multiCatSelectCls,
.multiSelectCls,
.multiSelectCls div:first-of-type {
  display: inline;
}

.multiSelectCls div:first-of-type div.css-1jllj6i-control,
.css-6y1x9t-control,
.css-1wy0on6 {
  display: flex !important;
}

.btnWidth {
  margin-top: 8px;
}

.dot {
  height: 60%;
  width: 30%;
  background-color: #bbb;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  text-align: center;
  display: flex;
  font-size: 25px;
}

.imgResponse {
  /* max-width: 8vw; */
  width: 96px;
}

.contact-lead {
  width: 100%;
}

.contact-icon-container {
  border: 1px solid #d9d9d9;
  border-radius: 1px;
  background: var(--page-bg-color);
  display: flex;
  flex-direction: row;
  height: 62px;
  padding: 20px 10px;
}

.contact-detail {
  margin: 0px 0px 0px 15px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: var(--primary-theme-color);
}

.MuiExpansionPanelSummary-content {
  margin: 12px 0;
  display: flex;
  flex-grow: 1;
  transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.tabs-container {
  height: 50px;
  width: 100%;
  background-color: white;
  text-align: left;
  padding: 0px 20px;
}

.tab {
  letter-spacing: 0.5px;
  width: 50%;
  display: block;
  text-align: center;
  float: left;
  padding: 10px;
  border-bottom: 2px solid #f0f0f0;
  height: 40px;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
}

.new-iocn {
  background-color: rgb(245, 34, 45);
  color: white;
  border-radius: 100px;
  font-size: 7px;
  padding: 2px;
  vertical-align: middle;
  font-weight: bold;
  margin-top: 10px;
  margin-left: 7px;
  padding: 5px 2px;
}

.active-tab {
  border-bottom: 2px solid var(--primary-theme-color);
  color: var(--primary-theme-color);
  font-weight: bold;
}

.export-file-container {
  display: flex;
  padding-top: 1.5%;
  background: white;
}

.export-list {
  width: 50%;
  text-align: left;
  font-style: normal;
  font-weight: normal;
  line-height: 22px;
  color: #000000;
  padding: 5px 0px;
}

/* candidate-details-popup */

.details-component-container {
  background: #ffffff;
  height: 100%;
  width: 100%;
}

.Contact-details-header {
  // height: 17%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.container-help-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 15px;
  position: absolute;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  height: 64px;
  left: 0px;
  bottom: 0px;
  background: #FFFFFF;
}

.help-text {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
}

.help-button Button {
  background: var(--primary-theme-light);
  color: var(--primary-theme-color);
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Candidate-details {
  width: 100%;
  display: flex;
  flex-direction: column;

  .ride-details {
    .warning-message-atrisk {
      border: none;
      height: 40px;
      padding: 9px 16px;
      background: var(--primary-theme-light);
    }
  }
}

.head-details {
  // width: 100%;
  display: flex;
  // flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.lead-image {
  height: 35px;
  width: 35px;
  border-radius: 100px;
  margin-right: 10px;
}

.name-number-container {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.lead-name {
  font-family: var(--primary-font-family);
        font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.85);
  text-transform: capitalize;
}

.lead-number {
  // height: 50%;
  // width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
  margin-top: 8px;
}

.contact-buttons {
  // width: 100%;
  display: flex;
  // flex-direction: row;
  // margin-top: 6px;
  // .call-now-button
}

.message-button {
  background: #ffffff;
  border: 1px dashed #d9d9d9;
  box-sizing: border-box;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 2px;
  color: var(--primary-theme-color);
  padding: 5px 0px;
  width: 130px;
  height: 100%;
  text-decoration: none;
}

.call-now {
  background: var(--primary-theme-color);
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 2px;
  color: white;
  width: 130px;
  padding: 5px 0px;
  margin-right: 8px;
}

.phone-icon {
  width: 20px;
  height: 20px;
  padding: 2px 0px;
  border: 2px solid #f5703e;
  color: #f5703e;
  text-align: center;
  border-radius: 100px;
  background: #fff7f4;
  font-size: 11px;
}

.whatsapp-icon {
  width: 20px;
  height: 20px;
  margin-top: -3px;
}

.close-popup {
  width: 10%;
  text-align: right;
  margin-top: -7px;
  font-size: 20px;
  color: var(--primary-theme-color);
}

.flow-diagram {
  padding: 15px;
  width: 100%;
}

.single-path {
  display: flex;
  flex-direction: row;
  height: 19%;
  width: 100%;
  text-align: left;
}

.symbol {
  width: 10%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.circle-symbol {
  height: 50%;
  width: 100%;
  font-size: 20px;
  text-align: center;
  color: rgba(0, 0, 0, 0.45);
}

.pipe-symbol {
  height: 50%;
  width: 100%;
  text-align: center;
  color: rgba(0, 0, 0, 0.45);
}

.single-path-data {
  width: 53%;
  height: 100%;
  padding-left: 10px;
  padding-top: 3px;
}

.path-title {
  width: 100%;
  height: 50%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
}

.path-text {
  width: 100%;
  height: 50%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
}

#ApplicationDate1,
#ClientName1 {
  color: var(--primary-theme-color);
}

#ApplicationDate2,
#ClientName2 {
  color: #000000;
}

.Chips-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  // margin: 4px 0;
  .single-chip {
    background: #ffffff;
    border: 1px solid #f0f0f0;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    border: 1px solid #595959;
    margin-bottom: 8px;
    font-family: var(--primary-font-family);
    font-weight: 700;

    .ant-tag-close-icon {
      color: #f5222d;
      font-size: 12px;
    }
  }

  .perf-dashboard-chip {
    background-color: var(--page-bg-color) !important;
    height: 24px;
    font-family: var(--primary-font-family);
    border-radius: 2px !important;
    border: 1px solid #0000000F !important;

    .ant-tag-close-icon {
      color: #00000073;
      font-size: 10px;
    }
  }
}

.chip-text {
  width: 80%;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.85);
  width: fit-content;
}

.chip-cross {
  color: #ff4d4f;
  padding: 0px 8px;
  // margin-top: -6px;
}

.header {
  background: rgb(255, 255, 255);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 20px;
}

.header-text {
  width: 50%;
  display: flex;
  flex-direction: row;
}

.breadcrumb-new {
  text-align: left;
  padding-right: 10px;
  font-weight: bold;
}

.icon-right {
  line-height: inherit;
}

.phoneNumber {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  padding: 0px 5px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
}

.phone-container {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.card-container {
  display: flex;
  flex-direction: row;
  padding: 16px;
  margin-top: 10px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  border-radius: 4px;
}

.card-container:hover {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
}

.contact-details-container {
  display: flex;
  flex-direction: row;
  height: 55px;
}

.contact-details {
  display: flex;
  justify-content: space-between;
}

.lead-name {
  font-weight: 900;
  letter-spacing: 0.7px;
  font-size: 16px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
}

.phone-container {
  display: flex;
  flex-direction: row;
}

.user-image-view-detail {
  display: flex;
  flex-direction: column;
  width: 15%;
  text-align: right;
}

// .lead-card-left {
//   width: 85%;
// }

.lead-card-right {
  display: flex;
  flex-direction: column;
  width: 15%;
  text-align: right;
}

.user-image {
  width: 50px;
  height: 50px;
  background: #d9d9d9;
  margin-left: auto;
  border-radius: 100px;
  text-align: center;
  align-items: center;
  padding: 7px 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 37px;
  text-align: center;
  color: #ffffff;
}

.view-details-container {
  background: #fff7f4;
  border: 1px solid var(--primary-theme-color);
  margin-left: auto;
  box-sizing: border-box;
  box-shadow: 0px 2px 0px rgb(0 0 0 / 2%);
  border-radius: 4px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 3% 4%;
  margin-top: auto;
  margin-bottom: 15px;
  margin-top: 20px;
  cursor: pointer;
}

.single-application-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 5px 0;
}

.job-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 0;
}

.application-details-container {
  display: flex;
  flex-direction: row;
  margin: 4px 0px;
  width: 100%;
}

.candidate-card-action-required-buttons-container {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.application-card-banners {
  margin: 2px 0px;
  align-items: center;
  text-align: initial;

  .action-banner {
    white-space: normal;
  }
}

.campaign-tag-container {
  margin-left: 78px;
  text-align: initial;
}

.single-chip {
  border-radius: 20px !important;
  padding: 4px !important;
  background-color: #ffffff !important;
  margin-bottom: 3px !important;
}

.action-banner {
  color: #00000073;
  margin: 2px 7.3em;
  max-width: 100%;
  width: 75%;
  display: flex;
  align-items: center;
  padding: 3px 7px !important;
}

.display-mitra-name {
  font-weight: bold;
  text-transform: capitalize;
}

div.click-to-top div.hover-priority div {
  display: none;
  position: absolute;
  top: 75px;
  left: 250px;
  width: 233px;
  z-index: 1;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  padding: 12px 16px;
  right: 0;
  background: #fff;
  color: #00000073;
}

div.hover-milestones {
  display: none;
  position: absolute;
  top: 170px;
  left: 400px;
  width: 230px;
  z-index: 1;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  padding: 12px 16px;
  right: 0;
  background: #fff;
  color: #00000073;
}

div.click-for-hover-milestone:hover div.hover-milestones {
  display: block;
}

div.onboarding-schedule div.withIcon {
  display: flex;
  align-items: center;
}

div.click-to-top div.hover-priority div span {
  color: #000000D9;
}

div.click-to-top:hover div.hover-priority div {
  display: block;
}

.added-mitra-name {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  margin: 5px 0;
  color: #00000073;
}

.page-filter-options {
  display: flex;
  width: 60%;
  align-items: flex-start;
  margin: 2%;
}

// .page-filter-options button {
//   color: #555555;
//   border-color: #555555;
// }
.display-city-name {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  // padding-right: 15px;
  font-size: 12px;
  margin: 5px 0;
  color: #00000073;
}

.referred-by {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  // padding-right: 15px;
  font-size: 12px;
  margin: 5px 0;
  color: #00000073;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}

.single-job-container {
  display: flex;
  flex-direction: row;
  margin: 4px 0px;
}

.onboarding-schedule {
  width: -webkit-fill-available;
  // padding-left: 10px;
  text-align: left;
  display: flex;
  // margin: 5px 0;
  flex-direction: row;
}

.onboarding-schedule-report {
  width: -webkit-fill-available;
  padding-left: 10px;
  text-align: left;
  display: flex;
  flex-direction: column;
}

.counter.counter-lg {
  margin-left: 26px;
  margin-top: -51px;
  background: var(--primary-theme-color);
  border: 1px solid #ffffff;
  width: 20px;
  border-radius: 100px;
  height: 20px;
  font-size: 13px;
  text-align: center;
  line-height: 14px;
  vertical-align: middle;
}

@media screen and (max-width: 890px) {
  .view-details-container {
    border-radius: 100px;
    padding: 4px 8px;
    height: 30px;
    width: 30px;
  }

  .upload-Icon-mobile {
    display: block;
  }

  .upload-Icon {
    display: none;
  }

  .complete-application-button {
    border-radius: 100px;
    padding: 4px 8px;
    height: 30px;
    width: 30px;
    margin-left: auto;
  }

  .view-details-button {
    display: none;
  }

  .btntext {
    display: none;
  }
}

.filter-label {
  padding: 0px 10px;
  line-height: inherit;
  text-transform: capitalize;
  padding: 0px 10px;
  height: 32px;
  line-height: inherit;
  border-radius: 3px;
}

.warning-message-atrisk {
  background: #fff7f4;
  border: 1px solid var(--primary-theme-color);
  box-sizing: border-box;
  border-radius: 2px;
  padding: 5px;
  display: flex;
  flex-direction: row;
}

.bottom-sheet-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.bottom-sheet-heading {
  padding: 10px;
  padding-right: 0px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #f0f0f0;
}

.bottom-sheet-heading-text {
  width: 90%;
  color: black;
  font-weight: bold;
  text-align: left;
}

.bottom-sheet-close {
  color: rgba(0, 0, 0, 0.55);
  text-align: right;
  margin-left: auto;
  line-height: inherit;
}

.bottom-sheet-contact-details-container {
  display: flex;
  flex-direction: column;
  padding: 1% 0%;
}

.bottom-sheet-contact-options {
  display: flex;
  flex-direction: row;
}

.bottom-sheet-contact-button {
  margin-left: auto;
}

.bottom-sheet-index {
  padding: 0.9% 2%;
  width: 10%;
  line-height: inherit;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  text-align: center;
  color: #ffffff;
  box-sizing: border-box;
}

.index-number {
  border-radius: 32px;
  width: 20px;
  height: 20px;
  background: var(--primary-theme-color);
  border: 1px solid var(--primary-theme-color);
}

.bottom-sheet-contact-text {
  display: flex;
  flex-direction: column;
  width: 60%;
  text-align: left;
  font-weight: bold;
}

.warning-message-at-risk-container {
  background-color: white;
  width: 100%;
  text-align: left;
}

.icon-cross {
  float: right;
  line-height: inherit;
  color: rgba(0, 0, 0, 0.5);
  margin: auto 10px;
}

.bottom-sheet-buttons-layout {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.85);
  width: 100%;
  height: 100%;
}

.filter-pop-up-container {
  display: flex;
  flex-direction: column;
}

.filter-popup-heading-container {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #f0f0f0;
  height: 15%;
  padding: 10px;
}

.select-client {
  width: 80%;
  text-align: left;
  font-weight: bold;
}

.filter-popup-cross-icon {
  width: 20%;
  text-align: right;
  margin-left: auto;
}

.popup-list-container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.single-filter-item {
  margin: 10px;
  text-align: justify;
}

.call-lead-name,
.message-lead-name {
  text-transform: capitalize;
}

.diffInDate {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #000000A6;
}

.leadsCardStatus {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: var(--primary-theme-color);
}

.left-arrow {
  vertical-align: middle;
  margin: auto 0px;
  margin-right: 10px;
}

.btntext {
  margin-left: 5px;
}

// .desktop-pagination{
//   margin: auto 10px auto auto
// }

.view-details-icon {
  margin-left: 10px;
}

.mobile-pagination {
  display: none;
}

/* Responsive structure */

@media (max-width: 550px) {
  .header {
    padding: 5px 10px;
  }

  // .desktop-pagination{
  //   display: none;
  // }
  // .mobile-pagination{
  //   display: block;
  // }
}

@media screen and (min-height: 500px) and (max-height: 750px) {
  .btnWidth {
    width: 30%;
    margin-top: 8px;
  }

  .dot {
    height: 8%;
    width: 8%;
    background-color: #bbb;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    text-align: center;
    display: flex;
    font-size: 18px;
  }

  // .desktop-pagination{
  //   display: none;
  // }
  // .mobile-pagination{
  //   display: block;
  // }
}

@media (max-width: 360px) {
  .more-filters {
    background-color: var(--page-bg-color);
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 0;
  }

  .Dropdown-control {
    background: #ffffff;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    width: 100px;
    margin-left: 1%;
  }

  .tab {
    width: 50%;
    display: block;
    text-align: center;
    float: left;
    border-bottom: 2px solid #f0f0f0;
    height: 40px;
    cursor: pointer;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
  }
}

@media screen and (max-width: 578px) {
  .button {
    width: 128px;
  }

  .btnWidth {
    margin-top: 8px;
  }

  .dot {
    height: 65%;
    width: 80%;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    font-size: 18px;
  }
}

@media only screen and (min-width: 700px) {
  .btnWidth {
    width: 30%;
    margin-top: 8px;
  }

  .dot {
    height: 60%;
    width: 30%;
    background-color: #bbb;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    text-align: center;
    display: flex;
  }
}

@media (max-width: 845px) {
  .Show-breadcrumb {
    display: none !important;
  }
}

@media screen and (max-width: 900px) {
  .view-details-container {
    border-radius: 100px;
    padding: 4px 8px;
    height: 30px;
    width: 30px;
  }

  .upload-Icon-mobile {
    display: block;
  }

  .upload-Icon {
    display: none;
  }

  .complete-application-button {
    border-radius: 100px;
    padding: 4px 8px;
    height: 30px;
    width: 30px;
    margin-left: auto;
  }

  .view-details-button {
    display: none;
  }

  .btntext {
    display: none;
  }
}

// @media (min-width: 900px) {
//   .lead-card-left {
//     width: 80%;
//   }

//   .lead-card-right {
//     width: 20%;
//   }
// }

@media only screen and (min-width: 992px) {
  .btnWidth {
    width: 30%;
    margin-top: 8px;
  }

  .dot {
    height: 60%;
    width: 30%;
    background-color: #bbb;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    text-align: center;
    display: flex;
    font-size: 25px;
  }
}

.incompleteApp {
  align-items: center;
  display: flex;
}

.incompleteApplicationBG {
  border-radius: 8px;
  position: relative;
}

.rapidoNotAttributed {
  background: #a5a5a529;
  padding: 5px;
  border-radius: 8px;
  position: relative;
}

#single-container-hot-lead {
  text-align: left;
  display: flex;
  flex-direction: row;
}

.show-only-mobile-footer {
  display: none;
  align-items: center;
  flex-direction: row;
}

.hot-lead-main-container-left-side {
  width: 60%;
}

.hot-lead-main-container-right-side {
  width: 40%;
}

.main-container-divider-right {
  border-left: 1px solid #0000000F;
}

.hot-tip-main-container {
  margin-left: 20px;
  display: flex;
  align-items: center;
}

.hot-tip-bulb-icon {
  color: #2F54EB !important;
  margin-right: 3px;
  font-size: 10px;
}

.hot-tip-font {
  color: #2F54EB;
  font-weight: 700;
  font-size: 12px
}

.hot-tip-sub-heading {
  margin-left: 20px;
  font-size: 12px;
  color: #000000A6;
}

.document-status-tag {
  width: 100%;
  padding: 3px !important;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #FFE58F !important;
  margin-right: 0 !important;
}

.document-status-exclamation-icon {
  color: #FAAD14 !important;
  font-size: 16px;
  margin-left: 5px;
}

.document-status-tag-font {
  font-size: 12px;
  color: #000000;
  flex-grow: 1;
}

.document-status-tag-info-icon {
  color: #00000073 !important;
  font-size: 14px;
  margin-right: 5px;
  margin-left: auto !important;
  display: flex !important;
  align-items: center;
}

.call-notes-icon {
  color: #52C41A !important;
  margin-right: 3px;
  font-size: 10px;
}

.call-notes-heading {
  color: #52C41A;
  font-weight: 700;
  font-size: 12px
}

.call-notes-tag {
  margin-left: 20px;
}

.call-notes-comment {
  font-style: italic;
  font-size: 12px;
  color: #000000A6;
  margin-top: 5px
}

.document-status-cross-icon {
  color: #ff4d4f !important;
  font-size: 16px;
  margin-left: 5px;
}

.document-status-rejected-tag {
  width: 100%;
  padding: 3px !important;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #FFCCC7 !important;
  margin-right: 0 !important;
}

.hover-document-status {
  display: none;
  position: absolute;
  top: 75px;
  right: 65px;
  // left: 900px;
  width: 200px;
  z-index: 1;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  padding: 8px 10px;
  background: #fff;
  color: #00000073;
}

.document-tooltip:hover .hover-document-status {
  display: block !important;
}

.hover-document-status:before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 180px;
  top: -0.2px;
  border-top: 2px solid transparent;
  border-right: 2px solid #06386c;
  box-sizing: border-box;
  transform: rotate(135deg);
  border: 0.4em solid rgb(89, 89, 89);
  border-color: transparent transparent #FFFFFF #FFFFFF;
  transform-origin: 0 0;
  box-shadow: -2px 2px 3px 0 rgba(0, 0, 0, 0.10);
}

.main-container-divider-right .ant-divider-horizontal {
  display: none;
}

.call-logs-opened-arrow-icon {
  color: #4BCF3F !important;
  border: 1px solid #4BCF3F;
  padding: 2px;
  border-radius: 2px;
  display: flex;
  align-items: center;
}

.added-by-footer-hot-lead {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.LeadPage .ant-card-bordered {
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.call-notes-tag .not-connected-tag {
  font-size: 10px;
  vertical-align: 0.175em !important;
  margin-right: 3px;
  color: #FF4D4F;
}

.call-notes-tag .connected-tag {
  font-size: 10px;
  vertical-align: 0.175em !important;
  margin-right: 3px;
  color: #4BCF3F;
}

.more-filters .ant-switch-checked {
  background: #1890ff !important;
}

.more-filters .high-intent-info-icon {
  display: flex;
  align-items: center;
  color: #00000073;
  font-size: 16px;
  margin-left: 6px;
}

.more-filters .high-intent-toggle-switch {
  margin-left: 10px;
  margin-right: 10px;
}

.more-filters .high-intent {
  margin-left: 16px;
  display: flex;
  align-items: center;
  color: #1890FF;
  background: #FFFFFF;
  font-weight: 600;
  border: 1px solid #D9D9D9;
}

.high-intent-lead {
  display: flex;
  align-items: center;
  color: #1890FF;
  margin-left: 16px;
  font-size: 12px;
  font-weight: 600;
}