.job-demand-banner-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background: var(--primary-theme-light);
    border-radius: 4px;
    margin: 1rem 2rem;
    margin-bottom: 0px;
    align-items: center;
}

.job-demand-banner-icon{
    width: 23px;
    margin-bottom: auto;
}

.job-demand-banner-text{
    text-align: left;
    padding-top: 5px;
    display: flex;
    flex-direction: row;
    margin-right: auto;
}

.cross{
    width: 23px;
    margin-bottom: auto;
}

.banner-vedio{
    padding-left: 20px;
    margin-bottom: -20px;
}

.video-tag{
    width: 80%;
    height: 80%;
    max-width:300px;
}

@media (max-width: 550px) {
    .job-demand-banner-text{
        text-align: left;
        padding-top: 5px;
        display: flex;
        flex-direction: column;
        margin-right: auto;
    }
    .banner-vedio{
        padding-left: 0px;
    }
}


.marginRight10px{
    margin-right: 10px;
}

.fullscreen-alert {
    display: block;
}

.ant-alert-close-icon {
    position: absolute;
    top: 30px;
    right: 225px;
    font-size: 18px;
    outline: solid;
    outline-style: solid;
    outline-color: black;
}
 #jb-image {
    margin: 5px;
    max-width: 100%;
    padding-right: 200px;
 }
 .fullscreen-alert .ant-alert-info {
    /* max-height: 80%; */
    overflow-y: auto;
 }

@media screen and (max-width: 768px) {
    .ant-alert-close-icon {
      top: 10px;
      right: 10px;
      font-size: 14px;
      /* Adjust other styles as needed for mobile view */
    }
    #jb-image {
        width: 100%;
        height: 100%;
        max-width: 300px;
        max-height: 300px;
        padding-right: 0px;
    }
  }