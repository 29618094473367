.branding-section-parent {
    .bs-header {
        flex-direction: column;

        .bs-header-icon {
            font-size: 16px;
        }

        .bs-header-txt {
            color: var(--light-theme-neutrals-neutral-1, rgba(0, 0, 0, 0.85));
            font-size: 16px;
            font-weight: 600;
            margin-left: 8px;
        }

        .bs-header-sub-txt {
            margin-top: 4px;
        }
    }

    @media only screen and (min-width: 320px) and (max-width: 786px) {
        .bs-edit-btn-container {
            width: 20% !important;
        }
    }

    .bs-edit-btn-container {
        width: 10%;

        .bs-edit-btn {
            .bs-edit-btn-icon {
                font-size: 16px;
            }
        }
    }

    @media only screen and (min-width: 320px) and (max-width: 786px) {
        .bs-body {
            flex-direction: column !important;
        }
    }

    .bs-body {
        margin-top: 3em;

        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .bs-logo-upload-and-txt-container {
                max-width: 100px !important;
            }
        }

        .bs-logo-upload-and-txt-container {
            position: relative;
            display: inline-block;
            flex-direction: column;

            .bs-logo-upload-parent {
                border: 1px solid rgba(0, 0, 0, 0.15);
                border-radius: 4px;
                margin-bottom: 8px;

                @media only screen and (min-width: 320px) and (max-width: 786px) {
                    .bs-logo-upload-bg {
                        padding: 2.5vh !important;
                    }
                }

                .bs-logo-upload-bg {
                    font-size: 48px;
                    padding: 1.5vw;
                    background-color: var(--light-theme-neutrals-neutral-6, rgba(0, 0, 0, 0.06));
                    border-radius: 4px;
                    color: rgba(0, 0, 0, 0.45);
                }

                .bs-logo-upload-section {
                    .ant-upload-list {
                        display: none;
                    }
                }

                .bs-logo-img-container {
                    .bs-logo-img {
                        object-fit: contain;
                        width: 100px;
                        height: 100px;
                    }
                }

                .bs-logo-upload-post {
                    position: absolute;
                    bottom: 40px;
                    right: -10px;
                    padding: 0 4px 4px 4px;
                    border-radius: 2px;
                    border: 0.75px solid var(--light-theme-neutrals-neutral-5, rgba(0, 0, 0, 0.15));
                    background: var(--light-theme-white, #FFF);
                }

                .bs-logo-upload-txt {
                    margin-top: 8px;
                }
            }
        }

        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .bs-ml-name-input-container {
                margin: 1em 0em;
                flex-direction: column;
                margin-left: 0 !important;
                align-items: flex-start !important;
            }
        }

        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .bs-ml-name-inp {
                flex-direction: column;
                align-items: flex-start !important;
                justify-content: flex-start !important;
            }

        }

        .bs-ml-name-inp {
            align-items: center;
            justify-content: space-between;
            align-items: center;
        }

        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .bs-ml-link-inp-container {
                align-items: flex-end !important;
                justify-content: flex-end !important;
            }
        }

        .bs-ml-link-inp-container {
            align-items: center;
            justify-content: flex-end;
            margin-top: 16px;
        }


        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .bs-ml-link-inp {
                flex-direction: column;
                align-items: flex-start !important;
                justify-content: flex-start !important;
            }
        }

        .bs-ml-link-inp {
            justify-content: space-between;
            align-items: center;
        }

        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .bs-ml-input-container {
                margin-left: 0% !important;
                width: 100% !important;
            }
        }

        .bs-ml-input-container {
            margin-left: 10%;
            width: 90%;

            @media only screen and (min-width: 320px) and (max-width: 786px) {
                .bs-ml-input-label {
                    margin: 1em 0 1em 0 !important;
                }
            }

            @media only screen and (min-width: 320px) and (max-width: 786px) {
                .bs-ml-input-label {
                    text-align: left;
                    width: 100% !important;
                }
            }

            .bs-ml-input-label {
                text-align: left;
                width: 8%;
            }

            .bs-ml-input {
                // width: 85%;
                // margin-bottom: 8px;

                .ant-input-disabled{
                    color: rgba(0, 0, 0, 0.85) !important;
                    font-weight: 600 !important;
                }
            }

            @media only screen and (min-width: 320px) and (max-width: 786px) {
                .bs-ml-input {
                    margin-left: 0 !important;
                    width: 100% !important;
                }
            }

            .bs-ml-input {
                max-height: 35px;
                width: 84%;
            }

            @media only screen and (min-width: 320px) and (max-width: 786px) {
                .bs-ml-input-link {
                    width: 100%;
                }
            }

            .bs-ml-input-link {
                width: 81.7%;
                // margin-right: 2%;
            }
        }

        .bs-link-inp-container {
            width: 88%;
        }

        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .bs-copy-link-btn-container {
                width: 21.75% !important;
            }
        }

        .bs-copy-link-btn-container {
            width: 11.75%;
            margin-left: 1%;

            .bs-copy-link-btn {
                .bs-copy-link-btn-icon {
                    font-size: 16px;
                }
            }
        }
    }
}