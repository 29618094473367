.filter-and-selection-details-container {
    margin: 24px 32px;
    display: flex;
}

.adv-filter-container {
    border-radius: 10px;
    border: 1px solid #C8C9CC;
    background: #FFF;
    width: 295px;
    height: 507px;

    padding: 20px 16px;
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 2;
}

.upload-container {
    flex: 5;
}

.min-candidate-error-image {
    width: 94px;
}

.custom-popover-pro {
    .ant-popover-inner-content {
        padding: 0px !important;
    }

    .ant-popover-arrow {
        display: none !important;
    }
}

.clear-filter-container {
    border-radius: 4px;
    border: 1px solid #1D5FFF;
    padding: 7px 18px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 18px;
    bottom: 20px;
    cursor: pointer;
}

.filter-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.filter-details {
    display: flex;
    align-items: center;
    gap: 12px;
}

.adv-filter-selection {
    border-radius: 4px !important;
    background: #F6F6F6 !important;
    margin-top: 12px !important;
    border: none !important;
}

.custom-date-lp {
    border-radius: 4px !important;
    background: #F6F6F6 !important;
    margin-top: 0px !important;
    border: none !important;
    width: 260px;
    border: 1px solid #83B5FF !important;
    background: #fff !important;
}

.lp-datepicker-customised {
    .ant-picker-panel-container {
        display: flex;
        flex-direction: column-reverse;

        .ant-picker-body {
            background-color: #FDFAF9;
        }
    }
}

.custom-date-lp,.adv-filter-selection > .ant-picker-input > input, .adv-filter-selection > .ant-picker-input-active > input {
    font-family: Inter;
    font-size: 12px;
} 

.adv-filter-search {
    border-radius: 4px !important;
    background: #F6F6F6 !important;
    width: 88% !important;
    margin-left: 28px;
    margin-top: 12px;
    height: 30px !important;
    border: none !important
}

.adv-filter-search > .ant-input {
    background: #F6F6F6 !important;
}

.popular-cities-container {
    margin-top: 6px;
    margin-left: 28px;
    display: flex;
    flex-wrap: wrap;
}

.popular-cities-container > .ant-checkbox-wrapper {
    margin: 0px !important;
}

.popular-cities-container > .ant-checkbox-wrapper > .ant-checkbox > .ant-checkbox-inner {
    border-radius: 4px !important;
    border: 2px solid #D7D7D8 !important;
    background: #D7D7D8 !important;
}

.popular-cities-container > .ant-checkbox-wrapper-checked > .ant-checkbox > .ant-checkbox-inner {
    border-radius: 4px !important;
    border: 2px solid #1D5FFF !important;
    background: #1D5FFF !important;
}

.popular-cities-container > .ant-checkbox-wrapper-checked > .ant-checkbox > .ant-checkbox-inner::after {
    width: 2px;
    height: 5px;
}

.custom-dropdown-container {
    width: 88% !important;
    margin-left: 28px;
    margin-top: 12px;
    background: #FFF;
    position: absolute;
    z-index: 99999999;
    top: -13px;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
    max-height: 150px;
    overflow: scroll;
}

.new-custom-chip {
    border-radius: 10px;
    border: 1px solid #DDD;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 2px 16px;
    width: min-content;
}

.selected-city-list {
    margin: 8px 0px 0px 24px;
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
}

.candidate-count {
    border-radius: 4px;
    border: 1px solid #DDD;
    background: #CBDAFF;
    padding: 16px 42px;
    width: 142px;
}

.candidate-count-ineligible {
    border: 1px solid #FF4545;
    background: #F8F0F5;
}

.candidate-count-text {
    font-family: Inter;
    font-size: 48px;
    font-weight: 700;
    line-height: 58px;
}

.count-sub-text {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

.selection-indicator-text {
    padding-top: 40px;
    padding-left: 20px;
    padding-bottom: 16px;
    display: flex;
    flex-direction: column;
}

.adv-filter-top-section {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;
}

.selection-indicator {
    background: #F3E4F7;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden
}

.selected-values-display-section {
    display: flex;
    flex-wrap: wrap;
    padding: 36px 0px 0px;
    gap: 24px;
    width: 100%;
}

.single-section {
    border-radius: 8px;
    background: #F6F6F6;
    padding: 16px 20px;
    display: flex;
    gap: 16px;
    width: 45%;
    flex-direction: row;
}

.adv-filter-selection-images {
    width: 118px;
    height: 60px;
}

.filter-section-button-container {
    margin-top: auto;
    padding-top: 32px;
}

.adv-filter-preview-container {
    margin: 0px 0px 0px 32px
}

.mobile-filter-button-adv-filter, .side-adv-filter-close-btn {
    display: none !important;
}

.adv-filter-banner-img {
    position: absolute;
    /* width: 164px; */
    right: -27px;
    bottom: -37px;
    /* height: 100%; */
    width: 215px;
}

.less-than-five-text {
    color: #FE4848;
    gap: 2px;
    font-size: 10px;
    width: max-content;
}

.min-required-leads-text {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}

.min-leads-content-container {
    margin-left: 24px;
}

.min-leads-err-image {
    height: 36px;
    object-fit: contain;
}


@media only screen and (min-width: 320px) and (max-width: 786px) { 
    .adv-filter-container {
        display: none;
    }

    .min-required-leads-text {
        font-family: Inter;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
    }

    .min-leads-err-image {
        height: 28px;
        object-fit: contain;
    }

    .min-leads-content-container {
        margin-left: 0px;
    }

    .leads-ineligible-container {
        padding: 8px;
    }

    .adv-filter-preview-container {
        margin: 0px
    }

    .filter-and-selection-details-container {
        margin: 24px 24px;
    }

    .filter-screen-btn-width {
        width: 100%;
    }

    .mobile-filter-button-adv-filter {
        display: flex !important;
        align-items: center;
        justify-content: center;
        padding: 8px 24px;
        gap: 6px;
        margin: 12px 24px;
        width: 134px;
    }
    
    .mobile-adv-filter-sidesheet {
        display: flex;
        flex-direction: column;
        margin: 12px 24px;
    }

    .clear-filter-container {
        position: unset;
        width: fit-content;
    }

    .side-adv-filter-close-btn {
        display: flex !important;
        position: absolute !important;
        bottom: 24px;
        width: 88%;
        align-items: center;
        justify-content: center;
        height: 40px !important;
    }

    .single-section {
        flex-direction: column;
        padding: 10px 12px 24px
    }

    .adv-filter-selection-images {
        width: 100%;
        height: 42px;
    }

    .adv-filter-banner-img {
        position: absolute;
        right: -14px;
        bottom: 8px;
        height: 75%;
        width: auto;
    }

    .candidate-count {
        width: 83px;
        height: 80px;
        padding: 48px 42px;
    }

    .candidate-count-text {
        font-family: Inter;
        font-size: 32px;
        font-weight: 700;
        line-height: 36px;
    }

    .count-sub-text {
        font-family: Inter;
        font-size: 10px;
        font-weight: 400;
        line-height: 16px;
    }

    .selection-indicator-text {
        z-index: 1;
        padding-left: 12px;
        padding-top: 14px;
        padding-bottom: 4px;
    }

    .your-selections {
        font-size: 14px !important;
        text-align: start;
    }

    .your-selections-subtext {
        font-size: 10px;
        width: 70%;
        text-align: start;
    }

    .min-candidate-error-image {
        width: 70px;
        padding-top: 4px;
    } 

    .less-than-five-text {
        width: auto;
    }

    .adv-filter-top-section {
        gap: 8px;
    }
}