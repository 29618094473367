.searchPage {
  .desktop-version {
    background-color: #fff;
    display: flex;
  }
  .mobile-version {
    display: none;
  }
  // .show-only-mobile {
  //   display: none !important;
  // }
  // .show-only-desktop {
  //   display: flex !important;
  //   font-family: Nunito Sans Medium;
  // }
}
.search-page-header {
  .ant-page-header-heading-title {
    font-size: 16px;
  }
}
.detail-body-container {
  width: 98%;
}
.SearchPage {
  .desktop-version {
    display: flex;
    padding: 20px;
    padding-top: 0;
  }
  // .show-only-mobile {
  //   display: none !important;
  // }
  // .show-only-desktop {
  //   display: flex !important;
  //   font-family: Nunito Sans Medium;
  // }
}

@media (min-width: 320px) and (max-width: 768px) {
  .searchPage {
    .mobile-version {
      display: flex;
      align-items: center;
      svg {
        margin-left: 3px;
      }
    }
    .desktop-version {
      display: none;
    }
  }
  .SearchPage {
    .desktop-version {
      display: flex;
    }
    // .show-only-mobile {
    //   display: block !important;
    //   font-family: Nunito Sans Medium;
    // }
    // .show-only-desktop {
    //   display: none !important;
    // }
  }
}
