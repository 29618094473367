.mobile-version {
    display: none;
}

.page-description {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap:wrap;

    font-family: var(--primary-font-family);
    font-style: normal;
    font-weight: 700;
    font-size: 1.3em;
    line-height: 28px;

    margin: 10px;
    padding:15px;

    text-align: start;
}

.refer-and-earn {
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    font-weight: 700;
}
}

.refer-earn-card-container {
    margin: 10px;
    padding:15px;

    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    .ant-card {
        height: 210px;
        width: 330px;
    }

    .card-description-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }

    .step-number {
        font-family: var(--primary-font-family);
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        text-align: start;
    }

    .step-description {
        font-family: var(--primary-font-family);
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        text-align: start;
    }

    .step-reward {
        font-family: var(--primary-font-family);
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        color: var(--primary-theme-color);
        text-align: start;
    }

    img {
        width: 145px;
        height: 159px;
    }
}

.mobile-version {
    display: none;
}

.refer-and-earn-button {
    margin: 10px;
    padding: 15px;
    button {
        width: 100%;
        background: var(--primary-theme-color);
        border-radius: 2px;
        color: white;
        padding: 5px 16px;
        gap: 8px;

        .refer-earn-button-text {
            font-family: var(--primary-font-family);
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: white;

            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;
        }
    }
}

.refer-earn-table-container {
    margin: 10px;
    padding:15px;
    overflow: scroll;
    margin: 0
}

.formio-component-button {
    background-color: #FAFAFA;
    margin: 0;
    .btn .btn-primary,.btn .btn-primary:disabled {
        background-color: none;
        color: #fff;
    }
}

.btn-primary,.btn-primary:disabled {
    background-color: none;
    color: #fff;
}

.refer-and-earn-tnc {
    display: flex;
    justify-content: center;
    font-family: var(--primary-font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    text-decoration-line: underline;
    color: var(--primary-theme-color);
    cursor: pointer;
}

.refer-and-earn-form-modal {
    text-align: start;
    .ant-modal-title {
        font-family: var(--primary-font-family);
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
    }
    .btn {
        background-color: var(--primary-theme-color);
        border: none;
        font-family: var(--primary-font-family);
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #FFFFFF;
        width: 100%;
    }
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
   .refer-and-earn{
    padding-bottom: 50px;
   }

   .desktop-version {
    display:none
   }

   .mobile-version{
    display: flex;
   }

   .refer-earn-card-container {
    .ant-card{
        width: 358px;
        height: 102px;
    }
   }

   .step-reward {
    text-align: end;
   }
  }
