.spare-main-container {
    background-color: white;
    height: 100vh;
}

.onboarding-questions-main-container {
    display: flex;
    flex-direction: column;
    padding-top: 60px;
    
    gap: 16px;

    .welcome-header {
        display: flex;
        align-items: center;
        gap: 16px;

        .text-content-area {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
    }
}

.welcome-text {
    font-family: var(--primary-font-family);
    font-style: normal;
    font-weight: 700;
    font-size: 1.1rem;
    line-height: 24px;
    color: #595959;
}

.setup-account-text {
    font-family: var(--primary-font-family);
    font-style: normal;
    font-weight: 700;
    font-size: 1.3rem;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.85);
}

.positioning-container {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.questions-container {
    display: flex; 
    width: 100%;
    flex-direction: column;
    padding: 24px;
    border: 1px solid #F0F0F0;
    border-radius: 8px;
    gap: 24px;

    .question-number-indicator {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
    }
}

.onboarding-questions-container {
    display: flex;
    background: #F0F0F0;
    border-radius: 4px;
    padding: 24px;
    flex-direction: column;
    text-align: left;
    gap: 16px;

    .ant-slider-rail {
        background-color: #FFC2A5;
    }
}

.powered-by-content {
    bottom: 0;
    left: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.onboarding-questions-submit-button {
    width: 100%;
    background: var(--primary-theme-light);
    border: 1px solid var(--primary-theme-color);
    border-radius: 2px;
    font-family: var(--primary-font-family);
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 24px;
    color: var(--primary-theme-color);
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-text {
    display: flex;
    justify-content: center;
    gap: 8px;

    .vahan-logo {
        padding-left: 0;
    }
}

.question-text {
    font-family: var(--primary-font-family);
    font-style: normal;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    align-items: flex-start;
    gap: 8px;
}

.powered-by-text {
    font-family: var(--primary-font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #000000;
}

.custom-ob-logo {
    margin-bottom: 2.5rem;
}

.last-question {
    background-color: var(--primary-theme-color);
    color: white;
    width: 100%;
    border: 1px solid var(--primary-theme-color);
    border-radius: 2px;
    font-family: var(--primary-font-family);
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (min-width: 320px) and (max-width: 786px){
    .onboarding-questions-main-container {
        padding: 6px;
        padding-top: 60px;
    }
    }
