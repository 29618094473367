.section-card-layout {
  padding: 24px 24px 0px;
  // background-color: #ffffff;
  .layout-header {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    svg {
      font-size: 18px;
      color: var(--primary-theme-color);
    }
    .switch-view {
      display: flex;
      align-items: center;
      padding: 8px;
      .siwtch-name {
        padding-left: 8px;
        padding-right: 8px;
        font-family: var(--primary-font-family);
      }
    }
  }
  .layout-content {
    canvas {
      // padding: 10px;
      border: 1px solid #f0f0f0;
      box-sizing: border-box;
      border-radius: 2px;
    }
  }
}
