.account-sidesheet-parent {

    .create-team-modal-parent {
        height: 50%;

        .ant-modal-content {
            height: 100%;
        }

        .ant-divider-horizontal {
            margin: 0px !important;
        }

        .as-divider {
            margin: 0px !important;
        }

        .ant-modal-body {
            padding: 0;
        }

        .ant-divider {
            margin: 0;
        }
    }

    .ant-select-item-option-state {
        margin-left: 8px;
        display: flex;
        align-items: center;
    }

    .containerSS {

        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .navigator {
                width: 95%;
            }
        }

        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .as-btns-container {
                bottom: 16vh !important;
                width: 98% !important;
            }
        }

        .as-btn-w-90 {
            width: 90% !important;
        }

        .as-btns-container {
            bottom: 10vh;
            width: 100%;
            padding: 0 1em;

            .as-remove-btn {
                width: 45%;

                .as-remove-btn-icon {
                    color: var(--primary-theme-color);
                }
            }

            .as-submit-btn {
                width: 45%;

                .as-submit-btn-loader {
                    color: white;
                    margin-left: 8px;
                }
            }
        }

    }

    @media only screen and (min-width: 320px) and (max-width: 786px) {
        .as-body {
            margin-top: 2vh !important;
            width: 98% !important;
        }
    }

    .as-body {
        margin-top: 2vw;
        padding: 0 2vw;

        .as-inactive-txt {
            margin: 1em 0;
        }

        .as-profile-pic-image-container {
            position: relative;
            width: fit-content;

            .as-profile-pic-image {
                width: 64px;
                height: 64px;
                border-radius: 32px;
                object-fit: contain;
                border: 1px solid lightgray;
            }

            .as-profile-pic-delete-icon {
                font-size: 14px;
                position: absolute;
                right: -5px;
                bottom: 4px;
                color: #818589;
                border: 1px solid #818589;
                border-radius: 50%;
                padding: 2px;
                background-color: whitesmoke;
            }
        }

        .as-profile-pic-upload {
            :disabled {
                pointer-events: none;
            }

            .as-profile-pic-icon-container {
                padding: 16px;
                width: 64px;
                height: 64px;
                background-color: lightgray;
                border-radius: 32px;

                .as-profile-pic-upload-icon {
                    color: gray;
                    font-size: 32px;
                }
            }

        }


        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .as-input-parent {
                margin-top: 3vh !important;
                // position: fixed !important;
                bottom: 8vh !important;
            }
        }

        .as-input-parent {
            margin-top: 1vw;

            .as-team-select-container {
                flex-direction: column;
                margin-bottom: 15px;

                .as-tl-add-txt {
                    font-style: italic;
                }

                .as-existing-team {
                    flex-direction: column;
                    .as-tl-team {
                        border-radius: 2px;
                        border: 1px solid var(--light-theme-neutrals-neutral-5, rgba(0, 0, 0, 0.15));
                        background: var(--light-theme-white, #FFF);
                        box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
                        padding: 4px 15px;
                    }

                    .as-remove-team-txt {
                        margin-top: 4px;
                        text-align: left;
                    }
                }
            }
        }
    }

}

// .ant-divider-horizontal {
//     margin: 0px !important;
// }

.as-confirm-changes-modal-parent {
    height: 25%;

    .ant-modal-close-x {
        display: none !important;
    }

    .as-divider {
        margin: 0px !important;
    }

    .ant-modal-body {
        padding: 0;
    }

    .as-confirm-changes-modal-content {
        height: 100%;

        .asccm-header-body {
            flex-direction: column;

            .asccm-header {
                padding: 12px;

                .asccm-header-title {
                    .asccm-comp-logo {
                        height: 40px;
                        width: 40px;
                        margin-right: 8px;
                        object-fit: contain;
                    }

                    .asccm-header-icon {
                        font-size: 20px;
                        margin-right: 8px;
                    }
                }

                .asccm-header-close {
                    padding: 8px;
                    border-radius: 2px;
                    border: 1px solid rgba(0, 0, 0, 0.15);
                    background: #FFF;

                    .asccm-header-close-icon {
                        font-size: 20px;
                    }
                }
            }

            .asccm-body {
                overflow: scroll;
                padding: 12px;
            }
        }

        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .asccm-footer {
                padding: 2vh 2vh !important;
                // position: fixed !important;
                bottom: 8vh !important;
            }
        }

        .asccm-footer {
            // position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 20%;
            background-color: #FFF;
            z-index: 10;

            .asccm-footer-btns-container {
                padding: 1% 2%;

                .asccm-footer-cancel-btn {
                    width: 45%;
                    border-radius: 2px;
                    border: 1px solid var(--light-theme-neutrals-neutral-6, rgba(0, 0, 0, 0.06));
                    background: var(--light-theme-white, #FFF);
                    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
                    padding: 4px 15px;
                    max-height: 40px;
                }

                .asccm-footer-confirm-btn {
                    width: 45%;
                    max-height: 40px;
                }

                .asccm-footer-confirm-btn:disabled {
                    opacity: 0.4;
                }
            }

            .asccm-tc-txt {
                color: var(--primary-theme-light, var(--primary-theme-color));
                font-size: 16px;
                font-weight: 700;
                text-decoration-line: underline;
            }

        }
    }
}