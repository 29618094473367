.HelpComponent {
    .containerTop {
      display: flex;
      justify-content: space-between;
      padding: 20px;
      background: #fff;
      margin-bottom: 5px;
      a {
        font-size: 16px;
        font-weight: 400;
        text-decoration: none;
      }
    }
    .containerMiddle {
      display: flex;
      flex-direction: column;
      text-align: left;
      .tab-wrapper {
        background: #fff;
        .table-container {
          padding: 8px;
          margin: 0;
          .incentive-tags {
            margin: 10px;
            float: left;
            font-weight: 500;
            .tag-names {
              padding: 5px;
              padding-left: 0;
              float: left;
            }
          }
        }
      }
      .ant-tabs-nav {
        margin: 0;
        background-color: white;
        .ant-tabs-nav-wrap {
          margin-left: 20px;
        }
        .ant-tabs-nav-list {
          color: var(--primary-theme-color);
          .ant-tabs-tab-btn {
            color: var(--primary-theme-color) !important;
          }
        }
      }
      .ant-btn {
        display: flex !important;
        align-items: center !important;
        justify-content: center;
      }
      .ant-table-tbody {
        tr {
          td {
            border-bottom: none;
          }
        }
      }
      .parentCard{
        padding: 5px;
      }
      .inputFieldDiv{
        padding:15px 15px 0px 15px;
      }
      .ant-collapse-header  {
       font-weight: 900;
      }
      .ant-card-meta-title{
       font-weight: 900;
      font-size: 14px;
      font-family: var(--primary-font-family);
      font-weight: 700;
      }
      .ant-table-thead th{
        font-weight: 800;
      }
      .inAppAlertContainer{
        padding-bottom: 10px;
      }
      .ant-alert{
        position: unset !important;
        width: 100% !important;
      }
      .iconFontMessageSuccess{
        font-size: 140%;
        color: #3de414;
      }
      .iconFontMessageError{
        font-size: 140%;
        color: #e41437;
      }
      .ticket-table-header{
        text-align: right;
        display: flex;
        text-align: right;
        margin-bottom: 10px;
      }
      .desktop-row-mobile-column{
        display: flex;
        flex-direction: row;
      }
    }
    .detail-body-container {
      width: 100%;
    }
    .card-container {
      display: block;
      margin-top: 1.3rem;
      button {
        background-color:rgb(82, 196, 26);
        color: white;
        border-color: green;  
        height: 30px;
        padding: 10px;
        font-size: 14px;
        font-weight: 700;
        margin-left: 45%;
      }
      .issue-status {
        margin-top: 2px;
        font-family: var(--primary-font-family);
        color: #BFBFBF;
        display: flex;
        font-weight: 800;
        padding-bottom: 1%;
        border-bottom: 1px solid #F0F0F0;
        button {
          color: #F6FFED;
          background: var(--primary-theme-color);
          border-radius: 2px;
          border-color: var(--primary-theme-color);
          height: 25px;
          padding: 10px;
          font-size: 12px;
          font-weight: 600;
          margin-left: 5px;
        }
        button.grey {
          background: #BFBFBF !important;
          border-color: #BFBFBF !important;
        }
        button.green {
          background: #52C41A;
          border-color: #52C41A;
        }
        button.re-open{
          background: #6fbced;
          border-color: #6fbced;  
        }
      }
      .ticket-details {
        margin-left: auto;
        span {
          margin-left: 2em;
        }
      }
      .candidate-details {
        color: #595959;
        display: flex;
        margin-top: 1em;
        span {
          min-width: 10rem;
          font-weight: 500;
          span.ticket {
            display: block;
            margin-top: 1rem;
            font-size: 12px;
          }
          span.ticket.bold {
            font-size: 14px;
            font-weight: 600;
          }
        }
        span.more-width {
          min-width: 15rem;
        }
      }
    }

    .table-section {
      position: relative;
      display: inline-block;
      width: 100%;
      margin: 0 0 16px;
      overflow: scroll;
      border: 1px solid rgba(5, 5, 5, 0.06);
      border-radius: 6px;
      margin-top: 16px
    }
  }

  .help-sider-name {
    font-family: var(--primary-font-family);
    font-style: normal;
    font-weight: 700;
    font-size: 1.1rem;
    line-height: 27px;
    color: #000000;
  }

  .ant-table {
    .re-open-dash-button{
      border-color: #177CCD;
      border-radius: 20px;
      color: #177CCD;
      font-family: var(--primary-font-family);
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
    }
  
    .reply {
      border-color: #52C41A;
      color: #52C41A;
    }
  }

  .main-ticket-container {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .ticket-details {
      display: flex;
      flex-direction: column;
      gap: 24px;
      margin-top: 24px;

      .ticket-detailed-elements {
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        .ticket-detail-fieldname {
          font-family: var(--primary-font-family);
          font-style: normal;
          font-weight: 700;
          font-size: 1rem;
          line-height: 22px;
          color: #565656;
          flex: 2
        }

        .extras {
          flex: 1
        }

        .ticket-detail-fieldvalue {
          font-family: var(--primary-font-family);
          font-style: normal;
          font-weight: 700;
          font-size: 1rem;
          line-height: 22px;
          color: #000000;
          flex: 2
        }
      }
    }

    .description-section {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      gap: 12px;
      padding-top: 16px;

      .description-title {
        text-align: start;
        margin-left: 25px;
        font-family: var(--primary-font-family);
        font-style: normal;
        font-weight: 700;
        font-size: 1.1rem;
        line-height: 22px;
        color: #111111;
      }

      .description-content {
        text-align: start;
        margin-left: 25px;
        font-family: var(--primary-font-family);
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #000000;
      }
    }

    .conversation-header {
      font-family: var(--primary-font-family);
      font-style: normal;
      font-weight: 700;
      font-size: 1.1rem;
      line-height: 22px;
      color: #000000;
    }

    .help-sider-conversation-history-container {
      display: flex;
      flex-direction: column;
      text-align: start;
      height: 300px; 
      overflow: scroll;

      border: 4px solid #fafafa;
      margin: 8px;
      padding-top: 16px;
      padding-bottom: 16px;

      .single-message-container {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .message-header {
          display: flex;
          flex-direction: row;
          margin-left: 24px;
          gap: 16px;

          .message-header-icon{
            height: 30px;
            width: 30px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            
            font-family: var(--primary-font-family);
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 26px;
            text-align: center;
            color: #FFFFFF;
          }

          .support {
            background: #39A99B;
          }

          .mitra-leader {
            background: #6C4585;
          }

          .message-header-text {
            display: flex;
            flex-direction: column;
            gap: 4px;

            .client-name-text {
              font-family: var(--primary-font-family);
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 19px;
              color: #000000;
            }

            .date-text {
              font-family: var(--primary-font-family);
              font-style: normal;
              font-weight: 700;
              font-size: 10px;
              line-height: 11px;
              color: #888888;
            }
          }
        }

        .message-description {
          margin-left: 70px;
          margin-right: 32px;
          display: flex;
          flex-direction: column;
        }
      }
    }

    .reply-button {
      position: absolute;
      display: flex;
      width: 100%;
      bottom: 30px;
      background-color: #fff;
      padding-left: 20px;
      padding-right: 20px;

      .button-style{
        font-family: var(--primary-font-family);
        font-style: normal;
        font-weight: 700;
        font-size: 1rem;
        line-height: 20px;
        color: #FFFFFF;
        width: 100%;
        background: #52C41A;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .re-open {
        background: #177CCD;
      }
    }
  }

  .new-issue-created-message{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px;
    margin: 5px 20px;
    gap: 10px;

    height: 54px;

    background: #F6FFED;
    border-radius: 2px;
    
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
  }

  .raise-issue-container {
    display: flex;
    flex-direction: column;
    padding: 0 20px 20px;
    gap: 16px;

    .form-input-field {
      display: flex;
      flex-direction: column;
      padding: 0px;
      width: 100%;
      // gap: 8px;
      
      .ant-select-selection-placeholder{
        display: flex;
      }

      .voice-note-delete{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        padding: 16px;
        gap: 16px;
        margin-top: 8px;

        background: var(--primary-theme-light);

        box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);

        .voice-note-delete-text{
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0px;
          gap: 8px;
          font-weight: 600;
          font-size: 15px;
          line-height: 24px;
        }

        .voice-note-delete-buttons{
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 0px;
          gap: 8px;

          .voice-note-delete-button{
              border: none;
              display: flex;
              justify-content: center;
              font-weight: 700;
          }
        }
      }

      .voice-record-container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 8px 16px;
        gap: 8px;
        margin-top: 10px;

        height: 44px;
        width: 100%;

        background: #FAFAFA;

        border: 1px solid #F5222D;
        border-radius: 2px;

        .voice-record-timer{
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 0px;
          gap: 4px;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
        }

        .voice-record-buttons{
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 0px;
          gap: 4px;

          button{
            border: none;
            background: none;
            font-size: 20px;
            display: flex;
            justify-content: center;
            width: 10px;
          }
        }

        .play-content{
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0px;
          gap: 3px;

          width: 100%;

          .hp_range {
            background: #ff9393;
            height: 28px;
          }
        }
      }

      .form-input-fieldname {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px 0px 8px;
        gap: 4px;

        font-family: var(--primary-font-family);
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #262626;

        .requiredField {
          margin-right: 4px;
          color: rgba(255, 77, 79, 1);
        }

        .voice-note-button{
          background: var(--primary-theme-light);
          border-radius: 14px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 4px 8px;
          gap: 8px;
          font-weight: 700;
          font-size: 12px;
          line-height: 20px;
          border: none;
        }

        .stop-button{
          background: #F0F0F0;
        }
      }

      .voice-note-description{
        justify-content: space-between;
        align-items: flex-start;
      }

      .form-input-fieldvalue {
        box-sizing: border-box;

        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 12px;
        gap: 4px;

        background: #FFFFFF;

        border: 1px solid #D9D9D9;
        border-radius: 2px;
      }

      .search-box-container{
        border: none;
        padding: 8px 0px;
      }
      
      .upload-button-container{
        border: none;
        padding: 8px 0px;
      }
    }

    .candidate-card-container{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 16px;
      gap: 8px;

      width: 100%;
      // height: 138px;
      background: #F0F0F0;

      border: 1px solid #F0F0F0;
      border-radius: 4px;
      
      text{
        color: #595959;
      }

      .candidate-card-header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0px;
        gap: 14px;
        width: 100%;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;

        .mobile-number-content{
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: 4px;
          font-weight: 600;
          font-size: 14px;
          line-height: 22px;
        }
      }

      .candidate-card-content{
        // display: flex;
        // flex-direction: column;
        // align-items: flex-start;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 8px;
        height: 36px;

        .client-logo{
          width: 68px;
        }

        .milestone-content{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0px 5px;

          .milestone-name{
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            color: var(--primary-theme-color);
          }

          .milestone-date{
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #595959;
          }
        }
      }

      .candidate-card-footer{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0px;
        padding-top: 8px;
        gap: 8px;

        width: 100%;

        .city-content{
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          padding: 0px;
          gap: 4px;
          font-weight: 700;
          font-size: 12px;
          line-height: 20px;
        }
      }
    }
    
    .submit-button{
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 8px 16px;
      gap: 8px;
      bottom: 30px;

      width: 90%;  

      background: var(--primary-theme-color);
      border-radius: 2px;
      color: #FFFFFF;

      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .search-item-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 5px 0px;
    gap: 4px;

    .search-item-headline{
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      gap: 10px;

      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
    }

    .search-item-subheadline{
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #BFBFBF;
    }
  }
  
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    .HelpComponent {
      .containerTop {
        padding: 0;
      }
      .containerMiddle {
        overflow: scroll;
        .table-container {
          overflow: scroll;
          height: calc(100vh - 200px);
        }
        .desktop-row-mobile-column{
          display: flex;
          flex-direction: column;
        }
      }
      .card-container {
        text-align: left;
        button{
          margin-left: 27%;
        }
        .issue-status {
          display: grid !important;
          .ticket-details {
            display: contents;
            span {
              margin-left: 0 !important;
              margin-top: 0.5rem;
            }
          }
          button.re-open{
            margin-top: 7px;
          }
        }
        .candidate-details {
          display: grid !important;
          span.ticket {
            margin-top: 0.2rem !important;
            margin-bottom: 1rem;
          }
        }
      }
    }

    .raise-issue-container{
      margin-top: 10px;
    }
  }
