.incoming-calls-main-container {
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.incoming-calls-primary-details, .incoming-call-other-details {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.incoming-calls-modal-style {
    .ant-modal-title, .ant-modal-close{
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.3px;
        color: #000000;
    }
    .ant-modal-footer {
        display: flex;
        justify-content: center;
    }
}

.missed-call-text {
    font-family: var(--primary-font-family);
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
    .incoming-calls-modal-style .ant-modal-content{
        position:fixed;
        bottom:0;
        left:0;
        width: 100%;
    }
}
