.illustration {
  background: url("/image/illustration.png");
  background-repeat: no-repeat;
  background-position: center bottom;
  padding-top: 300px;
  /* position: fixed; */
  /* z-index: 999; */
}

.vahan-logo {
  max-width: 150px;
}

.vahan {
  margin-bottom: 2.5rem;
  max-width: 150px;
  margin-top: 12%;
}

.new-logo-signup-page {
  margin-top: 0px !important;
  width: 70px !important;
}

.login-page-custom-branding-txt {
  margin-bottom: 2%;
}

.login-page-custom-branding-txt-margin-top {
  margin-top: 15%;
}

.login-page-custom-branding {
  flex-direction: column;
}

.login-page-powered-by-vahan {
  position: absolute;
  bottom: 1em;
  right: 1em;
}

.powered-by-footer-img {
  max-width: 28px;
  margin-left: 8px;
}

@media screen and (max-width: 500px) {
  .Login {
    height: 100vh;
  }

  .illustration {
    position: absolute;
    bottom: 0;
    left: 0;
    height: auto;
    width: 100%;
  }

  .vahan-logo {
    max-width: 100px;
    margin-top: 0 !important;
    float: left;
    padding-left: 5%;
  }

  .vahan {
    margin-bottom: 5%;
    max-width: 150px;
    margin-top: 10%;
  }

  .cards-elements-surface-filled {
    border: 0 !important;
    background-color: #f1f1f1;
    box-shadow: none;
  }

  h3.subheading {
    padding-right: 28%;
    line-height: 100px;
  }

  .arrow-back {
    position: absolute;
    left: 45%;
  }
}

.signup_link {
  cursor: pointer;
  color: #ff712d;
  margin-left: 5px;
}