.content{
  text-align: left;
  padding: 20px;
}
.incentive-page-container{
  .filters{
    padding: 10px 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .period{
      margin-right: 20px;
    }
    .incentive-type{
      margin: auto 0px;
    }
  }
  .incentive-list{
    overflow: scroll;
    max-height: calc(100vh - 156px);
    
    .incentive-cards-container{
      padding: 10px 20px;
      background-color: #f4f4f4;
      .incentive-card{
        display: flex;
        flex-direction: column;
        background-color: white;
        align-items: flex-start;
        .incentive-card-header{
          border-bottom: 1px solid #dddddd;
          width: 100%;
          text-align: left;
          padding: 15px;
        }
        .locations-list-container{
          width: 100%;
          margin: 10px 0px;
          .single-location-container{
            background-color: #fbfbfb;
            margin-bottom: 10px;
            padding: 20px;
  
            .location-list{
              display: flex;
              flex-direction: row;
              padding: 0px 0px 10px 0px;
              .location-container-left{
                width: 50%;
                text-align: initial;
              }
              .location-container-right{
                width: 50%;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                font-weight: bold;
                .location-earn-upto{
                  margin-right: 10px;
                }
              }
            }
            .milestone-data{
              display: flex;
              flex-wrap: wrap;
              border-top: 1px solid #dddddd;
              padding-top: 20px;
              .milestone-container{
                text-align: left;
                flex: 0 0 33.333333%;
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }
  }
  .incentive-footer{
    position: fixed;
    bottom: 0;
    width: 83%;
    background-color: #ffffff;
    padding: 16px;
    z-index: 3;
    justify-content: center;
    display: flex;
    margin-left: 2px;
    cursor: pointer;
    .incentive-footer-box{
      border: 1px solid #F0F0F0;
      width: 100%;
      background: #FAFAFA;
      padding: 9px 16px;
      border-radius: 2px;
      display: flex;
      flex-direction: row;
    }
  }
  
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .incentive-page-container{
    .incentive-list{
      max-height: calc(100vh - 156px);
      margin-bottom: 164px;
      .milestone-data{
        display: flex;
        flex-direction: column;
        text-align: center;
      }
    }
    .incentive-footer{
      position: fixed;
      bottom: 75px;
      width: 100%;
      display: flex;
      background-color: #ffffff;
      padding: 16px;
      z-index: 3;
      cursor: pointer;
      .incentive-footer-box{
        border: 1px solid #F0F0F0;
        width: 100%;
        background: #FAFAFA;
        padding: 9px 16px;
        border-radius: 2px;
        display: flex;
        flex-direction: row;
      }
    }
  }
}
