.al-modal-parent {

    .ant-modal-close-x {
        display: none !important;
    }

    .alm-header {
        // padding: 12px;
        // border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        margin-bottom: 2em;

        .alm-header-txt {
            color: var(--light-theme-neutrals-neutral-1, rgba(0, 0, 0, 0.85));
            font-size: 20px;
            font-weight: 600;
            line-height: 28px;
        }


        .alm-header-title {
            .alm-comp-logo {
                height: 40px;
                width: 40px;
                margin-right: 8px;
                object-fit: contain;
            }

            .alm-header-icon {
                font-size: 20px;
                margin-right: 8px;
            }
        }

        .alm-header-close {
            padding: 8px;
            border-radius: 2px;
            border: 1px solid rgba(0, 0, 0, 0.15);
            background: #FFF;

            .alm-header-close-icon {
                font-size: 20px;
            }
        }
    }
}