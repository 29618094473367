.team-details-container-parent {
    .ant-divider-horizontal {
        margin: 0px !important;
    }

    .tdc-header {
        .tdc-header-txt {
            text-align: left;
            color: var(--light-theme-neutrals-neutral-1, rgba(0, 0, 0, 0.85));
            font-size: 14px;
            font-weight: 400;
            padding: 2em 0 2em 3em;
        }
    }


    @media only screen and (min-width: 320px) and (max-width: 786px) {
        .tdc-team-details-container {
            flex-direction: column;
            align-items: flex-start !important;
        }
    }

    .tdc-team-details-container {
        padding: 2em;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.06);

        .tdc-team-details-txt-container {
            flex-direction: column;
            width: 60%;
        }
    }

    @media only screen and (min-width: 320px) and (max-width: 786px) {
        .tdc-team-details-btns-container {
            margin-top: 1vh;
            width: 100% !important;
        }
    }

    .tdc-team-details-btns-container {
        width: 40%;

        .tdc-edit-btn {
            width: 45%;
        }

        .tdc-add-btn {
            width: 45%;
        }
    }

    @media only screen and (min-width: 320px) and (max-width: 786px) {
        .tdc-tl-tc-info-container {
            width: 50% !important;
        }
    }

    .tdc-tl-tc-info-container {
        padding: 1em 2em;
        width: 15%;

        .tdc-tl-name {}

        .tdc-tc-count {}

        .tdc-tl-name-value {
            margin-left: 8px;
        }

        .tdc-tc-count-value {
            margin-left: 8px;
        }
    }

    .tdc-body-container {
        padding: 1em 2em;
        padding-bottom: 80px;

        .tdc-sort-container {
            margin-left: 8px;

            .tdc-sort {
                .tdc-sort-txt {
                    margin-left: 4px;
                }
            }

            .tdc-sort-action-small-icon {
                font-size: 12px;
                margin-left: 4px;
            }
        }
    }
}