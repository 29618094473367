.teamManagementContainer {
  // font-family: Nunito Sans;
  .containerTop {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background: #fff;
    a {
      font-size: 16px;
      font-weight: 400;
      text-decoration: none;
    }
  }
  .containerMiddle {
    display: flex;
    flex-direction: column;
    .filterOps {
      display: flex;
      align-items: center;
      margin: 16px;
      .selectionContainer {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        background-color: #fff;
        margin-right: 10px;
        cursor: pointer;
        img {
          margin-right: 6px;
          margin-left: 6px;
        }
      }
    }
    .listViewMember {
      display: flex;
      background: #fff;
      margin-bottom: 1px;
      flex-direction: column;
      overflow: auto;
      max-height: 600px;

      .scrollContainer {
        overflow: auto;
        height: calc(100vh - 220px);
        // font-family: Nunito Sans;
        .listviewContainer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 2px solid rgba(240, 242, 245, 1);
          .viewDetail {
            margin-right: 12px;
            font-weight: bold;
            font-size: 14px;
            line-height: 22px;
            color: var(--primary-theme-color);
            cursor: pointer;
            text-transform: none;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .disableView {
            @extend .viewDetail;
            color: #bfbfbf;
            pointer-events: none;
          }
        }
      }
      .listview {
        display: flex;
        cursor: pointer;
        padding: 16px;
        .list-img {
          height: 40px;
          width: 40px;
          margin-top: 3px;
          border-radius: 50%;
        }
        .listview-name-des {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          margin-left: 12px;
          .des {
            // font-family: Nunito Sans;
            font-size: 12px;
            line-height: 20px;
            color: #262626;
            .active-status {
              color: #a7df8d;
              font-weight: bold;
            }
            .inactive-status {
              @extend .active-status;
              color: var(--primary-theme-color);
            }
          }
          .name {
            // font-family: Nunito Sans;
            font-weight: bold;
            font-size: 14px;
            line-height: 22px;
            color: #262626;
          }
        }
      }
      .listview2 {
        @extend .listview;
        justify-content: center;
        align-content: center;
        border-bottom: 2px solid rgba(240, 242, 245, 1);
        .add-btn {
          height: 40px;
          background-color: var(--primary-theme-color);
          color: #fff;
          font-size: 14px;
          width: 358px;
          line-height: 22px;
          font-weight: bold;
          text-transform: none;
        }
      }
    }
  }
  @media only screen and (min-device-width: 320px) and (max-device-width: 1024px) {
    .containerTop {
      padding: 0;
    }
  }
}

.siderInnerContainer2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px 20px 20px;
  // font-family: Nunito Sans;
  .outerContainer {
    width: 100%;
  }
  .avataarImg {
    cursor: pointer;
    margin-bottom: 8px;
    border-radius: 50%;
    height: 80px;
    width: 80px;
  }
}
