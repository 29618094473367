@media only screen and (min-width: 320px) and (max-width: 786px) {
    .header {
        background: white;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 15px 0px;
        margin-left: -10px
    }
    .telecaller-dashboard-desktop{
        display: none;
    }
    .telecaller-dashboard-mobile{
        display: visible;
    }
}
@media only screen and (min-width: 787px) {
    .header {
        background: white;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 15px 20px;
        margin: 0px 20px
    }
    .telecaller-dashboard-desktop{
        display: visible;
        
    }
    .telecaller-dashboard-mobile{
        display: none;
    }
}