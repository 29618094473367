.otp-verification-container {
    margin-top: 32px;
    border: 1px solid #e7e9ec;
    border-radius: 4px;
}

.otp-form-container {
    margin: 16px;
}

.otp-input-section{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 6px;
    margin-top: 24px;
}

.otp-header{
    margin-top: 32px;
    text-align: start;
}

.cta-container {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 16px;
}

.button-styles-primary {
    border-radius: 8px !important;
    background-color: #ff702d !important;
    color: #fffcfb !important;
    font-weight: 600 !important;
}

.button-styles-secondary {
    border-radius: 8px !important;
    margin-top: 24px;
}

.ant-notification-notice-with-icon {
    display: flex;
}

.ant-notification-notice-message {
    text-align: start;
}

.otp-input{
    height: 50px;
    border: 1px solid #D9D9D9 !important;
    background-color: '#FFFFFF';
    border-radius: 10px !important;
    width: 50px !important;
    text-align: center;
    font-size: 24px;
}

.otp-input-popup{
    height: 32px;
    border: 1px solid #D9D9D9 !important;
    background-color: '#FFFFFF' !important;
    border-radius: 4px !important;
    width: 32px !important;
    text-align: center;
}
