.instructions-container {
    border: 1px solid #1890FF40;
    padding: 2em 2em 0em;
    text-align: start;
    border-radius: 12px;
    height: 100%;
    position: relative;

    .instruction-ai-filtering {
        width: 80%;
        margin-top: 20px;
    }

    .mobile-audio-section {
        display: none !important;
    }

    .client-inclusion-indicator {
        background: #1890FF1A;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px 24px;
        border-radius: 8px;
        position: absolute;
        bottom: 24px;
        width: 90%;
        flex-direction: column;
        
        .applicable-clients-container {
            display: flex;
            gap: 20px;
            align-items: center;
            margin-top: 16px;
        }
    }
}

.ai-step-action-fields {
    position: absolute !important;
    bottom: 32px;
    width: 95% !important;
    flex-direction: column;
    align-items: flex-end;
    background-color: #FFF;
}

.mobile-bottom-fixed {
    position: relative;
}



.audio-container {
    padding: 20px 0px;
    gap: 12px;
    width: 100%;
    // .samvaadini-audio-progress-bar {
    //     width: 100%;
    //     border-radius: 12px;
    //     outline: none;
    //     overflow: hidden;

    //     -webkit-appearance: none;
    //     appearance: none;
    //     background: transparent;
    //     cursor: pointer;
    // }

    // /***** Chrome, Safari, Opera, and Edge Chromium *****/
    // input[type="range"]::-webkit-slider-runnable-track {
    //     background: #2DB7F540;
    // }
  
    // /******** Firefox ********/
    // input[type="range"]::-moz-range-track {
    //     background: #2DB7F540;
    // }

    .play-pause-btn {
        border: 1px solid #1890FF;
        padding:4px 15px 4px 15px;
        display: flex;
        gap: 6px;
        align-items: center;
    }

    input[type='range'] {
        cursor: pointer;
        overflow: hidden;
        border-radius: 12px
      }
      
      @media screen{
        input[type='range'] {
          width: 100%;
          -webkit-appearance: none;
          background-color: #2DB7F540;
        }
        input[type='range']::-webkit-slider-runnable-track {
          height: 12px;
          -webkit-appearance: none;
          color: #13bba4;
          margin-top: -1px;
        }
        input[type='range']::-webkit-slider-thumb {
          width: 0px;
          -webkit-appearance: none;
          height: 12px;
          box-shadow: calc(-1 * 300px) 0 0 300px #1890FF;
        }
      }
}

.img-container-ai-br {
    display: flex;
    justify-content: center;
    border: 1px solid #1890FF40;
    padding: 2em 2em;
    text-align: start;
    border-radius: 12px;
    align-items: center;
    flex-direction: column;
}

.desktop-height-100 {
    height: 100%;
    overflow: scroll;
}

.main-container-ai-filter-step {
    padding-top: 16px;
    position: relative;
    gap: 16px;
    padding-bottom: 16px;
    overflow: scroll;
    margin-bottom: 60px;
    // border-bottom: 1px solid #0000000F

    .hero-image-ai-filtering {
        height:auto;
        width: 326px;
    }
}

.ai-filtering-next-btn {
    margin-top: 16px;
    width: 250px;
}

.instruction-header {
    font-family: var(--primary-font-family);
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    color: #2F54EB;
    margin-bottom: 16px;
}

.switch-container {
    padding: 18px 32px 21px 32px;
    gap: 0px;
    border-radius: 4px;
    border: 1px solid #1890FF40;
    display: flex;
    margin-top: 16px;
    justify-content: space-between;
    margin-bottom: 24px;

    background: linear-gradient(0deg, rgba(45, 183, 245, 0.1), rgba(45, 183, 245, 0.1));

    .ant-switch-checked {
        background: #2F54EB;
    }

}

.switch-header {
    font-family: var(--primary-font-family);
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;

    color: #2F54EB;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
    .img-container-ai-br {
        // display: none;
        flex-direction: column-reverse;
    }

    .ai-filtering-next-btn {
        margin-top: 16px;
        width: 100%;
    }

    .desktop-height-100 {
        height: auto;
    }
    
    .mobile-scroll {
        // padding-bottom: 100px;
        overflow: scroll;
    }

    .main-container-ai-filter-step {
        flex-direction: column-reverse;

        .hero-image-ai-filtering {
            height:auto;
            width: 154px;
        }
    }

    .instructions-container .instruction-ai-filtering {
        width: 100%;
        margin-top: 20px;
    }

    .instructions-container .mobile-audio-section {
        display: flex !important;
    }

    .mobile-bottom-fixed {
        position: absolute;
        width: 90% !important;
        bottom: var(--additional-info-screen-mob-padding-bottom) !important;
        background: #FFF;
        justify-content: center !important;
        height: 112px;
    }

    .instructions-container .client-inclusion-indicator {
        margin-top: 16px;
        margin-bottom: 16px;
        position: relative;
        bottom: 0px;
        width: 100%;
    }
}