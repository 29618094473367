.inputLabel {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 14px;
  margin-bottom: 15px;
  width: 100%;
  .requiredField {
    margin-right: 4px;
    color: rgba(255, 77, 79, 1);
  }
  .inputImg {
    width: 100%;
    .ant-input-group-addon {
      width: 100%;
    }
  }
  .inputText {
    width: 100%;
    padding: 2px 30px;
    margin-bottom: 24px;
  }
  label {
    margin-bottom: 8px;
  }
}
.inputSelect {
  // padding: 20px;
  padding-top: 0;
  padding-bottom: 0;
  .selectorCss {
    width: 200px;
    .option-select {
      display: flex;
    }
  }
}
.selection-value {
  border: none;
  background-color: transparent;
}
