.multi-script-container {
    margin: 24px 32px;
    gap: 40px;
}

.recommended-container {
    border-radius: 10px;
    background: #8B61E3;
    padding: 0px 18px;
    width: 85px;
}


.script-header {
    width: 100%;
}

.audio-main-container {
    border-radius: 10px;
    border: 1px solid #B7B7B7;
    width: 100%;
    padding: 12px 14px;
}

.width-100-percent {
    width: 100%;
}

.new-audio-bar {
    border-radius: 4px;
    background: #E3E3E3;
}

.audio-player-container {
    gap: 8px;
}

.audio-bar {
    width: 100%;
    height: 2px;
    border-radius: 4px;
    background: #E3E3E3;
    position: relative;
}

.track-fill {
    position: absolute;
    height: 100%;
    background: #4BDA58;
}

.track-pointer {
    height: 12px;
    width: 12px;
    background: #252526;
    position: absolute;
    border-radius: 10px;
    bottom: -5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pointer {
    height: 4px;
    width: 4px;
    background: white;
    border-radius: 10px;
}

.audio-sub-container > .ant-select {
    width: 50% !important;
}

.audio-sub-container > .ant-select > .ant-select-selector {
    background: #F7F7F7 !important;
}

.audio-card {
    width: 40%;
}

@media only screen and (min-width: 320px) and (max-width: 786px) {
    .multi-script-container {
        flex-direction: column;
        margin: 24px 24px;
    }

    .audio-card {
        width: 100%;
    }

    .script-image-section {
        height: 138px !important; 
    }

    .audio-script-header {
        font-size: 20px;
    }
}