.poster-modal-parent {
    .ant-modal-body {
        padding: 0;
    }

    .ant-modal-close-x {
        display: none;
    }

    .poster-modal-content {

        .pm-header {
            padding: 24px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);

            .pm-header-title {
                .pm-header-icon {
                    font-size: 20px;
                    margin-right: 8px;
                }

                .pm-header-txt {
                    color: var(--black-85, rgba(0, 0, 0, 0.85));
                    font-size: 20px;
                    font-weight: 600;
                }
            }

            .pm-header-close {
                padding: 8px;
                border-radius: 2px;
                border: 1px solid rgba(0, 0, 0, 0.15);
                background: #FFF;

                .pm-header-close-icon {
                    font-size: 20px;
                }
            }
        }

        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .pm-body {
                flex-direction: column-reverse;
                padding: 0px !important;
            }
        }

        .pm-body {
            padding: 24px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            @media only screen and (min-width: 320px) and (max-width: 786px) {
                .pm-left-container {
                    width: 100% !important;
                    margin-top: 1vh;
                    padding: 24px;
                }
            }

            .pm-left-container {
                width: 50%;

                @media only screen and (min-width: 320px) and (max-width: 786px) {
                    .pm-select-container {
                        margin-bottom: 1vh !important;
                    }
                }

                @media only screen and (min-width: 320px) and (max-width: 786px) {
                    .pm-share-steps {
                        margin-top: 3vh !important;
                        .pm-poster-created {
                            margin-bottom: 0.75em !important;
                        }
                    }
                }

                @media only screen and (min-width: 320px) and (max-width: 786px) {
                    .pm-download-btn-txt {
                        margin-left: 1vh !important;
                    }
                }

                .pm-share-steps {
                    .pm-share-steps-list {
                        .pm-share-steps-list-item {
                            color: #000;
                            font-family: "var(--primary-font-family)";
                            font-size: 14px;
                            font-weight: 600;
                            margin-top: 1vw;
                        }

                        .pm-share-step-orange-txt {
                            color: var(--primary-default, var(--primary-theme-color));
                            font-size: 14px;
                            font-weight: 600;
                        }
                    }
                    .pm-poster-created {
                        padding-left: 0.5rem;
                        margin-bottom: 1.5em;
                        .pm-green-icon {
                            font-size: 32px;
                            color: #4BCF3F;
                        }
                        .pm-poster-created-txt {
                            font-size: 16px;
                            margin-left: 10px;
                            color: '#000000A6';
                            font-weight: 600;
                        }
                    }
                }

                .pm-select-container {
                    margin-bottom: 1vw;

                    .pm-select-label {
                        width: 30%;
                    }

                    @media only screen and (min-width: 320px) and (max-width: 786px) {
                        .pm-select {
                            width: 70% !important;
                        }
                    }

                    .pm-select {
                        width: 60%;

                        .ant-select-selection-item {
                            display: flex;
                            justify-content: center;
                        }

                        .pm-contact-autocomplete {
                            text-align: center;

                            .ant-select-selection-search-input {
                                text-align: center;
                            }
                        }
                    }
                }

                .pm-view-hide-more-container {
                    .pm-view-hide-more {
                        margin-bottom: 1vw;

                        .pm-view-more-txt {
                            color: var(--primary-theme-color);
                            font-weight: 600;
                        }

                        .pm-view-more-icon {
                            color: var(--primary-theme-color);
                        }
                    }
                }

                @media only screen and (min-width: 320px) and (max-width: 786px) {
                    .pm-input-container {
                        margin-bottom: 1vh !important;
                    }
                }

                .pm-input-container {
                    margin-bottom: 1vw;

                    .pm-input-label {
                        width: 30%;
                    }

                    @media only screen and (min-width: 320px) and (max-width: 786px) {
                        .pm-input {
                            width: 70% !important;
                        }
                    }

                    .pm-input {
                        width: 60%;

                        .ant-input {
                            text-align: center;
                        }
                    }
                }

                .pm-logo-upload {
                    .ant-upload {
                        width: 90%;
                        display: flex;

                        .ant-upload {
                            width: 100%;
                        }
                    }

                    .ant-upload-list {
                        display: none;
                    }

                    .pm-logo-upload-btn {
                        border-radius: 2px;
                        border: 1px solid var(--primary-default, var(--primary-theme-color));
                        background: #FFF;
                        box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);

                        .pm-logo-upload-icon {
                            font-size: 14px;
                            color: var(--primary-theme-color);
                            margin-right: 8px;
                        }

                        .pm-logo-upload-txt {
                            color: var(--primary-default, var(--primary-theme-color));
                            text-align: center;
                            font-size: 14px;
                            font-weight: 600;
                        }
                    }
                }

                .pm-uploaded-logo-container {
                    width: 90%;

                    .pm-uploaded-logo {
                        border-radius: 2px;
                        border: 1px solid var(--primary-default, var(--primary-theme-color));
                        background: #FFF;
                        box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
                        padding: 4px 15px;
                        width: 88%;

                        .pm-uploaded-logo-icon {
                            font-size: 14px;
                            margin-right: 8px;
                        }

                        .pm-uploaded-logo-file-name-txt {
                            color: var(--primary-default, var(--primary-theme-color));
                            text-align: center;
                            font-size: 14px;
                            font-weight: 600;
                        }
                    }

                    .pm-uploaded-logo-delete {
                        border-radius: 2px;
                        border: 1px solid rgba(0, 0, 0, 0.15);
                        background: #FFF;
                        padding: 4px 15px;
                        width: 5%;

                        .pm-uploaded-logo-delete-icon {
                            font-size: 16px;
                        }
                    }
                }

                .pm-logo-instructions-txt {
                    width: 90%;
                    margin-top: 1vw;
                }

            }

            @media only screen and (min-width: 320px) and (max-width: 786px) {
                .pm-poster-img-section {
                    width: 100% !important;
                }
            }

            .pm-poster-img-section {
                width: 50%;
                position: relative;

                .pm-poster-img {
                    border: 1px solid rgba(0, 0, 0, 0.15);
                    border-radius: 4px;
                    width: 100%;
                    max-height: 50vh;
                }
            }
        }

        .pm-footer {
            padding: 24px;
            border-top: 1px solid rgba(0, 0, 0, 0.15);

            .ant-btn[disabled] {
                opacity: 0.5;
                /* Example: reduce opacity for disabled state */
                cursor: not-allowed;
                /* Example: change cursor for disabled state */
            }

            .pm-share-on-txt {
                color: var(--black-65, rgba(0, 0, 0, 0.65));
                text-align: center;
                font-size: 14px;
                font-weight: 600;
            }

            .pm-share-option-icon {
                margin-left: 16px;
                height: 32px;
                width: 32px;
            }

            .pm-share-download {
                border-radius: 50%;
                border: 1px solid var(--black-15, rgba(0, 0, 0, 0.15));
                padding: 8px;
            }

            .pm-download-btn {
                width: 50%;
                display: flex;
                padding: 4px 15px;
                justify-content: center;
                align-items: center;
                border-radius: 2px;
                border: 1px solid var(--primary-default, var(--primary-theme-color));
                box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);

                .pm-download-btn-txt {
                    color: var(--primary-default, var(--primary-theme-color));
                    text-align: center;
                    font-size: 14px;
                    font-weight: 600;
                    margin-left: 4px;
                }
            }

            .pm-share-btn {
                width: 50%;
                display: flex;
                padding: 4px 15px;
                justify-content: center;
                align-items: center;
                border-radius: 2px;
                border: 1px solid var(--primary-default, var(--primary-theme-color));
                background: var(--primary-default, var(--primary-theme-color));
                box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
                .ant-btn-loading-icon {
                    margin-top: -5px;
                }
                .pm-share-btn-txt {
                    color: #FFF;
                    text-align: center;
                    font-size: 14px;
                    font-weight: 600;
                    margin-left: 4px;
                }
            }

            .pm-orange-icon {
                color: var(--primary-theme-color);
            }

            .pm-white-icon {
                color: white;
            }
        }
    }
}