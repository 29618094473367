/* @font-face {
  font-family: "Noto Sans Light";
  src: url("./../fonts/NotoSans-ExtraLight.ttf");
} */
.data-table {
  text-align: center;
  border-collapse: collapse;
  /* border: 2px solid #FF712D; */
  border-radius: 5px;
  width: calc(70% + 50px);
}
.data-table-content {
  width: 100%;
  border-collapse: collapse;
}
.padding-class {
  padding-left: 20%;
}
.data-td,
.data-th {
  border: 2px solid #ff712d;
  padding: 8px;
}

.data-body .data-tr:nth-child(even) {
  background-color: #f2f2f2;
}
.data-th {
  padding: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  /* background-color: #4CAF50; */
}
button.viewLocations,
button.createViewWeblink {
  color: white;
  background: var(--primary-theme-color);
  padding: 8px 2px;
  width: 48%;
  margin-right: 2%;
  margin-top: 1rem;
  font-weight: 700;
}
button#full-size {
  width: 100% !important;
}
button.createViewWeblink {
  color: var(--primary-theme-color);
  background: var(--primary-theme-light);
  border: 1px solid var(--primary-theme-color);
  padding: 2px;
}
button.moreLocalities {
  color: var(--primary-theme-color);
  padding: 8px 16px;
  margin-top: 1rem;
  font-weight: 700;
  border: 2px solid var(--primary-theme-color);
  width: 95%;
  margin: 10px;
}
button.createWebLinks {
  color: white;
  background: var(--primary-theme-color);
  /* padding: 8px 16px; */
  /* margin-top: 1rem; */
  font-weight: 900;
  width: 92%;
  margin: 10px;
}
.jobs-page h4 {
  text-align: left;
  padding: 1rem 0;
  margin-left: 3%;
  font-size: 1.2rem;
  background: #ffffff;
  color: #151515;
  font-weight: 700;
}
.jobs-page {
  background: #ffffff;
  width: 100%;
}
.jobs-page .detail-element-body {
  display: inline-block;
  padding: 1rem;
  width: 50%;
}
.jobs-page .Dropdown-control {
  display: inline-flex !important;
  text-transform: none !important;
  letter-spacing: 0.5px;
  font-weight: 600 !important;
}
.jobs-page .allCities {
  color: var(--primary-theme-color);
  font-weight: 700;
  text-align: left;
  padding: 1.5rem;
  letter-spacing: 0.3px;
  /* background: #F0F2F5; */
}
.jobs-page h6 {
  font-weight: 700;
}
.jobs-page h6.content {
  font-size: 0.9rem;
  font-weight: 100;
}
.jobs-page .row .col {
  background: #fafafa;
  padding: 1.5rem;
}
.jobs-page .row {
  margin-right: 0 !important;
}
.jobs-page .cityHeading,
.jobs-page .row {
  font-weight: 100;
  font-size: 0.9rem;
}
.jobs-page .cityHeading b.bold,
.jobs-page .row b.bold {
  font-weight: 600;
  color: #525252;
}
div.form_control.job-demands-table {
  margin-bottom: 60px;
}
/* .jobs-page .tab {
  width: 100%;
} */
.header-text .requirements-title {
  font-weight: 100;
  color: #666565;
}
.jobs-page .header .header-search {
  display: flex;
  visibility: visible;
}
.jobs-page .salary-filter {
  margin: 1%;
  font-size: inherit;
}
.job-posting-sidesheet .detail-element-body {
  width: 100% !important;
  padding-top: 0 !important;
}
.job-posting-sidesheet .card-container .row {
  padding: 0 !important;
}
.job-posting-sidesheet .col-6 {
  text-align: right !important;
}
.job-posting-sidesheet .client-logo.col-6,
.job-posting-sidesheet .contact-details.col-6 {
  text-align: left !important;
}
.job-posting-sidesheet .content {
  font-weight: 600 !important;
  color: #595959 !important;
}
.whatsapp-phone-icon {
  width: 30px;
  height: 30px;
  padding: 5px 0px;
  border: 2px solid #f5703e;
  color: #f5703e;
  text-align: center;
  border-radius: 100px;
  background: #fff7f4;
  font-size: 15px;
  font-weight: 600;
}
button.shareLink {
  color: white;
  background: #52c41a;
  padding: 1rem;
  margin: 2rem 0.5rem 2rem 0;
  font-weight: 700;
}
.job-posting-sidesheet .col-6 .viewLocations {
  width: 10rem;
}
.jobs-page .jobPostingLink {
  text-align: left;
  padding: 1rem;
  margin: 2rem 0 2rem 2.5%;
  border: 2px solid #d8d8d8;
}
.jobs-page .input-fields .dropdown-control {
  width: 95%;
  padding: 0.8rem;
  margin-bottom: 1rem;
}
.create-popup-page h6.content {
  text-align: left;
  font-size: 0.9rem;
  font-weight: 100;
  margin-left: 3%;
}
.filter-pop-up-container .header {
  font-weight: 100;
}
.create-popup-page select {
  width: 90%;
  text-transform: capitalize;
}

.jobs-page .preview {
  width: 40%;
  margin: 0 auto;
  background: #f0f2f5;
  margin-bottom: 5rem;
}
.jobs-page .preview img.companyName {
  margin: 1%;
  width: 97%;
}
.jobs-page .preview img.poster {
  width: 90%;
  margin: 2%;
}
.jobs-page .header .job-post-title {
  color: var(--primary-theme-color);
  font-size: 1rem;
  font-weight: 700;
  padding-left: 5%;
}
.jobs-page .preview .jobPostingCityPoster {
  position: absolute;
  padding-top: 7.6%;
  padding-left: 5%;
  font-weight: bold;
  font-size: 22px;
}
.jobs-page .preview .jobPostingCityPosterRider {
  position: absolute;
  padding-top: 3.5%;
  font-size: 0.7rem;
  color: var(--primary-theme-color);
  font-weight: 600;
  padding-left: 26.4%;
  width: 200px;
}
.jobs-page .preview .salaryPoster {
  position: absolute;
  color: white;
  font-size: 2rem;
  padding-top: 15.5%;
  font-weight: 600;
  padding-left: 3.5%;
}

.jobs-page .preview .jobPostingCityPoster-leads {
  position: absolute;
  padding-top: 9.4%;
  padding-left: 6%;
  font-weight: bold;
  font-size: 22px;
}
.jobs-page .preview .jobPostingCityPosterRider-leads {
  position: absolute;
  padding-top: 3.7%;
  font-size: 0.7rem;
  color: var(--primary-theme-color);
  font-weight: 600;
  padding-left: 31.4%;
  width: 200px;
}
.jobs-page .preview .salaryPoster-leads {
  position: absolute;
  color: white;
  font-size: 2rem;
  padding-top: 17.5%;
  font-weight: 600;
  padding-left: 3.5%;
}

.jobs-page .error {
  color: red;
  font-weight: bold;
  text-align: left;
  padding-left: 3%;
}
@media screen and (max-width: 500px) {
  .padding-class {
    padding-left: 5%;
  }
  .jobs-page .detail-element-body {
    width: 100%;
  }
  .jobs-section.active-tab {
    border-bottom: 2px solid var(--primary-theme-color) !important;
    color: var(--primary-theme-color) !important;
  }
  .jobs-page h4 {
    font-size: 1rem;
  }
  .header-text .requirements-title {
    font-weight: 700;
  }
  button.viewLocations,
  button.createViewWeblink {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 680px) {
  .jobs-page .preview {
    width: 100%;
  }
  .jobs-page .preview img.companyName {
    margin: 3%;
  }
  .jobs-page .preview .jobPostingCityPoster {
    position: absolute;
    padding-top: 21.8%;
    padding-left: 14%;
    font-weight: 600;
    font-size: 22px;
  }
  .jobs-page .preview .salaryPoster {
    position: absolute;
    color: white;
    font-size: 2rem;
    padding-top: 45%;
    font-weight: 600;
    padding-left: 9%;
  }

  .jobs-page .preview .jobPostingCityPosterRider {
    position: absolute;
    padding-top: 10%;
    font-size: 0.7rem;
    color: var(--primary-theme-color);
    font-weight: 600;
    padding-left: 77%;
    width: 207px;
  }

  .jobs-page .preview .jobPostingCityPoster-leads {
    position: absolute;
    padding-top: 21.8%;
    padding-left: 14%;
    font-weight: 600;
    font-size: 22px;
  }
  .jobs-page .preview .salaryPoster-leads {
    position: absolute;
    color: white;
    font-size: 2rem;
    padding-top: 45%;
    font-weight: 600;
    padding-left: 9%;
  }

  .jobs-page .preview .jobPostingCityPosterRider-leads {
    position: absolute;
    padding-top: 10%;
    font-size: 0.7rem;
    color: var(--primary-theme-color);
    font-weight: 600;
    padding-left: 77%;
    width: 207px;
  }

  .jobs-page .salary-filter {
    width: 25% !important;
    min-width: 25% !important ;
  }
}

.fieldsContainer {
  position: sticky;
  height: 200px;
  overflow-y: auto;
}
