.bg_gray {
  padding: 15px;
  background-color: lightgray;
  position: relative;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.5s;
}

.bg_gray:hover {
  background-color: #ff712d;
}

.AccountPage {
  overflow-x: hidden;
  .ant-page-header-back {
    display: none;
  }
  .containerTop {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background: #fff;
    margin-bottom: 8px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }
  .containerMiddle {
    display: flex;
    padding: 16px;
    background: #fff;
    margin-bottom: 1px;
    .listview {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      cursor: pointer;
      .list-img {
        height: 20px;
        width: 20px;
        margin-top: 4px;
        color: var(--primary-theme-color);
      }
      .listview-name-des {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        margin-left: 12px;
        .des {
          font-size: 12px;
          line-height: 20px;
          color: #262626;
          text-align: start;
        }
        .name {
          font-weight: bold;
          font-size: 14px;
          line-height: 22px;
          color: #262626;
          display: flex;
        }
      }
    }
  }
  .containerBottom {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    color: #262626;
    span {
      cursor: pointer;
    }
    span:last-of-type {
      margin-top: 24px;
    }
  }
}
.MoreMenuPage {
  display: none;
}

.AccountPage,
.row {
  /* padding: 10px; */
  /* margin-left: auto; */
  /* text-align: center; */
}

.siderInnerContainer1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 20px 20px 20px;
  .titleLabel {
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    color: #000000;
    margin-bottom: 15px;
    span {
      color: var(--primary-theme-color);
    }
  }
  .uploadedBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--page-bg-color);
    width: 100%;
    padding: 10px;
    img {
      margin-right: 12px;
    }
  }
  .userInfoWrapper {
    .userInfoContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      label {
        font-size: 14px;
        line-height: 22px;
        color: #8c8c8c;
      }
      div {
        font-size: 14px;
        line-height: 22px;
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }
  .outerContainer {
    width: 100%;
  }
  .paraTag {
    text-align: left;
    word-break: break-word;
    font-size: 12px;
    margin-top: 15px;
    span {
      color: #ff712d;
    }
  }
  .drag_drop {
    // background-image: '/image/Drag_Drop.svg';
    height: 120px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .formio-component-button {
    margin: 0;
  }
}

.AccoutLogout {
  display: none;
}

@media screen and (max-width: 1023px) {
  .AccoutLogout {
    display: block;
  }
}
@media screen and (min-width: 320px) and (max-width: 786px) {
  .AccountPage {
    .containerBottom {
      .mobileViewBottomBtn {
        display: none;
      }
    }
    .ant-page-header-back {
      display: block;
    }
  }
  .MoreMenuPage {
    display: block;
    overflow: scroll;
    margin-bottom: 90px;
    // height: calc(100vh - 150px);
    .scrollable-section {
      overflow: scroll;
      height: calc(100vh - 150px);
    }
    .newTag-banner {
      margin-left: 8px;
      background-color: var(--primary-theme-color);
      color: #ffffff;
      font-size: 12px;
    }
  }
}
