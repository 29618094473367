.main-container {
    background-color: #FFF;
}
.new-item-badge {
    height: 15px;
    transform: rotate(45deg);
    position: absolute;
    z-index: 99;
    right: -30px;
    top: -2px;
}
.upload-file-container {
    margin-top: 6px;

    .header-info {
        display: flex;
        align-items: flex-start;
        gap: 6px;
        margin: 12px 24px 0px;

        font-weight: 400;
        font-size: 14px;
        color: rgba(0,0,0,.45);

        text-align: start;
    }

    .button-container {
        display: flex;
        justify-content: center;
    }

    .file-upload-button {
        background-color: var(--primary-theme-color);
        color: #FFF;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 250px;
        font-weight: 700;
    }

    .template-header-text{
        font-weight: 400;
        font-size: 14px;
        color: #d48806;
        margin-top: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .template-download-button {
        background-color: #FFF;
        color: #D34B18;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        border: 1px solid #D34B18;
        border: none;
        text-decoration: underline;
        padding-left: 8px;
        text-underline-offset: 4px;
    }

    .mt-8{
        margin-top: 8px;
    }
}

.file-upload-button {
    background-color: var(--primary-theme-color) !important;
    color: #FFF !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 250px;
    font-weight: 700;
}

.samvadini-upload-header {
    background-color: #f1f1f1;
    margin: 0 24px;
    display: flex;

    font-weight: 600;
    font-size: 16px;
    border-radius: 8px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
}

.mt-16{
    margin-top: 16px;
}

.mt-12{
    margin-top: 12px;
}

.throughput-selection-container {
    margin: 12px 24px 0;
    display: flex;
}
.throughput-header {
    color: #000;
    display: flex;
    margin-bottom: 8px;
    margin-right: 12px;
}

.tags {
    display: flex;
    margin-top: 8px;
    gap: 8px;
    flex-wrap: wrap;

    .individual-tag {
      border: 1px solid #D9D9D9;
      background-color: #FFF;
      padding: 2px 12px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 400;
      color: #000000D9;
    }

    .active-tag {
      color: #D34612;
      border: 1px solid #D34612;
    }
  }

.throughput-details-container, .client-details-container {
    margin: 12px 24px 0;
}

.leads-input-container {
    display: flex;
    align-items: center;
    gap: 8px;
}

.mt-40 {
    margin-top: 40px;
}

.card-list-container {
    margin: 16px 24px 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .ant-card{
        border-radius: 8px;
    }

    .upper-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .ant-card-body {
        padding: 12px;
    }
}

.left-section {
    display: flex;
    flex-direction: column;
    flex: 2
}

.right-section {
    // flex: 1
}

.file-name{
    margin-top: 6px;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, .45);
    gap: 4px;
}

.details-label {
    font-weight: 600;
    font-size: 14px;
}

.input-width {
    width: 60% !important;
}

.file-upload-bottom-container {
    margin-top: 12px;
    display: flex;
    justify-content: flex-end;
    margin-right: 24px;

    .prim-button {
        width: 300px;
    }
    
}

.desktop-view {
    display: block;

    .no-border-highlight {
        background-color: #FFF;
        color: #D34B18;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        border: 1px solid #D34B18;
        border: none;
        // text-decoration: underline;
        padding-left: 8px;
        text-underline-offset: 4px;
        margin-left: 24px;
        margin-bottom: 12px;
    }
    
}

.instructions-popover{
    .ant-popover-title{
        font-weight: 600;
    }
}

.sessionlist-filter-popup {
    .ant-popover-content {
        min-width: 350px;
        max-width: 350px;
    }
}


.ant-popover {
    display: flex;
    justify-content: center;
}

.ant-popover-hidden {
    display: none !important;
}

.mobile-view {
    display: none;
}

.form-container-samvadini {
    display: flex;
    justify-content: space-between;
    margin: 0px 24px;
    gap: 8px;
}

.individual-field-container {
    display: flex;
    flex: 1;
    gap: 4px;
    align-items: center;
}

.list-style {
    font-size: 14px;
    line-height: 24px;
}

.text-align-start {
    text-align: start;
}

.samvadini-modal {
    .ant-modal-content {
        background-color: #fafafa;
    }
    .ant-modal-footer {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        background: #FFF;
    }
    .ant-modal-header{
        text-align: start !important;
    }
}

.uploaded-file-details {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-size: 1.2rem;
}

.remove-file-icon {
    cursor: pointer;
    display: flex;
}

.mt-24 {
    margin-top: 24px;
}

.orange-dot {
    height: 5px;
    width: 5px;
    background: #0000005c;
    border-radius: 100%;
}

.card-body-styles {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    display: flex;
}

.contact-button {
    display: flex;
    align-items: center;
}

.card-body-mobile-view {
    display: none !important;
}

.card-body-desktop-view {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
}

.border-bottom-white {
    border-bottom: 2px solid #fff;
}

.blue-container {
    background-color: #e6f7ff;
    color: #21457c;
    text-align: left;
    padding: 12px;
    display: flex;
    align-items: center;
    gap: 12px;
    border-radius: 12px;
    font-weight: 600;
}

.primary-theme-container {
    background-color: #D34B16;
    color: #FFF;
    text-align: left;
    padding: 12px;
    display: flex;
    align-items: center;
    gap: 12px;
    border-radius: 12px;
    font-weight: 600;
    margin-bottom: 12px;
}

.more-clients {
    background-color: #FFF;
    color: rgba(0, 0, 0, 0.55);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #D34B18;
    border: none;
    text-decoration: underline;
    text-underline-offset: 4px;
    cursor: pointer;
}

@media only screen and (min-width: 320px) and (max-width: 786px) {
    .main-container {
        padding-bottom: 80px;
    }

    .upload-file-container {
        .header-info {
            margin-left: 16px;
            margin-right: 16px;
        }

        .template-header-text {
            flex-direction: column;
        }
    }

    .samvadini-upload-header, .throughput-selection-container, .throughput-details-container {
        margin-left: 16px;
        margin-right: 16px;
    }

    .throughput-selection-container, .leads-input-container {
        flex-direction: column;
    }

    .leads-input-container {
        align-items: flex-start;
        gap: 0px;
    }

    .input-width {
        width: 100% !important;
    }
    
    .throughput-details-container {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-top: 0;
    }

    .samvadini-upload-header {
        padding: 12px;
    }

    .card-list-container {
        margin: 16px 16px 16px;
    }

    .main-container {
        .header{
            padding: 0px;
            border-bottom: 1px solid #f1f1f1;
            margin-bottom: 16px;
        }

        .ant-page-header {
            padding: 8px 24px !important;
        }

        .ant-page-header-heading-title  {
            font-size: 16px;
        }
    }

    .file-upload-bottom-container {
        margin-top: 12px;
        justify-content: center;
        align-items: center;
        width: 100%;
        .prim-button {
            width: 90%;
        }
        
    }

    .desktop-view{
        display: none !important;
    }

    .mobile-view {
        display: block;
        margin: 8px 12px;
    }

    .form-container-samvadini {
        flex-wrap: wrap;
    }

    .individual-field-container {
        flex: auto;
    }

    .field-label, .input-width {
        flex: 1;
    }

    .card-body-styles {
        flex-direction: row;
        // align-items: flex-start !important;
        gap: 12px;
        justify-content: space-between;
    }

    .flex-col-mobile {
        flex-direction: column;
    }

    .card-body-desktop-view {
        display: none !important;
    }

    .card-body-mobile-view {
        display: flex !important;
        flex-direction: column;
        line-height: 24px;

        .contact-button-mobile {
            border-radius: 50%;
            padding: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #D34B18 !important;
            color: #FFF !important;
        }
    }
    
  }