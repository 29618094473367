.background-overlay-container {
    position: absolute;
    top: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 13;
}

.mobile-menu-main-container {
    position: fixed;
    width: 85%;
    height: 100%;
    left: 0;
    background-color: #ffffff;
    color: #000;
    animation: menuanimation 0.7s;
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.mobile-menu-top-branding {
    height: 70px;
    display: flex;
    background: #FFF;
    z-index: 14;
    position: relative;
    box-shadow: 0px 0px 4px 0px rgba(41, 91, 209, 0.20), 0px 0px 16px 0px rgba(41, 91, 209, 0.08);

    display: flex;
    align-items: center;
    padding-left: 16px;
    gap: 16px;
}

.mobile-menu-main-container > .desktop-sidebar-main-container {
    display: inherit;
    width: 100%;
    padding-bottom: 60px;
}

@keyframes menuanimation {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0%);
    }
}