 .call-feedback-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.ant-notification-notice-icon {
  display: flex;
  padding-top: 2px;
}

.ant-notification-notice-with-icon {
  display: block;
  font-size: 15px;
}

.ant-notification-notice-with-icon .ant-notification-notice-message{
  font-family: Inter;
  font-size: 14px !important;
  font-weight: 400;
  line-height: 20px;
}

.ant-notification-notice-message {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  font-size: 15px;
  white-space: break-spaces;
}
.ant-notification-notice-description {
  display: flex;
  justify-content: flex-end;
  padding: 15px;
}

.call-status-main-container {
    padding-bottom: 80px;
    max-height: 90vh;
    padding: 6px;
}

.contact-information {
    font-family: var(--primary-font-family);
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 22px;
}

.call-metadata {
    font-family: var(--primary-font-family);
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 22px;
}

.contact-name {
    font-family: var(--primary-font-family);
font-style: normal;
font-weight: 700;
font-size: 30px;
line-height: 38px;
}

.feedback-header {
    font-family: var(--primary-font-family);
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 24px;
}

.call-feedback {
    padding-top: 6px;
}

.radio-text {
    font-family: var(--primary-font-family);
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 22px;
}

.tag-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 20px;
    flex: 1 0 21%;
    .tag-wrapper-container-connected, .tag-wrapper-container-not-connected {
      flex: 1 0 21%;
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      .tags {
        height: 22px;
        box-sizing: border-box;
        border-radius: 2px;
        cursor: pointer;
        margin: 5px;
        margin-left: 0;
        svg {
          color: #52C41A;
          margin-bottom: 4px;
        }
      }
      .tag-active {
        color: var(--primary-theme-color);
        background: #F6FFED;
        border: 1px solid #52C41A;
      }
    }
    .tag-wrapper-container-not-connected {
      .tags {
        svg {
          color: #F5222D;
          margin-bottom: 4px;
        }
      }
      .tag-active {
        color: #F5222D;
        background: var(--primary-theme-light);
        border: 1px solid #F5222D;
      }
    }
  }

  .radio-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
  }

  .custom-submit-button {
    cursor: pointer;
    width: 358px;
    height: 38px;
    background: #F5222D;
    color: #FFFFFF;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 2px;
  }

  .new-call-notes-main-container {
    display: flex;
    flex-direction: column;
    margin-left: 24px;
    width: 90%;
    margin-top: 16px;

    .call-status-container {
      display: flex;

      .individual-call-status:nth-child(1) {
        border-radius: 2px 0px 0px 2px;
      }

      .individual-call-status:nth-child(2) {
        border-radius: 0px 2px 2px 0px;
      }
      
      .individual-call-status {
        border: 1px solid #D9D9D9;
        background-color: #FFF;
        padding: 4px 12px;
        text-transform: uppercase;
        font-size: 12px;
        cursor: pointer;
        font-weight: 400;
      }

      .connected-active {
        border: 1px solid #4BCF3F;
        color: #4BCF3F;
        background-color: #FFF;
        font-weight: 700;

        display: flex;
        align-items: center;
        gap: 4px;
      }

      .not-connected-active {
        border: 1px solid #FF4D4F;
        color: #FF4D4F;
        background-color: #FFF;
        font-weight: 700;

        display: flex;
        align-items: center;
        gap: 4px;
      }
    }

    .call-tags-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .tag-label {
        font-weight: 400;
        font-size: 13px;
        color: #00000073
      }

      .tags {
        display: flex;
        margin-top: 8px;
        gap: 8px;
        flex-wrap: wrap;

        .individual-tag {
          border: 1px solid #D9D9D9;
          background-color: #FFF;
          padding: 2px 12px;
          cursor: pointer;
          font-size: 14px;
          font-weight: 400;
          color: #000000D9;
        }

        .active-tag {
          color: var(--primary-theme-color);
          border: 1px solid var(--primary-theme-color);
        }
      }

    }

    .call-back-comment-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 48px;
    }
  }

  .bottom-section {
    position: absolute;
    bottom: 22px;
    width: 90%;
    
    .button-container {
      display: flex;
      justify-content: flex-end;
      margin-top: 0px;
    }
    .add-note-button {
      background-color: var(--primary-theme-color);
      color: #FFF;
      padding: 4px 15px 4px 15px;
      width: 250px;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    .disable-submission {
      opacity: .4;
    }
  }

  @media screen and (min-width: 320px) and (max-width: 786px) {

    .call-status-main-container {
            padding-bottom: 25%;
            height: 80vh;
    }

    .call-feedback {
      padding-bottom: 80px;
    }

    .new-call-notes-main-container {
      width: auto;
      margin: 0 16px;
    }

    .bottom-section {
      .button-container {
        justify-content: center;
      }

      .add-note-button {
        width: 100%;
      }
    }

  }
