.create-team-modal-parent {
    height: 60%;
    overflow: scroll;

    .ant-modal-content {
        height: 100%;
    }

    .ant-divider-horizontal {
        margin: 0px !important;
    }

    .ant-modal-body {
        padding: 0;
    }

    .ant-modal-close-x {
        display: none !important;
    }

    .ant-divider {
        margin: 0;
    }

    .ct-modal-content {
        height: 100%;

        .ctm-header {
            padding: 12px;
            // border-bottom: 1px solid rgba(0, 0, 0, 0.15);

            .ctm-header-txt {
                color: var(--light-theme-neutrals-neutral-1, rgba(0, 0, 0, 0.85));
                font-size: 20px;
                font-weight: 600;
                line-height: 28px;
            }


            .ctm-header-title {
                .ctm-comp-logo {
                    height: 40px;
                    width: 40px;
                    margin-right: 8px;
                    object-fit: contain;
                }

                .ctm-header-icon {
                    font-size: 20px;
                    margin-right: 8px;
                }
            }

            .ctm-header-close {
                padding: 8px;
                border-radius: 2px;
                border: 1px solid rgba(0, 0, 0, 0.15);
                background: #FFF;

                .ctm-header-close-icon {
                    font-size: 20px;
                }
            }
        }

        .ctm-body {
            height: 36vh;
            overflow: scroll;
            padding: 12px;

            @media only screen and (min-width: 320px) and (max-width: 786px) {
                .ctm-name-input-container {
                    margin-left: 0% !important;
                    width: 100% !important;
                    flex-direction: column;
                }
            }

            @media only screen and (min-width: 320px) and (max-width: 786px) {
                .ctm-field-label {
                    margin: 1em 0 1em 0 !important;
                }
            }

            @media only screen and (min-width: 320px) and (max-width: 786px) {
                .ctm-field-label {
                    text-align: left;
                    width: 100% !important;
                }
            }

            .ctm-field {
                margin-top: 12px;
            }

            .ctm-field-label {
                text-align: left;
                width: 20%;
            }

            .ctm-name-input-container {
                width: 100%;

                @media only screen and (min-width: 320px) and (max-width: 786px) {
                    .ctm-name-input-parent{
                        flex-direction: column;
                    }
                }

                .ctm-name-input {
                    width: 80%;
                    max-height: 35px;
                    // margin-bottom: 8px;
                }

                .ctm-team-name-error {
                    margin-left: 20%;
                    margin-top: 4px;
                }

                @media only screen and (min-width: 320px) and (max-width: 786px) {
                    .ctm-name-input {
                        margin-left: 0 !important;
                        width: 100% !important;
                    }
                }
            }


            @media only screen and (min-width: 320px) and (max-width: 786px) {
                .ctm-dropdown-container {
                    margin-left: 0% !important;
                    width: 100% !important;
                    flex-direction: column;
                }
            }

            .ctm-tc-dropdown .ant-select-selection-item {
                display: none;
                /* Hide selected items */
            }

            .ctm-tc-dropdown .ant-select-selection-placeholder {
                opacity: 1 !important;
                /* Always show placeholder */
            }

            .ctm-tc-dropdown .ant-select-selection-overflow {
                flex-wrap: nowrap;
                /* Prevent wrapping */
            }

            .ctm-tc-dropdown .ant-select-selection-overflow-item {
                display: none;
                /* Hide the selected items from view */
            }

            .ctm-selected-tc-list-parent {


                @media only screen and (min-width: 320px) and (max-width: 786px) {
                    .ctm-selected-tc-list {
                        margin-left: 0% !important;
                        width: 100% !important;
                    }
                }

                .ctm-selected-tc-list {
                    width: 80%;
                    margin-top: 8px;
                    flex-wrap: wrap;
                    gap: 8px;
                }

                .ctm-selected-tc {
                    border-radius: 2px;
                    border: 1px solid var(--light-theme-neutrals-neutral-5, rgba(0, 0, 0, 0.15));
                    background: var(--light-theme-white, #FFF);
                    margin-right: 8px;
                    padding: 2px 4px 2px 8px;
                }

                .ctm-selected-tc-icon {
                    font-size: 10px;
                    margin-left: 4px;
                }
            }

            .ctm-dropdown-container {
                width: 100%;

                .ant-select-selection-item {
                    display: flex;
                    align-items: center;
                }

                .ctm-dropdown {
                    width: 80%;
                    // margin-bottom: 8px;
                }

                @media only screen and (min-width: 320px) and (max-width: 786px) {
                    .ctm-dropdown {
                        margin-left: 0 !important;
                        width: 100% !important;
                    }
                }

                .ctm-dropdown {
                    max-height: 35px;
                    width: 80%;
                }
            }
        }


        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .rpc-footer {
                padding: 2vh 2vh !important;
                position: fixed !important;
                bottom: 8vh !important;
            }
        }

        .ctm-footer {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 15%;
            background-color: #FFF;
            z-index: 10;

            .ctm-footer-btns-container {
                padding: 1% 2%;

                .ctm-footer-create-btn {
                    width: 40%;
                    max-height: 40px;
                }

                .ctm-footer-create-btn:disabled {
                    opacity: 0.4;
                }
            }

            .ctm-tc-txt {
                color: var(--primary-theme-light, var(--primary-theme-color));
                font-size: 16px;
                font-weight: 700;
                text-decoration-line: underline;
            }

        }
    }
}

.ant-select-item-option-state {
    margin-left: 8px;
    display: flex;
    align-items: center;
}