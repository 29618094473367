.bulk-actions-listing-parent-mobile {
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    margin: 24px 0px;

    .ba-above-divider-mobile {
        width: 100%;
        padding: 16px 10px;

        .ba-uploaded-on-img {
            font-size: 16px;
        }

        .ba-uploaded-on-txt {
            font-weight: 400;
            font-size: 16px;
        }

        .ba-uploaded-on-date {}

        .ba-uploaded-on-time {}

        .ba-card-txt {
            margin-left: 8px;
            font-weight: 500;
            font-size: 16px;
        }

        .ba-card-icon-margin-mobile {
            margin-left: 8px;
        }

        .ba-file-name-txt {
            margin-left: 4px;
            font-weight: 400;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.45);
        }

        .ba-file-link {
            margin-top: 12px;
            .ba-upload-txt-parent {
                padding: 1px 8px;
                background-color: var(--primary-theme-color);
                border-radius: 3px;
                margin: 0px 10px 0px 0px;
                .ba-upload-txt {
                    color: #FFFFFF;
                    font-size: 12px;
                    font-weight: 700;
                }
            }
            .ba-file-link-img {
                color: rgba(0, 0, 0, 0.45);
            }
        }

        .ba-process-status-parent {
            margin-top: 12px;
            gap: 24px;

            .ba-process-status-txt {
                margin-left: 4px;
            }

            .ba-processing-txt {
                font-size: 12px;
                font-weight: 400;
                margin-left: 8px;
                color: rgba(0, 0, 0, 0.45);
            }

            .ba-process-status-completed {
                color: rgba(49, 200, 36, 1);
                font-weight: 400;
                font-size: 16px;
            }

            .ba-process-status-processing {
                color: rgba(212, 136, 6, 1);
                font-weight: 400;
                font-size: 16px;
            }
        }
    }

    .ba-card-divider {
        margin: 0px 0px !important;
    }

    .ba-below-divider {
        .ba-below-divider-left {
            padding: 16px 10px;
            width: 100%;

            .ba-company-img-parent {
                margin-right: 8px;

                .ba-company-img {
                    height: 32px;
                    width: 32px;
                    border-radius: 4px;
                    object-fit: contain;
                }
            }

            .ba-delivery-txt {
                font-weight: 600;
                font-size: 16px;
            }

            .ba-location-img {
                height: 12px;
                width: 12px;
                color: rgba(0, 0, 0, 0.45);
                margin-right: 8px;
            }

            .ba-location-txt-parent {
                margin-top: 12px;

                .ba-location-txt {
                    font-weight: 400;
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.45);
                }
            }

            .ba-round-circle {
                height: 4px;
                width: 4px;
                border-radius: 2px;
                background-color: rgba(0, 0, 0, 0.45);
                margin: 0px 8px;
            }

            .ba-area-txt {}
        }

        .ba-vertical-divider {
            height: 20vh;
            background-color: rgba(0, 0, 0, 0.06);
            width: 1px;
        }

        .ba-below-divider-right {
            width: 100%;
            padding: 16px 10px;

            .ba-number-of-leads {
                font-weight: 600;
                font-size: 16px;
            }

            .ba-leads-breakdown-parent {
                margin-top: 12px;

                .ba-leads-processing {
                    background-color: rgba(212, 136, 6, 1);
                    height: 6px;
                }

                .ba-leads-unique {
                    background-color: rgba(49, 200, 36, 1);
                    height: 6px;
                }

                .ba-leads-non-unique {
                    background-color: rgba(0, 0, 0, 0.25);
                    height: 6px
                }

                .ba-leads-error {
                    background-color: rgba(255, 77, 79, 1);
                    height: 6px;
                }
                .ba-upload-leads-error {
                    background-color: #FF712D;
                    height: 6px;
                }

                .ba-leads-breakdown-border-left {
                    border-top-left-radius: 12px;
                    border-bottom-left-radius: 12px
                }

                .ba-leads-breakdown-border-right {
                    border-top-right-radius: 12px;
                    border-bottom-right-radius: 12px
                }

                .ba-leads-processing-container {
                    background-color: rgba(212, 136, 6, 1);
                    height: 6px;
                    border-top-right-radius: 12px;
                    border-bottom-right-radius: 12px;
                    border-top-left-radius: 12px;
                    border-bottom-left-radius: 12px;
                    width: 100%;
                }
            }

            .ba-leads-breakdown-chips-parent { 
                overflow: scroll;
                margin-top: 12px;

                .ba-leads-breakdown-chip {
                    padding: 4px 8px;
                    border: 1px solid rgba(0, 0, 0, 0.06);
                    border-radius: 4px;
                    margin: 0px 10px 0px 0px;
                }

                .ba-leads-breakdown-processing-circle {
                    height: 10px;
                    width: 10px;
                    border-radius: 5px;
                    background-color: rgba(212, 136, 6, 1);
                }

                .ba-leads-breakdown-unique-circle {
                    height: 10px;
                    width: 10px;
                    border-radius: 5px;
                    background-color: rgba(49, 200, 36, 1);
                }

                .ba-leads-breakdown-non-unique-circle {
                    height: 10px;
                    width: 10px;
                    border-radius: 5px;
                    background-color: rgba(0, 0, 0, 0.25);
                }

                .ba-leads-breakdown-errors-circle {
                    height: 10px;
                    width: 10px;
                    border-radius: 5px;
                    background-color: rgba(255, 77, 79, 1);
                }

                .ba-chip-txt-parent {
                    margin-left: 8px;
                }

                .ba-chip-txt {
                    color: rgba(0, 0, 0, 0.45);
                    font-size: 14px;
                    font-weight: 400;
                }

                .ba-chip-value-txt {
                    color: rgba(0, 0, 0, 0.65);
                    font-size: 14px;
                    font-weight: 400;
                    margin-left: 8px;
                }
            }
        }
    }

    .ba-below-second-divider {
        display: flex;
        padding: 24px;
        flex-direction: column;
        gap: 8px;

        .secondary-btn-styles {
            background: #fff !important;
            border: 1px solid var(--primary-theme-color);
        }

        .secondary-btn-text {
            color: var(--primary-theme-color) !important;
        }

        .margin-left-12 {
            margin-left: 12px;
        }

        .ba-download-candidate-status-btn {
            background: var(--primary-theme-color);
            width: 100%;
            justify-content: center;
            border-radius: 2px;
            padding: 4px;
        }

        .ba-download-candidate-status-btn-img {
            color: white;
            margin-right: 12px;
        }

        .ba-download-candidate-status-btn-txt {
            color: white;
        }

        .ba-validation-error-cta {
            color: var(--primary-theme-color);
            border: 1px solid var(--primary-theme-color);
            background-color: #FFF;
            margin-right: 12px;
            justify-content: center;
            border-radius: 2px;
            display: flex;
            gap: 9px;
            align-items: center;
            padding: 4px 16px;
            width: 100%;
        }
    }
}