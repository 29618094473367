.share-popup-comtainer {
  display: flex;
  flex-direction: column;
  // font-family: "Nunito Sans", sans-serif;
  font-weight: bold;
  .stepper-modal {
    padding: 20px;
    padding-left: 45px;
  }

  .share-popup-header {
    display: flex;
    flex-direction: row;
    padding: 24px 16px;
  }

  .share-header-text {
    width: 90%;
    text-align: left;
    /* padding-left: 10px; */
  }

  .share-cross {
    text-align: right;
    width: 10%;
    /* padding-right: 10px; */
  }

  .platform-container {
    display: flex;
    flex-direction: row;
  }

  .share-element {
    width: 25%;
    display: flex;
    flex-direction: column;
  }

  .share-element a {
    text-decoration: none;
    color: black;
  }

  a:hover,
  .hover-blue:hover {
    color: #0d6efd;
  }
  .sharing-modal {
    width: 200px;
  }
}

/* .hover-blue:hover{
    color: #0d6efd;
} */
