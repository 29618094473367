.new-call-note-main-container {
    background-color: #FDFAF9;
    padding: 24px;
    height: 60vh;
    overflow: scroll;
}

.custom-scroll-bar::-webkit-scrollbar {
    width: 12px; /* Width of the scrollbar */
  }
  
.custom-scroll-bar::-webkit-scrollbar-thumb {
    background-color: #5E8AF5; /* Scrollbar color */
    border-radius: 10px; /* Rounded corners */
    border: 2px solid #f1f1f1; /* Padding around the scrollbar */
}

.call-note-text-area-vp {
    /* marginTop: '24px', background: '#F6F6F6', borderRadius: '8px',border: 'none' */
    margin-top: 24px !important;
    background-color: #f6f6f6 !important;
    border-radius: 8px !important;
    border: none !important;
}

.call-connection-type-tab-container {
    border-radius: 8px;
    border: 1px solid #E5ECFF;
    background: #FFF;
    padding-bottom: 32px;
    padding: 24px;
}

.current-active-connection {
    font-family: Inter;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 600;
    border-bottom: 3px solid #1D5FFF;
}

.single-connection {
    color: #AFAFAF;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    cursor: pointer;
}

.active-connected {
    color: #4EBB57;
    border-bottom: 3px solid #4EBB57;
}

.active-not-connected {
    color: #FF4545;
    border-bottom: 3px solid #FF4545;
}

.call-tags-v2 {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 16px;
}

.call-tags-v2 > .individual-tag {
    border-radius: 4px;
    border: 1px solid #AFCFFF;
    padding: 8px 12px;
    display: flex;
    cursor: pointer;
    user-select: none;
}

.call-tags-v2 > .active-tag {
    background: #E5ECFF;
    border: none;
}

.dsktp-alignment {
    align-items: center;
}


@media screen and (max-width: 800px) {
    .new-call-note-main-container {
        padding: 12px;
        height: calc(100vh - 128px);
    }

    .call-connection-type-tab-container {
        flex-direction: column;
        padding: 16px;
    }

    .dsktp-alignment {
        align-items: flex-start;

        .filter-search-box {
            padding-left: 0px !important;
            margin-left: 0px !important;
            width: 100% !important;
        }
    }

    .ant-modal {
        top: 0px !important;
        max-width: 100% !important;
        margin: 0px !important;
        height: 100% !important;
    }
}