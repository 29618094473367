.team-section-parent {
    @media only screen and (min-width: 320px) and (max-width: 786px) {
        .ts-header-container {
            flex-direction: column !important;
            align-items: flex-start !important;
        }
    }

    .ts-header-container {

        .ts-header-left {
            flex-direction: column;

            .ts-header-icon {
                font-size: 16px;
                margin-right: 8px;
            }

            @media only screen and (min-width: 320px) and (max-width: 786px) {
                .ts-header-sub-txt {
                    max-width: 100% !important;
                }
            }

            .ts-header-sub-txt {
                text-align: left;
            }
        }

        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .ts-header-right {
                margin-top: 1vh;
                justify-content: space-between !important;
                width: 100% !important;
            }
        }

        .ts-header-right {
            .ts-header-right-btn {
                margin-right: 16px;
                min-width: 10vw;
            }

            .ts-header-add-member-btn {
                min-width: 10vw;
            }

            .ts-header-add-member-btn-icon {
                font-size: 16px;
                color: #FFF;
            }

            .ts-header-add-member-btn-txt {
                color: #FFF;
            }
        }
    }


    @media only screen and (min-width: 320px) and (max-width: 786px) {
        .ts-team-cards-container {
            margin-top: 2vh !important;
            justify-content: space-between !important;
        }
    }

    .ts-team-cards-container {
        margin-top: 2vw;
        flex-wrap: wrap;
        gap: 16px;


        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .ts-team-card {
                flex: 1 1 calc(50% - 16px);
                min-width: 45% !important;
                max-width: 45% !important;
            }
        }

        .ts-team-card-vertical-divider-container {
            width: 24%;
            height: 100%;
        }

        .ts-team-card-vertical-divider {
            width: 2px;
            height: 88px;
            background-color: rgba(0, 0, 0, 0.15);
            height: 100%;
        }

        .ts-team-card-selected {
            border: 1px solid var(--primary-theme-light, var(--primary-theme-color));
            background: #FFF7F4;
        }

        .ts-team-card-unselected {
            background-color: white;
            border: 1px solid var(--light-theme-neutrals-neutral-5, rgba(0, 0, 0, 0.15));
        }

        .ts-team-card {
            min-width: 18%;
            max-width: 18%;
        }

        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .ts-team-card-margin {
                margin-right: 0% !important;
            }
        }

        .ts-team-card-margin {
            margin-right: 1%;
        }

        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .ts-team-card-content {
                width: 100% !important;
            }
        }

        .ts-team-card-content {
            border-radius: 4px;
            padding: 12px 24px;
            width: 90%;
            height: 100%;

            .ts-team-card-count-label {
                flex-direction: column;

                .ts-team-card-count {
                    text-align: left;
                }

                .ts-team-card-txt {
                    text-align: left;
                    margin-top: 8px;
                }
            }

            .ts-team-card-icon {
                font-size: 16px;
            }

            .ts-team-card-icon-selected {
                color: var(--primary-theme-color);
            }

            .ts-team-card-icon-unselected {
                color: var(--primary-theme-color);
                opacity: 0.5;
            }
        }

        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .ts-team-card-margin-right {
                margin-right: 1vh !important;
            }
        }

        .ts-team-card-margin-right {
            margin-right: 1vw;
        }
    }

    .ts-team-list {

        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .ts-list-actions-container {
                margin-top: 2vh !important;
            }
        }

        .ts-list-actions-container {
            margin-top: 2vw;

            @media only screen and (min-width: 320px) and (max-width: 786px) {
                .ts-list-actions {
                    width: 100% !important;
                    flex-direction: column;
                    align-items: flex-start !important;
                }
            }

            .ts-list-actions {
                width: 78%;
            }

            @media only screen and (min-width: 320px) and (max-width: 786px) {
                .ts-sort-actions {
                    width: 100% !important;
                }
            }

            .ts-sort-actions {
                width: 55%;

                .ts-sort-action-item {
                    .ts-sort-action-item-label {
                        margin-right: 8px;
                    }
                }

                .ts-sort-action-item-role {
                    margin-left: 8px;
                }

                .ts-sort-action-icon {
                    font-size: 14px;
                    margin-right: 4px;
                }

                .ts-sort-action-small-icon {
                    font-size: 12px;
                    margin-left: 8px;
                }
            }

            @media only screen and (min-width: 320px) and (max-width: 786px) {
                .ts-member-search {
                    margin-left: 0 !important;
                    margin-top: 5%;
                }
            }

            .ts-member-search {
                margin-left: 2%;
                width: 48%;

                @media only screen and (min-width: 320px) and (max-width: 786px) {
                    .ts-member-search-inp {
                        width: 100% !important;
                    }
                }

                .ts-member-search-inp {
                    width: 55%;

                    .ant-input-search-button {
                        background: transparent;
                        color: rgba(0, 0, 0, 0.45);
                        border: 1px solid #d9d9d9;
                        padding: 4px 10px;
                    }
                }
            }
        }

        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .ts-team-list-container {
                margin-bottom: 125px;
            }
        }


        .ts-empty-list {
            padding: 4vw;
        }
    }
}

.team-list-parent {
    @media only screen and (min-width: 320px) and (max-width: 786px) {
        .team-list-container {
            margin: 2vh 0 !important;
        }
    }

    .tl-disabled-list-item {
        opacity: 0.4;
    }

    .team-list-container {
        border-radius: 12px;
        border: 1px solid var(--light-theme-neutrals-neutral-5, rgba(0, 0, 0, 0.15));
        background: var(--light-theme-white, #FFF);
        margin: 1vw 0;
        padding: 12px 20px;

        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .tr-tl-left-middle {
                flex-direction: column;
            }
        }

        .tr-tl-left-middle {
            width: 50%;

            @media only screen and (min-width: 320px) and (max-width: 786px) {
                .tr-tl-left {
                    width: 100%;
                }
            }

            @media only screen and (min-width: 320px) and (max-width: 786px) {
                .tr-tl-middle {
                    width: 75%;
                }
            }
        }

        .tl-left-mobile {
            width: 75% !important;
        }

        .tl-left-parent {
            width: 95% !important;

            .tl-left {
                width: 30%;

                .tl-member-profile-pic {
                    .tl-member-profile-pic-image {
                        height: 35px;
                        width: 35px;
                        border-radius: 32px;
                        border: 1px solid var(--primary-theme-light, var(--primary-theme-color));
                        object-fit: contain;
                    }
                }

                .tl-member-name-icon-admin {
                    height: 35px;
                    width: 35px;
                    border-radius: 32px;
                    padding: 6px 8px 6px 7px;
                    color: var(--primary-theme-color);
                    border: 1px solid var(--primary-theme-light, var(--primary-theme-color));
                    background: rgba(211, 70, 18, 0.05);
                }

                .tl-member-name-icon-tl {
                    height: 35px;
                    width: 35px;
                    border-radius: 32px;
                    padding: 6px 8px 6px 7px;
                    color: rgba(24, 144, 255, 1);
                    border: 1px solid var(--light-theme-secondary-blue-2, #1890FF);
                    background-color: rgba(24, 144, 255, 0.05);
                }

                .tl-member-name-icon-tc {
                    height: 35px;
                    width: 35px;
                    border-radius: 32px;
                    padding: 6px 8px 6px 7px;
                    color: rgba(75, 207, 63, 1);
                    border: 1px solid var(--light-theme-semantic-positive, #4BCF3F);
                    background-color: rgba(75, 207, 63, 0.05);
                }

                @media only screen and (min-width: 320px) and (max-width: 786px) {
                    .tr-tl-name {
                        margin-left: 0px !important;
                    }
                }

                .tl-name {
                    text-align: left;
                    margin-left: 8px;

                    // Adds an ellipsis after 2 lines (if browser supports) or after 1 line (if browser doesn't support)
                    @supports (-webkit-line-clamp: 2) {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: initial;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                }
            }
        }

        .tl-middle-mobile {
            min-width: 100% !important;
            max-width: 100% !important;
            margin-top: 1vh;
            justify-content: flex-start !important;
        }

        .tl-middle {
            min-width: 30%;
            max-width: 30%;

            .tl-role-tag {
                min-width: 20%;
                max-width: 20%;
            }

            @media only screen and (min-width: 320px) and (max-width: 786px) {
                .tl-role-tag {
                    min-width: 25% !important;
                    max-width: 25% !important;
                }
            }

            .tl-admin-bg {
                color: var(--primary-theme-color);
                border-radius: 4px;
                padding: 2px 4px;
                border: 1px solid var(--primary-theme-light, var(--primary-theme-color));
                background: rgba(211, 70, 18, 0.05);
            }

            .tl-team-lead-bg {
                color: rgba(24, 144, 255, 1);
                padding: 2px 4px;
                border-radius: 4px;
                border: 1px solid var(--light-theme-secondary-blue-2, #1890FF);
                background-color: rgba(24, 144, 255, 0.05);
            }

            .tl-team-member-bg {
                color: rgba(75, 207, 63, 1);
                padding: 2px 4px;
                border-radius: 4px;
                border: 1px solid var(--light-theme-semantic-positive, #4BCF3F);
                background-color: rgba(75, 207, 63, 0.05);
            }

            .tl-name-txt {
                margin-left: 8px;
            }

            .tc-count-txt {
                margin-left: 8px;
            }

            @media only screen and (min-width: 320px) and (max-width: 786px) {
                .tl-member-team {
                    margin-left: 2vh !important;
                    width: 75% !important;
                }
            }

            .tl-member-team {
                width: 30%;
                text-align: left;
                margin-left: 2vw;
            }

            @media only screen and (min-width: 320px) and (max-width: 786px) {
                .tl-member-status {
                    margin-left: 2vh !important;
                }
            }

            .tl-member-status {
                width: 25%;
                text-align: left;
                margin-left: 2vw;
            }
        }

        .tl-right {
            width: 50%;

            .tl-right-icon {
                margin-left: 8px;
            }
        }
    }
}