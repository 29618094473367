.session-list-container {
    background: none;
    padding: 0px;
}

.sticky-filter-and-pagination {
    position: sticky;
    top: -32px;
    background: #FDFAF9;
    z-index: 1;
    padding-bottom: 24px;
}

.session-type-filter {
    width: 150px;
    border-radius: 20px;
    border: 1px solid #000;
    padding: 4px 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}