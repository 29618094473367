.UploadDocs {
  padding: 10px;
  overflow-x: hidden;
  margin-bottom: 40px;
}

.UploadDocs h1 {
  color: rgb(63, 123, 235);
}

.UploadDocs .warning {
  display: inline-block;
  color: rgb(173, 40, 40);
  padding: 5px 15px;
  border-radius: 2px;
}

.UploadDocs .formio-component-button {
  /* font-family: 'Noto Sans Medium'; */
  background-color: inherit;
  text-transform: uppercase;
  font-size: 0.8rem;
  padding: 30px 0px;
  margin: 0px;
  text-align: center;
}

.UploadDocs .btn-primary,
.UploadDocs .btn-primary.disabled,
.UploadDocs .btn-primary:disabled {
  background-color: #ff712d !important;
  border-color: #ff712d !important;
}

.UploadDocs .formio-component {
  text-align: center;
}

.UploadDocs label {
  display: block;
  text-align: start;
  font-weight: bold;
}

.UploadDocs .row img {
  width: 300px !important;
  max-height: 200px;
}
