.job-demand-card-parent {
    max-width: 47%;
    border-radius: 12px;
    padding: 1.5vw;
    min-width: 47%;
    margin-bottom: 2vw;
    border: 1px solid var(--black-15, rgba(0, 0, 0, 0.15));
    background: #FFF;

    .jdc-header {
        margin-bottom: 0.75vw;

        .jdc-company-logo {
            width: 40px;
            height: 40px;
            object-fit: contain;
            border-radius: 4px;
        }

        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .jdc-company-name-type {
                margin-left: 1.5vh !important;
            }
        }

        .jdc-company-name-type {
            flex-direction: column;
            align-items: flex-start;
            margin-left: 1vw;

            @media only screen and (min-width: 320px) and (max-width: 786px) {
                .jdc-job-type-txt {
                    margin-top: 1vw !important;
                }
            }

            .jdc-job-type-txt {
                color: var(--black-45, rgba(0, 0, 0, 0.45));
                font-size: 14px;
                font-weight: 400;
                margin-top: 1vh;
            }

            .jdc-company-name-txt {
                color: rgba(0, 0, 0, 0.85);
                text-align: center;
                font-size: 18px;
                font-weight: 600;
                text-transform: capitalize;
                font-family: var(--primary-font-family);
            }


            @media only screen and (min-width: 320px) and (max-width: 786px) {
                .jdc-recommended-tag {
                    margin-left: 1vh !important;
                }
            }

            .jdc-recommended-tag {
                background-color: #1890FF;
                margin-left: 0.75vw;
                padding: 0px 4px;
                border-radius: 2px;

                .jdc-recommended-icon {
                    color: #FFFFFF;
                    font-size: 12px
                }

                .jdc-recommended-txt {
                    color: #FFF;
                    font-size: 12px;
                    font-weight: 700;
                    margin-left: 4px;
                }
            }
        }

        .jdc-pin-icon {
            font-size: 24px;
            color: var(--primary-theme-color);
            margin-right: 12px;
        }

        .jdc-menu-container {
            border-radius: 2px;
            border: 1px solid rgba(0, 0, 0, 0.15);
            background: #FFF;
            padding: 4px;
        }
    }

    .jdc-mid-section {
        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .jdc-mid-section-top {
                margin-top: 1vh !important;
                margin-bottom: 2vh !important;
            }
        }

        .jdc-mid-section-top {
            margin-top: 1vw;

            .jdc-mid-section-top-txt-light {
                color: var(--black-65, rgba(0, 0, 0, 0.65));
                font-size: 14px;
                font-weight: 400;
            }

            .jdc-mid-section-top-txt-bold {
                color: var(--black-85, rgba(0, 0, 0, 0.85));
                font-size: 14px;
                font-weight: 600;
            }

            .jdc-city-localities-icon {
                margin: 0 0.5vh 0 0.75vh;
                color: rgba(0, 0, 0, 0.45);
            }
        }

        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .jdc-mid-section-mid {
                margin-top: 1vh !important;
            }
        }

        .jdc-mid-section-mid {
            justify-content: flex-start;
            margin-top: 1vw;

            .jdc-mid-section-mid-header {
                color: var(--black-65, rgba(0, 0, 0, 0.65));
                font-size: 14px;
                font-weight: 700;
                text-align: start;
                margin-bottom: 6px;
            }

            .jdc-mid-section-mid-left {
                flex-direction: column;
                width: 47%;

                .jdc-mid-section-mid-requirements {
                    .jdc-mid-section-mid-requirements-txt {
                        color: var(--black-65, rgba(0, 0, 0, 0.65));
                        font-size: 14px;
                        font-weight: 400;
                        text-align: start;
                    }
                }
            }

            @media only screen and (min-width: 320px) and (max-width: 786px) {
                .jdc-vertical-divider {
                    margin: 0 1.5vh !important;
                }
            }


            .jdc-vertical-divider {
                // min-height: 50px;
                width: 1px;
                background-color: rgba(0, 0, 0, 0.06);
                margin: 0 1vw;
            }


            .jdc-mid-section-mid-right {
                width: 47%;

                .jdc-mid-section-mid-salary {
                    text-align: start;
                }
            }
        }
    }

    @media only screen and (min-width: 320px) and (max-width: 786px) {
        .jdc-bottom-section {
            margin-top: 4vh !important;
        }
    }

    .jdc-bottom-section {
        margin-top: 1.5vw;

        .jdc-view-pitch-btn {
            border-radius: 2px;
            border: 1px solid var(--primary-default, var(--primary-theme-color));
            background: var(--Neutral-1, #FFF);
            box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
            width: 49%;

            .jdc-view-pitch-btn-img {
                color: var(--primary-theme-color);
            }

            .jdc-view-pitch-btn-txt {
                color: var(--primary-theme-color);
                margin-left: 8px;
            }
        }

        .jdc-view-details-btn {
            border-radius: 2px;
            background: var(--primary-default, var(--primary-theme-color));
            box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
            width: 49%;

            .jdc-view-details-btn-txt {
                color: #FFF;
            }

            .jdc-view-details-btn-img {
                color: #FFF;
                margin-left: 8px;
            }
        }
    }
}

@media only screen and (min-width: 320px) and (max-width: 786px) {
    .job-demand-card-parent {
        max-width: 100%;
        background: white;
        border-radius: 12px;
        padding: 1.5vh;
        min-width: 100%;
        margin-bottom: 4vw;
    }
}

.job-demand-card-right-margin {
    margin-right: 2vw;
}