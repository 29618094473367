.navbar-container {
    display: flex;
    justify-content: space-between;
    margin: 0 32px;
    height: 80px;
    align-items: center;
}

.navbar-right-container, 
.user-info, 
.navbar-container, 
.user-name-role, 
.advertisement-and-product-demo, 
.advertisement-and-product-demo-container,
.demo-container {
    display: flex;
}

.color-252526 {
    color: #252526;
}

.user-name-role {
    flex-direction: column;
}

.samvaadini-search-candidate  {
    width: 500px !important;
    height: 43px !important;
}

.user-info {
    align-items: center;
}

.user-name-role {
    margin-left: 8px;
    text-align: start;

    font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
}

.navbar-right-container {
    align-items: center;
    gap: 24px;
}

.hero-text {
    /* margin: 0px 0px 32px; */
    color: #000;
    font-family: Unbounded;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    text-align: start;
}

.show-ad {
   margin: 0px 0px 32px; 
}

.placement-text {
    color: #252526;
    text-align: start;

font-family: Inter;
font-size: 36px;
font-style: normal;
font-weight: 500;
line-height: 47px;
}

.learn-more-text {
    color: #015DEE;

font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;

display: flex;
    align-items: center;
    gap: 8px;
}

.highlighted-text {
    color: #252526;
    font-family: Unbounded;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 47px;
}

.advertisement-and-product-demo-container {
    justify-content: space-between;
    margin: 8px 32px 16px;
}

.no-ad-container {
    display: flex;
    justify-content: space-between;
    margin: 4px 32px 16px;
    align-items: center;
}

.placement-btn {
    border-radius: 60px !important;
    background: #56E263 !important;
    width: 240px;
    padding: 12px !important;
    height: auto !important;
}

.placement-btn-text {
    color: #FFF;

    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 18px;
}

.image-btn-container {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.advertisement-and-product-demo {
    flex-direction: column;
}

.more-placement {
    color: #A2A0A0;
    text-align: start;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-top: 8px;
}

.demo-container {
    margin-top: 24px;
    gap: 16px;
    align-items: flex-end;
}

.new-btn {
    border-radius: 10px !important;
    background: #1D5FFF !important;
}

.demo-btn {
    padding: 0px 56px !important;
    border: 2px solid #030F2C !important;
}

.font-inter-white {
    font-family: Inter;
    color: #FFF;
}

.demo-video-text {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 47px;
}

.custom-divider {
    display: flex;
    clear: both;
    width: 80% !important;
    min-width: 10% !important;
    margin: 0px 32px 0 !important;
    background: #e2e2e2 !important;
    color: #e2e2e2 !important;
    height: 1px;
}

.session-creation-options-container  {
    margin: 18px 32px 42px;
    display: flex;
    flex-direction: column;
}

.session-creation-header {
    border-radius: 40px;
    background: #F8DDCB;
    padding: 0px 10px;
    color: #252526;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    display: flex;
    width: -moz-fit-content;
    width: 159px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.session-creation-options {
    display: flex;
    margin-top: 16px;
    gap: 24px;
    flex-wrap: wrap;
    padding-bottom: 32px;
}

.single-option {
    border-radius: 10px;
    border: 1px solid #AFCFFF;

    background: #F9F9F9;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    padding: 24px 24px 24px 32px;
    height: 320px;
    width: 303px;
    box-shadow: none;

    transition: box-shadow 0.3s ease-in-out,  border 0.3s ease-in-out;
}

.single-option:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border: none;
}

.highlighted-option {
    /* box-shadow: 0px 9px 2px -4px rgba(0, 0, 0, 1), 0px 0px 6px -6px rgba(0, 0, 0, 1); */
    border-radius: 10px;
    background: #4E4E4E;
    display: flex;
    flex-direction: column;
    height: 320px;
    width: 303px;
    position: relative;
    z-index: 1;
}

.highlighted-portion {
    height: 6px;
}

.option-img  {
    border-radius: 8px;
    border: 1px solid #9D9D9D;
    height: 130px;
    width: 248px;
}

.option-header {
    color: #252526;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    text-align: start;
}

.option-sub-header {
    color: #838383;
    margin-top: 4px;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-align: start;
    max-width: 180px;
}

.hero-img-2 {
    height: 54px;
    width: 54px;
    object-fit: contain;
    border-radius: 100%;
}

.option-btn  {
    border: 1px solid #030F2C !important;
    height: 44px !important;
    padding: 6px 66px !important;
    margin-top: 16px;
    width: 242px;
}

.option-btn > button {
    border-radius: 8px !important;
    background-color: #C8C9CC !important;
    border: none;
}

.option-btn-text {
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}

.session-details-entrypoint-container {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    margin: 24px 32px;
}

.session-tab-container {
    display: flex;
    gap: 16px;
    align-items: center;
}

.single-tab {
    cursor: pointer;
    padding: 5.5px 29px;
    border-radius: 20px;
    border: 1px solid #A6A6A6;

    background: #E8E9EB;

    color: #AFAFAF;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;

    display: flex;
    align-items: center;
    gap: 10px;
    height: 22px;
}

.active-tab {
    border: 1px solid #81DA36;
    background: #EDFFDE;

    color: #0F1E30;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    height: 29px;
}

.active-tab-blue {
    border: 1px solid #405EA5;
    background: #8BAAF6;
    color: #F8FAFF;
}

.active-dot {
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background-color: #A4EA69;
}

.session-details-container {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    align-items: center;
    flex-wrap: wrap;
    gap: 24px;

    border: 1px solid #dcd6d6;
    padding: 22px 26px;
    border-radius: 10px;

    background: #fff;
}

.left-details-section {
    display: flex;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;
}

.field-container {
    display: flex;
    flex-direction: column;
}

.details-header {
    text-align: start;
    color: #000;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
}

.details-sub-header {
    color: #838383;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-align: start;
    margin-top: 8px;
    line-height: 18px;
}

.view-btn {
    border-radius: 10px !important;
    border: 2px solid #030F2C !important;
    background: #1D5FFF !important;
    padding: 6px 66px !important;
    color: #FFF !important;
    font-family: Inter;
    font-size: 16px !important;
    /* font-style: normal; */
    /* line-height: 47px; */
    font-weight: 400 !important;
    height: 44px !important;
    width: 169px;
}

.no-active-session-header {
    color: #000;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px;
    margin-top: 24px;
}

.no-active-sub-header {
    color: #A2A0A0;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    width: 60%;
    text-align: center;
}

.start-session-indication::after {
    content: 'Click below to start one now.'
}

.no-active-sessions-container {
    display: flex;
    align-items: center;
}

.no-active-session-desc {
    display: flex;
    flex-direction: column;
    /* margin-left: 16px; */
    margin-top: 24px;
    margin-bottom: 60px;
    align-items: center;
    width: 100%;
}

.ad-image-and-hide-option-container {
    margin-right: 24px;
}

.option-flag {
    padding: 0px 18px;
    border-radius: 10px;
    height: 16px;
    font-family: Inter;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    color: #fff;
}

.option-header-container {
    justify-content: space-between;
    display: flex;
    margin-top: 30px;
    align-items: center;
}

.hero-img-show-ad {
    height:235px;width:235px;object-fit:contain
}

.tab-section-image {
    height:103px;width: 189px; object-fit:contain
}

.floater-container {
    display: none !important;
}

.mobile-no-session {
    display: none;
}

.shadow-highlighter {
    box-shadow: 6px 6px #AFCFFF;
}

@media only screen and (min-width: 320px) and (max-width: 786px) {
    .hero-img-show-ad {
        height:68px;width:82px;object-fit:contain;
        margin-top: 128px;
    }

    .mobile-no-session {
        width: 185px;
        object-fit: contain;
        display: flex;
    }

    .hero-text {
        font-size: 16px;margin: 0px;
    }

    .placement-text {
        text-align: start;
    }

    .more-placement {
        display: none;
    }

    .demo-btn {
        width: 175px;
        height: 36px !important;
        padding: 0px !important;
    }

    .demo-video-text {
        line-height: normal;
        font-size: 14px;
    }

    .ad-image-and-hide-option-container {
        margin-right: 0px;
        position: relative;
    }

    .advertisement-and-product-demo-container {
        margin: 0px 24px 16px;
    }

    .close-ad {
        font-size: 20px !important;
        cursor: pointer;
        position: absolute;
        top: 64px;
        right: 0px;
    }

    .learn-more-text {
        font-size: 12px;
    }

    .mob-bottom-margin {
        margin-bottom: 24px;
    }

    .active-tab {
        font-size: 14px !important;
        height: 32px !important;
    }

    .single-tab {
        font-size: 12px;
        height: 24px;
        padding: 5.5px 16px;
    }

    .session-creation-options-container {
        display: none;
    }

    .session-details-entrypoint-container {
        margin: 24px;
    }

    .session-details-container {
        flex-direction: column;
    }

    .left-details-section {
        flex-wrap: wrap;
        justify-content: center;
    }

    .tab-section-image {
        height: auto;
        width: 100%;
        object-fit: contain;
        flex: 1;
        margin-bottom: 20px;
    }

    .field-container {
        width: 46%;
    }

    .right-button-section {
        margin-top: 24px;
        width: 100%;
    }

    .view-btn {
        width: 100% !important;
    }

    .no-ad-container {
        flex-wrap: wrap;
        /* flex-direction: column-reverse; */
        gap: 24px;
        margin: 4px 24px 16px;
    }

    .placement-btn-text {
        font-size: 14px;
    }

    .placement-btn {
        border-radius: 60px !important;
        background: #56E263 !important;
        width: 144px;
        padding: 12px !important;
        height: auto !important;
    }

    .image-btn-container {
        align-self: flex-end;
    }

    .hero-img-2 {
        display: none;
    }

    .gray-main-container {
        padding-bottom: 86px;
    }

    .floater-container {
        position: fixed;
        bottom: 90px;
        right: 16px;
        z-index: 1000;
        display: flex !important;
    }

    .floater-container-btn {
        z-index: 99;
        width: 78px;
        height: 78px;
        background: #1D5FFF;
        border-radius: 50%;
        display: flex !important;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 4px 28px 6px rgba(0,0,0,0.2);
        transition: transform 0.3s ease-in-out;
    }

    .floater-container-btn-disabled {
        border: 1px #4D4D4D;
        background: #C8C9CC;
    }

    .floater-container-btn.clicked {
        animation: grow-shrink 0.3s forwards;
    }

    @keyframes grow-shrink {
        0% {
          transform: scale(1);
        }
        50% {
          transform: scale(1.1); /* Increase size by 20% */
        }
        100% {
          transform: scale(1);
        }
    }

    .plus-icon {
        transition: transform 0.2s ease-in-out;
      }
      
    .plus-icon.rotate {
        transform: rotate(45deg);
    }

    .menu {
        position: absolute;
        bottom: 0px;
        right: 11px;
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-end;
        transition: opacity 0.3s ease, transform 0.3s ease;
        opacity: 0;
        transform: translateY(20px);
        gap: 16px;
    }

    .menu.show {
        opacity: 1;
        transform: translateY(0);
        width: max-content;
    }

    .menu.show .single-menu-container {
        opacity: 1;
        transform: scale(1);
      }
      
      .menu.hide .single-menu-container {
        opacity: 0;
        transform: scale(0);
      }
      
    .menu-item {
        background-color: #fff;
        color: #333;
        height: 40px;
        width: 40px;
        margin: 5px 0;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }

    .blue-bg {
        background: #1D5FFF;
        height: 56px;
        width: 56px;
    }

    .right-margin {
        margin-right: 9px;
    }

    .backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.92);
        z-index: 999;
        opacity: 0;
        transition: opacity 0.3s ease;
    }
      
    .backdrop.show {
        opacity: 1;
    }

    .single-menu-container {
        display: flex;
        align-items: center;
        gap: 12px;
        transform: scale(0);
    }

    .no-active-session-desc { 
        margin-top: 80px;
    }

    .start-session-indication::after {
        content: 'Click plus button to start one now.'
    }

    .no-active-sessions-container {
        padding-bottom: 80px;
    }
}