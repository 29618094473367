.samvaadini-search-candidate-new {
    border-radius: 8px !important;
    background: #F6F6F6 !important;
    width: 80% !important;
    padding: 10px !important;
}

.samvaadini-search-candidate-new>.ant-input {
    background: #F6F6F6 !important;
}

.step-header-info-container {
    margin: 24px 32px 0px;
}

.telecaller-selection-container {
    .ant-checkbox-wrapper {
        margin: 0px !important;
    }
}

.telecaller-selection-container {
    .ant-checkbox-wrapper {
        .ant-checkbox {
            .ant-checkbox-inner {
                border-radius: 4px !important;
                border: 2px solid #D7D7D8 !important;
                background: #D7D7D8 !important;
            }
        }
    }
}

.telecaller-selection-container {
    .ant-checkbox-wrapper-checked {
        .ant-checkbox>.ant-checkbox-inner {
            border-radius: 4px !important;
            border: 2px solid #1D5FFF !important;
            background: #1D5FFF !important;
        }
    }
}

@media only screen and (min-width: 320px) and (max-width: 786px) {
    .samvaadini-search-candidate-new {
        width: 100% !important;
    }
}

.telecaller-selection-container {
    padding: 24px;
    background: #fff;
    border-radius: 10px;

    @media only screen and (min-width: 320px) and (max-width: 786px) {
        .select-tc-parent {
            grid-template-columns: repeat(1, 1fr) !important;
        }
    }

    .select-tc-parent {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 16px;
        // flex-wrap: wrap;
        // justify-content: space-between;

        @media (max-width: 600px) {
            .tc-card-parent {
                flex-basis: 100% !important;
                /* 1 box per row for mobile screens */
            }
        }

        .tc-card-parent {
            flex-basis: calc(33.33% - 10px);
            margin: 0 12px 12px 0;
            border-radius: 8px;
            border: 1px solid #BEBEBE;
            background: #F8F8FA;
            padding: 16px 12px;
            text-align: center;
            box-sizing: border-box;

            .tc-card-img {
                min-width: 15%;
                max-width: 15%;

                .tc-card-img-val {}
            }

            .tc-card-details {
                min-width: 60%;
                max-width: 60%;

                .tc-card-icon {
                    margin-right: 4px;
                    font-size: 9px;
                }

                .tc-card-city {
                    margin-left: 12px;
                }
            }

            .tc-card-checkbox {
                min-width: 25%;
                max-width: 25%;

            }
        }
    }
}