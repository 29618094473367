@media only screen and (min-device-width: 320px) and (max-device-width: 1023px) {
  .bread-crumb-desktop {
    display: none;
  }
  .pageheader-dropdown {
    font-family: var(--primary-font-family);
        font-weight: 700;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    svg {
      color: var(--primary-theme-color);
    }
  }
  .pageheader-title {
    display: flex;
    justify-content: space-between;
    background: #fff;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    .ant-page-header-heading-extra {
      display: flex;
      align-items: center;
    }
  }
}
@media only screen and (min-device-width: 1024px) {
  .pageheader {
    display: none;
  }
  .pageheader-dropdown {
    display: none !important;
  }
  .bread-crumb-desktop {
    display: flex;
    //padding-left: 20px;
    .ant-breadcrumb {
      padding: 10px 0;
      font-size: 14px;
      font-weight: 600;
      font-family: var(--primary-font-family);
      display: flex;
      align-items: center;
      color: #000000D9;
      a {
        font-size: 14px;
      }
    }
  }
  .ant-page-header-heading {
    // .ant-page-header-back {
    //   display: none;
    // }
    .ant-page-header-heading-extra {
      display: flex;
    }
  }
}
