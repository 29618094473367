.vahan-pro-component-container {
    background: linear-gradient(0deg, #FDFAF9 0%, #FDFAF9 100%), #FFF;
    height: var(--additional-info-screen-height);
    width: 100%;
    width: 100%;
    overflow: scroll;
    padding-bottom: 80px;
}

.hero-header {
    padding: 48px 24px 0px;
    display: flex;
}

.views-container {
    border-bottom: 1px solid #E8E9EB;
    display: flex;
    align-items: center;
    padding: 40px 32px 0px;
}

.child-timeline-items-container > .ant-timeline > .ant-timeline-item > .ant-timeline-item-head {
     background-color: transparent !important;
}

.views {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
}

.single-view {
    color: rgba(9, 18, 31, 0.65);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    cursor: pointer;
}

.lr-input{
    background: #FFF !important;
    width: 100% !important;
    border: 1px solid #83B5FF !important;
    margin-top: 24px;
}

.telecaller-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 24px;
    gap: 8px;
}

.disable-bulk-reassignment {
    opacity: 0.6;
    cursor: not-allowed !important;
}

.telecaller {
    border-radius: 4px;
    background: #E8E9EB;
    display: flex;
    padding: 8px 12px;
    align-items: center;
}

.active-view {
    color: #295BD1;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    border-bottom: 4px solid #1D5FFF;
}

.sub-view-filters-container {
    border-radius: 8px;
    border: 1px solid #AFCFFF;
    background: #FFF;
    margin: 32px 24px 0px;
    padding: 20px 24px;
    display: flex;
    flex-direction: column;
    position: relative
}

.sub-view-header {
    display: flex;
    align-items: center;
    gap: 32px;
}

.single-sub-view {
    cursor: pointer;
}

.sub-views {
    border-radius: 4px;
    background: #F6F6F6;
    padding: 8px 16px;
    gap: 32px;
    display: flex;
    align-items: center;
}

.active-sub-view {
    /* some css */
    display: flex;
    align-items: center;
    justify-content: center;
}

.active-sub-view::before {
    content: '';
    position: absolute;
    top: 0px;
    height: 3px;
    width: 33px;
    background-color: #1D5FFF;
}

.custom-dropdown-box {
    border-radius: 4px;
    border: 1px solid #83B5FF;
    background: #FFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    width: 174px;
}

.custom-dropdown-box > .ant-select-selector {
    border: none !important;
}

.lp-mobile-filter-top-section {
    background-color: #FDFAF9;
    display: flex;
    padding: 12px;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
}

.lp-mobile-filter-top-section > .custom-dropdown-box {
    width: 100%;
}

.new-theme-shadow {
    box-shadow: 2px 4px #AFCFFF;
}

.new-theme-shadow-light {
    box-shadow: 2px 3px #E5ECFF;
}

.filter-view {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 32px;
}

.selected-filters-section {
    display: flex;
    align-items: center;
    gap: 16px;
    /* margin-left: 16px; */
    width: 100%;
}

.selected-filters-box {
    border-radius: 4px;
    border: 1px solid #83B5FF;
    background: #FFF;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    max-width: 75%;
    min-width: 65%;
    flex-wrap: wrap;
}

.leads-filter-item {
    display: flex;
    padding: 4px 8px;
    align-items: center;
    gap: 6px;
    border-radius: 4px;
    background: #E5ECFF;
}

.filter-search-box {
    border-bottom: 1px solid #E8E9EB !important;
    border-radius: 0px !important;
    background: #FFF !important;
}

.filter-search-box > .ant-input {
    background-color: #FFF !important;
    padding-left: 8px !important;
}

.filter-search-box > .ant-input::placeholder {
    color: #838383;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;         
}

.add-filter-btn {
    border-radius: 4px;
    border: 1px solid #1D5FFF;
    background: #FFF;
    display: flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.save-subview {
    border-radius: 4px;
    border: 1px solid #1D5FFF;
    padding: 8px 16px;
    align-items: center;
    gap: 8px;
    display: flex;
    cursor: pointer;
}

.saved-subview {
    background-color: #1D5FFF;
}

.leads-filter-modal > .ant-modal-content, .leads-filter-modal > .ant-modal-content > .ant-modal-body, .leads-filter-modal > .ant-modal-content > .ant-modal-header {
    border-radius: 8px;
    overflow: hidden;
}

.leads-filter-modal > .ant-modal-content {
    width: 800px;
}

@media screen and (min-width: 801px) {
    .leads-filter-modal {
        width: 800px !important;
        display: flex;
        align-items: center;
    }
}

.leads-filter-header {
    background: #FFF;
    box-shadow: 0px 0px 4px 0px rgba(41, 91, 209, 0.20), 0px 0px 16px 0px rgba(41, 91, 209, 0.08);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 24px;
    position: relative;
    height: 64px;
}

.leads-filter-footer {
    background: #FFF;
    box-shadow: 0px 0px 4px 0px rgba(41, 91, 209, 0.20), 0px 0px 16px 0px rgba(41, 91, 209, 0.08);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 16px 24px;
    position: relative;
    height: 64px;
}

.leads-filter-footer-button {
    display: flex !important;
    align-items: center !important;
    gap: 6px !important;
    justify-content: center;
    width: 160px !important;
}

.leads-filter-content {
    background-color: #FDFAF9;
    padding: 32px;
    height: 60vh;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.single-filter-card {
    padding: 16px 22px 24px 24px;
    border-radius: 8px;
    border: 1px solid #E5ECFF;
    background: #FFF;

    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
}

.single-filter-card-header {
    display: flex;
    align-items: center;
}

.selected-subfilter-count {
    border-radius: 20px;
    background: #1D5FFF;
    color: #FFF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    height: 22px;
    width: 22px;
    margin-left: 16px;
}

.single-filter-card-content {
    display: flex;
    align-items: center;
    align-content: center;
    gap: 8px;
    flex-wrap: wrap;
    margin-top: 24px;
}

.new-single-filter-item {
    border-radius: 4px;
    border: 1px solid #AFCFFF;
    display: flex;
    padding: 4px 12px;
    align-items: center;
    cursor: pointer;
}

.new-single-filter-item-selected {
    background: #E5ECFF;
    gap: 4px;
}

.pagination-section {
    margin: 26px 24px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.filter-pagination-lvmc {
    display: flex;
    align-items: center;
    gap: 24px
}

.lvmc-filter {
    display: flex;
    align-items: center;
    gap: 16px;
}

.leads-card-container {
    margin: 40px 24px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 24px;
}

.mobile-extra-width {
    width: auto;
}

.mobile-sortby-dd-filter {
    border-radius: 4px;
    width: 140px;

    .ant-select-selector {
        border: 1px solid #83B5FF !important;
        background: #FFF !important;
    }
}

.no-adjacent-borders {
    width: 240px;
    .ant-select-selector {
        border: 1px solid #83B5FF !important;
        background: #FFF !important;

        border-top: none !important;
        border-left: none !important;
        border-right: none !important;
    }
}

@media screen and (max-width: 800px) {
    .leads-card-container {
        margin: 40px 16px 0px;
    }

    .pagination-section {
        margin: 26px 16px 0px;
        flex-wrap: wrap;
        gap: 16px;
    }

    .sub-view-filters-container {
        margin: 32px 16px 0px;
        padding: 24px 16px;
    }

    .views-container {
        padding: 40px 16px 0px;
    }

    .hero-header {
        padding: 32px 16px 0px;
    }

    .sub-view-header {
        gap: 8px;
    }

    .custom-dropdown-box {
        padding: 2px 12px;
        width: max-content;
        gap: 8px;
    }

    .leads-filter-content { 
        height: calc(100vh - 130px);
    }

    .vahan-pro-component-container > .parentContainer > .containerSS > .childContainer {
        height: 100vh;
    }

    .leads-filter-content {
        padding: 12px;
    }

    .single-filter-card {
        padding: 12px;
    }

    .mobile-extra-width {
        width: 100%;
    }

    .single-filter-card-header {
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        .filter-search-box {
            padding-left: 0px !important;
            margin-left: 0px !important;
        }
    }

    .leads-filter-modal > .ant-modal-content {
        width: 100%;
    }

    .telecaller-list {
        margin-top: 12px;
    }

    .leads-filter-modal > .ant-modal-content, .leads-filter-modal > .ant-modal-content > .ant-modal-body, .leads-filter-modal > .ant-modal-content > .ant-modal-header {
        border-radius: 0px;
        overflow: hidden;
    }

    .mobile-full-height {
        height: calc(100vh - 64px) !important;
    }

    .new-single-filter-item {
        padding: 4px 6px;
    }

    .mobile-sortby-dd-filter {
        width: 75%;
        border-radius: 4px;

        .ant-select-selector {
            border: 1px solid #83B5FF !important;
            background: #FFF !important;
        }
    }
}