.ant-modal-title#rc_unique_0 {
    text-align: left;
    font-size: 18px !important;
    margin: 8px 0 !important;
}

.ant-modal-close-icon {
    color: #FF4D4F !important;
    padding: 5px;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    background: #FFF;
}
.ant-modal-body {
    background: #fafafa;
}
.ant-modal-footer {
    padding: 16px !important;
} 
.feedback-modal-body {
    height: 50vh;
    text-align: left;
}
.ratings {
    font-size: 2.3em !important;
    margin: 10px 0 !important;
}
.ratings .ant-rate-star-second {
    margin-right: 14px;
}