//Regular fonts
.regular12-20 {
    color: var(--light-theme-white, #111);
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    font-family: var(--primary-font-family);
}

.regular14-22 {
    color: var(--light-theme-white, #111);
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    font-family: var(--primary-font-family);
}

.regular16-24 {
    color: var(--light-theme-white, #111);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    font-family: var(--primary-font-family);
}

.regular20-28 {
    color: var(--light-theme-white, #111);
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    font-family: var(--primary-font-family);
}

.regular24-32 {
    color: var(--light-theme-white, #111);
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    font-family: var(--primary-font-family);
}

.regular30-38 {
    color: var(--light-theme-white, #111);
    font-size: 30px;
    font-weight: 400;
    line-height: 38px;
    font-family: var(--primary-font-family);
}

.regular38-46 {
    color: var(--light-theme-white, #111);
    font-size: 38px;
    font-weight: 400;
    line-height: 46px;
    font-family: var(--primary-font-family);
}

.regular46-54 {
    color: var(--light-theme-white, #111);
    font-size: 46px;
    font-weight: 400;
    line-height: 54px;
    font-family: var(--primary-font-family);
}

.regular56-64 {
    color: var(--light-theme-white, #111);
    font-size: 56px;
    font-weight: 400;
    line-height: 64px;
    font-family: var(--primary-font-family);
}

.regular68-76 {
    color: var(--light-theme-white, #111);
    font-size: 68px;
    font-weight: 400;
    line-height: 76px;
    font-family: var(--primary-font-family);
}

// <---------------------------------------------------------------------------------------------------------------------------------------------->
// <---------------------------------------------------------------------------------------------------------------------------------------------->
// <---------------------------------------------------------------------------------------------------------------------------------------------->

//Semibold fonts
.semibold12-20 {
    color: var(--light-theme-white, #111);
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    font-family: var(--primary-font-family);
}

.semibold14-22 {
    color: var(--light-theme-white, #111);
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    font-family: var(--primary-font-family);
}

.semibold16-24 {
    color: var(--light-theme-white, #111);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    font-family: var(--primary-font-family);
}

.semibold20-28 {
    color: var(--light-theme-white, #111);
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    font-family: var(--primary-font-family);
}

.semibold24-32 {
    color: var(--light-theme-white, #111);
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    font-family: var(--primary-font-family);
}

.semibold30-38 {
    color: var(--light-theme-white, #111);
    font-size: 30px;
    font-weight: 600;
    line-height: 38px;
    font-family: var(--primary-font-family);
}

.semibold38-46 {
    color: var(--light-theme-white, #111);
    font-size: 38px;
    font-weight: 600;
    line-height: 46px;
    font-family: var(--primary-font-family);
}

.semibold46-54 {
    color: var(--light-theme-white, #111);
    font-size: 46px;
    font-weight: 600;
    line-height: 54px;
    font-family: var(--primary-font-family);
}

.semibold56-64 {
    color: var(--light-theme-white, #111);
    font-size: 56px;
    font-weight: 600;
    line-height: 64px;
    font-family: var(--primary-font-family);
}

.semibold68-76 {
    color: var(--light-theme-white, #111);
    font-size: 68px;
    font-weight: 600;
    line-height: 76px;
    font-family: var(--primary-font-family);
}

// <---------------------------------------------------------------------------------------------------------------------------------------------->
// <---------------------------------------------------------------------------------------------------------------------------------------------->
// <---------------------------------------------------------------------------------------------------------------------------------------------->

//Bold fonts
.bold12-20 {
    color: var(--light-theme-white, #111);
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    font-family: var(--primary-font-family);
}

.bold14-22 {
    color: var(--light-theme-white, #111);
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    font-family: var(--primary-font-family);
}

.bold16-24 {
    color: var(--light-theme-white, #111);
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    font-family: var(--primary-font-family);
}

.bold18-26 {
    color: var(--light-theme-white, #111);
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    font-family: var(--primary-font-family);
}

.bold20-28 {
    color: var(--light-theme-white, #111);
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    font-family: var(--primary-font-family);
}

.bold24-32 {
    color: var(--light-theme-white, #111);
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    font-family: var(--primary-font-family);
}

.bold30-38 {
    color: var(--light-theme-white, #111);
    font-size: 30px;
    font-weight: 700;
    line-height: 38px;
    font-family: var(--primary-font-family);
}

.bold38-46 {
    color: var(--light-theme-white, #111);
    font-size: 38px;
    font-weight: 700;
    line-height: 46px;
    font-family: var(--primary-font-family);
}

.bold46-54 {
    color: var(--light-theme-white, #111);
    font-size: 46px;
    font-weight: 700;
    line-height: 54px;
    font-family: var(--primary-font-family);
}

.bold56-64 {
    color: var(--light-theme-white, #111);
    font-size: 56px;
    font-weight: 700;
    line-height: 64px;
    font-family: var(--primary-font-family);
}

.bold68-76 {
    color: var(--light-theme-white, #111);
    font-size: 68px;
    font-weight: 700;
    line-height: 76px;
    font-family: var(--primary-font-family);
}

// <---------------------------------------------------------------------------------------------------------------------------------------------->
// <---------------------------------------------------------------------------------------------------------------------------------------------->
// <---------------------------------------------------------------------------------------------------------------------------------------------->

//Text Alignment
.capital-case-txt {
    text-transform: capitalize;
}

.txt-left {
    text-align: left;
}

.txt-center {
    text-align: center;
}

.txt-right {
    text-align: right;
}

// <---------------------------------------------------------------------------------------------------------------------------------------------->
// <---------------------------------------------------------------------------------------------------------------------------------------------->
// <---------------------------------------------------------------------------------------------------------------------------------------------->

//Colours
.primary-orange1 {
    color: rgba(255, 113, 45, 1) !important;
}

.primary-orange2 {
    color: var(--primary-theme-color) !important;
}

.primary-orange3 {
    color: rgba(193, 64, 16, 1) !important;
}

.black85 {
    color: rgba(0, 0, 0, 0.85) !important;
}

.black65 {
    color: rgba(0, 0, 0, 0.65) !important;
}

.black45 {
    color: rgba(0, 0, 0, 0.45) !important;
}

.black25 {
    color: rgba(0, 0, 0, 0.25) !important;
}

.black15 {
    color: rgba(0, 0, 0, 0.15) !important;
}

.black06 {
    color: rgba(0, 0, 0, 0.06) !important;
}

.black04 {
    color: rgba(0, 0, 0, 0.04) !important;
}

.black02 {
    color: rgba(0, 0, 0, 0.02) !important;
}

.plain-white {
    color: rgba(255, 255, 255, 1) !important;
}

.plain-black {
    color: rgba(0, 0, 0, 1) !important;
}


.green-positive {
    color: rgba(75, 207, 63, 1) !important
}

.red-negative {
    color: rgba(255, 77, 79, 1) !important;
}

.blue-secondary1 {
    color: rgba(45, 183, 245, 1) !important;
}

.blue-secondary2 {
    color: rgba(24, 144, 255, 1) !important;
}

.blue-secondary3 {
    color: rgba(47, 84, 235, 1) !important;
}

.blue-new-primary {
    color: rgba(29, 95, 255, 1) !important;
}

.color-252526 {
    color: #252526;
}

.color-848484 {
    color: #848484;
}

.color-838383 {
    color: #838383;
}

.color-2C2C2C {
    color: #2C2C2C;;
}

.color-5A5A5A {
    color: #5A5A5A;;
}

.color-606060 {
    color: #606060;;
}

.color-1D5FFF {
    color: #1D5FFF;
}

.color-FF4D4F {
    color: #FF4D4F;
}

.color-595959 {
    color: #595959;
}

.color-323232 {
    color: #323232;
}

.color-4EBB57 {
    color: #4EBB57;
}

.color-E8BC1D {
    color: #E8BC1D;
}

.color-AFAFAF {
    color: #AFAFAF;
}

.color-FF4545 {
    color: #FF4545;
}

.muted-text {
    color: #6c757d !important;
}

// <---------------------------------------------------------------------------------------------------------------------------------------------->
// <---------------------------------------------------------------------------------------------------------------------------------------------->
// <---------------------------------------------------------------------------------------------------------------------------------------------->


//Text Style
.text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

// <---------------------------------------------------------------------------------------------------------------------------------------------->
// <---------------------------------------------------------------------------------------------------------------------------------------------->
// <---------------------------------------------------------------------------------------------------------------------------------------------->


//Buttons
.vl-primary-btn {
    color: #FFF !important;
    border: 1px solid var(--primary-theme-color) !important;
    border-radius: 2px !important;
    background-color: var(--primary-theme-color) !important;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02) !important;
}

.vl-primary-btn:disabled {
    opacity: 0.3
}

.border-none {
    border: none !important;
}

.vl-secondary-btn {
    color: var(--primary-theme-color);
    border: 1.5px solid var(--primary-theme-color) !important;
    border-radius: 4px !important;
    background-color: transparent !important;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02) !important;
}

.vl-secondary-btn-wo-border {
    color: var(--primary-theme-color);
    border-radius: 4px !important;
    background-color: transparent !important;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02) !important;
}

.vl-secondary-btn:disabled {
    opacity: 0.3
}

// <---------------------------------------------------------------------------------------------------------------------------------------------->
// <---------------------------------------------------------------------------------------------------------------------------------------------->
// <---------------------------------------------------------------------------------------------------------------------------------------------->

//Common tabs 
.gs-tabs-section {
    @media only screen and (min-width: 320px) and (max-width: 786px) {
        .gs-tabs {
            padding: 2vh 1vh !important;
        }
    }


    .gs-tabs {
        padding: 2vw 1vw;

        .ant-select-selection-item {
            color: var(--primary-theme-color);
        }

        .ant-select-arrow {
            color: #000;
        }
    }

    .gs-tabs-container-mobile {
        width: 75%;
    }

    .gs-selected-tab {
        border: 1px solid var(--primary-theme-color);
        color: var(--primary-theme-color);
        font-weight: 700;
    }

    .gs-unselected-tab {
        border: 1px solid rgba(217, 217, 217, 1);
    }
}






// *********************** NEW THEME DESIGN SYSTEM ********************

// ============== FONT STYLES =============

.text-align-start {
    text-align: start !important;
}

.text-align-center {
    text-align: center !important;
}

.inter-bold-12-18 {
    font-family: Inter !important;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
}

.inter-bold-16-18 {
    font-family: Inter !important;
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
}

.inter-bold-20-20 {
    font-family: Inter !important;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
}

.inter-bold-24-18 {
    font-family: Inter !important;
    font-size: 24px;
    font-weight: 700;
    line-height: 18px;
}

.inter-bold-48-58 {
    font-family: Inter !important;
    font-size: 48px;
    font-weight: 700;
    line-height: 58px;
}

.inter-semibold-48-58 {
    font-family: Inter !important;
    font-size: 48px;
    font-weight: 600;
    line-height: 58px;
}

.inter-semibold-40-48 {
    font-family: Inter !important;
    font-size: 40px;
    font-weight: 600;
    line-height: 48px;
}

.inter-semibold-36-38 {
    font-family: Inter !important;
    font-size: 36px;
    font-weight: 600;
    line-height: 38px;
}

.inter-semibold-32-38 {
    font-family: Inter !important;
    font-size: 32px;
    font-weight: 600;
    line-height: 38px;
}

.inter-semibold-28-34 {
    font-family: Inter !important;
    font-size: 28px;
    font-weight: 600;
    line-height: 34px;
}

.inter-semibold-24-28 {
    font-family: Inter !important;
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
}

.inter-semibold-22-28 {
    font-family: Inter !important;
    font-size: 22px;
    font-weight: 600;
    line-height: 28px;
}

.inter-semibold-18-28 {
    font-family: Inter !important;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
}

.inter-semibold-16-24 {
    font-family: Inter !important;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
}

.inter-semibold-14-16 {
    font-family: Inter !important;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
}

.inter-semibold-12-18 {
    font-family: Inter !important;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
}

.inter-semibold-10-18 {
    font-family: Inter !important;
    font-size: 10px;
    font-weight: 600;
    line-height: 18px;
}

.inter-semibold-8-18 {
    font-family: Inter !important;
    font-size: 8px;
    font-weight: 600;
    line-height: 18px;
}

.inter-medium-18-18 {
    font-family: Inter !important;
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
}

.inter-medium-20-18 {
    font-family: Inter !important;
    font-size: 20px;
    font-weight: 500;
    line-height: 18px;
}

.inter-medium-16-24 {
    font-family: Inter !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}

.inter-medium-14-20 {
    font-family: Inter !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 20px;
}

.inter-medium-12-18 {
    font-family: Inter !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    line-height: 18px;
}

.inter-medium-10-20 {
    font-family: Inter !important;
    font-size: 10px !important;
    font-weight: 500 !important;
    line-height: 20px;
}

.inter-medium-8-20 {
    font-family: Inter !important;
    font-size: 8px !important;
    font-weight: 500 !important;
    line-height: 20px;
}

.inter-regular-20-24 {
    font-family: Inter !important;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
}

.inter-regular-16-24 {
    font-family: Inter !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.inter-regular-14-20 {
    font-family: Inter !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.inter-regular-12-16 {
    font-family: Inter !important;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

.inter-regular-10-16 {
    font-family: Inter !important;
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
}

.inter-regular-6-16 {
    font-family: Inter !important;
    font-size: 6px;
    font-weight: 400;
    line-height: 16px;
}

.inter-regular-8-16 {
    font-family: Inter !important;
    font-size: 8px;
    font-weight: 400;
    line-height: 16px;
}

.unbounded-semibold-48-58 {
    font-family: Unbounded;
    font-size: 48px;
    font-weight: 600;
    line-height: 58px;
}

.unbounded-semibold-40-48 {
    font-family: Unbounded;
    font-size: 40px;
    font-weight: 600;
    line-height: 48px;
}

.unbounded-semibold-32-38 {
    font-family: Unbounded;
    font-size: 32px;
    font-weight: 600;
    line-height: 38px;
}

.unbounded-semibold-28-34 {
    font-family: Unbounded;
    font-size: 28px;
    font-weight: 600;
    line-height: 34px;
}

.unbounded-semibold-24-28 {
    font-family: Unbounded;
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
}

.unbounded-semibold-20-24 {
    font-family: Unbounded;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
}

.unbounded-semibold-16-24 {
    font-family: Unbounded;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
}

.unbounded-regular-16 {
    font-family: Unbounded;
    font-size: 16px;
    font-weight: 400;
}

.unbounded-regular-14 {
    font-family: Unbounded;
    font-size: 14px;
    font-weight: 400;
}

.btn-text-semibold-18-24 {
    font-family: Inter !important;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
}

.btn-text-semibold-16-20 {
    font-family: Inter !important;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
}

.btn-text-semibold-14-16 {
    font-family: Inter !important;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
}

.btn-text-semibold-12-16 {
    font-family: Inter !important;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
}

.btn-text-semibold-10-12 {
    font-family: Inter !important;
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
}


// ============== Button STYLES =============

.btn-width-416 {
    width: 416px;
}

.btn-width-285 {
    width: 285px;
}

.btn-width-250 {
    width: 250px;
}

.btn-width-174 {
    width: 174px;
}

.primary-btn-styles-v2 {
    border-radius: 8px !important;
    background-color: #1D5FFF !important;
    border: 1px solid #4B5265 !important;
}

.btn-52h {
    height: 52px !important;
}

.btn-44h {
    height: 44px !important;
}

.btn-36h {
    height: 36px;
}

.btn-32h {
    height: 32px;
}

.width-fit-content {
    width: fit-content !important;
}

.width-max-content {
    width: max-content !important;
}

.width-min-content {
    width: min-content !important;
}

.secondary-btn-styles-v2 {
    border-radius: 8px !important;
    border: 1px solid #1D5FFF !important;
    border-radius: 8px !important;
}

.disabled-btn-v2 {
    border-radius: 8px !important;
    background-color: #C8C9CC !important;
    border: 1px solid #4D4D4D !important;
}

.btn-light-blue {
    border-radius: 8px;
    background-color: #96B4FD !important;
    border: 1px solid #041A4E !important;
}


// ============== Margin STYLES =============

.mt-4 {
    margin-top: 4px !important;
}

.mt-8 {
    margin-top: 8px !important;
}

.mt-12 {
    margin-top: 12px !important;
}
.mt-16 {
    margin-top: 16px !important;
}
.mt-20 {
    margin-top: 20px !important;
}
.mt-24 {
    margin-top: 24px !important;
}

.mt-28 {
    margin-top: 28px !important;
}

.mt-32 {
    margin-top: 32px !important;
}

//Opacity
.opacity04{
    opacity: 0.4;
}

// Flex-gap
.flex-gap-2 {
    gap: 2px !important;
}

.flex-gap-4 {
    gap: 4px !important;
}

.flex-gap-8 {
    gap: 8px !important;
}

.flex-gap-12 {
    gap: 12px !important;
}

.flex-gap-16 {
    gap: 16px !important;
}

.flex-gap-20 {
    gap: 20px !important;
}

.flex-gap-24 {
    gap: 24px !important;
}

.flex-gap-28 {
    gap: 28px !important;
}

.flex-gap-32 {
    gap: 32px !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.user-select-none {
    user-select: none !important;
}

.text-capitalise {
    text-transform: capitalize !important;
}

#form-io {
    padding-bottom: 60px !important;
}
