.account-page-parent {
    background-color: whitesmoke;
    height: 100%;
    width: 100%;

    .ant-divider-horizontal {
        margin: 0px !important;
    }

    .ap-header {
        background-color: white;

        .ap-header-txt {
            text-align: left;
            color: var(--light-theme-neutrals-neutral-1, rgba(0, 0, 0, 0.85));
            font-size: 14px;
            font-weight: 400;
            padding: 2em 0 2em 3em;
        }
    }

    .ap-selected-tab {
        border: 1px solid var(--primary-theme-color);
        color: var(--primary-theme-color);
        font-weight: 700;
    }

    .ap-tabs-section {
        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .ap-tabs {
                padding: 2vh 3vh !important;
            }
        }


        .ap-tabs {
            padding: 2vw 3vw;

            .ant-select-selection-item {
                color: var(--primary-theme-color);
            }

            .ant-select-arrow {
                color: #000;
            }
        }

        .ap-tabs-container-mobile {
            width: 75%;
        }

        .ap-unselected-tab {
            border: 1px solid rgba(217, 217, 217, 1);
        }

        @media only screen and (min-width: 320px) and (max-width: 786px) {
            .ap-lfp-tabs {
                width: 30%;
            }
        }

        .ap-lfp-tabs {
            background-color: rgba(0, 0, 0, 0.06);
            padding: 4px 4px;
            align-items: center;
            justify-content: center;
        }

        .ap-lfp-selected-tab {
            background-color: white;
            padding: 4px 6px;
            border-radius: 2px;
        }

        .ap-lfp-selected-tab-left-margin {
            margin-left: 4px;
        }

        .ap-lfp-selected-tab-right-margin {
            margin-right: 4px;
        }
    }

    @media only screen and (min-width: 320px) and (max-width: 786px) {
        .ap-branding-team-container {
            padding: 2vh 3vh !important;
        }
    }

    .ap-admin-view {
        margin-bottom: 50px;

        .ap-branding-team-container {
            padding: 2vw 3vw;

            @media only screen and (min-width: 320px) and (max-width: 786px) {
                .ap-branding-container {
                    margin: 0 0 2vh 0 !important;
                }
            }

            .ap-branding-container {
                margin: 0 0 2vw 0;
            }

            @media only screen and (min-width: 320px) and (max-width: 786px) {
                .ap-team-container {
                    margin: 2vh 0 0 0 !important;
                }
            }

            .ap-team-container {
                margin: 2vw 0 0 0;
            }
        }

        .ap-account-details-onboarding-container {
            padding: 2vw 3vw;
            padding-bottom: 125px;

            @media only screen and (min-width: 320px) and (max-width: 786px) {
                .ap-account-details-container {
                    margin: 0 0 2vh 0 !important;
                }
            }

            .ap-account-details-container {
                margin: 0 0 2vw 0;
            }

            @media only screen and (min-width: 320px) and (max-width: 786px) {
                .ap-onboarding-details-container {
                    margin: 2vh 0 0 0 !important;
                }
            }

            .ap-onboarding-details-container {
                margin: 2vw 0 0 0;

                .ap-pointer-none {
                    opacity: 0.5;
                    pointer-events: none;
                }

                @media only screen and (min-width: 320px) and (max-width: 786px) {
                    .ap-onboarding-details-edit-btn-container {
                        width: 20% !important;
                    }
                }

                .ap-onboarding-details-edit-btn-container {
                    width: 10%;

                    .ap-onboarding-details-edit-btn {
                        .ap-onboarding-details-edit-btn-icon {
                            font-size: 16px;
                        }
                    }
                }

                .AdditionalInfo1 {
                    padding: 10px 0;

                    .formio-form {
                        .formio-component-panel {
                            margin: 1rem 0 !important;
                        }
                    }
                }

                .ap-onboarding-details-header {
                    flex-direction: column;
                    align-items: flex-start;

                    .ap-onboarding-details-header-icon {
                        font-size: 16px;
                        margin-right: 8px;
                    }

                    @media only screen and (min-width: 320px) and (max-width: 786px) {
                        .ap-onboarding-details-sub-txt {
                            max-width: 85%;
                        }
                    }

                    .ap-onboarding-details-sub-txt {
                        margin-top: 4px;
                        text-align: left;
                    }
                }
            }
        }
    }
}

.ap-footer {
    position: sticky;
    bottom: var(--account-page-footer-bottom);
    left: 0;
    right: 0;
    width: 100%;
    height: 50px;
    background-color: #FFF;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    padding: 2vw 2vw;
    z-index: 10;

    .ap-tc-txt {
        color: var(--primary-theme-color);
        font-size: 16px;
        font-weight: 700;
        text-decoration-line: underline;
    }

    .ap-footer-btns-container {
        .ap-cancel-btn {
            width: 30%;
        }

        .ap-submit-btn {
            margin-left: 4%;
            width: 48%;

            .ap-submit-btn-loader {
                color: white;
                margin-left: 8px;
            }
        }
    }
}

@media only screen and (min-width: 320px) and (max-width: 786px) {
    .ap-footer {
        padding: 2vh 2vh !important;
        position: fixed !important;
        bottom: var(--account-page-footer-bottom-mobile) !important;
    }
}