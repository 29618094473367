.progress-bar {
    display: flex;
    justify-content: flex-start;
    margin: 10px;
    .ant-card-body {
      padding: 15px;
      width: 100%;
    }
    .progress-bar-header {
      display: flex;
      font-family: var(--primary-font-family);
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
    }
    .progress-bar-images {
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: 10px;
      .road {
        width: 100%;
        height: 2px;
        background-color: #F0F0F0;
      }
      .current {
        background-color: #BFBFBF;
      }
    }
    .progress-bar-content {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      margin-top: 3em;
      .ant-timeline{
        flex-grow: 1;
        flex-shrink:1;
        flex-basis: auto
      }
    }
    .ant-collapse {
      background: #fff;
      border: none;
      .ant-collapse-item {
        border: none;
      }
    }
    .progress-bar-get-started {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 5px 16px;
      gap: 8px;
      width: 326px;
      height: 32px;
      background: var(--primary-theme-light);
      border: 1px solid var(--primary-theme-color);
      border-radius: 2px;
      font-family: var(--primary-font-family);
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      color: var(--primary-theme-color);
    }
    .progress-bar-main-content {
      display: flex;
      flex-direction:row;
      justify-content:space-between;
      align-items: center;
      gap: 30px;
    }
    img {
      height: 100px;
    }
  }

  //Mobile to table -> write common css for mobile
@media only screen and (min-width: 320px) and (max-width: 786px){
  .progress-bar {
    .progress-bar-main-content {
      display: flex;
      flex-direction:column;
      justify-content:space-between;
      align-items: flex-start;
    }
  }
}
